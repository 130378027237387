import { map } from 'rxjs/operators';
import { Injectable } from "@angular/core";
import { BehaviorSubject, from, Observable, Subject } from "rxjs";
import { Hyperlink } from "src/app/models/hyperlink.model";
import { NewsAttachment } from 'src/app/models/news-attachment';
import { NewsEntry } from "src/app/models/news-entry.model";
import { LanguageService } from "src/app/services/language.service";
import { ColumnMapEntry, GraphService } from './graph.service';
import { compareDates } from 'src/app/compare-dates';
import { ResourceNames } from "./resource-names";
import { split } from 'core-js/fn/symbol';

@Injectable({
	providedIn: 'root'
})
export class GraphNewsService {

	static listTitle = ResourceNames.NEWS_LIST_NAME;
	static viewTitle = 'News'
	static allowedExtensions = ['docx','doc','xlsx','xls','pdf','csv','txt'];

	static readonly attachmentcolumnMap: ReadonlyArray<ColumnMapEntry<NewsAttachment>> = [
		{ modelKey: "fileId", spFriendlyFieldName: "UniqueId" },
		{ modelKey: "fileUrl", spFriendlyFieldName: "ServerRelativeUrl" },
		{ modelKey: "fileName", spFriendlyFieldName: "Name" },
	];

	static readonly columnMap: ReadonlyArray<ColumnMapEntry<NewsEntry>> = [
		{ modelKey: "ID", spFriendlyFieldName: "ID" },
		{ modelKey: "title", spFriendlyFieldName: "Title" },
		{ modelKey: "author", spFriendlyFieldName: "Author0", transformer: x => x ? new Hyperlink(x.Url, x.Description) : null },
		{ modelKey: "content", spFriendlyFieldName: "Content" },
		{ modelKey: "Attachments", spFriendlyFieldName: "Attachments" },
		{ modelKey: "expiryDate", spFriendlyFieldName: "ExpiryDate" },
		{ modelKey: "activeDate", spFriendlyFieldName: "ActiveDate" },
		{ modelKey: "languageCode", spFriendlyFieldName: "Language" },
	];

	newsList: BehaviorSubject<NewsEntry[]> = new BehaviorSubject<NewsEntry[]>([]);

	constructor(
		private graphService: GraphService,
		private languageService: LanguageService
	) {
		// filter the sharepoint data using the current language according to the language service
	}

	getNewsEntries(): Observable<NewsEntry[]> {
		if (this.newsList.value.length > 0) {
			return this.newsList;
		}

		const entities = this.graphService.getListItems<NewsEntry>(GraphNewsService.listTitle, NewsEntry, GraphNewsService.columnMap);
		// filter the sharepoint data using the current language according to the language service

		this.languageService
			.filterByLanguage(from(entities))
			.pipe(map(
				x => x.sort(
					(a, b) => compareDates(a.activeDate, b.activeDate)
				)
			)).subscribe((result) => {
				this.newsList.next(result);
			});

		return this.newsList;
	}

	getNewsAttachments(atchId: string): Observable<NewsAttachment[]> {

		const subj$ = new Subject<NewsAttachment[]>();

		this.graphService.getListAttachments<NewsAttachment>(GraphNewsService.listTitle, atchId, NewsAttachment, GraphNewsService.attachmentcolumnMap).subscribe(result => {
			const filteredResult = [];
			result.forEach(a=>{
				let splitfile = a.fileName.split(".");
				let extension = "";
				if(splitfile.length > 1)
				{
					extension = splitfile[splitfile.length-1];
					if(GraphNewsService.allowedExtensions.indexOf(extension.toLowerCase()) > 0)
					{
						filteredResult.push(a);
					}
				}
			})
			subj$.next(filteredResult);
		});
		return subj$;

	}
}
