import { CollectionViewer, DataSource } from '@angular/cdk/collections';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { catchError, finalize, map } from 'rxjs/operators';
import { DataFilesManagementService } from 'src/app/services/graph/data-files-management.service';
import { Constants, FileUploadStatuses } from 'src/app/services/graph/resource-names';
import { ApprovalWorkflowService } from 'src/app/services/approval-workflow.service';
import { ReportRefreshViewModel } from 'src/app/view-models/report-refresh-view-model';

export class RefreshReportsDataSource implements DataSource<ReportRefreshViewModel> {

    private reportRefreshSubject = new BehaviorSubject<ReportRefreshViewModel[]>([]);
    private loadingSubject = new BehaviorSubject<boolean>(false);

    public loading$ = this.loadingSubject.asObservable();
	public reports: ReportRefreshViewModel[]
	private allReports: ReportRefreshViewModel[]
	
	constructor(private workflowService: ApprovalWorkflowService, private dataFilesService: DataFilesManagementService) {}

	connect(collectionViewer: CollectionViewer): Observable<ReportRefreshViewModel[]> {
		return this.reportRefreshSubject.asObservable();
	}

	disconnect(collectionViewer: CollectionViewer): void {
		this.reportRefreshSubject.complete();
		this.loadingSubject.complete();
	}

	loadReports(filter, sortColumn = 'subjectAreaTitle', sortDirection = 'asc', pageIndex = 0, pageSize = 10) {
		this.loadingSubject.next(true);

		combineLatest([
			this.workflowService.getReports().pipe(
				map(data => {
					let reports = this.allReports = data as ReportRefreshViewModel[];
					reports = reports.filterDataFiles(filter).sortDataFiles(sortColumn, sortDirection).slice(pageSize * pageIndex, pageSize * pageIndex + pageSize);
					return reports;
				}),
				catchError(() => of([] as ReportRefreshViewModel[])),
				finalize(() => this.loadingSubject.next(false))
			),
				this.dataFilesService.getDataFiles()
			])
		.subscribe(([reports, dataFiles]) => {
			for (const report of reports) {
					const eligibleFiles = dataFiles.filter(item => item.subjectAreaCode === report.subjectAreaCode && (item.status === FileUploadStatuses.UAT_OK || item.status === FileUploadStatuses.PROD_UPLOADED)  && item.uploadedDate > report.reportRefreshedDate);
					report.providedFileName = eligibleFiles.map(x => x.name).join(Constants.SEPARATOR_SEMICOLON);
					report.uploadedDate = eligibleFiles.map(x => new Date(x.uploadedDate).toLocaleDateString("en-US", { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit', hour12: false })).join(Constants.SEPARATOR_SEMICOLON);
					report.sharedWith = this.getSharedReports(report.subjectAreaCode);
			}
			this.reports = reports;
			
			this.reportRefreshSubject.next(reports)
		});
	}

	getSharedReports(subjectAreaCode: string) {
		const sharedReports = this.allReports.filter(x => x.subjectAreaCode === subjectAreaCode);
		return sharedReports.length > 1 ? sharedReports.map(x => x.reportName).join(", ") : "";
	}
}