import { Router } from '@angular/router';
import { Component, EventEmitter, Output } from '@angular/core';
import { ThemeService } from 'src/app/shared/services/theme.service';

@Component({
	selector: 'app-beta-reports-slidein',
	templateUrl: './beta-reports-slidein.component.html',
	styleUrls: ['./beta-reports-slidein.component.scss']
})
export class BetaReportsSlideinComponent {
	@Output() closed = new EventEmitter<boolean>();

	constructor(
		public themeService: ThemeService,
		private router: Router,
	) {}

	CloseBetaPanel() {
		this.closed.emit(true);
	}

	OpenBetaReports() {
		this.CloseBetaPanel();
		this.router.navigate(['/categories/category', { categoryKey: 'beta' }]);
	}
}
