import { Hyperlink } from "./hyperlink.model";
import { TypeName } from "src/app/type-name";
import { getPropertyNames } from "src/app/property-names";
import { HasLanguageCode } from "../services/language.service";

@TypeName.decorate("Video")
export class Video implements HasLanguageCode {
	public constructor(
		public readonly id          : null | string    = null,
		public readonly url         : null | Hyperlink = null,
		public readonly group       : null | string    = null,
		public readonly fileType    : null | string    = null,
		public readonly languageCode: null | string    = null,
		public readonly fileref: null | string    = null,
	) {}

	public static readonly DefaultPropertyNames = getPropertyNames(new Video());
}