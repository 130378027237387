import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { UserAuditSteps } from 'src/app/services/graph/resource-names';
import { UserAuditService } from 'src/app/services/graph/user-audit.service';

@Component({
  selector: 'app-user-audit',
  templateUrl: './user-audit.component.html',
  styleUrls: ['./user-audit.component.scss']
})
export class UserAuditComponent {

	translations: Record<keyof typeof UserAuditSteps, UserAuditTranslation>;
	activeStep: UserAuditSteps;
	currentTranslation: UserAuditTranslation;

	constructor(
		private translateService: TranslateService,
		private userAuditService: UserAuditService,
	) {
		this.userAuditService.getActiveStep().subscribe(
			(step: UserAuditSteps) => { 
				this.activeStep = step;
				this.initTranslations();
			}
		);

		this.translateService.onLangChange.subscribe(() => this.initTranslations());
	};

	getCurrentAuditStep(): UserAuditSteps {
		return UserAuditSteps.StepOne;
	}

	onAuditAction(): void {
		this.activeStep = undefined;
	}

	private initTranslations(): void {
		this.translations =  {
			"StepOne": new UserAuditTranslation(
				this.translateService.instant("UserAudit.StepOne.Title"),
				this.translateService.instant("UserAudit.StepOne.Legend"),
				this.translateService.instant("UserAudit.StepOne.ApproveButtonTitle"),
				this.translateService.instant("UserAudit.StepOne.RejectButtonTitle"),
				this.translateService.instant("UserAudit.StepOne.ApproveLegend"),
				this.translateService.instant("UserAudit.StepOne.RejectLegend"),
				this.translateService.instant("UserAudit.StepOne.ApproveButtonTooltip"),
				this.translateService.instant("UserAudit.StepOne.ApproveSuccessfully"),
				this.translateService.instant("UserAudit.StepOne.UploadedSuccessfully"),
			),
			"StepTwo": new UserAuditTranslation(
				this.translateService.instant("UserAudit.StepTwo.Title"),
				this.translateService.instant("UserAudit.StepTwo.Legend"),
				this.translateService.instant("UserAudit.StepTwo.ApproveButtonTitle"),
				this.translateService.instant("UserAudit.StepTwo.RejectButtonTitle"),
				this.translateService.instant("UserAudit.StepTwo.ApproveLegend"),
				this.translateService.instant("UserAudit.StepTwo.RejectLegend"),
				this.translateService.instant("UserAudit.StepTwo.ApproveButtonTooltip"),
				this.translateService.instant("UserAudit.StepTwo.ApproveSuccessfully"),
				this.translateService.instant("UserAudit.StepTwo.UploadedSuccessfully"),
			),
		};

		this.currentTranslation = this.translations[this.activeStep];
	}
}

export class UserAuditTranslation {
	constructor (
		public title: string,	
		public legend: string, 
		public approveButtonTitle: string, 
		public rejectButtonTitle: string, 
		public approveLegend: string, 
		public rejectLegend: string,
		public approveButtonTooltip: string,
		public approveSuccessfully: string,
		public uploadedSuccessfully: string,
	) {}
}