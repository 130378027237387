import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject, Subscription } from 'rxjs';
import { LanguageService } from 'src/app/services/language.service';
import { Subscriptions } from 'src/app/subscriptions';
import { GraphService } from './services/graph/graph.service';
import { GraphUserSettingsService } from './services/graph/user-settings.service';
import { GraphUserService } from './services/graph/user.service';
import { MsalBroadcastService, MsalService } from './services/msal';
import { filter, takeUntil } from 'rxjs/operators';

const SupportedBrowsersVersions = {
	"Chrome" : 71,
	"Firefox": 64,
	"Safari" : 12,
	"Edge"	 : 79
}
/**
 * Checks if browser and version is supported.
 * Returns true is supported, false otherwise.
 */
function isBrowserSupported() {
	const current: IBrowserInfo = getBrowserInfo();
	const supportedVersion = SupportedBrowsersVersions[current.browser] || 1000000;

	return +current.version >= supportedVersion;
}

function getBrowserInfo(): IBrowserInfo {
	const navigator = window.navigator as any;
	const userAgentData = navigator.userAgentData;
	if (userAgentData) {
		const brands = userAgentData.brands;
		if (brands.map(x => x.brand).includes("Microsoft Edge")) {
			return { browser: "Edge", version: brands.find(x => x.brand.includes("Microsoft Edge"))?.version };
		} else if(brands.map(x => x.brand).includes("Opera")) {
			return { browser: "Opera", version: brands.find(x => x.brand.includes("Opera"))?.version };
		} else if(brands.map(x => x.brand).includes("Google Chrome")) {
			return { browser: "Chrome", version: brands.find(x => x.brand.includes("Google Chrome"))?.version };
		} else if (brands.map(x => x.brand).includes("Chromium")) {
			return { browser: "Chrome", version: brands.find(x => x.brand.includes("Chromium"))?.version };
		}
	} else {
		console.info("navigator.userAgentData is not supported, using deprecated navigator.userAgent");
		return getBrowserInfoDeprecated();
	}
}

function getBrowserInfoDeprecated(): IBrowserInfo {
	const userAgent = navigator.userAgent;

	let temp: RegExpMatchArray;
	let info: IBrowserInfo;
	let match = userAgent.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];

	if (/(trident|msie)/i.test(match[1])) { // IE
		let version =  /\brv[ :]+(\d+)/g.exec(userAgent) || [];
		info = { browser: 'IE', version: version[1] || '' };
	} else if (match[1] === 'Chrome' && /\b(OPR)\/(\d+)/.test(userAgent)){ // Opera
			info = { browser: 'Opera', version: "0" };
	} else if (match[1] === 'Chrome' && /\b(Edg)\/(\d+)/.test(userAgent)){ // Edge
			let version = userAgent.match(/Edg\/(\d+)/i) || [];
			info = { browser: 'Edge', version: version[1] || "" };
	} else { // any other
		match = match[2] ? [match[1], match[2]]: [navigator.appName, navigator.appVersion, '-?'];
		if ((temp = userAgent.match(/version\/(\d+)/i)) !=  null) {
			match.splice(1, 1, temp[1]);
		}
		info = { browser: match[0], version: match[1] };
	}

	return info;
}

function checkIsSupported() {
	const isSupported = isBrowserSupported();
	localStorage.setItem("IsBrowserSupported", String(isSupported));
}

export let isBrowserRefreshed = false;

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})


export class AppComponent implements OnInit, OnDestroy {

	private routerSub: Subscription;
	private readonly subscriptions = new Subscriptions();
	loggedIn = false;
	isReportView = false;
	isIframe = false;
	showLogin = false;
	private readonly _destroying$ = new Subject<void>();
	constructor(
		@Inject(DOCUMENT) private readonly document: Document,
		private readonly languageService: LanguageService,
		public translateService: TranslateService,
		private broadcastService: MsalBroadcastService,
		private router: Router,
		private graphService: GraphService,
		private userService: GraphUserService,
		private userSettingsService: GraphUserSettingsService,
		private authService: MsalService
	) {
		this.showLogin = !(!!this.authService.getAccount()); // check if user is logged in. No need to render sign-in
		translateService.addLangs(['en', 'fr']);
		translateService.setDefaultLang('en');
		translateService.use('en');
		this.document.documentElement.setAttribute("lang", this.translateService.currentLang); // <html lang="en">

		this.routerSub = router.events.subscribe((event) => {
			if (event instanceof NavigationStart) {
			  isBrowserRefreshed = !router.navigated;
			}
		});
	}

	ngOnInit() {
		this.isIframe = window !== window.parent && !window.opener;
		this.router.events.subscribe(e => {
			if (e instanceof NavigationEnd) {
				this.isReportView = e.url.startsWith("/report-view/");
			}});

		checkIsSupported();
		
		// initialize global translation service
		this.translateService.setDefaultLang(LanguageService.defaultLanguageCode);
		this.subscriptions.push(
			this.languageService.currentLanguageCode.subscribe(x => this.setLanguage(x))
		);

		// listen to the sign-in to notify that login has completed
		this.broadcastService.msalSubject$
		.pipe(
			filter(msg => msg.type === 'osims:loginSuccess'),
			takeUntil(this._destroying$)
		).subscribe(() => this.initProfile());

		if (!this.showLogin) { // already logged in so just load the profile
			this.initProfile();
		}
	}

	initProfile() {
		this.loadProfile().subscribe(authorized => {
			if (!authorized) { // unauthorized user will end up here
				this.showLogin = true;
				this.router.navigateByUrl('sign-up');
			}
			else // authorized user will end up here
			{
				this.loggedIn = true;
			}
		});
	}

	loadProfile(): Observable<boolean> {
		const sub$ = new Subject<boolean>();
		this.graphService.getMe().subscribe((user => {
			this.userService.setUser(user);
			// load the settings only if the user is authorized
			if (user.authorized) {
				this.userSettingsService.init().subscribe((result) => {
					sub$.next(user.authorized);
				});
			}
			else {
				sub$.next(user.authorized);
			}
		}));
		return sub$.asObservable();
	}

	private setLanguage(newLanguageCode: string): void {
		// triggers a change of language through the translate service, including static text keys piped through `translate`
		this.translateService.use(newLanguageCode);
		this.document.documentElement.setAttribute("lang", newLanguageCode); // <html lang="en">
	}

	ngOnDestroy(): void {
		this._destroying$.next(null);
		this._destroying$.complete();
		this.routerSub.unsubscribe();
	  }
}

interface IBrowserInfo {
	browser: string,
	version: string
}