<mat-sidenav-container fxFlex="0 1 auto" [ngClass]="{'app-light-theme': !themeService.isDark, 'app-dark-theme': themeService.isDark}">
	<div class="table-container">
		<table mat-table [dataSource]="dataSource">
			<ng-container matColumnDef="reportName">
				<th mat-header-cell *matHeaderCellDef> {{'ApprovalWorkflow.ColumnName' | translate}} </th>
				<td mat-cell *matCellDef="let element"> {{element.reportName}} </td>
			</ng-container>
			<ng-container matColumnDef="status">
				<th mat-header-cell *matHeaderCellDef> {{'ApprovalWorkflow.ColumnStatus' | translate}} </th>
				<td mat-cell *matCellDef="let element"> {{element.status}}</td>
			</ng-container>
			<ng-container matColumnDef="statusDate">
				<th mat-header-cell *matHeaderCellDef> {{'ApprovalWorkflow.ColumnDate' | translate}} </th>
				<td mat-cell *matCellDef="let element"> {{element.statusDate | date:'MMMM d, y, HH:mm' }}</td>
			</ng-container>
<!-- workflow statuses -->
			<ng-container matColumnDef="status_uploaded">
				<th mat-header-cell *matHeaderCellDef class="workflow-header"> {{'ApprovalWorkflow.Status_UatProcessed' | translate}} </th>
				<td mat-cell *matCellDef="let element">
					<mat-icon [ngClass]="getStatusClass(element.status, 'status_uploaded')">add_task</mat-icon>
				</td>
			</ng-container>
			<ng-container matColumnDef="status_uat_refreshed">
				<th mat-header-cell *matHeaderCellDef class="workflow-header"> {{'ApprovalWorkflow.Status_UatRefreshed' | translate}} </th>
				<td mat-cell *matCellDef="let element">
					<mat-icon [ngClass]="getStatusClass(element.status, 'status_uat_refreshed')">published_with_changes</mat-icon> 
				</td>
			</ng-container>
			<ng-container matColumnDef="status_uat_approved">
				<th mat-header-cell *matHeaderCellDef class="workflow-header"> {{'ApprovalWorkflow.Status_UatApproved' | translate}} </th>
				<td mat-cell *matCellDef="let element">
					<mat-icon [ngClass]="getStatusClass(element.status, 'status_uat_approved')">done_all</mat-icon> 
				</td>
			</ng-container>
			<ng-container matColumnDef="status_promoted">
				<th mat-header-cell *matHeaderCellDef class="workflow-header"> {{'ApprovalWorkflow.Status_ProdPromoted' | translate}}</th>
				<td mat-cell *matCellDef="let element">
					<mat-icon [ngClass]="getStatusClass(element.status, 'status_promoted')">publish</mat-icon> 
				</td>
			</ng-container>
			<ng-container matColumnDef="status_prod_refreshed">
				<th mat-header-cell *matHeaderCellDef class="workflow-header"> {{'ApprovalWorkflow.Status_ProdRefreshed' | translate}} </th>
				<td mat-cell *matCellDef="let element">
					<mat-icon [ngClass]="getStatusClass(element.status, 'status_prod_refreshed')">published_with_changes</mat-icon> 
				</td>
			</ng-container>
<!-- /workflow statuses -->
			<ng-container matColumnDef="actions">
				<th mat-header-cell *matHeaderCellDef> {{'DownloadDataset.Action' | translate}} </th>
				<td mat-cell *matCellDef="let element">
					<mat-chip-list>
						<mat-chip><a routerLink="/data-files-management" routerLinkActive="active" [queryParams]="{env: env, report: element.reportCode}">{{(isProd() ? 'ApprovalWorkflow.Action_ViewFiles' : 'ApprovalWorkflow.Action_ManageFiles') | translate}}</a></mat-chip>
						<mat-chip *ngIf="canRefreshUat(element.status)"><a routerLink="/reports-refresh" routerLinkActive="active" [queryParams]="{report: element.reportCode}">{{'ApprovalWorkflow.Action_RefreshUatReport' | translate}}</a></mat-chip>
						<mat-chip *ngIf="canApprove(element.status)" (click)="onUatApprove(element)">{{'ApprovalWorkflow.Action_ApproveUatData' | translate}}</mat-chip>
						<mat-chip *ngIf="canPromote(element.status)"><a routerLink="/uat-transfer" routerLinkActive="active" [queryParams]="{report: element.reportCode}">{{'ApprovalWorkflow.Action_TransferFromUat' | translate}}</a></mat-chip>
						<mat-chip *ngIf="canRefreshProd(element.status)" (click)="onProdRefresh(element)">{{'ApprovalWorkflow.Action_RequestProdRefresh' | translate}}</mat-chip>
						<mat-chip *ngIf="isReadyForProd(element.status)" (click)="onProdRedirect()">{{'ApprovalWorkflow.Action_TransferFromUat' | translate}}</mat-chip>
					</mat-chip-list>
				</td>
			</ng-container>
		
			<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
			<tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
		</table>
	</div>
</mat-sidenav-container>
