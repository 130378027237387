import { CollectionViewer, DataSource } from '@angular/cdk/collections';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, finalize, map, take } from 'rxjs/operators';
import { DataFileModel } from 'src/app/models/data-file.model';
import { SubjectArea } from 'src/app/models/subject-area.model';
import { DataFilesManagementService } from 'src/app/services/graph/data-files-management.service';
import { DataFileViewModel } from 'src/app/view-models/data-file-view.model';

export class DataFilesDataSource implements DataSource<DataFileViewModel> {

    private dataFilesSubject = new BehaviorSubject<DataFileViewModel[]>([]);
    private loadingSubject = new BehaviorSubject<boolean>(false);

	private userIds: Record<number, string> = {};

    public loading$ = this.loadingSubject.asObservable();
	public dataFiles: DataFileViewModel[]
	public filteredCount: number;
	
	constructor(private dataFilesService: DataFilesManagementService, private subjectAreas: SubjectArea[]) {}

	connect(collectionViewer: CollectionViewer): Observable<DataFileViewModel[]> {
		return this.dataFilesSubject.asObservable();
	}

	disconnect(collectionViewer: CollectionViewer): void {
		this.dataFilesSubject.complete();
		this.loadingSubject.complete();
	}

	loadDataFiles(filter = '', sortColumn = 'id', sortDirection = 'desc', pageIndex = 0, pageSize = 5) {
		this.loadingSubject.next(true);

		this.dataFilesService.getDataFiles().pipe(
			map(async data => {
				let files = data as DataFileViewModel[];

				for (const item of files) {
					item.subjectAreaName = this.subjectAreas.find(x => x.subjectAreaCode === item.subjectAreaCode).subjectAreaTitle;
					item.createdBy = await this.getUserNameById(+item.createdBy);
				}
				files = files.filterDataFiles(filter).sortDataFiles(sortColumn, sortDirection);
				this.filteredCount = files.length;
				this.dataFiles = files.slice(pageSize * pageIndex, pageSize * pageIndex + pageSize);
				return this.dataFiles;
			}),
			catchError(() => of([])),
			finalize(() => this.loadingSubject.next(false))
		)
		.subscribe(async dataFiles => this.dataFilesSubject.next(await dataFiles));
	}

	getDataFile(fileId: number): Observable<DataFileModel> {
		return this.dataFilesService.getDataFiles().pipe(
			map(data => data.find(x => x.id === fileId)));
	}

	async getUserNameById(userId: number): Promise<string> {
		return this.userIds[userId] ? this.userIds[userId] : await this.loadUserById(userId);
	}

	loadUserById(userId: number): Promise<string> {
		return this.dataFilesService.getUserNameById(userId).pipe(take(1), map(userName => {
			this.userIds[userId] = userName;
			return userName;
		})).toPromise();
	}
}

declare global {
	interface Array<T> {
		sortDataFiles(colName, direction): Array<T>;
		filterDataFiles(filter): Array<T>;
	}
}

if (!Array.prototype.sortDataFiles) {
	Array.prototype.sortDataFiles = function<T>(colName, direction): T[] {
		if (!colName) {
			return this;
		}
		const isAsc = direction === 'asc';
		const data = this.slice();
		return data.sort((a, b) => {
			switch (colName) {
				case 'name': return compare(a.name, b.name, isAsc);
				case 'id': return compare(a.id, b.id, isAsc);
				case 'status': return compare(a.status, b.status, isAsc);
				case 'reportName': return compare(a.reportName, b.reportName, isAsc);
				case 'reportCode': return compare(a.reportCode, b.reportCode, isAsc);
				case 'subjectAreaName': return compare(a.subjectAreaName, b.subjectAreaName, isAsc);
				case 'comment': return compare(a.comment, b.comment, isAsc);
				case 'createdBy': return compare(a.createdBy, b.createdBy, isAsc);
				case 'uploadedDate': return compare(a.uploadedDate, b.uploadedDate, isAsc);
				case 'subjectAreaTitle': return compare(a.subjectAreaTitle, b.subjectAreaTitle, isAsc);
				case 'sourceFileName': return compare(a.sourceFileName, b.sourceFileName, isAsc);
				case 'reportRefreshedDate': return compare(a.reportRefreshedDate.toString(), b.reportRefreshedDate.toString(), isAsc);
				default: return 0;
			}
		});
	}
}

if (!Array.prototype.filterDataFiles) {
	Array.prototype.filterDataFiles = function<T>(filter): T[] {
		if (!filter) {
			return this;
		}
		let data = this.slice();
		for (const filterPair of filter.split(",")) {
			const filter = filterPair.split(":");
			data = data.filter(item => item[filter[0]].toString().toLowerCase().includes(filter[1].toLowerCase()))
		}
		return data;
	}
}

function compare(a: number | string, b: number | string, isAsc: boolean) {
	return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}