<ng-container *ngIf="!institutionName">
	<h1 id="maincontent" tabindex="0">{{ 'Factbooks.PIRL.Title2' | translate }}</h1>
	<p tabindex="0">{{ 'Factbooks.PIRL.Subtitle' | translate }}</p>
</ng-container>
<mat-tab-group 
	*ngIf="!institutionName && showAllInstitutions" 
	mat-align-tabs="center" 
	[selectedIndex]="colleges.length > 0 ? 0 : 1"
	(selectedTabChange)="onTabChanged($event)"
	[class]="{'app-light-theme': !themeService.isDark, 'app-dark-theme': themeService.isDark}"
>
	<mat-tab label="{{ 'Factbooks.Colleges' | translate}}" [disabled]="colleges.length == 0" tabindex="0">
		<div class="factbook-sort-by" fxLayout="row" fxLayoutAlign="end center">
			<label id="sortby-label">{{ 'Common.Controls.SortBy.Title' | translate }}</label>
			<mat-form-field appearance="outline" aria-labelledby="sortby-label">
				<mat-select [formControl]="sortCollegesControl" [(ngModel)]="sortCollegesValue" (selectionChange)="onSortColleges()">
					<mat-option *ngFor="let sortby of sortBys" [value]="sortby.value">
						{{ sortby.viewValue }}
					</mat-option>
				</mat-select>
			</mat-form-field>
		</div>
		<div fxLayout="row wrap" fxLayoutAlign="center space-between">
			<mat-card fxLayout="column" fxLayoutAlign="center center" *ngFor="let college of colleges; let i = index">
				<div class="icon-container">
					<img title="{{college.name}}" alt="{{college.name}} college" src="{{ college.logoImageSource.address }}" />
				</div>
				<mat-divider></mat-divider>
				<div class="report-selector">
					<span class="view-factbooks">{{'Factbooks.PIRL.ViewFor' | translate}} {{college.name}}</span>
					<mat-form-field appearance="outline" [attr.aria-label]="'Factbooks.PIRL.ReportTypeAriaLabel' | translate" class="report-list">
						<mat-select 
							[(ngModel)]="college.selectedReportTypeCode" 
							placeholder="{{'Factbooks.PIRL.ReportTypePlaceholder' | translate}}"
							aria-label="{{'Factbooks.PIRL.ReportTypePlaceholder' | translate}} for {{college.name}}"
						>
							<mat-option *ngFor="let type of college.reportTypes" [value]="type.code">
								{{ type.title }}
							</mat-option>
						</mat-select>
					</mat-form-field>
					<button 
						routerLink="/factbooks" 
						[queryParams]="{ institution: college.name, selectedReportType: college.selectedReportTypeCode }" 
						mat-stroked-button 
						color="primary"
						[disabled]="!college.selectedReportTypeCode"
					>
						<mat-icon>arrow_right_alt</mat-icon>{{'Factbooks.PIRL.ViewFactbooks' | translate}}
					</button>
				</div>	
			</mat-card>
		</div>
	</mat-tab>

	<mat-tab label="{{ 'Factbooks.Universities' | translate}}" [disabled]="universities.length == 0">
		<div class="factbook-sort-by" fxLayout="row" fxLayoutAlign="end center">
			<label id="sortby-label">{{ 'Common.Controls.SortBy.Title' | translate }}</label>
			<mat-form-field appearance="outline" aria-labelledby="sortby-label">
				<mat-select [formControl]="sortUniversitiesControl" [(ngModel)]="sortUniversitiesValue" (selectionChange)="onSortUniversities()">
					<mat-option *ngFor="let sortby of sortBys" [value]="sortby.value">
						{{ sortby.viewValue }}
					</mat-option>
				</mat-select>
			</mat-form-field>
		</div>
		<div fxLayout="row wrap" fxLayoutGap="20px" fxLayoutAlign="center space-between">
			<mat-card fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px" *ngFor="let university of universities; let i = index">
				<div class="icon-container">
					<img title="{{university.name}}" alt="{{university.name}} university" src="{{ university.logoImageSource.address }}" />
				</div>
				<mat-divider></mat-divider>
				<div class="report-selector">
					<span class="view-factbooks">{{'Factbooks.PIRL.ViewFor' | translate}} {{university.name}}</span>
					<mat-form-field appearance="outline"  [attr.aria-label]="'Factbooks.PIRL.ReportTypeAriaLabel' | translate" class="report-list">
						<mat-select 
							[(ngModel)]="university.selectedReportTypeCode" 
							placeholder="{{'Factbooks.PIRL.ReportTypePlaceholder' | translate}}"
							aria-label="{{'Factbooks.PIRL.ReportTypePlaceholder' | translate}} for {{university.name}}"
						>
							<mat-option *ngFor="let type of university.reportTypes" [value]="type.code">
								{{ type.title }}
							</mat-option>
						</mat-select>
					</mat-form-field>
					<button 
						routerLink="/factbooks" 
						[queryParams]="{ institution: university.name, selectedReportType: university.selectedReportTypeCode }" 
						mat-stroked-button 
						color="primary"
						[disabled]="!university.selectedReportTypeCode"
					>
						<mat-icon>arrow_right_alt</mat-icon>{{'Factbooks.PIRL.ViewFactbooks' | translate}}
					</button>
				</div>
			</mat-card>
		</div>	
	</mat-tab>

	<mat-tab label="{{ 'Factbooks.IngigenousInstitutes' | translate}}" *ngIf="indigenousInstitutes.length > 0">
		<div class="factbook-sort-by" fxLayout="row" fxLayoutAlign="end center">
			<label id="sortby-label">{{ 'Common.Controls.SortBy.Title' | translate }}</label>
			<mat-form-field appearance="outline" aria-labelledby="sortby-label">
				<mat-select [formControl]="sortIndigenousInstitutesControl" [(ngModel)]="sortIndigenousInstitutesValue" (selectionChange)="onSortIndigenousInstitutes()">
					<mat-option *ngFor="let sortby of sortBys" [value]="sortby.value">
						{{ sortby.viewValue }}
					</mat-option>
				</mat-select>
			</mat-form-field>
		</div>
		<div fxLayout="row wrap" fxLayoutGap="20px" fxLayoutAlign="center space-between">
			<mat-card fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px" *ngFor="let institute of indigenousInstitutes; let i = index">
				<div class="icon container">
					<img title="{{institute.name}}" alt="{{institute.name}}" src="{{ institute.logoImageSource.address }}" />
				</div>
				<mat-divider></mat-divider>
				<div class="report-selector">
					<span class="view-factbooks">{{'Factbooks.PIRL.ViewFor' | translate}} {{institute.name}}</span>
					<mat-form-field appearance="outline"  [attr.aria-label]="'Factbooks.PIRL.ReportTypeAriaLabel' | translate" class="report-list">
						<mat-select 
							[(ngModel)]="institute.selectedReportTypeCode" 
							placeholder="{{'Factbooks.PIRL.ReportTypePlaceholder' | translate}}"
							aria-label="{{'Factbooks.PIRL.ReportTypePlaceholder' | translate}} for {{institute.name}}"
						>
							<mat-option *ngFor="let type of institute.reportTypes" [value]="type.code">
								{{ type.title }}
							</mat-option>
						</mat-select>
					</mat-form-field>
					<button 
						routerLink="/factbooks" 
						[queryParams]="{ institution: institute.name, selectedReportType: institute.selectedReportTypeCode }" 
						mat-stroked-button 
						color="primary"
						[disabled]="!institute.selectedReportTypeCode"
					>
						<mat-icon>arrow_right_alt</mat-icon>{{'Factbooks.PIRL.ViewFactbooks' | translate}}
					</button>
				</div>
			</mat-card>
		</div>	
	</mat-tab>
</mat-tab-group>

<ng-container *ngIf="!showAllInstitutions">
	<div 
		*ngIf="selectedInstitution$ | async"
		id="maincontent" 
		tabindex="0"
		class="view-single-institution" 
	>
		<mat-card 
			fxLayout="column" 
			fxLayoutAlign="center center" 
			*ngIf="!reports.length"
		>
			<div class="icon-container">
				<img title="{{selectedInstitution.name}}" alt="{{selectedInstitution.name}}" src="{{ selectedInstitution.logoImageSource.address }}" />
			</div>
			<mat-divider></mat-divider>
			<div class="report-selector">
				<span class="view-factbooks">{{'Factbooks.PIRL.ViewFor' | translate}} {{selectedInstitution.name}}</span>
				<mat-form-field appearance="outline" [attr.aria-label]="'Factbooks.PIRL.ReportTypeAriaLabel' | translate" class="report-list">
					<mat-select [(ngModel)]="selectedInstitution.selectedReportTypeCode" placeholder="{{'Factbooks.PIRL.ReportTypePlaceholder' | translate}}">
						<mat-option *ngFor="let type of selectedInstitution.reportTypes" [value]="type.code">
							{{ type.title }}
						</mat-option>
					</mat-select>
				</mat-form-field>
				<button 
					routerLink="/factbooks" 
					[queryParams]="{ institution: selectedInstitution.name, selectedReportType: selectedInstitution.selectedReportTypeCode }" 
					mat-stroked-button 
					color="primary"
					[disabled]="!selectedInstitution.selectedReportTypeCode"
				>
					<mat-icon>arrow_right_alt</mat-icon>{{'Factbooks.PIRL.ViewFactbooks' | translate}}
				</button>
			</div>	
		</mat-card>
	</div>
</ng-container>

<app-report-listing *ngIf="institutionName && reports.length" [reports]="reports" [institution]="selectedInstitution$"></app-report-listing>
