import { getPropertyNames } from "src/app/property-names";
import { TypeName } from "src/app/type-name";

@TypeName.decorate("SubjectArea")
export class SubjectArea  {
	public constructor(
		public readonly subjectAreaCode: null | string          = null,
		public readonly subjectAreaTitle: null | string          = null,
		public readonly sourceFileName: null | string       = null,
		public readonly reportCode: string | null = null,
		public readonly reportName: string | null = null,
		public readonly isActive: boolean | null = null,
		public readonly triggerFileName: null | string          = null,
		public readonly isFlexible: null | boolean          = null
	) {}

	public static readonly defaultPropertyNames = getPropertyNames(new SubjectArea());
}
