import { TypeName } from "src/app/type-name";
import { getPropertyNames } from "src/app/property-names";

@TypeName.decorate("IntermediateStorageModel")
export class IntermediateStorageModel {
	public constructor(
		public title: null | string = null,
		public fileName:      null | string = null,
		public url:      null | string = null,
		public lookupId:      null | string = null,
	) { }

	public static readonly defaultPropertyNames = getPropertyNames(new IntermediateStorageModel());
}