import { Injectable } from "@angular/core";
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map } from "rxjs/operators";
import { Hyperlink } from "src/app/models/hyperlink.model";
import { Video } from "src/app/models/video.model";
import { LanguageService } from 'src/app/services/language.service';
import { environment } from 'src/environments/environment';
import { ColumnMapEntry, GraphService } from './graph.service';
import { ResourceNames, VideoDataTypes } from "./resource-names";

@Injectable({
  providedIn: 'root'
})
export class GraphVideoService {

  constructor(
    private graphService: GraphService,
    private languageService: LanguageService,
    private sanitizer: DomSanitizer,
  ) {
  }

  static readonly listTitle = ResourceNames.VIDEOS_LIST_TITLE;
  static readonly columnMap: ReadonlyArray<ColumnMapEntry<Video>> = [
    { modelKey: "id", spFriendlyFieldName: "ID" },
    { modelKey: "url", spFriendlyFieldName: "FileRef" },
    { modelKey: "group", spFriendlyFieldName: "Group" },
    { modelKey: "fileType", spFriendlyFieldName: "FileType" },
    { modelKey: "languageCode", spFriendlyFieldName: "Language" },
    { modelKey: "url", spFriendlyFieldName: ["FileRef", "Title"], transformer: (x: any[]) => new Hyperlink(x[0] === null ? null : environment.sharepointSiteBaseUrl + x[0], x[1]) },
  ];

  // Here we assume all the videos consist of: 
  // 1. video file itself  - typeof VideoDataTypes.VIDEO
  // 2. captions file	   - typeof VideoDataTypes.CAPTION
  // 3. transcription file - typeof VideoDataTypes.TRANSCRIPT
  static readonly NumberOfFilesInGroup = 3;

  private entries: BehaviorSubject<Video[]> = new BehaviorSubject<Video[]>([]);
  private pendingRequests = false;

  getVideos() {
    if (this.entries.value.length > 0 || (this.pendingRequests)) {
      return this.entries;
    }

    this.pendingRequests = true;

    const entities = this.graphService.getListItems<Video>(GraphVideoService.listTitle, Video, GraphVideoService.columnMap);
    // filter the sharepoint data using the current language according to the language service
    this.languageService
      .filterByLanguage(entities)
      .subscribe((result) => {
        this.entries.next(result.filter(a => a.group != null));
        this.pendingRequests = false;
      });
    return this.entries;
  }

  getVideoFile(group, fileType = "Video"): Subject<SafeUrl | null> {
    const sub$ = new Subject<SafeUrl>();

    this.getVideos().subscribe(vids => {
      const video = vids.filter(x => x.group === group && x.fileType === fileType)[0];
      if (!video) {
        setTimeout(() => { sub$.next(null); });
      } else {
        const path = "/" + video.url.address.split('//').pop();
		this.graphService.getSharepointBlob(path)
          .subscribe((videoBlob: Blob) => {
            const vidurl = window.URL.createObjectURL(videoBlob);
			sub$.next(this.sanitizer.bypassSecurityTrustResourceUrl(vidurl));
          },
            error => {
              sub$.error(error);
            });
      }
    });
    return sub$;
  }

  getVideoData(videoGroup: string): Subject<IVideoData | null> {
    const sub$ = new Subject<IVideoData>();

    this.getVideos().subscribe(vids => {
		const videoData: Video[] = vids.filter(x => x.group === videoGroup);

		if (!videoData || videoData.length === 0) {
			setTimeout(() => { sub$.next(null); });
		} else {
			if (videoData.length !== GraphVideoService.NumberOfFilesInGroup) {
				sub$.error(new Error("Multiple videos in a single group is not implemented"));
			} else {
				const video = videoData.find(x => x.fileType === VideoDataTypes.VIDEO);
				const transcription = videoData.find(x => x.fileType === VideoDataTypes.TRANSCRIPT);
				const captions = videoData.find(x => x.fileType === VideoDataTypes.CAPTION);
				
				const videoName = video.url.address.split('/').pop();
				const captionPath = "/" + captions.url.address.split('//').pop();
	
				this.graphService.getSharepointBlob(captionPath).subscribe(
					(captionsBlob: Blob) => sub$.next({ 
							videoName, 
							transcriptionUrl: transcription.url.address,
							captionsUrl: this.sanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(captionsBlob)),
						}),
					error => sub$.error(error)
				);
			}
		}
    });
	
    return sub$;
  }

}

export interface IVideoData {
	videoName: string,
	transcriptionUrl: string,
	captionsUrl: SafeUrl
}	