<h1 id="maincontent" tabindex="0" role="note">{{'AboutUs.Title' | translate}}</h1>
<p tabindex="0" role="note">{{'AboutUs.Paragraph' | translate}}</p>

<mat-divider class="divider"></mat-divider>

<h2 tabindex="0" role="note">{{'AboutUs.Vision.Header' | translate}}</h2>
<p tabindex="0" role="note">{{'AboutUs.Vision.Paragraph' | translate}}</p>

<mat-divider class="divider"></mat-divider>

<h2 tabindex="0" role="note">{{'AboutUs.Mission.Header' | translate}}</h2>
<p tabindex="0" role="note">{{'AboutUs.Mission.Paragraph' | translate}}</p>

<mat-divider class="divider"></mat-divider>

<h2 tabindex="0" role="note">{{'AboutUs.WhoWeAre.Header' | translate}}</h2>
<p tabindex="0" [innerHTML]="'AboutUs.WhoWeAre.Paragraph' | translate" role="note"></p>