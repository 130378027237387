import { Hyperlink } from "./hyperlink.model";
import { TypeName } from "src/app/type-name";
import { getPropertyNames } from "src/app/property-names";
import { Exception } from '@microsoft/applicationinsights-web';

@TypeName.decorate("Factbook")
export class Factbook {
	public constructor(
		public readonly name: null | string = null,
		public readonly reportFile: null | Hyperlink = null,
		public readonly institutionType: null | string = null,
		public readonly institution: null | string = null,
		public readonly academicYear: null | string = null,
		public readonly reportFileType: null | string = null,
		public readonly id: null | string = null,
		public readonly lastRefresh: null | Date = null,
	) { }

	private static _defaultInstance: Factbook | null = null; // lazy property since assigning a static property appears to happen before decorators are applied
	public static get defaultInstance() {
		if (this._defaultInstance === null)
			this._defaultInstance = new Factbook();
		return this._defaultInstance;
	}

	public static readonly defaultPropertyNames = getPropertyNames(new Factbook()); // don't use 

	getDate(): null | Date {
		if (typeof this.academicYear !== 'string')
			return null;
		const years = this.academicYear.split('-');
		if (years.length !== 2) {
			throw new Error("Academic year invalid. Must be in format `YYYY-YYYY`.");
		}
		const parsed = parseInt(years[0]);
		if (isNaN(parsed))
			return null;
		return new Date(years[0]);
	}

	public getLastRefresh(): null | Date {
		if(this.lastRefresh)
			return new Date(this.lastRefresh);
		return this.lastRefresh;
	}
}