import { Component, Input, OnInit } from '@angular/core';
import { ApprovalWorkflowService, PROD_ENV_KEY } from 'src/app/services/approval-workflow.service';
import { WorkflowStatuses } from 'src/app/services/graph/resource-names';
import { ApprovalWorkflowModel } from 'src/app/models/approval-workflow.model';
import { ThemeService } from 'src/app/shared/services/theme.service';
import { ConfirmationPopupService } from 'src/app/shared/services/confirmation-popup.service';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { TranslateService } from '@ngx-translate/core';
import { DataFilesManagementService } from 'src/app/services/graph/data-files-management.service';
import { Params } from '@angular/router';
import { environment } from 'src/environments/environment';

const displayedColumnsUAT = ['reportName', 'status', 'statusDate', 'status_uploaded', 'status_uat_refreshed', 'status_uat_approved', 'status_promoted', 'actions'];
const displayedColumnsProd = ['reportName', 'status', 'statusDate', 'status_promoted', 'status_prod_refreshed', 'actions'];

@Component({
  selector: 'app-approval-workflow',
  templateUrl: './approval-workflow.component.html',
  styleUrls: ['./approval-workflow.component.scss']
})
export class ApprovalWorkflowComponent implements OnInit {
	@Input() env: string = "uat";

	displayedColumns: string[];
	dataSource: ApprovalWorkflowModel[];

	queryParams: Params;

	constructor(
		private readonly workflowService: ApprovalWorkflowService,
		private readonly popupService: ConfirmationPopupService,
		private readonly snackBar: SnackbarService,
		private readonly translateService: TranslateService,
		public readonly themeService: ThemeService,
		public readonly dataFilesService: DataFilesManagementService,
	) { }

	ngOnInit(): void {
		this.refresh();
		this.displayedColumns  = this.isProd() ? displayedColumnsProd : displayedColumnsUAT;
	}

	refresh() {
		this.workflowService.getApprovalItems().subscribe(items => this.dataSource = items);
	}

	isProd() {
		return this.env.toLowerCase() === PROD_ENV_KEY;
	}

	getStatusClass(status: WorkflowStatuses, workflowStage: string): string {
		// 'status_uploaded' -> 'status_uat_refreshed' -> 'status_uat_approved' -> 'status_promoted' -> 'status_prod_refreshed' 
		const baseClass = 'workflow-icon';
		let stageClass = "";

		if (status == WorkflowStatuses.PROD_REFRESHED) {
			stageClass = "workflow-status-passed";
		}
		if (status == WorkflowStatuses.UAT_PROCESSING) {
			if (workflowStage == 'status_uploaded') stageClass = "workflow-status-active";
		}
		if (status == WorkflowStatuses.UAT_UPLOADED) {
			if (workflowStage == 'status_uploaded') stageClass = "workflow-status-passed";
			if (workflowStage == 'status_uat_refreshed') stageClass = "workflow-status-active";
		}
		if (status == WorkflowStatuses.UAT_REFRESHED) {
			if (['status_uploaded', 'status_uat_processed', 'status_uat_refreshed'].includes(workflowStage)) stageClass = "workflow-status-passed";
			if (workflowStage == 'status_uat_approved') stageClass = "workflow-status-active";
		}
		if (status == WorkflowStatuses.UAT_APPROVED) {
			if (['status_uploaded', 'status_uat_processed', 'status_uat_refreshed', 'status_uat_approved'].includes(workflowStage)) stageClass = "workflow-status-passed";
			if (workflowStage == 'status_promoted') stageClass = "workflow-status-active";
		}
		if (status == WorkflowStatuses.PROD_PROMOTED) {
			if (['status_uploaded', 'status_uat_processed', 'status_uat_refreshed', 'status_uat_approved', 'status_promoted'].includes(workflowStage)) stageClass = "workflow-status-passed";
			if (workflowStage == 'status_prod_refreshed') stageClass = "workflow-status-active";
		}
		if (status == WorkflowStatuses.PROD_PROCESSED) {
			if (['status_uploaded', 'status_uat_processed', 'status_uat_refreshed', 'status_uat_approved', 'status_promoted'].includes(workflowStage)) stageClass = "workflow-status-passed";
			if (workflowStage == 'status_prod_refreshed') stageClass = "workflow-status-active";
		}
		return `${baseClass} ${stageClass}`;
	}

	canRefreshUat(status: WorkflowStatuses) {
		return !this.isProd() && status == WorkflowStatuses.UAT_UPLOADED;
	}

	canApprove(status: WorkflowStatuses) {
		return !this.isProd() && status == WorkflowStatuses.UAT_REFRESHED;
	}

	canPromote(status: WorkflowStatuses) {
		return this.isProd();
	}

	canRefreshProd(status: WorkflowStatuses) {
		return this.isProd() && status == WorkflowStatuses.PROD_PROCESSED;
	}

	isReadyForProd(status: WorkflowStatuses) {
		return !this.isProd() && status === WorkflowStatuses.UAT_APPROVED;
	}

	onUatApprove(item: ApprovalWorkflowModel): void {
		const dialogRef = this.popupService.openConfirmDialog("DataFilesManagement.ConfirmApprove");

		dialogRef.afterClosed().subscribe(dialogResult => {
			if (dialogResult) this.approveUatData(item.id); 
		  });
	}
	
	approveUatData(id: number) {
		this.workflowService.setUatDataApprovedStatus(id).subscribe(() => {
			this.snackBar.success(this.translateService.instant("DataFilesManagement.Approved"));
			this.refresh();
		}, error => this.onError(error));
	}

	onProdRefresh(item: ApprovalWorkflowModel): void {
		const dialogRef = this.popupService.openConfirmDialog("ReportRefresh.ConfirmProdRefresh");

		dialogRef.afterClosed().subscribe(dialogResult => {
			if (dialogResult) this.refreshProdData(item); 
		  }, error => this.onError(error));
	}

	refreshProdData(item: ApprovalWorkflowModel) {
		this.workflowService.refreshProdReport(item).subscribe(isRefreshed => {
			if (isRefreshed) this.workflowService.setProdRefreshedStatus(item.id).subscribe(isStatusSet => {
				if (isStatusSet) {
					this.snackBar.success(this.translateService.instant("ReportRefresh.ProdRefreshed"));
					this.refresh();
				}
			});
		}, error => this.onError(error));
	}

	onProdRedirect() {
		const url = `${environment.dataFilesManagement.prodUrl}/index.html#/prod-workflow`;
		window.open(url, "_blank");
	}

	onError(error: any) {
		const errorText = typeof(error) === "string" ? this.checkErrorType(error) : typeof(error.error) === "string" ? error.error : error.message || "Unknown error";
		this.snackBar.error(errorText);
	}

	private checkErrorType(error: String): string {
		return error == "FileAlreadyExists" ? this.translateService.instant('DataFilesManagement.ErrorFileExists') : error;
	}
}

 