import { Directionality } from '@angular/cdk/bidi';
import { CdkStepper } from '@angular/cdk/stepper';
import { ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ModalOrientationComponent } from '../modal-orientation/modal-orientation.component';

@Component({
	selector: 'app-stepper-orientation',
	templateUrl: './stepper-orientation.component.html',
	styleUrls: ['./stepper-orientation.component.scss'],
	providers: [{ provide: CdkStepper, useExisting: StepperOrientationComponent }]
})

export class StepperOrientationComponent extends CdkStepper {

	@ViewChild('getStarted') gs: HTMLElement;

	constructor(dir: Directionality,
		changeDetectorRef: ChangeDetectorRef,
		public dialogRef: MatDialogRef<ModalOrientationComponent>,
	) {
		super(dir, changeDetectorRef);
	}

	onClick(index: number): void {
		this.selectedIndex = index;
	}

	onClose() {
		this.dialogRef.close();
	}

	nextClick() {
		this.selectedIndex = this.selectedIndex + 1;
		if (this.selectedIndex === 3) {
			this.gs.focus();
		}
	}

}
