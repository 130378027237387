<div *ngIf="(entries | async)?.length; else sorry" [ngClass]="{'app-light-theme': !themeService.isDark, 'app-dark-theme': themeService.isDark}">
	<h1 id="maincontent" tabindex="0">{{ 'Admin.Title' | translate }}</h1>
	<div fxLayout="column" fxLayoutAlign="center center">
		<div fxLayout="column" fxLayoutAlign="center start">
			<ng-container *ngFor="let entry of entries | async">
				<h2 *ngIf="!entry.isHidden">
					<a *ngIf="!entry.isPowerBiReport" href="{{ getentryURL(entry.url) }}" target="{{entry.isOpenInSameTab ? '_self': '_blank'}}" rel="noopener noreferrer" matTooltip="{{'Common.Notifications.OpensInNewWindow' | translate}}">{{ entry.title }}</a>
					<a *ngIf="entry.isPowerBiReport" (click)="OpenReportWindow(entry)" rel="noopener noreferrer" matTooltip="{{'Common.Notifications.OpensInNewWindow' | translate}}">{{ entry.title }}</a>
				</h2>
				<p *ngIf="!entry.isHidden">{{ entry.description }}</p>
			</ng-container>
		</div>
	</div>
	<a (click)="testEmailNotify()" tabindex="-1">.</a>
</div>

<ng-template #sorry>
	<div fxLayoutAlign="center centers">
		<h3>
			{{ 'Admin.SorryNoAccess' | translate }}
		</h3>
	</div>
</ng-template>