import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatSelect } from '@angular/material/select';
import { ActivatedRoute } from '@angular/router';
import { ProjectDatasetViewModel } from 'src/app/models/dataset.model';
import { ProjectDatasetService } from 'src/app/services/graph/project-dataset.service';
import { ThemeService } from 'src/app/shared/services/theme.service';

@Component({
  selector: 'app-dataset-manager',
  templateUrl: './dataset-manager.component.html',
  styleUrls: ['./dataset-manager.component.scss']
})
export class DatasetManagerComponent implements OnInit {

	@ViewChild('projectSelector', {static: false}) projectSelector: MatSelect;

	stakeholder: string;
	selectedProjectCode: string;

	projectDropdowns: UploadDropdownModel[];

	allDatasets: ProjectDatasetViewModel[];

	constructor(
		private activatedRoute: ActivatedRoute,
		private datasetService: ProjectDatasetService,
		public themeService: ThemeService,
	) { }

	ngOnInit(): void {
		this.stakeholder =  this.activatedRoute.snapshot.params.stakeholder || this.activatedRoute.snapshot.queryParamMap.get('stakeholder');
		this.initProjectsDropdown();
	}

	initProjectsDropdown(): void {
		this.datasetService.getProjectDataSetViewModel(this.stakeholder).subscribe(datasets => {
			this.allDatasets = datasets;
			this.projectDropdowns = this.getDistinctProjects(datasets);
		});
	}

	onProjectChange() {
		this.selectedProjectCode = this.projectSelector.value;
	}

	private getDistinctProjects(datasets: ProjectDatasetViewModel[]): UploadDropdownModel[] {
		const unique = [];
		
		const arr = datasets.map(x => { return { key: x.projectCode, name: x.projectTitle}});
		for (const dataset of arr) {
			const exists = unique.find(x => x.key === dataset.key);
			if (!exists) unique.push(dataset);
		}
		return unique as UploadDropdownModel[];
	}
}

export interface UploadDropdownModel {
	key: string,
	name: string
}