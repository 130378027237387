import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { TranslateService } from "@ngx-translate/core";
import { ModalPopupComponent } from "../../components/modal-popup/modal-popup.component";

@Injectable()
export class ConfirmationPopupService {

	constructor(
		private dialog: MatDialog,
		private translateService: TranslateService
	) { }

	openConfirmDialog(dictionaryKey: string, plainTextMessage: string = null, okButtonCaptionDictionaryKey: string = null, cancelButtonCaptionDictionaryKey: string = null) {
		const data = { 
			popupCancel: this.translateService.instant(cancelButtonCaptionDictionaryKey || 'Common.Controls.Cancel'),
			popupOk: this.translateService.instant(okButtonCaptionDictionaryKey || 'Common.Controls.Ok'),
		};
		return this.openDialog(dictionaryKey, plainTextMessage, data);
	}

	openConfirmMessage(dictionaryKey: string, plainTextMessage: string = null, alignClass: string = null) {
		const data = alignClass ? { alignClass } : {};
		return this.openDialog(dictionaryKey, plainTextMessage, data);
	}

	private openDialog(dictionaryKey: string, plainTextMessage: string, data: any) {
		return this.dialog.open(ModalPopupComponent, {
			width: 'rem(300px)',
			panelClass: 'popup',
			data: { 
				popupMessage: plainTextMessage || this.translateService.instant(dictionaryKey), 
				popupOk: this.translateService.instant('Common.Controls.Ok'),
				...data,
			}
		});
	}
}