<ng-template #paginatorTemplate>
  <div *ngIf="hidePaginator"></div>
  <div
    fxLayout="row reverse-wrap"
    fxLayoutAlign="center center"
    *ngIf="!hidePaginator"
  >
    <mat-paginator
      fxFlexOrder.gt-sm="1"
      fxFlexOrder="2"
      #paginator
      [pageSize]="pageSize"
      [length]="news.length"
      [pageSizeOptions]="[5, 10, 20, 50]"
      [showFirstLastButtons]="false"
      (page)="getPaginatorData($event)"
    >
    </mat-paginator>

    <div
      fxFlexOrder.gt-sm="2"
      fxFlexOrder="1"
      fxLayoutAlign="center center"
      class="page-index-selector mat-paginator-page-size"
    >
      <span
        class="page-index-text-wrapper mat-select-trigger mat-paginator-page-size-label"
      >
        {{ "Search.Results.Page" | translate }}
      </span>

      <mat-form-field
        fxLayoutAlign="center center"
        class="mat-paginator-page-size-select mat-input-page-number mat-primary"
      >
        <input
          matInput
          type="number"
          [max]="pages"
          [min]="1"
          [ngModel]="getPage"
          [attr.aria-label]="'Search.Results.EnterPageNumber' | translate"
          (keyup.enter)="pageToIndex($event.target.value)"
        />
      </mat-form-field>

      <span
        class="page-index-text-wrapper mat-select-trigger mat-paginator-page-size-label"
      >
        {{ "Search.Results.OfXPages" | translate : { X: pages } }}
      </span>

      <button
        *ngIf="hasPrevPage"
        mat-stroked
        (click)="previousPage()"
        class="mat-paginator-page-size-select next-prev"
        [attr.aria-label]="'Common.Controls.Previous' | translate"
      >
        <mat-icon>chevron_left</mat-icon>
      </button>
      <button
        *ngIf="hasNextPage"
        mat-stroked
        (click)="nextPage()"
        class="mat-paginator-page-size-select next-prev"
        [attr.aria-label]="'Common.Controls.Next' | translate"
      >
        <mat-icon>chevron_right</mat-icon>
      </button>
    </div>
  </div>
</ng-template>
<div
  class="listing-controls"
  fxFlex="auto"
  fxLayout="column"
  fxLayout.gt-md="row"
  fxLayoutAlign.gt-md="space-between center"
  fxLayoutGap="20px"
  [ngClass]="{
    'app-light-theme': !themeService.isDark,
    'app-dark-theme': themeService.isDark
  }"
>
  <ng-container [ngTemplateOutlet]="paginatorTemplate"></ng-container>
  <div fxLayout="row" fxLayoutAlign="end center">
    <div class="sort-by" fxLayout="row" fxLayoutAlign="center center">
      <label id="sortby-label">{{
        "Common.Controls.SortBy.Title" | translate
      }}</label>
      <mat-form-field appearance="outline">
        <mat-select
          aria-labelledby="sortby-label"
          [formControl]="sortControl"
          [(ngModel)]="sortValue"
          (selectionChange)="onSortByChange()"
        >
          <mat-option *ngFor="let sortby of sortBys" [value]="sortby.value">
            {{ sortby.viewValue }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <button
      fxHide
      fxShow.gt-md
      *ngIf="!viewAsList"
      mat-button
      color="primary"
      (click)="viewAsList = true"
    >
      <mat-icon>list</mat-icon>{{ "Reports.ViewAsList" | translate }}
    </button>
    <button
      fxHide
      fxShow.gt-md
      *ngIf="viewAsList"
      mat-button
      color="primary"
      (click)="viewAsList = false"
    >
      <mat-icon>apps</mat-icon>{{ "Reports.ViewAsGrid" | translate }}
    </button>
  </div>
</div>
<div
  [ngClass]="{
    'app-light-theme': !themeService.isDark,
    'app-dark-theme': themeService.isDark
  }"
>
  <!-- List View -->
  <mat-table
    mat-table
    [dataSource]="dataSource"
    *ngIf="viewAsList && !isLowRes"
    fxLayout="column"
    fxLayoutAlign="center center"
  >
    <ng-container matColumnDef="id">
      <app-news-card
        mat-cell
        *matCellDef="let article"
        class="full"
        [article]="article"
        [attachmentList]="attachmentList"
        [isInList]="viewAsList"
        [isLowRes]="isLowRes"
        (ViewNewsDetail)="openNewsDetailModal(article)"
        fxLayout="column"
        fxLayoutGap="25px"
        role="cell"
      ></app-news-card>
    </ng-container>
    <mat-row
      fxLayoutAlign="center center"
      class="list-row"
      *matRowDef="let row; columns: columnsToDisplay"
    >
    </mat-row>
  </mat-table>
  
  <!-- Grid View -->
  <mat-table
    mat-table
    [dataSource]="dataSource"
    *ngIf="!viewAsList || isLowRes"
    fxLayout="column"
    fxLayout.gt-md="row wrap"
    fxLayoutGap="20px"
    fxLayoutAlign="center center"
  >
    <ng-container matColumnDef="id">
      <app-news-card
        mat-cell
        *matCellDef="let article"
        [article]="article"
        [attachmentList]="attachmentList"
        [isInList]="viewAsList"
        [isLowRes]="isLowRes"
        (ViewNewsDetail)="openNewsDetailModal(article)"
        role="cell"
      ></app-news-card>
    </ng-container>
    <mat-row *matRowDef="let row; columns: columnsToDisplay"> </mat-row>
  </mat-table>
  <ng-container [ngTemplateOutlet]="paginatorTemplate"></ng-container>
</div>
