import { Directive } from '@angular/core';
import { LayoutDirective } from '@angular/flex-layout';

const selector = `[fxLayout],
                  [fxLayout.gt-smd]
                  `;

const inputs = ['fxLayout',
                'fxLayout.gt-smd'];

@Directive({selector, inputs})
export class CustomLayoutDirective extends LayoutDirective {
  protected inputs = inputs;
}