import { DefaultUrlSerializer } from "@angular/router";
import { TypeName } from "src/app/type-name";
import { getPropertyNames } from "src/app/property-names";

@TypeName.decorate("Hyperlink")
export class Hyperlink {
	public constructor(
		public readonly address:     null | string = null,
		public readonly description: null | string = null,
		public readonly isInternal:  boolean       = false,
	) {}

	public get isRelative(): boolean {
		if (this.address === null)
			return false;
		const address = this.address;
		if (["//", "http://", "https://"].some(x => address.startsWith(x)))
			return false;
		return true;
	}

	public get addressWithoutQueryParams(): string | undefined {
		if (!this.address)
			return undefined;
		const addressWithoutQueryParams = this.address.split("?")[0];
		return addressWithoutQueryParams;
	}

	public get queryParameters(): { [key: string]: any } | undefined {
		if (!this.address)
			return undefined;
		const dus = new DefaultUrlSerializer();
		const urlTree = dus.parse(this.address);
		const queryParams = urlTree.queryParams;
		return queryParams;
	}

	public static from(fieldUrl: any): Hyperlink | null {
		if (fieldUrl === null || fieldUrl === undefined)
			return null;
		let address = fieldUrl.Url;
		let description = fieldUrl.Description;

		/**
		 * hack to get around sharepoint auto-resolving relative URLs "for" us in links which we want to be relative to our site (stay inside the SPA without reloading).
		 * for this to work, relative links in sharepoint must follow our made-up convention of starting with "//" which sharepoint will resolve to "file://" (?!?!)
		 * in other words, if you want to link to a page within this Open SIMS angular application, you need to set your sharepoint hyperlink URLs to start with "//"
		 * (or "file://") so the code below can recognize that and put it through the router instead of a direct link. this, of course, means that you can't supply
		 * actual file system links.
		 */
		const fixDescription = description === address; // if no description was set on the hyperlink, sharpoint gives us the url in this field
		const baseRelativeLocation = "file://"
		let isInternal = false;
		if (address !== null && address.startsWith(baseRelativeLocation)) {
			address = "/" + address.substr(baseRelativeLocation.length);
			isInternal = true;
		}
		if (fixDescription)
			description = address;

		return new Hyperlink(address, description, isInternal);
	}

	static readonly defaultPropertyNames = getPropertyNames(new Hyperlink());
}