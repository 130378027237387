
export class SearchableViewModel {
	constructor(
		readonly model: any,
		readonly id: string,
		readonly title: string,
		readonly description: string,
		readonly tags: string[],
	) {}


	isModelOfType<T>(constructor: new (... args: any[]) => T): boolean {
		return this.model instanceof constructor;
	}
	
}
