import { TypeName } from "src/app/type-name";

@TypeName.decorate("FrenchExperiencePopup")
export class FrenchExperiencePopup {
	public static readonly MESSAGE = `Afin que les rapports, les documents Excel et les fichiers au format .csv s’affichent correctement en français, ajustez les réglages de votre ordinateur et de votre navigateur comme suit :

	Changez la langue d’affichage de votre ordinateur pour le français :
	
	&emsp;1. Ouvrez le panneau de configuration.
	&emsp;2. Cliquez sur <b>Horloge, langue et région.</b>
	&emsp;3. Cliquez sur <b>Modifier la langue.</b>
	&emsp;4. Dans le menu déroulant sous « Choisissez une langue d’affichage », sélectionnez « français (Canada) », puis cliquez sur <b>OK.</b>
	
	Changez la langue de Google Chrome ou de Microsoft Edge pour le français :
	
	&emsp;1. Ouvrez votre navigateur Chrome ou Edge.
	&emsp;2. Dans le coin supérieur droit de la fenêtre, cliquez sur les trois petits points.
	&emsp;3. Cliquez sur <b>Paramètres</b> dans le menu qui s’ouvre.
	&emsp;4. Une page de paramètres s’affichera dans le navigateur.
	&emsp;&emsp;a. Dans Google Chrome, cliquez sur <b>Paramètres avancés</b> dans le menu à gauche de l’écran.
	&emsp;&emsp;b. Dans Microsoft Edge, passez à l’étape suivante.
	&emsp;5. Cliquez sur <b>Langues</b> dans le menu à gauche de l’écran.
	&emsp;6. Dans Chrome, cliquez sur <b>Langue</b> au haut de la liste qui se trouve au milieu de l’écran. Dans Edge, passez à la prochaine étape.
	&emsp;7. Sélectionnez « français (Canada) », puis cliquez sur les trois petits points et utilisez les options qui s’affichent pour faire monter la langue au haut de la liste.
	&emsp;&emsp;a. Si vous ne voyez pas l’option « français (Canada) », cliquez sur Ajouter des langues et effectuez une recherche pour l’ajouter à votre liste.`;
}