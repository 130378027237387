import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, of, Subject } from "rxjs";
import { map } from 'rxjs/operators';
import { UserSettings } from '../../models/user-settings.model';
import { User } from '../../models/user.model';
import { ColumnMapEntry, GraphService } from './graph.service';
import { GraphUserService } from './user.service';

@Injectable({
	providedIn: 'root'
})
export class GraphUserSettingsService {

	static readonly columnMap: ReadonlyArray<ColumnMapEntry<UserSettings>> = [
		{ modelKey: "itemId", spFriendlyFieldName: "Id" },
		{ modelKey: "settings", spFriendlyFieldName: "Settings" },
		{ modelKey: "userId", spFriendlyFieldName: "UserID" }
	];

	private _userSettings = this.defaultUserSettings();
	get userSettings(): Observable<UserSettings> { return this._userSettings; }
	loaded: Subject<boolean> = new Subject<boolean>();


	private readonly sharePointListName = 'User_Settings';
	private mockIdCounter = 0;
	private readonly mockData: UserSettings = new UserSettings(this.getNextMockId(),
		{ dark: "false", lang: "en", hasSeenOrientation: "false", bookmarkIds: "" }, '6');
	private getNextMockId() { return this.mockIdCounter++; }

	constructor(
		private readonly graphService: GraphService,
		private readonly userService: GraphUserService
	) {

	}

	init(): Observable<boolean> {
		const user: User = this.userService.getUser();
		this.graphService.getListItems<UserSettings>(this.sharePointListName, UserSettings, GraphUserSettingsService.columnMap)
		.subscribe((result: UserSettings[]) => {
			for (const setting of result) {
				if (setting.userId === user.loginName) {
					const found = JSON.parse(setting.settings);
					setting.settings = found;
					this._userSettings.next(setting);
					this.loaded.next(true);
					return;
				}
			}
			const defaultSettings = this.defaultUserSettings().value;
			defaultSettings.userId = user.loginName;
			this._userSettings.next(defaultSettings);
			this.loaded.next(true);
		});
		return this.loaded;
	}

	public getUserSetting(key: string): string {
		return key ? this._userSettings.value.settings[key] : undefined;
	}

	public setUserSetting(key: string, value: string): Observable<boolean> {

		const sub$ = new Subject<boolean>();
		const newSettings = { ...this._userSettings.value.settings, [key]: value };
		const newUserSettings = { ...this._userSettings.value, settings: newSettings };

		this.upsertUserSettings(newUserSettings).subscribe((result: UserSettings) => {
			this._userSettings.next(result); // then re-publish to tell everyone about the "new" values
			sub$.next(true);
		});

		return sub$.asObservable();
	}
	/** Sharepoint WRITE */
	private upsertUserSettings(userSettings: UserSettings): Observable<UserSettings> {
		if (userSettings.userId == null) {
			// don't save anonymous user settings
			return of(userSettings);
		}

		const item = { Settings: JSON.stringify(userSettings.settings), UserID: userSettings.userId };
		if (userSettings.itemId === null) {
			return this.graphService.addListItem(this.sharePointListName, item).pipe(map(a => {
				userSettings.itemId = a;
				return userSettings;
			}));
		}
		else {
			return this.graphService.updateListItem(this.sharePointListName, userSettings.itemId, item).pipe(map(a => {
				return userSettings;
			}));
		}
	}
	private defaultUserSettings(): BehaviorSubject<UserSettings> {
		return new BehaviorSubject<UserSettings>(new UserSettings(null, { dark: "false", lang: "en", hasSeenOrientation: "false", bookmarkIds: "",  captions:"false"}, null));
	}
}
