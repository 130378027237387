import { HasLanguageCode } from "src/app/services/language.service";
import { TypeName } from "src/app/type-name";
import { getPropertyNames } from "src/app/property-names";

@TypeName.decorate("GlossaryEntry")
export class GlossaryEntry implements HasLanguageCode {
	public constructor(
		public readonly title          : null | string = null,
		public readonly termDefinition : null | string = null,
		public readonly termLetter     : null | string = null,
		public readonly languageCode   : null | string = null,
	) {}

	public static readonly defaultPropertyNames = getPropertyNames(new GlossaryEntry());
}