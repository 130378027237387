<div class="container" [ngClass]="{'app-light-theme': !themeService.isDark, 'app-dark-theme': themeService.isDark}">
  <h1 id="maincontent" tabindex="0" role="note">{{ 'VideoGallery.Title' | translate }}</h1>
  <div fxLayout="column" fxLayoutAlign="center center">
    <div fxLayout="column" fxLayoutAlign="center start">
      <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="20px" *ngFor="let video of videos">
        <ng-container *ngIf="video.fileType === 'Video'">
          <button mat-stroked-button fxLayoutAlign="center center" (click)="ShowVideo(video)" [attr.aria-label]="'VideoGallery.PlayVideo' | translate:{title: video.url.description}">
            <mat-icon>play_circle_outline</mat-icon>
          </button>
          <h3 tabindex="0" aria-hidden="true">{{ video.url.description }}</h3><p><a *ngIf="GetTranscriptUrl(video.group); let transcriptUrl" [href]="transcriptUrl" matTooltip="{{'Common.Notifications.OpensInNewWindow' | translate}}" class="transcript" target="_blank" rel="noopener noreferrer">({{'Common.Controls.Transcript' | translate}})</a></p>
          <mat-divider></mat-divider>
        </ng-container>
      </div>
    </div>
  </div>
</div>
