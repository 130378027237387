import { TypeName } from "src/app/type-name";

@TypeName.decorate("ResourceNames")
export class ResourceNames {
	public static readonly ADMIN_DASHBOARD_LIST_NAME = "Admin_Dashboard";
	public static readonly APPROVAL_WORKFLOW_LIST_NAME = "Approval_Workflow";
	public static readonly CATEGORIES_LIST_NAME = "Categories";
	public static readonly CAP_OPER_GRANTS_LIST_NAME = "Capital_Operating_Grants";
	public static readonly CONTACT_US_LIST_NAME = "Contact_Us";
	public static readonly FACTBOOK_LIST_NAME = "Factbook";
	public static readonly GLOSSARY_LIST_NAME = "Glossary_Data";
	public static readonly INSTITUTIONS_LIST_NAME = "Institutions";
	public static readonly INST_PROFILES_LIST_NAME = "Institutional_Profiles";
	public static readonly INTERMEDIATE_STORAGE_LIST_NAME = "Intermediate_Storage";
	public static readonly LOOKUP_DATASET_LIST_NAME = "Project_Dataset_Lookup";
	public static readonly NEWS_LIST_NAME = "News";
	public static readonly PROJECT_DATASET_LIST_NAME = "Project_Dataset";
	public static readonly PIRL_REPORT_TYPES_LIST_NAME = "PIRL_ReportTypes";
	public static readonly REPORT_LIST_NAME = "Report_List";
	public static readonly REPORT_REFRESH_LIST_NAME = "Report_Refresh";
	public static readonly SUBJECT_AREA_LIST_NAME = "Subject_Area";
	public static readonly VIDEOS_LIST_TITLE = "Videos";
	public static readonly UPLOADED_FILES_LIST_TITLE = "Upload_BusinessFiles";
	public static readonly USER_AUDIT_STEPS_LIST_TITLE = "User_Audit_Steps";
}

@TypeName.decorate("VideoDataTypes")
export class VideoDataTypes {
	public static readonly CAPTION = "Caption";
	public static readonly TRANSCRIPT = "Transcript";
	public static readonly VIDEO = "Video";
}

@TypeName.decorate("FileUploadStatuses")
export class FileUploadStatuses {
	public static readonly PENDING = "Pending";
	public static readonly UAT_UPLOADED = "UAT - Uploaded";
	public static readonly UAT_PROCESSING = "UAT - Processing";
	public static readonly UAT_FAILED = "UAT - Failed";
	public static readonly UAT_OK = "UAT - OK";
	public static readonly PROD_PENDING = "PROD - Pending";
	public static readonly PROD_UPLOADED = "PROD - Uploaded";
	public static readonly PROD_PROCESSING = "PROD - Processing";
	public static readonly PROD_FAILED = "PROD - Failed";
	public static readonly PROD_OK = "PROD - OK";
}

@TypeName.decorate("WorkflowStatuses")
export class WorkflowStatuses {
	public static readonly UAT_PROCESSING = "New Files Added";
	public static readonly UAT_UPLOADED = "UAT Data Processed";
	public static readonly UAT_REFRESHED = "UAT Report Refreshed";
	public static readonly UAT_APPROVED = "UAT Data Approved";
	public static readonly PROD_PROMOTED = "Data Promoted to PROD";
	public static readonly PROD_PROCESSED = "PROD Data Processed";
	public static readonly PROD_REFRESHED = "PROD Report Refreshed";
}

@TypeName.decorate("Constants")
export class Constants {
	public static readonly SEPARATOR_SEMICOLON = ";";
}

@TypeName.decorate("AuditActions")
export class AuditActions {
	public static readonly STORAGE_UPLOAD = "Intermediate Storage Upload";
	public static readonly ADF_UPLOAD = "ADF Upload";
	public static readonly REPORT_REFRESH = "Report Refresh";
	public static readonly REPORT_APPROVE = "Report Approve";
	public static readonly PROD_TRANSFER = "Production Transfer";
	public static readonly PROD_REPORT_REFRESH = "Production Report Refresh Request";
	public static readonly DATASET_UPLOAD = "Dataset File Upload";
	public static readonly DATASET_REMOVE = "Dataset File Remove";
	public static readonly DATASET_UPDATE = "Dataset Metadata Update";
}

@TypeName.decorate("PirlReportTypeCodes")
export class PirlReportTypeCodes {
	public static readonly CAP_OPER_GRANTS = "Capital_Operating_Grants";
	public static readonly INST_ELMLP = "Institutional_ELMLP";
	public static readonly INST_PROFILES = "Institutional_Profiles";
	public static readonly FACTBOOKS = "Factbooks";
}

export enum UserAuditSteps {
	StepOne = "StepOne",
	StepTwo = "StepTwo",
}