import { Component } from '@angular/core';
import { UAT_ENV_KEY } from 'src/app/services/approval-workflow.service';

@Component({
  selector: 'app-approval-workflow-uat',
  templateUrl: './approval-workflow-uat.component.html',
  styleUrls: ['./approval-workflow-uat.component.scss']
})
export class ApprovalWorkflowUatComponent {
	EnvKey: string = UAT_ENV_KEY;
}
