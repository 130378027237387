import { Injectable } from "@angular/core";
import { Observable, Subject } from 'rxjs';
import { User } from "src/app/models/user.model";
import { GraphService } from './graph.service';


@Injectable({
	providedIn: 'root'
})
export class GraphUserService {
	private user: User;
	private user$: Subject<User> = new Subject<User>();

	constructor(private readonly graphService: GraphService) {

	}

	getUser() {
		return this.user;
	}

	setUser(user: User) {
		this.user = user;
		this.user$.next(this.user);
	}

	getUserAsObservable(): Observable<User> {
		return this.user$;
	}
}
