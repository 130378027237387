<div class="app-light-theme" [ngClass]="{'app-light-theme': !themeService.isDark, 'app-dark-theme': themeService.isDark}">
	<mat-sidenav-container (backdropClick)="betaPanelClose()">
		<mat-sidenav mode="over" [(opened)]="opened">
			<app-menu (closed)="onClosed($event)"></app-menu>
		</mat-sidenav>
		<mat-sidenav mode="over" [(opened)]="betaOpened" position="end" #betasidenav (keydown.escape)="betaPanelClose()" disableClose>
			<app-beta-reports-slidein (closed)="onBetaClosed($event)"></app-beta-reports-slidein>
		</mat-sidenav>
		<mat-sidenav-content fxLayout="column">
			<app-header (opened)="onOpened($event)"></app-header>
			<app-home></app-home>
			<div *ngIf="showBetaPullTab" class="open-btn-container" role="complementary" aria-labelledby="beta-title" matTooltip="{{'Home.BetaReports.Title' | translate}}">
				<button id="beta-title" tabindex="0" [attr.aria-label]="('Home.BetaReports.PullTabText' | translate)" class="open-btn" [ngClass]="{ 'button':true, 'open':betaOpened }" (click)="betaPanelToggle()">
					<div *ngIf="!betaOpened"><img aria-hidden="true" alt="" src="assets/img/dark/beta_reports_icon.svg"><span fxHide fxShow.gt-lg> {{'Home.BetaReports.Title' | translate}}</span></div>
					<mat-icon *ngIf="betaOpened">chevron_right</mat-icon>
				</button>
			</div>
			<app-footer></app-footer>
		</mat-sidenav-content>
	</mat-sidenav-container>
</div>
