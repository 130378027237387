import { TypeName } from "src/app/type-name";
import { getPropertyNames } from "src/app/property-names";

@TypeName.decorate("Contact")
export class Contact {
	public constructor(
		public categoryCode: null | string = null,
		public message:      null | string = null,
		public id:      null | string = null,
	) { }

	public static readonly defaultPropertyNames = getPropertyNames(new Contact());
}