import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ApprovalWorkflowService } from 'src/app/services/approval-workflow.service';
import { UserAuditSteps } from 'src/app/services/graph/resource-names';
import { UserAuditService } from 'src/app/services/graph/user-audit.service';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { ThemeService } from 'src/app/shared/services/theme.service';
import { UserAuditTranslation } from '../user-audit/user-audit.component';

@Component({
  selector: 'app-user-audit-step',
  templateUrl: './user-audit-step.component.html',
  styleUrls: ['./user-audit-step.component.scss']
})
export class UserAuditStepComponent {
	@Input() set translation(value: UserAuditTranslation) {
		this.currentTranslation = value;
	}
	@Input() set stepNumber(value: UserAuditSteps) {
		this.currentStep = value;
	};
	@Output() auditActionPerformed = new EventEmitter<void>();

	currentTranslation: UserAuditTranslation;
	currentStep: UserAuditSteps;

	readonly requiredFileType = '.csv';
	readonly expectedFileName = 'user_audit_donotcontact.csv';

	readonly userAuditDetails: Record<keyof typeof UserAuditSteps, UserAuditStepDetails> = {
		"StepOne": {
			approveFolderName: 'ACCT_AUDIT_USER_EMAIL_2',
			uploadFolderName: 'ACCT_AUDIT_USER_EMAIL_2',
			expectedFileName: this.expectedFileName,
		},
		"StepTwo": {
			approveFolderName: 'ACCT_AUDIT_USER_EMAIL_4',
			uploadFolderName: 'ACCT_AUDIT_BUS_REAPPROVE_4',
			expectedFileName: 'user_audit_approved.csv',
		},
	};
	
	fileToUpload: File | null = null;
	isFileNameValid = true;
	isUploadStarted = false;
	
	constructor(
		readonly themeService: ThemeService,
		private readonly snackBar: SnackbarService,
		private readonly translateService: TranslateService,
		private readonly workflowService: ApprovalWorkflowService,
		private readonly userAuditService: UserAuditService,
	) { }

	onFileSelected(event: Event): void {
		const input = event.target as HTMLInputElement;
		
		this.isFileNameValid = this.validateFileName(input.files[0]);

		if (this.isFileNameValid) {
			this.fileToUpload = input.files[0];
		} else {
			this.displayIncorrectFileNameMessage();
		}

		input.value = null;
	}

	onApprove() {
		this.dropEmptyFile(this.userAuditDetails[this.currentStep].approveFolderName);
	}

	onUploadFile() {
		if (this.fileToUpload && this.isFileNameValid) {
			this.workflowService.uploadOneToAdf(
				this.fileToUpload,
				this.expectedFileName,
				this.userAuditDetails[this.currentStep].uploadFolderName
			).subscribe(
				() => this.onUploadSucess(true), 
				error => this.onUploadError(error)
			);
		}
	}

	private dropEmptyFile(folder: string) {
		this.isUploadStarted = true;

		this.workflowService.dropFile(
			folder, 
			this.userAuditDetails[this.currentStep].expectedFileName
		).subscribe(
			() => this.onUploadSucess(),
			(error) => this.onUploadError(error)
		);
	}

	private onUploadError(error: any) {
		error = this.checkErrorType(error);
		const errorText = error.hasOwnProperty('error') 
			? error.error instanceof String ? error.error : error.message
			: error;
		this.snackBar.error(errorText);
	}

	private onUploadSucess(isUploaded: boolean = false) {
		this.fileToUpload = undefined;

		if (isUploaded) {
			this.displayUploadedMessage();
		} else {
			this.displayApprovedMessage();
		}

		if (this.currentStep === UserAuditSteps.StepOne || 
			(this.currentStep === UserAuditSteps.StepTwo && !isUploaded)
		) {
			this.clearActiveStep();
			this.auditActionPerformed.emit();
		}
	}

	private clearActiveStep() {
		this.userAuditService.clearActiveStep().subscribe();
	}

	private validateFileName(candidate: File): boolean {
		return candidate && candidate.name == this.expectedFileName;
	}

	private checkErrorType(error: any): any {
		if (error.status && (error.status === 401 || error.status === 403)) {
			return new Error(`${this.translateService.instant('DataFilesManagement.UnauthorizedError')}`);
		} else if (error.error && error.error == "FileAlreadyExists") {
			return new Error(`${this.translateService.instant('DataFilesManagement.ErrorFileExists')}`);
		} else if (error.message && error.message.includes("Unknown Error")) {
			return new Error(`${this.translateService.instant('DataFilesManagement.ErrorUnknown')}`);
		} else {
			return error;
		}
	}

	private displayIncorrectFileNameMessage(): void {
		this.snackBar.error(`${this.translateService.instant('DataFilesManagement.UnexpectedFileName')}.
${this.translateService.instant('DataFilesManagement.Expected')}: ${ this.expectedFileName }
${this.translateService.instant('DataFilesManagement.CaseSensitive')}`);
	} 

	private displayUploadedMessage(): void {
		this.snackBar.success(this.currentTranslation.uploadedSuccessfully);
	}

	private displayApprovedMessage(): void {
		this.snackBar.success(this.currentTranslation.approveSuccessfully);
	}
}

interface UserAuditStepDetails {
	approveFolderName: string;
	uploadFolderName: string;
	expectedFileName: string;
}