import { Component } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Contact } from 'src/app/models/contact.model';
import { GraphContactService } from 'src/app/services/graph/contact.service';
import { ThemeService } from 'src/app/shared/services/theme.service';
import { ModalPopupComponent } from './../modal-popup/modal-popup.component';

@Component({
	selector: 'app-contact',
	templateUrl: './contact.component.html',
	styleUrls: ['./contact.component.scss']
})
export class ContactComponent {
	public numberOfOptions = 5;

	readonly contactForm = new FormGroup({
		categoryCode: new FormControl(),
		message: new FormControl()
	});

	constructor(
		public themeService: ThemeService,
		public translateService: TranslateService,
		private dialog: MatDialog,
		private contactService: GraphContactService
	) { }

	openDialog() {
		this.dialog.open(ModalPopupComponent, {
			width: 'rem(300px)',
			panelClass: 'popup',
			data: { popupMessage: this.translateService.instant('ContactUs.Alert'), popupOk: this.translateService.instant('Common.Controls.Ok') }
		});
	}

	async submit() {
		const categoryCode = this.contactForm.get('categoryCode').value;
		const message = this.contactForm.get('message').value;
		await this.contactService.submit(new Contact(categoryCode, message));
		this.contactForm.reset();
	}
}
