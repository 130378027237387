<button mat-mini-fab class="close" (click)="$event.stopPropagation(); onClose()" [attr.aria-label]="'Common.Controls.Close' | translate">
	<mat-icon>close</mat-icon>
</button>

<app-report-card id="reportcard" class="full" [viewModel]="(reports || [])[reportIndex]" [isInList]="false" [isLowRes]="false" [keywords]="keywords" aria-live="assertive"></app-report-card>

<a *ngIf="reportIndex > 0" class="outside control prev with-background" [appA11yActivate]="{method:'PrevReport', context:this}" tabindex="0" aria-controls="reportcard" [attr.aria-label]="'Common.Controls.Previous' | translate">
	<span aria-hidden="true" class="span-centered"><</span>
</a>
<div class="outside report-position" [attr.aria-label]="'Reports.ReportXofY' | translate:{X:reportIndex+1, Y:numberOfReports}">{{'Reports.Report' | translate}} {{reportIndex+1}}/{{numberOfReports}}</div>
<a *ngIf="reportIndex < reports.length -1" class="outside control next with-background" [appA11yActivate]="{method:'NextReport', context:this}" tabindex="0" aria-controls="reportcard" [attr.aria-label]="'Common.Controls.Next' | translate">
	<span class="span-centered">></span>
</a>