import { Component, Input, OnDestroy, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { NewsEntry } from 'src/app/models/news-entry.model';
import { GraphNewsService } from 'src/app/services/graph/news.service';
import { ThemeService } from 'src/app/shared/services/theme.service';
import { ModalNewsDetailsComponent } from '../modal-news-details/modal-news-details.component';
import { NewsAttachment } from './../../models/news-attachment';

export interface SortBy {
	value: string;
	viewValue: string;
}

enum Sort {
	title = "title",
	titleReverse = "titleReverse",
	author = "author",
	datePosted = "datePosted"
}

@Component({
	selector: 'app-news-listing',
	templateUrl: './news-listing.component.html',
	styleUrls: ['./news-listing.component.scss']
})
export class NewsListingComponent implements OnInit, OnDestroy {
	private _news: NewsEntry[] = [];
	@Input() set news(value: NewsEntry[]) {
		this._news = value;
		this.dataSource = new MatTableDataSource<NewsEntry>(this._news);
		this.onSortByChange();
	}
	get news(): NewsEntry[] {
		return this._news;
	}

	@Input() hidePaginator = false;

	@ViewChild(MatPaginator) paginator: MatPaginator;
	columnsToDisplay: string[] = ['id'];
	dataSource: MatTableDataSource<NewsEntry>;

	viewAsList = false;
	isLowRes = false;

	lowValue = 0;
	highValue = this.hidePaginator ? 100000 : 5

	pageSize = 5;
	hasPrevPage = false;
	hasNextPage = true;

	sortControl = new FormControl('');
	sortBys: SortBy[];
	sortValue = "title";

	subscriptions$: Subscription[] = [];

	public attachmentList: { newsId: string, attachments: NewsAttachment[] }[] = [];

	constructor(
		private newsService: GraphNewsService,
		public themeService: ThemeService,
		private translateService: TranslateService,
		private dialog: MatDialog,
		private cd: ChangeDetectorRef
	) {

	}

	ngOnInit(): void {

		// get list of file ids with attachments
		const attachmentList = []; // reset attachment list

		this._news.forEach(y => {
			const isAtch = JSON.parse(y.Attachments as string);
			if (isAtch) {
				const atchId = y.ID as string;
				this.newsService.getNewsAttachments(atchId).subscribe((a) => {
					attachmentList.push({ newsId: y.ID as string, attachments: a });
					this.attachmentList = attachmentList;
				});
			}

		});
		this.setSortByText();
		this.subscriptions$.push(this.translateService.onLangChange.subscribe(x => {
			this.setSortByText();
		}));

		this.dataSource = new MatTableDataSource<NewsEntry>(this._news);
	}

	ngOnDestroy(): void {
		this.subscriptions$.map(a => a.unsubscribe());
	}

	ngAfterViewInit() {
		if (this.paginator) {
			this.dataSource.paginator = this.paginator;
			this.dataSource.paginator.initialized.subscribe(event => {
				this.hidePaginator = this.hidePaginator || (this.paginator.getNumberOfPages() < 2 && this.paginator.pageSize === 5);
			});
		}
		this.cd.detectChanges();
	}

	setSortByText() {
		this.sortBys = [
			{ value: Sort.title, viewValue: this.translateService.instant("Common.Controls.SortBy.NewsTitle") },
			{ value: Sort.titleReverse, viewValue: this.translateService.instant("Common.Controls.SortBy.ReverseNewsTitle") },
			{ value: Sort.author, viewValue: this.translateService.instant("Common.Controls.SortBy.Author") },
			{ value: Sort.datePosted, viewValue: this.translateService.instant("Common.Controls.SortBy.DatePosted") },
		];
	}

	onSortByChange() {
		if (this.sortValue === Sort.author) {
			// For dataOwner sorts, first sort by title so that we always get the same ordering 
			// for dataOwner sorts. Otherwise the prior sort affects the ordering of dataOwner.
			this.Sort(Sort.title);
		}
		this.Sort(this.sortValue);
	}

	Sort(sortOrder) {
		this._news = this._news.sort((x, y) => {
			switch (sortOrder) {
				case Sort.author:
					return this.stringSort(x.author?.description, y.author?.description);
				case Sort.datePosted:
					return this.stringSort(y?.activeDate, x?.activeDate, false);
				case Sort.titleReverse:
					return this.stringSort(y.title, x.title);
				default: // title
					return this.stringSort(x.title, y.title);
			}
		});
		this.dataSource = new MatTableDataSource<NewsEntry>(this._news);
		this.dataSource.paginator = this.paginator;
	}

	stringSort(x, y, blanksFirst = true) {
		if (blanksFirst) {
			return !x ? 1 : !y ? -1 : x < y ? -1 : 1;
		}
		return !x ? -1 : !y ? 1 : x < y ? -1 : 1;
	}

	openNewsDetailModal(article: NewsEntry) {
		const dialogRef = this.dialog.open(ModalNewsDetailsComponent, {
			width: '100% !important',
			panelClass: ['my-panel', 'news-card-panel'],
			data: {}
		});

		dialogRef.componentInstance.article = article;
		dialogRef.componentInstance.news = this._news;
		dialogRef.componentInstance.attachmentList = this.attachmentList;
	}


	public getPaginatorData(event: PageEvent): PageEvent {
		this.lowValue = event.pageIndex * event.pageSize;
		this.highValue = this.lowValue + event.pageSize;
		this.pageSize = event.pageSize;

		this.paginator.pageIndex = event.pageIndex;
		this.paginator.pageSize = event.pageSize;

		this.syncPaginatorsButtons();
		this.dataSource.paginator = this.paginator;

		return event;
	}

	get pages(): number {
		return this.paginator ? this.paginator.getNumberOfPages() :
			((Math.trunc(this._news.length / 5)) + (this._news.length % 5 > 0 ? 1 : 0));
	}

	get getPage(): number {
		return this.paginator ? this.paginator.pageIndex + 1 : 1;
	}

	pageToIndex(pageIndex) {
		if (pageIndex < 0) {
			pageIndex = pageIndex * -1;
		}
		this.paginator.pageIndex = pageIndex - 1;

		this.paginator.pageIndex = pageIndex - 1, // number of the page you want to jump.
			this.paginator.page.next({
				pageIndex: pageIndex - 1,
				pageSize: this.paginator.pageSize,
				length: this.paginator.length
			});
		this.syncPaginatorsButtons();
	}

	syncPaginatorsButtons() {
		this.hasPrevPage = this.paginator.hasPreviousPage();
		this.hasNextPage = this.paginator.hasNextPage();
	}

	nextPage() {
		this.paginator.nextPage();
		this.syncPaginatorsButtons();
	}

	previousPage() {
		this.paginator.previousPage();
		this.syncPaginatorsButtons();
	}
}
