import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NewsEntry } from 'src/app/models/news-entry.model';
import { NewsAttachment } from './../../models/news-attachment';
import { ThemeService } from './../../shared/services/theme.service';
import { environment } from 'src/environments/environment';

@Component({
	selector: 'app-news-card',
	templateUrl: './news-card.component.html',
	styleUrls: ['./news-card.component.scss']
})
export class NewsCardComponent implements OnInit {
	@Input() isLowRes = false;
	@Input() isInList = false;
	_article: NewsEntry = new NewsEntry();
	@Input() set article(newsEntryModel: NewsEntry) {
		this._article = newsEntryModel;
		this.attachment = undefined;
		this.processAttachments();
	}
	get article() { return this._article; }

	_attachmentList: { newsId: string, attachments: NewsAttachment[] }[] = [];
	@Input() set attachmentList(attachments) {
		this._attachmentList = attachments;
		this.attachment = undefined;
		this.processAttachments();
	}
	@Output() ViewNewsDetail: EventEmitter<any> = new EventEmitter();
	attachment: NewsAttachment;
	readonly environment = environment;

	@Input() isInDialog = false;

	constructor(
		public themeService: ThemeService,
	) { }

	ngOnInit(): void {
		this.processAttachments();
	}

	processAttachments() {
		const att = (this._attachmentList && this._attachmentList.length > 0) ? this._attachmentList.find(a => a.newsId === this._article.ID)?.attachments : undefined;
		if (att && att.length > 0) {
			this.attachment = att[0];
		}
	}

	viewMore() {
		this.ViewNewsDetail.emit();
	}
}
