import { getPropertyNames } from "src/app/property-names";
import { TypeName } from "src/app/type-name";
import { FileUploadStatuses } from "../services/graph/resource-names";

@TypeName.decorate("DataFileModel")
export class DataFileModel  {
	public constructor(
		public readonly name: null | string          = null,
		public readonly reportCode: string | null = null,
		public readonly subjectAreaCode: string | null = null,
		public readonly comment: string | null = null,
		public status: FileUploadStatuses | null = null,
		public lookupFileId: number | null = null,
		public readonly createdBy: string | null = null,
		public readonly uploadedDate: Date | null = null,
		public readonly id: number | null = null
	) {}

	public static readonly defaultPropertyNames = getPropertyNames(new DataFileModel());
}

export type PromoteModel = Pick<DataFileModel, "name" | "subjectAreaCode" | "uploadedDate" | "status" | "lookupFileId" | "id"> & { file: Blob | null };