import { TypeName } from "src/app/type-name";
import { getPropertyNames } from "src/app/property-names";

@TypeName.decorate("UserSetting2")
export class UserSettings {
	public constructor(
		public itemId    : null | number = null,
		public settings  : null | any = null,
		public userId    : null | string = null,
	) { }

	public static readonly defaultPropertyNames = getPropertyNames(new UserSettings());
}
