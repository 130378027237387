import { DOCUMENT } from '@angular/common';
import { Component, ElementRef, EventEmitter, HostListener, Inject, OnDestroy, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from 'src/app/services/language.service';
import { MsalService } from 'src/app/services/msal';
import { environment } from 'src/environments/environment';
import { ModalPopupComponent } from '../modal-popup/modal-popup.component';
import { ModalProfileComponent } from '../modal-profile/modal-profile.component';
import { GraphUserService } from './../../services/graph/user.service';
import { ModalSearchComponent } from './../modal-search/modal-search.component';

@Component({
	selector: 'app-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class HeaderComponent implements OnInit, OnDestroy {
	@Output() opened = new EventEmitter<boolean>();
	@ViewChild('section') Section: ElementRef;

	private readonly _destroying$ = new Subject<void>();

	menuTextKey = "";
	menuAriaLabelKey = "";
	readonly profileName: string;
	readonly profileEmail: string;
	readonly signOutUrl: string;
	readonly environment = environment;
	readonly isBrowserSupported: boolean = Boolean(JSON.parse(localStorage.getItem("IsBrowserSupported")));

	loggedIn = this.router.routerState.snapshot.url.startsWith("/sign-up") ? false : this.userService.getUser() !== undefined;
	desktopWidth = 880;
	currentLanguageCode: string;

	constructor(
		@Inject(DOCUMENT) private readonly document: Document,
		iconRegistry: MatIconRegistry,
		sanitizer: DomSanitizer,
		public translateService: TranslateService,
		public languageService: LanguageService,
		private dialog: MatDialog,
		private authService: MsalService,
		public userService: GraphUserService,
		private router: Router,
	) {
		iconRegistry.addSvgIcon('user-icon',
			sanitizer.bypassSecurityTrustResourceUrl('assets/img/user_icon.svg'));
		this.profileName = this.userService.getUser()?.displayName;
		this.profileEmail = this.userService.getUser()?.email;
		this.signOutUrl = environment.production ?
			`https://opensimspoc.auth0.com/v2/logout?returnTo=${this.document.location.origin}/_layouts/SignOut.aspx`
			: "#";
	}

	@HostListener('window:resize', ['$event'])
	onWindowResize() {
		this.menuTextKey = window.innerWidth >= this.desktopWidth ? "Common.Controls.Menu" : "";
		this.menuAriaLabelKey = window.innerWidth < this.desktopWidth ? "Common.Controls.Menu" : "";
	}

	ngOnInit() {
		this.onWindowResize();

		this.languageService.currentLanguageCode.pipe(
			takeUntil(this._destroying$)
		).subscribe(lang => {
			this.currentLanguageCode = lang;
		});
	}

	openSignOutPopup(): void {
		let openDialog = this.dialog.open(ModalPopupComponent, {
			width: 'rem(300px)',
			panelClass: 'popup',
			data: { popupTitle: this.GetFormattedUserName(this.profileName) + this.translateService.instant('SignOut.Header'),
					popupMessage: this.translateService.instant('SignOut.Message'), 
					popupOk: this.translateService.instant('SignOut.SignOut'), 
					popupCancel: this.translateService.instant('SignOut.Cancel')
				}
		});

		openDialog.beforeClosed().subscribe((value) => {
			if (value == 'confirm') {
				this.authService.logout(null, this.currentLanguageCode);
			}
		});
	}

	GetFormattedUserName(name) {
		try {
			if (!this.profileEmail?.endsWith("@ontario.ca")) { return name; }
			const splitname = name.split(" (")[0].split(", ");
			const result = splitname[1] + " " + splitname[0];
			return result.indexOf('undefined') >= 0 ? name : result;
		} catch {
			return name;
		}
	}

	SkipToSection(event) {
		//Either Enter or space button or mouse button
		if (event.keyCode == 13 || event.keyCode == 32 ||event.keyCode === undefined) {
			let htmlElement = this.document.getElementById('maincontent');
			htmlElement.focus();
			htmlElement.scrollIntoView();
		}
	}

	SkipToContent(event) {
		//Either Enter or space button or mouse button
		if (event.keyCode == 13 || event.keyCode == 32 ||event.keyCode === undefined) {
			let htmlElement = this.document.getElementById('Content');
			htmlElement.focus();
			htmlElement.scrollIntoView();
		}
	}

	OpenNavigation() {
		this.opened.emit(true);
	}

	OpenSearch() {
		// const dialogRef = 
		this.dialog.open(ModalSearchComponent, {
			width: '100%',
			panelClass: ['my-panel', 'search-panel'],
			position: { top: '13px', left: '35px' },
			data: {}
		});
	}

	OpenProfile() {
		this.dialog.open(ModalProfileComponent, {
			minWidth: '300px',
			panelClass: ['my-panel', 'profile'],
			data: {}
		});
	}

	switchLang(lang: string) {
		this.translateService.use(lang);
	}

	toggleLang() {
		const newLang = this.translateService.currentLang === 'en' ? 'fr' : 'en';
		this.translateService.use(newLang);
		this.languageService.overrideCurrentBrowserLanguage(newLang);
		this.document.documentElement.setAttribute("lang", newLang); // <html lang="en">

		if (newLang === "fr") this.languageService.displayFrenchPopup();
	}

	ngOnDestroy(): void {
		this._destroying$.next(null);
		this._destroying$.complete();
	}
}
