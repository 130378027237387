<div class="title">
	<div id="maincontent" tabindex="0">{{'DownloadDataset.UploadTitleStart' | translate}}{{stakeholder.toUpperCase()}}{{'DownloadDataset.UploadTitleEnd' | translate}}</div>
</div>
<div class="project-selector" [ngClass]="{'app-light-theme': !themeService.isDark, 'app-dark-theme': themeService.isDark}">
	<label id="project-label">{{ 'DownloadDataset.Project' | translate }}</label>
	<mat-form-field appearance="outline" aria-labelledby="project-label" class="upload-select-wide">
		<mat-select required #projectSelector (selectionChange)="onProjectChange()" placeholder="{{'DownloadDataset.ProjectPlaceholder' | translate}}">
			<mat-option *ngFor="let project of projectDropdowns" [value]="project.key">
				{{ project.name }}
			</mat-option>
		</mat-select>
	</mat-form-field>
</div>
<mat-tab-group mat-align-tabs="center" *ngIf="selectedProjectCode">
	<mat-tab label="{{'DownloadDataset.UploadFiles' | translate}}">
		<ng-template matTabContent>
			<app-dataset-uploader [projectCode]="selectedProjectCode"></app-dataset-uploader>
		</ng-template>
	</mat-tab>
	<mat-tab label="{{'DownloadDataset.RemoveFiles' | translate}}"> 
		<ng-template matTabContent>
			<app-download-picker [projectCode]="selectedProjectCode"></app-download-picker>
		</ng-template>
	</mat-tab>
	<mat-tab label="{{'DownloadDataset.UpdateMetadata' | translate}}"> 
		<ng-template matTabContent>
			<app-dataset-uploader [projectCode]="selectedProjectCode" [isMetadataUpdate]="true"></app-dataset-uploader>
		</ng-template>
	</mat-tab>
</mat-tab-group>