import { TypeName } from "src/app/type-name";
import { AdGroupInfo } from "../services/graph/graph.service";

@TypeName.decorate("User")
export class User {
	constructor(
		readonly id: null | string  = null,
		readonly loginName: null | string  = null,
		readonly isSiteAdmin: null | boolean = null,
		readonly title: null | string  = null,
		readonly email: null | string  = null,
		readonly displayName: null | string  = null,
		readonly firstName: null | string = null,
		readonly lastName: null | string = null,
		readonly companyName: null | string = null,
		readonly authorized: null | boolean = null,
		readonly adGroups: null | AdGroupInfo[] = null
	) {}
}
