import { TypeName } from "src/app/type-name";
import { getPropertyNames } from "src/app/property-names";

@TypeName.decorate("ProjectDatasetModel")
export class ProjectDatasetModel {
	public constructor(
		public stakeholder: null | string = null,
		public projectCode: null | string = null,
		public projectTitle: null | string = null,
		public datasetFileName: null | string = null,
		public lookupFileId: null | string = null,
		public datasetName: null | string = null,
		public datasetDescription: null | string = null,
		public title: null | string = null,
		public id:      null | string = null,
	) { }

	public static readonly defaultPropertyNames = getPropertyNames(new ProjectDatasetModel());
}


@TypeName.decorate("DatasetModel")
export class DatasetModel {
	public constructor(
		public fileNames: null | string = null,
		public datasetFileName: null | string = null,
		public lastRefreshedDate: null | Date = null,
		public period: null | string = null,
		public title: null | string = null,
		public url: null | string = null,
		public lookupId:      null | string = null,
	) { }

	public static readonly defaultPropertyNames = getPropertyNames(new DatasetModel());
}

export type ProjectDatasetViewModel = ProjectDatasetModel & DatasetModel;