import { Subscription } from "rxjs";

export class Subscriptions {
	private readonly subscriptions = new Array<Subscription>();

	push(subscription: Subscription): void {
		this.subscriptions.push(subscription);
	}

	unsubscribeAll(): void {
		// start unsubscribing from last to first
		// if unsubscribe throws, don't pop it so whatever catches the exception can retry unsubscribe by calling this method again
		while (this.subscriptions.length > 0) {
			const subscription = this.subscriptions[this.subscriptions.length - 1];
			subscription.unsubscribe();
			this.subscriptions.pop();
		}
	}
}
