import { Injectable } from '@angular/core';
import { fromEvent, Observable } from 'rxjs';
import { Router } from '@angular/router';
import { Location } from '@angular/common';

@Injectable({
	providedIn: 'root'
})
export class NetworkService {
	onlineEvent: Observable<Event>;
	offlineEvent: Observable<Event>;
	online: boolean;
	isNetworkStopped = false;

	constructor(private router: Router, private location: Location) {
		this.online = window.navigator.onLine;

		fromEvent(window, 'online').subscribe(e => {
			this.online = true;
			this.location.back();
		});

		fromEvent(window, 'offline').subscribe(e => {
			this.online = false;
			this.router.navigate(['/no-internet']);
		});

		// window.addEventListener('online', () => {
		// 	this.online = true;
		// 	this.location.back();
		// });

		// window.addEventListener('offline', () =>{
		// 	this.online = false;
		// 	this.router.navigate(['/no-internet']);
		// });
	}
}
