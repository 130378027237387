import { Directive } from '@angular/core';
import { FlexDirective } from '@angular/flex-layout';

const selector = `[fxFlex],
                  [fxFlex.gt-smd]
                  `;

const inputs = ['fxFlex',
                'fxFlex.gt-smd'];
                
@Directive({selector, inputs})
export class CustomFlexDirective extends FlexDirective {
  protected inputs = inputs;
}