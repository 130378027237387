import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class SnackbarService {

	constructor(
		private snackBar: MatSnackBar
	) { }

	error(message: string) {
		this.snackBar.open(message, 'Close', {
			horizontalPosition: "center",
			verticalPosition: "bottom",
			panelClass: ['error-snackbar']
		});
		}

	success(message: string) {
		this.snackBar.open(message, 'Close', {
			horizontalPosition: "center",
			verticalPosition: "bottom",
			panelClass: ['success-snackbar']
		});
	}

	message(message: string) {
		this.snackBar.open(message, 'Close', {
			horizontalPosition: "center",
			verticalPosition: "bottom",
			panelClass: ['notification-snackbar']
		});
	}
}
