<div class="body" [ngClass]="{'app-light-theme': !themeService.isDark, 'app-dark-theme': themeService.isDark}">
	<h1 id="maincontent" tabindex="0" role="note">{{'PageNotFound.Title' | translate}}</h1>
	<p tabindex="0" role="note">
		{{'PageNotFound.Message' | translate}}
		<br/><br/> {{'PageNotFound.Message2' | translate}}
		<a [routerLink]="['/categories']"><u class="bold">{{'PageNotFound.Homepage' | translate}}</u></a> {{'PageNotFound.Message3' | translate}}
		<a href="mailto: sims@ontario.ca"><u class="bold">{{'PageNotFound.Email' | translate}}</u></a>
	</p>
	<img fxHide.lt-lg aria-hidden="true" alt="" src="assets/img/404_img_lg.svg">
	<img fxHide fxShow.md aria-hidden="true" alt="" src="assets/img/404_img_md.svg">
	<img fxHide fxShow.smd aria-hidden="true" alt="" src="assets/img/404_img_sm.svg">
	<img fxHide.gt-sm aria-hidden="true" alt="" src="assets/img/404_img_sm.svg">
</div>