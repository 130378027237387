<mat-card fxLayout="column" fxLayoutGap="25px" tabindex="-1" [ngClass]="{'mat-card-list': isInList && !isLowRes, 'app-light-theme': !themeService.isDark, 'app-dark-theme': themeService.isDark}">
	<div fxLayout="column" fxFlex="100">
		<mat-card-header>
			<mat-card-title>
				<div *ngIf="!attachment" appEllipsify [innerHtml]="article?.title"></div>
				<a *ngIf="attachment" href="{{environment.sharepointSiteBaseUrl + attachment?.fileUrl}}" target="_blank" rel="noopener noreferrer" matTooltip="{{'Common.Notifications.OpensInNewWindow' | translate}}"><div appEllipsify [innerHtml]="article?.title"></div></a>
			</mat-card-title>
		</mat-card-header>
		<mat-divider></mat-divider>
		<div class="info" fxLayout="column" fxLayout.gt-sm="row">
			{{'LatestReportsAndNews.News.Posted' | translate}}: {{article?.activeDate | dateFormatted }}
			<div *ngIf="article.author" fxLayout="row" fxLayoutGap="10px">
				<div fxHide fxShow.gt-sm>
					&nbsp;&bull;&nbsp;
				</div>
				<div>
					{{'LatestReportsAndNews.News.Author' | translate }}
				</div>
				<div>
					<a *ngIf="article.author && article.author.address" class="author" href="{{article?.author?.address}}" target="_blank" rel="noopener noreferrer" matTooltip="{{'Common.Notifications.OpensInNewWindow' | translate}}">{{article?.author?.description}}</a>
					<div *ngIf="!article.author || !article.author.address" class="author">{{article?.author?.description}}</div>
				</div>
			</div>
		</div>
		<mat-divider></mat-divider>
		<div *ngIf="article?.content" class="content" [ngClass]="{'dialog': isInDialog}">
			<div fxFlex="auto" fxFlex.gt-lg="{{isInList ? 'auto' : '' " fxLayout.gt-lg="{{isInList && !isLowRes ? 'row' : 'column'}}">
				<div fxHide fxShow.lt-lg [innerHtml]="article?.content">
					<div *ngIf="!isInDialog" (click)="viewMore()" class="view-more">{{'Reports.ViewMore' | translate}}</div>
				</div>

				<div fxHide fxShow.gt-md="{{!isInDialog}}" class="description">
					<div class="croppedTexts" [innerHtml]="article?.content"></div>
					<div *ngIf="!isInDialog" (click)="viewMore()" class="view-more">{{'Reports.ViewMore' | translate}}</div>
				</div>

				<div fxHide fxShow.gt-md="{{!isInList && isInDialog}}" fxFlex.gt-lg="{{isInList ? 'auto' : '' }}" [innerHtml]="article?.content"></div>
			</div>
		</div>
		<a *ngIf="attachment" href="{{environment.sharepointSiteBaseUrl + attachment?.fileUrl}}" target="_blank" rel="noopener noreferrer" matTooltip="{{'Common.Notifications.OpensInNewWindow' | translate}}" mat-flat-button color="primary" class="readMore">{{'Common.Controls.FullStory' | translate}}</a>
		<div *ngIf="!attachment && !isInList && !isInDialog && !isLowRes" class="padding-button"></div>
	</div>
</mat-card>