import { Hyperlink } from 'src/app/models/hyperlink.model';
import { TypeName } from "src/app/type-name";
import { getPropertyNames } from "src/app/property-names";

export type InstitutionType = "College" | "University" | "Indigenous Institute";

@TypeName.decorate("Institution")
export class Institution  {
	public constructor(
		public readonly name: null | string          = null,
		public readonly displayName: null | string          = null,
		public readonly logoImageSource: null | Hyperlink       = null,
		public readonly institutionType: null | InstitutionType = null,
	) {}

	public static readonly defaultPropertyNames = getPropertyNames(new Institution());
}
