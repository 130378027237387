import { User } from './../models/user.model';
import { Injectable } from '@angular/core';
import { ApplicationInsights, IExceptionTelemetry, IPageViewTelemetry } from '@microsoft/applicationinsights-web';
import { GraphUserService } from 'src/app/services/graph/user.service';
import { environment } from 'src/environments/environment';
import { VersionService } from './version.service';
import { LanguageService } from './language.service';

@Injectable({
	providedIn: 'root'
})


export class ApplicationInsightsService {

	private user: User;
	private waitingForUserId: IPageViewTelemetry[] | null = [];
	private uiLanguageCode: string;

	private readonly appInsights = new ApplicationInsights({
		config: {
			instrumentationKey: environment.appInsightsInstrumentationKey,
		}
	});

	constructor(
		private readonly userService: GraphUserService,
		private readonly languageService: LanguageService
	) {
		this.languageService.currentLanguageCode.subscribe(x => this.uiLanguageCode = x);

		this.applyUserId();
		this.appInsights.loadAppInsights();
	}

	applyUserId() {

		this.userService.getUserAsObservable().subscribe(user => {
			this.user = user;
			this.setUserId(this.user?.loginName);
			for (const telemetry of this.waitingForUserId || []) {
				this.appInsights.trackPageView(telemetry);
			}
			this.waitingForUserId = null;
		});

	}

	setUserId(userId?: string) {
		if (userId != null) {
			const validatedId = userId.replace(/[,;=| ]+/g, "_"); // https://docs.microsoft.com/en-us/azure/azure-monitor/app/api-custom-events-metrics#authenticated-users
			this.appInsights.setAuthenticatedUserContext(validatedId);
		}
	}

	clearUserId() {
		this.appInsights.clearAuthenticatedUserContext();
	}

	logPageView(name?: string, uri?: string) {
		const pageViewTelemetry: IPageViewTelemetry = { name, uri, properties: { buildNumber: VersionService.defaultCurrentVersion, langCode: this.uiLanguageCode } };
		if (this.waitingForUserId !== null) {
			this.waitingForUserId.push(pageViewTelemetry);
		}
		else {
			this.appInsights.trackPageView(pageViewTelemetry);
		}
	}

	logException(error: Error | string | any) {
		if (error.response != null) {
			const errorMessage = "Failed url ::> " + error.response.url + "\n" + error?.message;
			this.appInsights.trackException(new osimException(new Error(errorMessage)));
		} else if(error instanceof Error) {
			this.appInsights.trackException(new osimException(error));
		} else {
			this.appInsights.trackException(new osimException(new Error(error)));
		}
	}
}

class osimException implements IExceptionTelemetry {
	constructor(public error: Error) { }
}

