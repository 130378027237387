<div class="beta-report-slide" fxLayout="row" [ngClass]="{'app-light-theme': !themeService.isDark, 'app-dark-theme': themeService.isDark}" role="complementary" aria-labelledby="title">
    <!-- need or Sass doesn't match against theme changes -->
    <div fxLayout="column" class="betatext">
        <button (click)="CloseBetaPanel()" class="close-btn" [attr.aria-label]="('Common.Controls.Close' | translate)">
			<mat-icon>close</mat-icon>
		</button>
        <div>
            <div id="title" class="title"><img aria-hidden="true" alt="" src="assets/img/{{themeService.isDark ? 'dark/': ''}}beta_reports_icon.svg"> {{'Home.BetaReports.Title' | translate}}</div>
            <div class="subtitle" tabindex="0">{{'Home.BetaReports.SubTitle' | translate}}</div>
            <div class="text" tabindex="0" >{{'Home.BetaReports.Text' | translate}}</div>
            <button mat-flat-button color="primary" class="view-beta-reports" [attr.aria-label]="'Home.BetaReports.ButtonText' | translate" (click)="OpenBetaReports()">{{'Home.BetaReports.ButtonText' | translate}}</button>
        </div>
    </div>
</div>	