import { Directive, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[appA11yActivate]'
})
export class A11yActivateDirective {

  @Input('appA11yActivate') activation = {method: "", params: null, context: null}

  @HostListener('keyup.enter', ['$event.target'])
  @HostListener('keyup.space', ['$event.target'])
  @HostListener('click', ['$event.target'])
  onModify(e) {
    if (this.activation && this.activation.method && this.activation.context) {
      // If params are an array, use Apply.
      if (Array.isArray(this.activation.params)) {
        return this.activation.context[this.activation.method].apply(this.activation.context, this.activation.params);
      }
      // If not, use Call.
      return this.activation.context[this.activation.method].call(this.activation.context, this.activation.params);
    }
  }
}