<ol class="breadcrumb" [ngClass]="{'app-dark-theme': themeService.isDark}">
	<li *ngFor="let breadcrumb of breadcrumbs">
		<a *ngIf="breadcrumb.url === '//'" routerLink="/" routerLinkActive="router-link-active">
			<span [innerHtml]="(breadcrumb.label | async) | translate: breadcrumb.params"></span>
		</a>
		<a *ngIf="breadcrumb.url != '//'" [routerLink]="[breadcrumb.url, breadcrumb.params]" routerLinkActive="router-link-active">
			<span [innerHtml]="(breadcrumb.label | async) | translate: breadcrumb.params"></span>
		</a>
	</li>
</ol>
