<footer [ngClass]="{'app-light-theme': !themeService.isDark, 'app-dark-theme': themeService.isDark}" aria-label="Application Footer">
	<!-- need or Sass doesn't match against theme changes -->
	<div fxFlex [ngClass]="{'footer1-light': !themeService.isDark, 'footer1-dark': themeService.isDark}">
		<div fxFlex="100" fxLayoutAlign="space-evenly stretch">
			<div *ngIf="loggedIn" fxFlex="100" fxLayout="column" fxLayout.gt-smd="row" fxLayoutAlign="center center" fxLayoutAlign.gt-smd="center center">
				<button tabindex="0" id="footer-first-button" disableRipple="true" mat-button color="primary" routerLink="/categories" routerLinkActive="active-link" (click)="scrollToTop()">{{'Home.Title' | translate}}</button>
				<button mat-button color="primary" disableRipple="true" routerLink="/about" routerLinkActive="active-link" (click)="scrollToTop()">{{'AboutUs.Title' | translate}}</button>
				<button mat-button color="primary" disableRipple="true" routerLink="/help" routerLinkActive="active-link" (click)="scrollToTop()">{{'Help.Title' | translate}}</button>
				<button mat-button color="primary" disableRipple="true" routerLink="/accessibility" routerLinkActive="active-link" (click)="scrollToTop()">{{'Accessibility.Title' | translate}}</button>
				<button mat-button color="primary" disableRipple="true" routerLink="/terms" routerLinkActive="active-link" (click)="scrollToTop()">{{'Terms.Title' | translate}}</button>
				<button mat-button color="primary" disableRipple="true" routerLink="/contact" routerLinkActive="active-link" (click)="scrollToTop()">{{'ContactUs.Title' | translate}}</button>
				<ng-container *ngIf="(adminEntries| async)?.length">
					<button mat-button color="primary" disableRipple="true" routerLink="/admin" routerLinkActive="active-link" (click)="scrollToTop()">{{'Admin.ShortTitle' | translate}}</button>
				</ng-container>
			</div>
		</div>
	</div>
	<div fxFlex fxLayout="column" fxLayout.gt-smd="row" fxLayoutAlign="space-around center" fxLayoutAlign.gt-smd="space-between center" class="footer2-list">
		<div>©{{Year}} <strong><a class="externalLink" target="_blank" rel="noopener noreferrer" matTooltip="{{'Common.Notifications.OpensInNewWindow' | translate}}" href="{{'Common.Informational.Copyright.URL' | translate}}">{{'Common.Informational.Copyright.QueensPrinter' | translate}}</a></strong>{{'Common.Informational.Copyright.ForOntario' | translate}}</div>
		<div *ngIf="buildVersion" class="brand-and-version">
			{{'Common.Informational.AppNameAndVersion' | translate}} 
			<span class="version" *ngIf="!isProduction">
				<a class="externalLink" href="{{buildLink | async}}" target="_blank">
					{{buildVersion | async}}
				</a>
			</span>
			<span class="version" *ngIf="isProduction">{{buildVersion | async}}</span>
		</div>
	</div>
</footer>