import { Injectable } from '@angular/core';
import { FlexAlignStyleBuilder } from '@angular/flex-layout';
import {ActivatedRoute, ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from "@angular/router";
import { Observable, Subject } from 'rxjs';
import { GraphService } from '../graph/graph.service';
import { GraphUserService } from '../graph/user.service';
import { MsalService } from '../msal';

@Injectable()
export class AuthGuard implements CanActivate {
	constructor(private authService: MsalService, private router: Router, private userService: GraphUserService,  private graphService: GraphService) {
	}

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
		const loggedIn = !!this.authService.getAccount(); // User is already logged in
		if (loggedIn) {
			if (this.isAllowed(state.url))
			{
				return true;
			}
			const user = this.userService.getUser();
			if (user) {
				if (user.authorized) { // User Has resplved and is authorized
					return true;
				} else {
					this.router.navigateByUrl('sign-up');
					return false;
				}
			}
			return this.resolveUserAuthorization(); // User has not resolved
		}
		this.router.navigateByUrl('sign-in');
	}

	isAllowed(url: string) {
		if (url.includes('sign-out')){
			return true;
		}
		return false;
	}

	resolveUserAuthorization(): Observable<boolean> {
		const subj$ = new Subject<boolean>();
		this.graphService.getMe().subscribe(a => {
			let result = false;
			if (a)
			{
				result = a.authorized;
			}
			subj$.next(result);
		});
		return subj$;
	}

}
