import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
	selector: 'app-modal-search',
	templateUrl: './modal-search.component.html',
	styleUrls: ['./modal-search.component.scss']
})
export class ModalSearchComponent {

	constructor(
		public dialogRef: MatDialogRef<ModalSearchComponent>,
		private router: Router,
	) { }


	onClose(): void {
		this.dialogRef.close();
	}

	onSearch($event) {
		this.router.navigate(['/search/'], {queryParams: $event});
		this.onClose();
	}

}
