import { TypeName } from "src/app/type-name";
import { getPropertyNames } from "src/app/property-names";

@TypeName.decorate("AdminDashboardEntry")
export class AdminDashboardEntry{
	public constructor(
		public readonly title      : null | string = null,
		public readonly url        : null | string = null,
		public readonly description: null | string = null,
	) {}

	public static readonly defaultPropertyNames = getPropertyNames(new AdminDashboardEntry());
}