<div [ngClass]="{'app-light-theme': !themeService.isDark, 'app-dark-theme': themeService.isDark}">
    <div class="title">
        <h1 id="maincontent" tabindex="0">{{'UserGuides.Title' | translate}}</h1>
        <p tabindex="0">{{'UserGuides.Description' | translate}}</p>
    </div>
    <div class="content">
        <div class="full-width" fxLayout="column" fxLayout.gt-md="wrap" fxLayoutAlign="center">
            <mat-card>
                <h2><a [href]="userGuideUrl" target="_blank" rel="noopener noreferrer"><img src="assets/img/word_document_icon.svg" aria-hidden="true" alt="">{{'UserGuides.CardTitle' | translate}}</a></h2>
                <mat-divider></mat-divider>
                <div class="bottom-card" fxLayout="column" fxLayoutAlign="space-between flex-end" fxLayoutAlign.gt-smd="space-between center">
                    <p>{{'Help.Document.Paragraph' | translate}}</p>
                    <a [href]="userGuideUrl" target="_blank" rel="noopener noreferrer"><button class="arrow" mat-stroked-button><mat-icon>arrow_right_alt</mat-icon> {{'Help.Document.Button' | translate}}</button></a>
                </div>
            </mat-card>
        </div>
    </div>
</div>