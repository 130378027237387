<ng-template #headerImageTemplate let-i="i">
	<img fxHide fxShow.lt-md aria-hidden="true" alt="" src="assets/img/orientation_{{i}}_sm.svg" />
	<img fxHide fxShow.md aria-hidden="true" alt="" src="assets/img/orientation_{{i}}_md.svg" />
	<img fxHide fxShow.lg aria-hidden="true" alt="" src="assets/img/orientation_{{i}}_lg.svg" />
	<img fxHide fxShow.gt-lg aria-hidden="true" alt="" src="assets/img/orientation_{{i}}_xl.svg" />
</ng-template>
<app-stepper-orientation linear fxFlex fxLayout="column" fxLayoutGap.md="0" fxLayoutGap="1.7rem" fxLayoutAlign="space-between">
	<cdk-step *ngFor="let item of [].constructor(numberOfSteps); let i = index" state="test">
		<div class="top-image-container" *ngIf="!showVideo || i != 3">
			<a *ngIf="i == 3 && videoData$ | async as transcriptData" [href]="transcriptData.transcriptionUrl" target="_blank" class="transcript" matTooltip="{{'Common.Notifications.OpensInNewWindow' | translate}}">Transcript</a>
			<button *ngIf="i == 3" (click)="toggleVideo()">
				<ng-container [ngTemplateOutlet]="headerImageTemplate" [ngTemplateOutletContext]="{i: i}"></ng-container>
			</button>
			<ng-container *ngIf="i != 3" [ngTemplateOutlet]="headerImageTemplate" [ngTemplateOutletContext]="{i: i}"></ng-container>
		</div>
		<div [hidden]="!(i == 3 && showVideo)">
			<div class="top-image-container" fxLayoutAlign="center center" *ngIf="videoData$ | async as videoData">
				<app-video-player *ngIf="videoSrc$ | async as videoSrc; else showSpinner" [videoSrc]="videoSrc" [videoName]="videoData.videoName" [captionSrc]="videoData.captionsUrl" [transcriptUrl]="videoData.transcriptionUrl" [showTranscript]="true" class="video-player"></app-video-player>
				<ng-template #showSpinner>
					<mat-spinner></mat-spinner>
				</ng-template>
			</div>
		</div>
		<div class="bottom-text-container" role="alert" tabindex="-1">
			<div fxHide fxShow.lt-lg *ngIf="hasTranslation('Orientation.Screen' + i + '.BottomIntro')" class="welcome-intro">{{'Orientation.Screen' + i + '.BottomIntro' | translate}}</div>
			<div fxHide fxShow.lt-lg class="welcome-title">{{'Orientation.Screen' + i + '.BottomTitle' | translate}}</div>
			<div fxHide fxShow.lg class="welcome-title"><span *ngIf="hasTranslation('Orientation.Screen' + i + '.BottomIntro')">{{'Orientation.Screen' + i + '.BottomIntro' | translate}} </span>{{'Orientation.Screen' + i + '.BottomTitle' | translate}}</div>
			<div class="welcome-body" [innerHTML]="'Orientation.Screen' + i + '.BottomText' | translate"></div>
		</div>
	</cdk-step>
</app-stepper-orientation>
