import { Component, Input } from '@angular/core';
import { SafeUrl } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { GraphUserSettingsService } from './../../services/graph/user-settings.service';
import { VgApiService } from '@videogular/ngx-videogular/core';
import { ApplicationInsightsService } from 'src/app/services/application-insights.service';

@Component({
	selector: 'app-video-player',
	templateUrl: './video-player.component.html',
	styleUrls: ['./video-player.component.scss']
})
export class VideoPlayerComponent{
	@Input() videoSrc: SafeUrl;
	@Input() captionSrc: SafeUrl;
	@Input() transcriptUrl: SafeUrl;
	@Input() showTranscript: boolean;
	@Input() videoName: string;
	public captionsOn: boolean;
	api: VgApiService;
	hasVideoStarted: boolean = false;

	constructor(public translateService: TranslateService,
		private userSettingsService: GraphUserSettingsService,
		private applicationInsightsService: ApplicationInsightsService,
	) {
		this.captionsOn = this.userSettingsService.getUserSetting('captions') === 'true';
  }

  toggleCaptions() {
    this.captionsOn = !this.captionsOn;
    this.userSettingsService.setUserSetting('captions', String(this.captionsOn));
  }

  onPlayerReady(api: VgApiService) {
	this.api = api;

	this.api.getDefaultMedia().subscriptions.play.subscribe(() => {
		if(!this.hasVideoStarted) {
			this.hasVideoStarted = true;
			this.applicationInsightsService.logPageView(`Video`, this.videoName);
		}
	});
  }

}
