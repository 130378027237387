import { OverlayContainer } from '@angular/cdk/overlay';
import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { GraphUserSettingsService } from 'src/app/services/graph/user-settings.service';
import { GraphUserService } from 'src/app/services/graph/user.service';
import { LanguageService } from 'src/app/services/language.service';
import { MsalService } from 'src/app/services/msal';
import { ThemeService } from 'src/app/shared/services/theme.service';
import { ModalPopupComponent } from '../modal-popup/modal-popup.component';

@Component({
	selector: 'app-modal',
	templateUrl: './modal-profile.component.html',
	styleUrls: ['./modal-profile.component.scss']
})
export class ModalProfileComponent implements OnInit {
	isDarkTheme: Observable<boolean>;
	profileName: string;
	profileEmail: string;
	captionsOn: boolean;

	constructor(
		@Inject(DOCUMENT) private readonly document: Document,
		private readonly translate: TranslateService,
		public translateService: TranslateService,
		private authService: MsalService,
		private languageService: LanguageService,
		public themeService: ThemeService,
		public dialogRef: MatDialogRef<ModalProfileComponent>,
		public overlayContainer: OverlayContainer,
		private userService: GraphUserService,
		private userSettingsService: GraphUserSettingsService,
		private dialog: MatDialog,
		@Inject(MAT_DIALOG_DATA) public data) {
			this.profileName = this.userService.getUser().displayName;
			this.profileEmail = this.userService.getUser().email;
		}

	onClose(): void {
		this.dialogRef.close();
	}

	openSignOutPopup(): void {
		this.dialogRef.close();
		let openDialog = this.dialog.open(ModalPopupComponent, {
			width: 'rem(300px)',
			panelClass: 'popup',
			data: { popupTitle: this.GetFormattedUserName(this.profileName) + this.translate.instant('SignOut.Header'),
					popupMessage: this.translate.instant('SignOut.Message'), 
					popupOk: this.translate.instant('SignOut.SignOut'), 
					popupCancel: this.translate.instant('SignOut.Cancel')
				}
		});

		openDialog.beforeClosed().subscribe((value) => {
			if (value == 'confirm') {
				this.authService.logout();
			}
		});
	}

	ngOnInit() {
		const isDarkTheme = this.userSettingsService.getUserSetting('dark') === 'true';
		this.themeService.setDarkTheme(isDarkTheme);

		this.captionsOn = this.userSettingsService.getUserSetting('captions') === 'true';
	}

	GetFormattedUserName(name) {
		try {
			if (!this.profileEmail?.endsWith("@ontario.ca")) { return name; }
			const splitname = name.split(" (")[0].split(", ");
			const result = splitname[1] + " " + splitname[0];
			return result.indexOf('undefined') >= 0 ? name : result;
		} catch {
			return name;
		}
	}

	toggleDarkTheme(checked: boolean) {
		this.themeService.setDarkTheme(checked);
		this.userSettingsService.setUserSetting('dark', String(checked));
	}

	toggleVideoCaptioning(checked: boolean) {
		this.captionsOn = checked;
		this.userSettingsService.setUserSetting('captions', String(checked));
	}

	toggleLang() {
		const newLang = this.translateService.currentLang === 'en' ? 'fr' : 'en';
		this.translateService.use(newLang);
		this.languageService.overrideCurrentBrowserLanguage(newLang);
		this.document.documentElement.setAttribute("lang", newLang); // <html lang="en">

		if (newLang === "fr") this.languageService.displayFrenchPopup();
	}
}
