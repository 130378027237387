import { Injectable } from "@angular/core";
import { BehaviorSubject, from, Observable } from "rxjs";
import { concatMap, map, shareReplay } from "rxjs/operators";
import { Hyperlink } from "src/app/models/hyperlink.model";
import { Report } from "src/app/models/report.model";
import { LanguageService } from "src/app/services/language.service";
import { compareDates } from "../../compare-dates";
import { GraphService, ColumnMapEntry } from './graph.service';
import { Constants, ResourceNames } from "./resource-names";
import { GraphUserSettingsService } from "./user-settings.service";

@Injectable({
	providedIn: 'root'
})
export class GraphReportService {

	static readonly columnMap: ReadonlyArray<ColumnMapEntry<Report>> = [
		{ modelKey: "id", spFriendlyFieldName: "ID", transformer: x => x ? (x + "") : null, /* convert to string (wtf javascript?! toString doesn't work) */ },
		{ modelKey: "title", spFriendlyFieldName: "Title", },
		{ modelKey: "code", spFriendlyFieldName: "ReportCode", },
		{ modelKey: "description", spFriendlyFieldName: "ReportDescription", },
		{ modelKey: "lastRefresh", spFriendlyFieldName: "LastDataRefresh" },
		{ modelKey: "categories", spFriendlyFieldName: "ReportCatKey", },
		{ modelKey: "dataOwner", spFriendlyFieldName: "DataOwner", transformer: x => Hyperlink.from(x) },
		{ modelKey: "dataProvider", spFriendlyFieldName: "DataProvider" },
		{ modelKey: "tags", spFriendlyFieldName: "ReportTags", transformer: x => GraphReportService.split(x, Constants.SEPARATOR_SEMICOLON), },
		{ modelKey: "url", spFriendlyFieldName: "URL", transformer: x => Hyperlink.from(x) },
		{ modelKey: "status", spFriendlyFieldName: "ReportStatus" },
		{ modelKey: "videoId", spFriendlyFieldName: "VideoID", transformer: x => Hyperlink.from(x) },
		{ modelKey: "linkToData", spFriendlyFieldName: "LinktoData", transformer: x => Hyperlink.from(x) },
		{ modelKey: "languageCode", spFriendlyFieldName: "SiteLanguage" },
	];

	reportList: BehaviorSubject<Report[]> = new BehaviorSubject<Report[]>([]);

	static split(fieldValue: any, separator: string): string[] | null {
		if (typeof fieldValue !== 'string') {
			return null;
		}
		return fieldValue.split(separator).filter((x: any) => x);
	}

	constructor(
		private graphService: GraphService,
		private languageService: LanguageService,
		private userSettingsService: GraphUserSettingsService
	) {
		this.languageService.overrideCurrentBrowserLanguage(this.userSettingsService.getUserSetting('lang'));
	}

	getReports(): Observable<Report[]> {

		if (this.reportList.value.length > 0) {
			return this.reportList;
		}

		const entities = this.graphService.getListItems<Report>(ResourceNames.REPORT_LIST_NAME, Report, GraphReportService.columnMap);
		// filter the sharepoint data using the current language according to the language service
		this.languageService
			.filterByLanguage(from(entities))
			.pipe(map(
				x => x.sort(
					(a, b) => compareDates(a.lastRefresh, b.lastRefresh)
				)
			)).subscribe((result) => {
				this.reportList.next(result);
			});
		return this.reportList;
	}

	readonly reportList$ = this.graphService.getListItems<Report>(ResourceNames.REPORT_LIST_NAME, Report, GraphReportService.columnMap).pipe(
		concatMap((entities) => this.languageService.filterByLang(entities)),
		map((filteredData) => filteredData.sort((a, b) => compareDates(a.lastRefresh, b.lastRefresh))),
		shareReplay({
			refCount: false,
			bufferSize: 1,
		})
	);
}