import { SearchableViewModel } from './searchable-view-model';
import { Hyperlink } from "../models/hyperlink.model";
import { Status } from "../models/report.model";
import { BehaviorSubject } from "rxjs";

export class ReportViewModel extends SearchableViewModel {
	constructor(
		readonly model: any,
		readonly id: string,
		readonly title: string,
		readonly code: string,
		readonly description: string,
		readonly dataOwner: Hyperlink,
		readonly url: Hyperlink,
		readonly categoryKeys: string[],
		readonly properties: { [key: string]: any },
		readonly tags: string[],
		readonly published: null | Date,
		readonly status: null | Status,
		readonly lang: null | string,
		readonly pirlReportCode?: null | string,
		readonly downloadableFiles?: DownloadableFile[],
	) {
		super(model, id, title, description, tags);
	}

	readonly isBookmarked = new BehaviorSubject<boolean>(false);
}

export type DownloadableFile = {
	type: string;
	name: string;
	url: Hyperlink;
};