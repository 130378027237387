import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { AdminDashboardEntry } from 'src/app/models/admin-dashboard-entry.model';
import { GraphAdminDashboardService } from 'src/app/services/graph/admin-dashboard.service';
import { GraphUserService } from 'src/app/services/graph/user.service';
import { MsalBroadcastService } from 'src/app/services/msal';
import { ThemeService } from 'src/app/shared/services/theme.service';
import { VersionService } from './../../services/version.service';
import { filter, takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Component({
	selector: 'app-footer',
	templateUrl: './footer.component.html',
	styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit, OnDestroy {
	loggedIn = this.userService.getUser() !== undefined;
	Year: number = new Date().getFullYear();
	adminEntries: Observable<AdminDashboardEntry[]>;
	private readonly _destroying$ = new Subject<void>();
	readonly environment = environment;
	buildVersion: Observable<string>;
	buildLink: Observable<string>;
	isProduction: boolean;

	constructor(public themeService: ThemeService,
		public versionService: VersionService,
		public userService: GraphUserService,
		private broadcastService: MsalBroadcastService,
		readonly adminDashboardService: GraphAdminDashboardService,
	) {	}

	scrollToTop() {
		window.scroll(0, 0);
	}

	ngOnInit() { // need to wait for a token before querying the service. 
		this.isProduction = this.isEnvironment('production') || this.isEnvironment('local');

		this.broadcastService.msalSubject$
		.pipe(
		  filter(msg => msg.type === 'osims:loginSuccess'),
		  takeUntil(this._destroying$)
		).subscribe((result) => {
				this.adminEntries = this.adminDashboardService.getEntries();
		});

		this.buildVersion = this.versionService.currentVersion;	
		this.buildLink = this.versionService.currentBuildLink;
	}

	ngOnDestroy() {
		this._destroying$.unsubscribe();
	}

	isEnvironment(type: string): boolean {
		return environment.name.toLocaleLowerCase().includes(type);
	}
}
