import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, FormGroupDirective } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Subject, Subscription, timer } from "rxjs";
import { mapTo, switchMap, filter } from 'rxjs/operators';

import { SubjectArea } from 'src/app/models/subject-area.model';
import { ThemeService } from 'src/app/shared/services/theme.service';
import { DataFilesManagementService } from 'src/app/services/graph/data-files-management.service';
import { DataFileModel } from 'src/app/models/data-file.model';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ApprovalWorkflowService, PROD_ENV_KEY, UAT_ENV_KEY } from 'src/app/services/approval-workflow.service';
import { ConfirmationPopupService } from 'src/app/shared/services/confirmation-popup.service';
import { Constants, FileUploadStatuses } from 'src/app/services/graph/resource-names';
import { IntermediateStorageModel } from 'src/app/models/intermediate-storage.model';
import { environment } from 'src/environments/environment';
import { Location } from '@angular/common';
import { SharepointDataSource } from 'src/app/services/graph/graph.service';
import { isBrowserRefreshed } from 'src/app/app.component';

@Component({
  selector: 'app-data-files-management',
  templateUrl: './data-files-management.component.html',
  styleUrls: ['./data-files-management.component.scss']
})
export class DataFilesManagementComponent implements OnInit, OnDestroy {

	@ViewChild('fileUpload', {static: false}) fileSelector: ElementRef;
	@ViewChild('form') form : FormGroupDirective;

	pageRefreshSub: Subscription;

	subjectAreas: SubjectArea[];
	eventsSubject: Subject<void> = new Subject<void>();

	readonly dataFileForm = new FormGroup({
		subjectAreaControl: new FormControl(),
		commentControl: new FormControl(),
	});

	env: string;

	currentLanguageCode: string;

	areas: SubjectAreaExtended[];
	selectedSubjectArea: SubjectArea = undefined;

	fileToUpload: File | null = null;

	fileName = "";
	isFileNameValid = true;
	isUploadStarted = false;
	isUploadFailed = false;
	isMultipleFilesSubjectArea = false;
	isAllFilesUploaded = false;
	spinnerMode = "indeterminate";
	uploadProgress:number;
	requiredFileType: string;

	selectedReportCode: string;
	selectedReportName: string;
	selectedSubjectAreaCode: string;

	canManageFiles: boolean = true;

	constructor(
		private readonly dataFilesService: DataFilesManagementService,
		private readonly workflowService: ApprovalWorkflowService,
		private translateService: TranslateService,
		private activatedRoute: ActivatedRoute,
		public themeService: ThemeService,
		private snackBar: SnackbarService,
		private popupService: ConfirmationPopupService,
		private router: Router,
		private location: Location
	) { }

	ngOnInit(): void {
		this.requiredFileType = '.csv';
		this.currentLanguageCode = this.translateService.currentLang;

		this.selectedReportCode = this.activatedRoute.snapshot.params.report || this.activatedRoute.snapshot.queryParamMap.get('report') || "NO_REPORT_SELECTED";
		this.env = isBrowserRefreshed ? localStorage.ApprovalWorkflowEnv ?? this.getEnvFromUrl() : this.getEnvFromUrl();
		this.canManageFiles = environment.dataFilesManagement.hasUatApprovalWorkflow && this.isUat();

		this.confirmEnvironment();
		this.initDropDowns();

		this.pageRefreshSub = timer(0, 30 * 1000).subscribe(_ => this.reloadGrid());
	}	

	ngOnDestroy(): void {
		this.pageRefreshSub.unsubscribe();
	}

	getEnvFromUrl() {
		const env = this.activatedRoute.snapshot.params.env || this.activatedRoute.snapshot.queryParamMap.get('env') || PROD_ENV_KEY;
		localStorage.setItem("ApprovalWorkflowEnv", env);
		return env;
	}

	isUat() {
		return this.env.toLowerCase() === UAT_ENV_KEY;
	}

	confirmEnvironment() {
		if (this.isUat() && !environment.dataFilesManagement.hasUatApprovalWorkflow) {
			this.env = PROD_ENV_KEY;
		}

		this.excludeEnvParameterFromUrl();
	}

	excludeEnvParameterFromUrl() {
		const url = this.router.createUrlTree([], {relativeTo: this.activatedRoute, queryParams: {report: this.selectedReportCode}}).toString()
 		this.location.go(url);
	}

	initDropDowns(): void {
		this.workflowService.getSubjectAreas().subscribe(
			areas => {
				this.subjectAreas = areas;
				this.areas = areas.filter(item => item.reportCode === this.selectedReportCode).sort((a, b) => a.subjectAreaTitle.localeCompare(b.subjectAreaTitle)) as SubjectAreaExtended[];
				
				// temp option disabling prototype
				const mcuOsap = this.areas.find(x => x.reportCode === "DataCompass" && x.subjectAreaCode === "OSAP_SECTOR");
				if (mcuOsap) mcuOsap.isDisabledInReport = true;
				
				this.selectedReportName = areas.find(x => x.reportCode === this.selectedReportCode).reportName;
			}
		);
	}

	onSubjectAreaChange() {
		this.isUploadFailed = false;
		const selectedSubjectAreaCode = this.dataFileForm.get('subjectAreaControl').value;

		this.selectedSubjectArea = this.areas.find(x => x.subjectAreaCode === selectedSubjectAreaCode);

		if (this.selectedSubjectArea.isActive) {
			if (this.fileName) this.validateFileName();
			this.isMultipleFilesSubjectArea = this.selectedSubjectArea.triggerFileName != null;
			this.checkAllFilesUploaded();
		} else {
			this.snackBar.error(`${this.translateService.instant('DataFilesManagement.UploadForbidden')}`);
		}
	}

	onFileSelected(event: Event): void {
		const input = event.target as HTMLInputElement;
		this.fileToUpload = input.files[0];
		this.fileName = this.fileToUpload.name;
		this.isUploadFailed = false;

		this.isFileNameValid = this.validateFileName();
	}

	onUploadFile() {
		this.isUploadFailed = false;

		if (this.fileToUpload && this.isFileNameValid) {
		 	this.isUploadStarted = true;

			const metadata = new IntermediateStorageModel(this.fileToUpload.name, this.fileToUpload.name);
 
			this.dataFilesService.uploadToIntermediateStorage(this.fileToUpload, metadata).subscribe(
				fileId => this.submitFileName(fileId), 
				error => this.onUploadError(error));
		}
	}

	onUploadError(error: any) {
		error = this.checkErrorType(error);
		const errorText = error.error instanceof String ? error.error : error.message
		this.snackBar.error(errorText);
		this.isUploadFailed = true;
		this.isUploadStarted = false;
	}

	validateFileName(): boolean {
		let isValid = false;
		
		const expectedFileNames = this.areas.find(x => x.subjectAreaCode === this.selectedSubjectArea.subjectAreaCode)?.sourceFileName.split(Constants.SEPARATOR_SEMICOLON);
		for (let expectedName of expectedFileNames) {
			if (this.workflowService.wildcardCompare(expectedName, this.fileName)) {
				isValid = true;
				break;
			}
		}

		if (!isValid) this.snackBar.error(
			`${this.translateService.instant('DataFilesManagement.IncorrectFileName')}.\n${this.translateService.instant('DataFilesManagement.Expected')}: ${expectedFileNames.join(" or ")}\n${this.translateService.instant('DataFilesManagement.CaseSensitive')}`);
		return isValid;
	}

	reset() {
		this.isUploadStarted = false;
		this.uploadProgress = null;
		this.fileToUpload = null;
		this.selectedSubjectAreaCode = this.selectedSubjectArea.subjectAreaCode;
		this.selectedSubjectArea = undefined;
		this.resetFileSelector();
	}

	resetFileSelector(timeout?: number) {
		setTimeout(() => {
			this.form.resetForm();
			this.fileName = "";
			this.isUploadFailed = false;
			this.fileSelector.nativeElement.value = "";
		}, 
		timeout || 0);
	}

	checkAllFilesUploaded(params?: { isFileJustUploaded: boolean}) {
		this.isAllFilesUploaded = false;
		const isFileJustUploaded = params?.isFileJustUploaded ?? false;
		this.workflowService.checkAllFilesUploadedForSubjectArea(
			this.selectedSubjectArea.subjectAreaCode, 
			this.dataFilesService.getDataFiles()
		).subscribe(allFilesUploaded => {
			this.isAllFilesUploaded = allFilesUploaded;

			if (this.isMultipleFilesSubjectArea) {
				if (allFilesUploaded) {
					if (isFileJustUploaded) {
						this.onPipelineTrigger();
					}
				} else if (this.selectedSubjectArea.isFlexible) {
					this.displayFlexibleSubjectAreaNotice();
				} else {
					this.displayMultipleFilesSubjectAreaNotice();
				}
			} else if (isFileJustUploaded && allFilesUploaded) {
					this.triggerPipeline();
			}
		});
	}

	displayMultipleFilesSubjectAreaNotice() {
		if (this.isMultipleFilesSubjectArea) {
			this.snackBar.message(
				`${this.translateService.instant('DataFilesManagement.MultipleFilesSubjectArea_NoticeStart')}${this.selectedSubjectArea.subjectAreaTitle}${this.translateService.instant('DataFilesManagement.MultipleFilesSubjectArea_NoticeEnd')}`);
		}
	}

	displayFlexibleSubjectAreaNotice() {
		if (this.selectedSubjectArea.isFlexible) {
			this.snackBar.message(
				`${this.translateService.instant('DataFilesManagement.MultipleFilesSubjectArea_NoticeStart')}${this.selectedSubjectArea.subjectAreaTitle}${this.
					translateService.instant('DataFilesManagement.FlexibleSubjectArea_NoticeMiddle')}${this.selectedSubjectArea.subjectAreaCode}${this.translateService.instant('DataFilesManagement.FlexibleSubjectArea_NoticeEnd')}`);
		}
	}

	onPipelineTrigger() {
		if(this.isAllFilesUploaded) {
			if(this.isMultipleFilesSubjectArea) {
				const dialogRef = this.popupService.openConfirmDialog(
					"DataFilesManagement.AllFilesUploaded", 
					null, 
					"Common.Controls.Yes", 
					"DataFilesManagement.UploadMoreFiles"
				);
				dialogRef.afterClosed().pipe(
					filter(dialogResult => dialogResult)
				).subscribe(dialogResult => {
					if (dialogResult) this.triggerPipeline();
				});
			} else {
				this.triggerPipeline();
			}
		}
	};

	triggerPipeline() {
		this.isUploadFailed = false;
		this.isUploadStarted = true;

		const subjectArea = this.selectedSubjectArea?.subjectAreaCode ?? this.selectedSubjectAreaCode;

		this.dataFilesService.getDataFilesToTriggerPipeline(subjectArea).pipe(
			switchMap(files => this.workflowService.uploadToAdf(files).pipe(mapTo(files))),
			switchMap(files => this.workflowService.dropTriggerFile(subjectArea, this.selectedReportCode).pipe(mapTo(files))),
			switchMap(files => this.dataFilesService.markAsUploaded(files, SharepointDataSource.Production))
			).subscribe(() => {
				this.reloadGrid();
				this.snackBar.success(`${this.translateService.instant('ReportRefresh.MultiFilesPipelineTriggerred')}`);
				this.isAllFilesUploaded = false;
				this.reset();
			}
			, error => this.onUploadError(error)
		);
	}

	uploadCompleted() {
		this.snackBar.success(`${this.fileName} ${this.translateService.instant('DataFilesManagement.Uploaded')}`);
		this.reloadGrid();
		this.checkAllFilesUploaded({ isFileJustUploaded: true });
		this.reset();
	}

	reloadGrid() {
		this.eventsSubject.next();
	}

	async onRefresh() {
		this.isUploadStarted = true;
		await new Promise(f => setTimeout(f, 500));
		this.reloadGrid();
		this.isUploadStarted = false;
	}

	onBack() {
		if (this.isUat()) this.router.navigate(['/uat-workflow'])
		else this.router.navigate(['/prod-workflow']);
	}

	async submitFileName(fileId: number) {
		const subjectAreaCode = this.dataFileForm.get('subjectAreaControl').value;
		const comment = this.dataFileForm.get('commentControl').value;

		await this.dataFilesService.saveUploadedFileHistory(new DataFileModel(this.fileName, this.getReportNamesBySubjectAreaCode(subjectAreaCode), subjectAreaCode, comment, FileUploadStatuses.PENDING, fileId)).pipe(
			switchMap(_ => this.workflowService.setNewFilesAddedStatus(this.selectedReportCode))
		).subscribe(_ => this.uploadCompleted());
	}

	private getReportNamesBySubjectAreaCode(subjectAreaCode: string): string {
		return this.subjectAreas.filter(x => x.subjectAreaCode === subjectAreaCode).map(x => x.reportCode).join(Constants.SEPARATOR_SEMICOLON);
	}

	private checkErrorType(error: any): any {
		if (error.status === 401 || error.status === 403) {
			return new Error(`${this.translateService.instant('DataFilesManagement.UnauthorizedError')}`);
		} else if (error.error == "FileAlreadyExists") {
			return new Error(`${this.translateService.instant('DataFilesManagement.ErrorFileExists')}`);
		} else if (error.message.includes("Unknown Error")) {
			return new Error(`${this.translateService.instant('DataFilesManagement.ErrorUnknown')}`);
		}
		return error;
	}
}

export class SubjectAreaExtended extends SubjectArea {
	public isDisabledInReport: boolean = false;
}