<div class="title" *ngIf="!isForRemoval">
	<div id="PeriodName">{{selectedProjectName}}</div>
</div>

<div class="refresh-container">
	<div class="grid-container">
		<mat-sidenav-container fxFlex="0 1 auto" [ngClass]="{'app-light-theme': !themeService.isDark, 'app-dark-theme': themeService.isDark}">
			<div class="table-container">
				<table mat-table *ngIf="projectDataSets.length > 0" [dataSource]="dataSource"  matSort (matSortChange)="sortData($event)" aria-labelledby="PeriodName">
					<!-- ['id', 'datasetName', 'datasetDescription', 'lastRefreshedDate', 'fileNames', 'action']; -->
					<!-- id -->
					<ng-container matColumnDef="id">
						<mat-header-cell *matHeaderCellDef mat-sort-header> ID </mat-header-cell>
						<mat-cell *matCellDef="let element"> {{element.id}} </mat-cell>
					</ng-container>
					<!-- datasetName -->
					<ng-container matColumnDef="datasetName">
						<mat-header-cell *matHeaderCellDef mat-sort-header [ngClass]="isForRemoval ? 'mat-column-datasetName-wide' : ''"> {{'DownloadDataset.Dataset' | translate}} </mat-header-cell>
						<mat-cell *matCellDef="let element" [ngClass]="isForRemoval ? 'mat-column-datasetName-wide' : ''"> {{element.datasetName}} </mat-cell>
					</ng-container>
					<!-- datasetDescription -->
					<ng-container matColumnDef="datasetDescription">
						<mat-header-cell *matHeaderCellDef mat-sort-header> {{'DownloadDataset.Description' | translate}} </mat-header-cell>
						<mat-cell *matCellDef="let element"> {{element.datasetDescription}} </mat-cell>
					</ng-container>
					<!-- lastRefreshedDate -->
					<ng-container matColumnDef="lastRefreshedDate">
						<mat-header-cell *matHeaderCellDef mat-sort-header> {{'DownloadDataset.RefreshDate' | translate}} </mat-header-cell>
						<mat-cell *matCellDef="let element"> {{element.lastRefreshedDate | dateFormatted:'mediumDate' }} </mat-cell>
					</ng-container>
					<!-- fileNames -->
					<ng-container matColumnDef="fileNames">
						<mat-header-cell *matHeaderCellDef mat-sort-header [ngClass]="isForRemoval ? 'mat-column-fileNames-wide' : ''"> {{'DownloadDataset.FilesInDataset' | translate}} </mat-header-cell>
						<mat-cell *matCellDef="let element" [ngClass]="isForRemoval ? 'mat-column-fileNames-wide' : ''">
							<div class="croppedTexts" title="{{element.fileNames}}" [innerHTML]="element.fileNames | newline"></div>
						</mat-cell>
					</ng-container>
					<!-- action -->
					<ng-container matColumnDef="action">
						<mat-header-cell *matHeaderCellDef> {{'DownloadDataset.Action' | translate}} </mat-header-cell>
						<mat-cell *matCellDef="let element">
							<button *ngIf= "!isForRemoval" (click)="onDownloadDataset(element)" matTooltip="{{'DownloadDataset.DownloadDataset' | translate}}" mat-flat-button color="primary">{{'Common.Controls.Download' | translate}}</button>
							<button *ngIf= "isForRemoval" (click)="onRemoveFromDataset(element)" matTooltip="{{'DownloadDataset.ClickToRemove' | translate}}" mat-flat-button color="primary">{{'DownloadDataset.RemoveFile' | translate}}</button>
						</mat-cell>
					</ng-container>
					
					<mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
					<mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
				</table>
			</div>
		</mat-sidenav-container>
	</div>
</div>