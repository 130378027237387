import { Component, OnInit } from '@angular/core';
import { LanguageService } from 'src/app/services/language.service';
import { ThemeService } from 'src/app/shared/services/theme.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-user-guides',
  templateUrl: './user-guides.component.html',
  styleUrls: ['./user-guides.component.scss']
})
export class UserGuidesComponent implements OnInit {

	public userGuideUrl: string;

	constructor(
		public themeService: ThemeService,
		private languageService: LanguageService
	) { }

	ngOnInit(): void {
		this.languageService.currentLanguageCode.subscribe(x => this.userGuideUrl = x.toLowerCase() === "en" ? environment.userGuideEnUrl : environment.userGuideFrUrl);
	}
}