import { ApplicationInsightsService } from './application-insights.service';
import { ErrorHandler, Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root'
})
export class ErrorHandlerService extends ErrorHandler {

	constructor(private applicationInsightsService: ApplicationInsightsService) {
		super();
	}

	handleError(error: Error) {
		this.applicationInsightsService.logException(error); // Manually log exception
	}
}
