<div class="title">
	<div id="maincontent" tabindex="0">{{'DownloadDataset.UploadTitle' | translate}}</div>
</div>
<div class="project-selector" [ngClass]="{'app-light-theme': !themeService.isDark, 'app-dark-theme': themeService.isDark}">
	<label id="project-label">{{ 'DownloadDataset.Organization' | translate }}</label>
	<mat-form-field appearance="outline" aria-labelledby="project-label" class="upload-select-wide">
		<mat-select required #organizationsSelector (selectionChange)="onOrganizationSelected()" placeholder="{{'DownloadDataset.ProjectPlaceholder' | translate}}">
			<mat-option *ngFor="let org of organizationDropdowns" [value]="org.key">
				{{ org.name }}
			</mat-option>
		</mat-select>
	</mat-form-field>
</div>