import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ThemeService } from 'src/app/shared/services/theme.service';

@Component({
	selector: 'app-modal-popup',
	templateUrl: './modal-popup.component.html',
	styleUrls: ['./modal-popup.component.scss']
})
export class ModalPopupComponent implements OnInit {

	alignClass: string;
	popupTitle: string;
	popupMessage: string;
	popupOk: string;
	popupCancel: string;
	reloadOnClose = true;
	closeWindowOnOk = false;

	constructor(
		readonly dialogRef: MatDialogRef<ModalPopupComponent>,
		@Inject(MAT_DIALOG_DATA) readonly data: any,
		public themeService: ThemeService
	) { }

	ngOnInit(): void {
		this.popupTitle = this.data.popupTitle;
		this.popupMessage = this.data.popupMessage;
		this.popupOk = this.data.popupOk;
		this.popupCancel = this.data.popupCancel
		this.reloadOnClose = this.data.reloadOnClose !== "undefined" ? this.data.reloadOnClose : this.reloadOnClose;
		this.closeWindowOnOk = this.data.closeWindowOnOk !== "undefined" ? this.data.closeWindowOnOk : this.closeWindowOnOk;
		this.alignClass = this.data.alignClass || "align-center";
	}

	onCancel(): void {
		if (this.popupCancel) {
			this.dialogRef.close(false);
		}
	}

	onOk(): void {
		if (this.closeWindowOnOk) {
			window.close();
		}
		if (this.reloadOnClose) {
			window.location.reload();
		}
		this.dialogRef.close(true);
	}
}

