/**
 * This class supports retrieving a class type name at run-time. It requires TypeScript decorators.
 * Usage: Register the typename with `@TypeName.decorate(...)`
 * 
 * @TypeName.decorate("Foo")
 * class Foo {
 *     readonly text: string = "text";
 * }
 * 
 * const typeName = TypeName.tryGet(new Foo());
 * assert(typeName === "Foo")
 * 
 */

export class TypeName {
	private constructor() {}

	private static readonly typeNameSymbol = Symbol("typeName");

	public static decorate(name: string): Function {
		return function getExtendedContructor<Base extends { new(...args: any[]): any }>(target: Base) {
			return class Constructor extends target {
				constructor(...args: any[]) {
					super(...args);
					(this as any)[TypeName.typeNameSymbol] = name;
				}
			}
		}
	}

	public static tryGet(o: any): string | undefined {
		if (o === undefined || o === null || typeof o[TypeName.typeNameSymbol] !== typeof "")
			return undefined;
		return o[TypeName.typeNameSymbol];
	}
}