import { KeyValue } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { GlossaryEntry } from 'src/app/models/glossary-entry.model';
import { GraphGlossaryService } from 'src/app/services/graph/glossary.service';
import { ThemeService } from 'src/app/shared/services/theme.service';

@Component({
	selector: 'app-glossary',
	templateUrl: './glossary.component.html',
	styleUrls: ['./glossary.component.scss']
})
export class GlossaryComponent implements OnInit, OnDestroy {
	searchResult: GlossaryEntry[] = [];
	keywords: string;
	sortValue = "title";
	sortAsc = true;
	list: Observable<any>;
	searchField = new FormControl('');
	categoryField = new FormControl('');

	subscriptions$: Subscription[] = [];

	letter: string | null = null;
	glossaryMap = new Map<string, Map<string, string>>();

	public alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');

	constructor(
		public themeService: ThemeService,
		private readonly glossaryService: GraphGlossaryService,
		private readonly route: ActivatedRoute,
	) {
	}

	ngOnInit(): void {
		this.getGlossary(null);
	}

	getKeys(map: Map<string, string>): Map<number, string> {
		const result = new Map<number, string>();
		let dummykey = 0;
		map.forEach((key, value) => {
			result.set(dummykey, value);
			dummykey++;
		});
		return result;
	}

	ngOnDestroy() {
		this.subscriptions$.map(a => a.unsubscribe());
	}

	onClick() {
		window.scroll(0, 0);
	}

	getGlossary(value) {
		this.subscriptions$.push(this.route.queryParamMap.subscribe(x => this.letter = x.get('letter')));

		this.subscriptions$.push(this.glossaryService.getGlossary().subscribe(glossary => {
			this.glossaryMap.clear();
			// filter the glossary by the search value
			if (value) {
				this.keywords = value;
				glossary = this.search(glossary, value);
			}

			for (const glossaryEntry of glossary) {
				if (glossaryEntry.termLetter === null || glossaryEntry.title === null || glossaryEntry.termDefinition === null) {
					continue;
				}
				let mapper = this.glossaryMap.get(glossaryEntry.termLetter);
				if (mapper === undefined) {
					mapper = new Map<string, string>();
					this.glossaryMap.set(glossaryEntry.termLetter, mapper);
				}
				mapper.set(glossaryEntry.title, glossaryEntry.termDefinition);
			}
		}));
	}

	search(glossary: GlossaryEntry[], value: string): GlossaryEntry[] {
		const toReg = str => new RegExp(str.replace(/\//g, '//').replace(/\s+/g, '|'), 'gi');
		const match = reg => x => ((x || '').match(reg) || []).length;
		// filter an array with a predicate
		const filter = f => a => {
			const ret = [];
			const l = a.length;
			for (let i = 0; i < l; i++) {
				const count = f(a[i]);
				if (count > 0) {
					ret.push({ count, gloss: a[i] });
				}
			}
			const result = ret.map(r => r.gloss);
			return result;
		}
		// Pre-compiled regext matcher. About 2X as fast as array.filter, albeit a bit more complicated.
		const hasValue = f => entry => {

			// If the user searches using a single letter, just filter by that term letter
			if (value.length === 1) {
				return entry.termLetter.toLowerCase() === value.substr(0).toLowerCase() ? 1 : 0;
			}

			const exactTitle = entry.title.toLowerCase() === value.toLowerCase() ? 1 : 0;
			const titleCount = f(entry.title);

			// NB: exact hits in tag are caught by partial hits in tag. Exact tag hits do not supercede anything.
			return Number("" + exactTitle + titleCount);

		};

		const filterArrByValue = value => {
			// create a regular expression based on your search value
			// cache it for all filter iterations
			const reg = toReg(value.trim().replace(/[^A-Za-z0-9_\sàâçéèêëïîôùûüÿÀÂÇÉÈÊËÏÎÔÙÛÜŸ]/g, ''))
			// filter the array of report view models
			return filter(
				// only return the results that match the regex
				hasValue(match(reg))
			);
		};
		return (filterArrByValue(value.trim()))(glossary);
	}

	// Order by ascending property value
	valueAscOrder = (a: KeyValue<number, string>, b: KeyValue<number, string>): number => {
		return a.value.localeCompare(b.value);
	}

	// Order by descending property key
	keyDescOrder = (a: KeyValue<number, string>, b: KeyValue<number, string>): number => {
		return - a.value.localeCompare(b.value);
	}

	sort(value) {
		if (value == "0") {
			this.alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('').reverse();
			this.sortAsc = false;
		}
		else {
			this.alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');
			this.sortAsc = true;
		}
	}

	highlight(content) {

		if (!this.keywords) {
			return content;
		}
		const reg = this.keywords ? new RegExp(this.keywords + '|' + this.keywords.replace(/\//g, '//').replace(/\s+/g, '|'), "gi") : null;
		return content.replace(reg, match => {
			return '<span class="highlightText">' + match + '</span>';
		});
	}
}
