import { AuthenticationResult } from '@azure/msal-browser';
import { HttpClient, HttpErrorResponse, HttpEvent, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, mapTo, switchMap } from 'rxjs/operators';
import { SilentRequest } from '@azure/msal-browser';
import { BearerTokenFetchClient } from '@pnp/common';
import { sp, ISPConfiguration } from '@pnp/sp/presets/all';
import { ReplaySubject, Observable, Subject, combineLatest } from 'rxjs';
import { environment } from 'src/environments/environment';
import 'whatwg-fetch';
import { User } from '../../models/user.model';
import { MsalService } from '../msal';
import { ResourceNames } from "./resource-names";
import { ReportRefreshApiModel } from 'src/app/models/report-refresh';

export type ColumnMapEntry<TModel> = {
	readonly modelKey: keyof TModel;
	readonly spFriendlyFieldName: string | string[];
	readonly transformer?: (field: any) => any;
};

const RESTRICTED_ACCESS_LISTS_NAMES: string[] = [ 
	ResourceNames.ADMIN_DASHBOARD_LIST_NAME, 
	ResourceNames.FACTBOOK_LIST_NAME,  
	ResourceNames.CAP_OPER_GRANTS_LIST_NAME, 
	ResourceNames.INST_PROFILES_LIST_NAME 
];
const GRAPH_ENDPOINT = 'https://graph.microsoft.com/v1.0/me';

@Injectable({
	providedIn: 'root'
})
export class GraphService {
	constructor(private msalService: MsalService, private http: HttpClient) {}

	public getPowerBIEmbedToken(): Observable<AuthenticationResult> {
		const subject: ReplaySubject<AuthenticationResult> = new ReplaySubject<AuthenticationResult>();
		const pbiScopes = ['https://analysis.windows.net/powerbi/api/Report.Read.All'];
		const acct = this.msalService.getAccount();
		const msalProm = this.msalService.acquireTokenSilent({
			redirectUri: environment.auth.redirectUri,
			scopes: pbiScopes,
			account: acct
		} as SilentRequest);
		msalProm.subscribe(result => {
			subject.next(result);
		}, a => this.msalService.loginRedirect({ scopes: pbiScopes }));
		return subject;
	}

	public getMe(): Observable<User> {
		const sub$ = new ReplaySubject<User>();
		const fields = [
			'companyName', 
			'displayName',
			'givenName',
			'jobTitle',
			'mail',
			'mobilePhone',
			'officeLocation',
			'preferredLanguage',
			'surname',
			'userPrincipalName',
			'id',];
		const spScopes = ['user.read'];
		let headers = new HttpHeaders();
		const acct = this.msalService.getAccount();
		const msalProm = this.msalService.acquireTokenSilent({
			redirectUri: environment.auth.redirectUri,
			scopes: spScopes,
			account: acct
		} as SilentRequest);
		
		msalProm.subscribe(result => {
			headers = headers.set('Authorization', 'Bearer ' + result.accessToken);
			this.http.get(GRAPH_ENDPOINT + "?$select=" + fields.join(','), { headers })
				.subscribe((res: any) => {
					combineLatest([
						this.isAuthorized(),
						this.getUserGroups()
					]).subscribe(([a, groups]) => {
						const user = new User(res.accountIdentifier, res.userPrincipalName, false, res.jobTitle,
							res.mail, res.displayName, res.givenName, res.surname, res.companyName, a, groups);
						sub$.next(user);
					});
				});
		}, a => this.msalService.loginRedirect({ scopes: spScopes }));
		return sub$;
	}

	public getUserGroups(): Observable<AdGroupInfo[]> {
		const spScopes = ['user.read'];
		const acct = this.msalService.getAccount();

		return this.msalService.acquireTokenSilent({
			redirectUri: environment.auth.redirectUri,
			scopes: spScopes,
			account: acct
		} as SilentRequest).pipe(
			switchMap(result => {
				let headers = new HttpHeaders();
				headers = headers.set('Authorization', 'Bearer ' + result.accessToken);
				return this.http.get(GRAPH_ENDPOINT + "/memberOf", { headers }).pipe(
					map((res: any) => res.value.map(x => { return { id: x.id, name: x.mailNickname };} ))
				);
			})
		);
	}

	private configureSharepointToken(): Observable<void> {
		const spScopes = [environment.sharepointSiteBaseUrl + 'AllSites.Write'];
		const acct = this.msalService.getAccount();
		const msalProm$ = this.msalService.acquireTokenSilent({
			redirectUri: environment.auth.redirectUri,
			scopes: spScopes,
			account: acct
		} as SilentRequest);

		return msalProm$.pipe(map(result => {
			const sharepointToken = result.accessToken;
			sp.setup({
				sp: {
					baseUrl: environment.sharepointSiteBaseUrl + environment.simsSite,
					fetchClientFactory: () => new BearerTokenFetchClient(sharepointToken)
				}
			} as ISPConfiguration);
		}));
	}

	private getOpenSimsApiToken(): Observable<AuthenticationResult> {
		const sub$ = new Subject<AuthenticationResult>();
		const scopes = [ environment.auth.apiScope ];
		
		const acct = this.msalService.getAccount();
		const msalProm = this.msalService.acquireTokenSilent({
			redirectUri: environment.auth.redirectUri,
			scopes: scopes,
			account: acct
		} as SilentRequest);
		
		msalProm.subscribe(result => sub$.next(result), a => this.msalService.loginRedirect({ scopes: scopes }));
		return sub$;
	}

	private configureSharepointTokenForLowerEnv(): Observable<void> {
		const spScopes = [environment.sharepointSiteBaseUrl + 'AllSites.Write'];
		const acct = this.msalService.getAccount();
		const msalProm$ = this.msalService.acquireTokenSilent({
			redirectUri: environment.auth.redirectUri,
			scopes: spScopes,
			account: acct
		} as SilentRequest);

		return msalProm$.pipe(map(result => {
			const sharepointToken = result.accessToken;
			sp.setup({
				sp: {
					baseUrl: environment.sharepointSiteBaseUrl + environment.lowerSimsSite,
					fetchClientFactory: () => new BearerTokenFetchClient(sharepointToken)
				}
			} as ISPConfiguration);
		}));
	}
	public getUserNameById(userId: number): Observable<string> {
		const sub$ = new Subject<any>();
		this.configureSharepointToken().subscribe(async configured => {
			await sp.web.siteUsers.getById(userId).get()
				.then(user => sub$.next(user.Title));
		});
		return sub$.asObservable();
	}

	public getCurrentSharepointUserId(): Observable<string> {
		const sub$ = new ReplaySubject<string>();
		this.configureSharepointToken().subscribe(async configured => {
			await sp.web.currentUser()
				.then(user => sub$.next(user.Id.toString()));
		});
		return sub$.asObservable();
	}

	private getSharepointToken(dataSource: SharepointDataSource): Observable<void> {
		const tokenSource$ = dataSource === SharepointDataSource.Production 
			? this.configureSharepointToken()
			: this.configureSharepointTokenForLowerEnv();
		return tokenSource$;
	}

	public getListItems<TResult>(
		listTitle: string,
		resultConstructor: new () => TResult,
		columnMap: ReadonlyArray<ColumnMapEntry<TResult>>,
		camlQuery?: string,
		dataSource: SharepointDataSource = SharepointDataSource.Production
	): Observable<TResult[]> {
		const sub$ = new Subject<TResult[]>();
		let itemsPromise: Promise<any> = null;

		const tokenSource$ = this.getSharepointToken(dataSource);
		tokenSource$.subscribe(_ => {
			if (camlQuery && (camlQuery.length > 0)) {
				itemsPromise = sp.web.lists.getByTitle(listTitle).getItemsByCAMLQuery({ ViewXml: camlQuery });
			} else {
				itemsPromise = sp.web.lists.getByTitle(listTitle).items.select(columnMap.map((a) => a.spFriendlyFieldName).filter(a => a.length > 0).join(",")).getAll();
			}
			itemsPromise.then((listItems => {
				const result = listItems.map(li => this.getModelFrom(li, listTitle, resultConstructor, columnMap));
				sub$.next(result);
			}))
			.catch((error: HttpErrorResponse) => {
				const emptyArray = this.handleConditionally(error);
				sub$.next(emptyArray);
			});
		});
		return sub$;
	}

	public getViewListItems<TResult>(
		listTitle: string,
		viewTitle: string,
		resultConstructor: new () => TResult,
		columnMap: ReadonlyArray<ColumnMapEntry<TResult>>
	): Observable<TResult[]> {
		const sub$ = new ReplaySubject<TResult[]>();
		this.configureSharepointToken().subscribe(_ => {
			sp.web.lists.getByTitle(listTitle).views.getByTitle(viewTitle).get().then(
				(iar) => {
					const query = "<View><Query>" + iar.ViewQuery + "</Query></View>";
					this.getListItems(listTitle, resultConstructor, columnMap, query).subscribe(a => sub$.next(a));
				}
			);
		});
		return sub$;
	}

	public getListAttachments<TResult>(listTitle: string, itemId: string,
		resultConstructor: new () => TResult,
		columnMap: ReadonlyArray<ColumnMapEntry<TResult>>): Observable<TResult[]> {
		const attachArray = [];
		const sub$ = new Subject<TResult[]>();
		this.configureSharepointToken().subscribe(_ => {
			sp.web.getFolderByServerRelativeUrl('Lists/' + listTitle + '/Attachments/' + itemId).files.get().then((listItems) => {
				const result = listItems.map(
					(li) => this.getModelFrom(li, listTitle, resultConstructor, columnMap)
				);
				sub$.next(result);

			});
		});
		return sub$;
	}

	public getListFoldersNames(listTitle: string): Observable<string[]> {
		const sub$ = new ReplaySubject<string[]>(1);
		this.configureSharepointToken().subscribe(_ => {
			sp.web.lists.getByTitle(listTitle).rootFolder.folders.get().then(
				(folders) => {
					sub$.next(folders.filter(x => x.Name != "Item").map(x => x.Name));
				}
			);
		});
		return sub$;
	}

	public updateListItem(listTitle: string, itemId: number, listItem: any, dataSource: SharepointDataSource = SharepointDataSource.Production): Observable<boolean> {
		const tokenSource$ = this.getSharepointToken(dataSource);
		return tokenSource$.pipe(
			switchMap(() => sp.web.lists.getByTitle(listTitle).items.getById(itemId).update(listItem)),
			mapTo(true)
		);
	}

	public addDocumentLibraryItem(listTitle: string, file: File, metadata: any, overwriteFile: boolean = false): Observable<number> {
		const sub$ = new Subject<number>();

		this.configureSharepointToken().subscribe(async () => {
			// upload file, fetch file Id and update it's metadata 
			try {
				const uploadedReference = await sp.web.getFolderByServerRelativeUrl(`${listTitle}`).files.add(file.name, file, overwriteFile);
				const uploadedFile = await uploadedReference.file.getItem();
				// @ts-ignore
				const insertedId = uploadedFile.ID;
				const uploadedFileUrl = environment.sharepointSiteBaseUrl.replace(/\/$/, "") + uploadedReference.data.ServerRelativeUrl;
	
				metadata.URL = uploadedFileUrl;
				await sp.web.lists.getByTitle(listTitle).items.getById(insertedId).update(metadata);
				sub$.next(insertedId);
			}
			catch(err) {
				sub$.error(err);
			}
		});
		return sub$;
	}

	public deleteDocumentLibraryItem(listTitle: string, fileName: string): Observable<boolean> {
		const sub$ = new Subject<boolean>();
		this.configureSharepointToken().subscribe(() => {
			sp.web.getFolderByServerRelativeUrl(`${listTitle}`).files.getByName(fileName).delete()
				.then(res => sub$.next(true))
				.catch(err => sub$.error(err))
			}
		);

		return sub$;
	}

	public addListItem(listTitle: string, listItem: any): Observable<any> {
		const sub$ = new Subject<any>();
		this.configureSharepointToken().subscribe(_ => {
			sp.web.lists.getByTitle(listTitle).items.add(listItem)
				.then(iar => {
					sub$.next(iar.data.Id);
				});
		});
		return sub$.asObservable();
	}

	public addListItemToFolder(listTitle: string, folderName: string, listItem: any): Observable<any> {
		const sub$ = new Subject<any>();
		this.configureSharepointToken().subscribe(async _ => {
			const iar = await sp.web.lists.getByTitle(listTitle).items.add(listItem);
			await sp.web.getFileByServerRelativeUrl(`/${environment.simsSite}Lists/${listTitle}/${iar.data.Id}_.000`)
				.moveTo(`/${environment.simsSite}Lists/${listTitle}/${folderName}/${iar.data.Id}_.000`);
			sub$.next(iar.data.Id);
		});
		return sub$.asObservable();
	}

	public getFilteredListItems(listTitle: string, filter: string): Observable<any[]> {
		// Note: a single quote (') in the filter string must be escaped in the calling method!!!
		return this.configureSharepointToken().pipe(
			switchMap(() => sp.web.lists.getByTitle(listTitle).items.filter(filter).get())
		);
	}

	public deleteListItem(listTitle: string, itemId: number): Observable<boolean> {
		return this.configureSharepointToken().pipe(
			switchMap(() => sp.web.lists.getByTitle(listTitle).items.getById(itemId).delete()),
			mapTo(true)
		);
	}

	public isAuthorized(): Observable<boolean> {
		const sub$ = new Subject<boolean>();
		this.configureSharepointToken().subscribe(_ => {
			sp.web.lists.get()
				.then(iar => {
					sub$.next(true);
				}, a => sub$.next(false));
		});

		return sub$.asObservable();
	}

	public refreshUatReport(report: ReportRefreshApiModel): Observable<boolean> {
		const sub$ = new Subject<boolean>();
		let headers = new HttpHeaders();
		this.getOpenSimsApiToken().subscribe(authResult => {
			headers = headers.set('Authorization', 'Bearer ' + authResult.accessToken);
			this.http.post<ReportRefreshApiModel>(environment.dataFilesManagement.azureRefreshUatFunctionUrl, report, { headers })
				.subscribe((response) => sub$.next(true),
					error => sub$.error(typeof(error.error) === "string" ? error.error : error.message));
		});
		return sub$;
	}
	
	public triggerMultipleFilesPipeline(formData: FormData): Observable<void> {
		const sub$ = new ReplaySubject<void>();
		let headers = new HttpHeaders();
		this.getOpenSimsApiToken().subscribe(authResult => {
			headers = headers.set('Authorization', 'Bearer ' + authResult.accessToken);
			headers = headers.set('Content-Encoding', 'gzip');
			this.http.post(environment.dataFilesManagement.azureTriggerUatFunctionUrl, formData, { headers })
				.subscribe((response) => sub$.next(), error => sub$.error(error.error || error.message));
		});
		return sub$;
	}

	public uploadToAdfSourceFolder(formData: FormData): Observable<void> {
		const sub$ = new ReplaySubject<void>();
		let headers = new HttpHeaders();
		this.getOpenSimsApiToken().subscribe(authResult => {
			headers = headers.set('Authorization', 'Bearer ' + authResult.accessToken);
			headers = headers.set('Content-Encoding', 'gzip');
			this.http.post(environment.dataFilesManagement.azureFunctionUrl, formData, { headers })
				.subscribe(event => sub$.next(), error => sub$.error(error));
		});
		return sub$;
	}

	public auditLog(formData: FormData): Observable<void> {
		let headers = new HttpHeaders();
		return this.getOpenSimsApiToken().pipe(
			switchMap((authResult) => {
				headers = headers.set('Authorization', 'Bearer ' + authResult.accessToken);
				headers = headers.set('Content-Encoding', 'gzip');
				return this.http.post(environment.dataFilesManagement.azureAuditLogUrl, formData, { headers })
			}),
			mapTo(undefined)
		);
	}

	public emailNotify(formData: FormData): Observable<void> {
		let headers = new HttpHeaders();
		return this.getOpenSimsApiToken().pipe(
			switchMap((authResult) => {
				headers = headers.set('Authorization', 'Bearer ' + authResult.accessToken);
				headers = headers.set('Content-Encoding', 'gzip');
				return this.http.post(environment.dataFilesManagement.azureEmailNotifyUrl, formData, { headers })
			}),
			mapTo(undefined)
		);
	}

	public testEmailNotifications(formData: FormData): Observable<void> {
		let headers = new HttpHeaders();
		return this.getOpenSimsApiToken().pipe(
			switchMap((authResult) => {
				headers = headers.set('Authorization', 'Bearer ' + authResult.accessToken);
				headers = headers.set('Content-Encoding', 'gzip');
				return this.http.post(environment.dataFilesManagement.azureTestEmailUrl, formData, { headers })
			}),
			mapTo(undefined)
		);
	}

	public getSharepointBlob(blobPath) {
		console.log(`blobPath: ${blobPath}`);
		const sub$ = new Subject<Blob>();
		this.configureSharepointToken().subscribe(() => 
			sp.web.getFileByServerRelativeUrl(blobPath).getBlob()
				.then(blob => sub$.next(blob))
				.catch(err => sub$.error(err)));
		return sub$;
	}

	public getMultipleSharepointBlobs(blobPaths: string[], dataSource: SharepointDataSource = SharepointDataSource.Production): Observable<NamedBlob[]> {
		const sub$ = new Subject<NamedBlob[]>();

		const tokenSource$ = this.getSharepointToken(dataSource);

		tokenSource$.subscribe(async () => {
			const blobs: NamedBlob[] = [];
			for (const path of blobPaths) {
				const fileName = path.split("/").pop();
				const blob = await sp.web.getFileByUrl(path).getBlob()
				blobs.push({ fileName, blob});
			}
			sub$.next(blobs);
		});

		return sub$;
	}

	public copyFromLowerSharePoint(fileName: string): Observable<void> {
		const listName = ResourceNames.LOOKUP_DATASET_LIST_NAME;
		const sourceBlobPath = `/${environment.lowerSimsSite}${listName}/${fileName}`;

		return this.getBlobFromLowerSharepoint(sourceBlobPath).pipe(
			switchMap(blobFile => this.configureSharepointToken().pipe(map(_ => { return blobFile }))),
			map((blobFile: Blob) => {
				const file = new File([blobFile], "TRANSFERRED.xlsx")
				const overwriteFile = true;
				return sp.web.getFolderByServerRelativeUrl(`${listName}`).files.add(file.name, file, overwriteFile);
			}),
			mapTo(undefined),
			catchError(err => { throw new Error(err) })
		);
	}

	private getBlobFromLowerSharepoint(sourceBlobPath: string): Observable<Blob> {
		return this.configureSharepointTokenForLowerEnv().pipe(
			switchMap(_ => sp.web.getFileByServerRelativeUrl(sourceBlobPath).getBlob())
		);
	}

	private getModelFrom<TResult>(
		listItem: any, istTitle: string, resultConstructor: new () => TResult, columnMap: ReadonlyArray<ColumnMapEntry<TResult>>): any {
		const model = new resultConstructor();
		const keys = Object.keys(listItem);
		for (const columnMapping of columnMap) {
			if (typeof model[columnMapping.modelKey] === typeof undefined) {
				continue;
			}

			const fields: string | string[] =
				typeof columnMapping.spFriendlyFieldName === 'string' ?
					listItem[columnMapping.spFriendlyFieldName] :
					columnMapping.spFriendlyFieldName.map(x => listItem[x]);

			const transformer = columnMapping.transformer;
			model[columnMapping.modelKey] = transformer === undefined ? fields : transformer(fields);
		}
		return model;
	}

	private handleConditionally<T>(error: HttpErrorResponse) {
		// Bug 23991: App Insights Exceptions
		// For users with restricted access calling some SharePoint lists will return (404) although the list is there.
		// No need to log such exceptions in AppInsights.
		// Bug 62193: Open SIMS: PRODUCTION - permissions access issues
		// For users with restricted access calling some SharePoint lists will return (404) even though the list is there.
		// Will return an empty array instead to make sure no downstream logic is broken.
		if (error.status === 404 && RESTRICTED_ACCESS_LISTS_NAMES.some(listName => error.message.includes(listName))) {
			return [];
		} else {
			throw error;
		}

	}
}

export interface AdGroupInfo {
	id: string,
	name: string
}
export interface NamedBlob {
	fileName: string,
	blob: Blob
}
export enum SharepointDataSource {
	Production,
	UAT
}