<div fxFill fxLayout="column">
	<button *ngIf="hideFrame" mat-flat-button color="primary" class="top-frame-button" (click)="hideFrame = false">{{'Reports.View.RestoreFrame' | translate}} 			<mat-icon>wallpaper</mat-icon></button>
	<header *ngIf="!hideFrame">
		<mat-toolbar fxFlex fxLayoutAlign="space-between center" fxLayout.lt-md="column" role="heading" aria-level="1" >
			<a tabindex="0" [appA11yActivate]="{method:'CloseWindow', context:this}" [attr.aria-label]="'Common.Controls.Back' | translate"	role="link"	>
				<mat-icon class="exit">exit_to_app</mat-icon>
				<img aria-hidden="true" alt="" src="assets/img/open_sims_logo_white.svg">
			</a>
			<div fxLayoutGap="20px" fxLayout.lt-md="column">
				<button mat-flat-button color="primary" (click)="powerBiPrint()">{{'Reports.View.PrintCurrentPage' | translate}} <mat-icon>print</mat-icon></button>
				<button mat-flat-button color="primary" (click)="hideFrame = true">{{'Reports.View.RemoveFrame' | translate}} <mat-icon>close</mat-icon></button>
			</div>
		</mat-toolbar>
	</header>
	<mat-expansion-panel *ngIf="!hideFrame" #expansion [hideToggle]="true" role="contentinfo" aria-label="Power BI Report">
		<mat-expansion-panel-header>
			<mat-panel-title fxLayoutAlign="start center">
				<mat-icon *ngIf="!expansion.expanded">add_circle_outline</mat-icon>
				<mat-icon *ngIf="expansion.expanded">close</mat-icon>
				<ng-container *ngIf="!expansion.expanded">{{viewModel?.title}} - {{'Reports.View.ClickHereToExpand' | translate}}</ng-container>
				<ng-container *ngIf="expansion.expanded">{{'Reports.View.CloseDescription' | translate}}</ng-container>
			</mat-panel-title>
		</mat-expansion-panel-header>
		<div class="title">{{viewModel?.title}}</div>
		<mat-divider aria-hidden="true"></mat-divider>
		<div *ngIf="viewModel?.dataOwner?.description?.length > 0 " class="dataowner">{{'Reports.DataOwner' | translate}}: <a target="_blank" rel="noopener noreferrer" matTooltip="{{'Common.Notifications.OpensInNewWindow' | translate}}" href="{{viewModel?.dataOwner?.address}}">{{viewModel?.dataOwner?.description }}</a></div>
		<div *ngIf="viewModel?.published">{{'Reports.Updated' | translate}}: {{viewModel?.published | dateFormatted }}</div>
		<div>{{viewModel?.description}}</div>
	</mat-expansion-panel>
	<div #reportContainer fxFlex="auto" role="main" title="{{'Reports.View.ReportFrame' | translate}}" class="frame" [ngClass]="{'no-frame':hideFrame}"></div>
	<app-footer *ngIf="!hideFrame"></app-footer>
</div>