import { getPropertyNames } from "src/app/property-names";
import { TypeName } from "src/app/type-name";

@TypeName.decorate("ReportRefreshModel")
export class ReportRefreshModel  {
	public constructor(
		public readonly reportName: null | string          = null,
		public readonly reportCode: string | null = null,
		public readonly subjectAreaCode: string | null = null,
		public readonly subjectAreaTitle: string | null = null,
		public readonly sourceFileName: string | null = null,
		public readonly reportRefreshedDate: Date | null = null,
		public readonly allFilesRequired: boolean | null = null,
		public refreshedBy: string | null = null,
		public refreshedDate: Date | null = null,
		public readonly id: null | number          = null,
	) {}

	public static readonly defaultPropertyNames = getPropertyNames(new ReportRefreshModel());
}

@TypeName.decorate("ReportRefreshApiModel")
export class ReportRefreshApiModel  {
	public constructor(
		public readonly Id: number | null = null,
		public readonly Environment: string | null = null,
		public readonly Title: string | null = null,
		public readonly Report_Name: string | null = null,
		public readonly Report_Code: string | null = null,
		public readonly Workspace_Name: string | null = null,
		public readonly Workspace_Id: string | null = null,
		public readonly Refresh_Date: Date | null = null,
		public readonly Last_Refresh_Date: Date | null = null
	) {}

	public static readonly defaultPropertyNames = getPropertyNames(new ReportRefreshApiModel());
}


