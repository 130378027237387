import { OverlayContainer } from '@angular/cdk/overlay';
import { Component, HostListener, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { ReportViewModel } from 'src/app/view-models/report-view-model';
import { ThemeService } from './../../shared/services/theme.service';

@Component({
	selector: 'app-modal-report-details',
	templateUrl: './modal-report-details.component.html',
	styleUrls: ['./modal-report-details.component.scss'],
})
export class ModalReportDetailsComponent implements OnInit {

	viewModel: ReportViewModel;
	reports: ReportViewModel[] = [];
	reportIndex: number;
	numberOfReports: number;
	repSubscription$: Subscription = Subscription.EMPTY;
	keywords: string;

	constructor(
		public themeService: ThemeService,
		public dialogRef: MatDialogRef<ModalReportDetailsComponent>,
		public overlayContainer: OverlayContainer,
	) { }

	ngOnInit(): void {
		this.reportIndex = this.reports.findIndex(rep => rep.id === this.viewModel.id);
		this.numberOfReports = this.reports.length;
	}

	onClose(): void {
		this.dialogRef.close();
	}

	NextReport() {
		this.reportIndex = this.reportIndex < this.numberOfReports-1 ? this.reportIndex + 1 : this.reportIndex;
	}

	PrevReport() {
		this.reportIndex = this.reportIndex > 0 ? this.reportIndex - 1 : 0;
	}

	@HostListener('keyup', ['$event'])
	ArrowKeys(event: KeyboardEvent) {
		if (event.key === 'ArrowLeft') {
			this.PrevReport();
		}
		if (event.key === 'ArrowRight') {
			this.NextReport();
		}
	}
}
