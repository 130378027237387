import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { TranslateService } from '@ngx-translate/core';
import { merge } from 'rxjs';
import { tap } from 'rxjs/operators';
import { DataFilesManagementService } from 'src/app/services/graph/data-files-management.service';
import { ApprovalWorkflowService } from 'src/app/services/approval-workflow.service';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { ReportRefreshViewModel } from 'src/app/view-models/report-refresh-view-model';
import { RefreshReportsDataSource } from './refresh-reports-data-source';
import { ThemeService } from 'src/app/shared/services/theme.service';

@Component({
  selector: 'app-report-refresh-grid',
  templateUrl: './report-refresh-grid.component.html',
  styleUrls: ['./report-refresh-grid.component.scss']
})
export class ReportRefreshGridComponent implements OnInit, AfterViewInit {

	reportCodeFilter: string;

	@Input()
	set reportCode(reportCode: string) {
		if (reportCode) {
			this.reportCodeFilter = `reportCode:${reportCode}`;
		} 
	}

	dataSource: RefreshReportsDataSource;
	displayedColumns: string[] = [ 'subjectAreaTitle', 'sourceFileName', 'providedFileName', 'uploadedDate'];

	@ViewChild(MatSort) sort: MatSort;
	@ViewChild(MatPaginator) paginator: MatPaginator;

	constructor(
		private readonly workflowService: ApprovalWorkflowService,
		private readonly dataFilesService: DataFilesManagementService,
		private readonly translateService: TranslateService,
		private snackBar: SnackbarService,
		public themeService: ThemeService
	) { }

	ngOnInit(): void {
		this.dataSource = new RefreshReportsDataSource(this.workflowService, this.dataFilesService);
		this.dataSource.loadReports(this.reportCodeFilter);
	}

	ngAfterViewInit() {
		this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);

		merge(this.sort.sortChange, this.paginator.page).pipe(
			tap(() => this.loadReports())
		).subscribe();
	}

	loadReports() {
		this.dataSource.loadReports(this.reportCodeFilter, this.sort.active, this.sort.direction, this.paginator.pageIndex, this.paginator.pageSize);
	}

	displaySharedReports(report: ReportRefreshViewModel): void {
		this.snackBar.message(`${report.sourceFileName}${this.translateService.instant("ReportRefresh.SharedWith")}${report.sharedWith}`);
	}
}
