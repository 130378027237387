<div fxLayoutAlign="center" class="search-container" [ngClass]="{'app-light-theme': !themeService.isDark, 'app-dark-theme': themeService.isDark}">
	<form fxFlex="100" fxLayout="row" fxLayoutAlign="center center" class="search-form" [formGroup]="searchForm">
		<mat-form-field fxFlex="100" class="keywords" appearance="none">
			<input type="text" matInput [formControl]="searchInputControl" placeholder="{{'Search.Placeholder' | translate}}" [matAutocomplete]="auto"  (keyup.enter)="submitSearch()" [attr.aria-label]="'Search.Placeholder' | translate">
			<mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" (optionSelected)="optionSelected($event)">
				<mat-option *ngFor="let result of results | async" [value]="result">
					{{result.title}}
				</mat-option>
			</mat-autocomplete>
		</mat-form-field>
		<button mat-flat-button color="primary" (click)="submitSearch()" [attr.aria-label]="'Home.Sections.Search' | translate" type="submit">
			<mat-icon>search</mat-icon>
		</button>
	</form>
</div>