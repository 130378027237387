import { Injectable } from '@angular/core';
import { ColumnMapEntry, GraphService } from './graph/graph.service';
import { FactbookLikeModel } from '../models/factbook-like.model';
import { Hyperlink } from '../models/hyperlink.model';
import { environment } from 'src/environments/environment';
import { ReportType } from '../models/report-type.model';
import { Observable } from 'rxjs';
import { ResourceNames } from './graph/resource-names';
import { map, shareReplay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PirlReportTypeService {

	constructor(
		private readonly graphService: GraphService,
	) { }

	static readonly reportTypesListName = ResourceNames.PIRL_REPORT_TYPES_LIST_NAME;

	static readonly columnMap: ReadonlyArray<ColumnMapEntry<FactbookLikeModel>> = [
		{ modelKey: "name", spFriendlyFieldName: "Title" },
		{ modelKey: "reportFile", spFriendlyFieldName: "FileRef", transformer: x => x == null ? null : new Hyperlink(environment.sharepointSiteBaseUrl + x, x) },
		{ modelKey: "institutionType", spFriendlyFieldName: "InstitutionType", },
		{ modelKey: "institution", spFriendlyFieldName: "Institution", },
		{ modelKey: "academicYear", spFriendlyFieldName: "AcademicYear", },
		{ modelKey: "reportFileType", spFriendlyFieldName: "ReportFileType", },
		{ modelKey: "id", spFriendlyFieldName: "ID", transformer: x => x == null ? null : (x + ""), /* convert to string (wtf javascript?! toString doesn't work) */ },
		{ modelKey: "lastRefresh", spFriendlyFieldName: "Modified" }
	];

	static readonly reportTypeColumnMap: ReadonlyArray<ColumnMapEntry<ReportType>> = [
		{ modelKey: "name", spFriendlyFieldName: "Title" },
		{ modelKey: "reportTypeCode", spFriendlyFieldName: "ReportTypeCode", },
		{ modelKey: "titleEn", spFriendlyFieldName: "ReportTypeEn", },
		{ modelKey: "titleFr", spFriendlyFieldName: "ReportTypeFr", },
	];

	readonly reportTypes$: Observable<ReportType[]> =  this.graphService.getListItems<ReportType>(
		PirlReportTypeService.reportTypesListName,
		ReportType, 
		PirlReportTypeService.reportTypeColumnMap
	).pipe(
		map(items => items.sort((a, b) => a.name.localeCompare(b.name))),
		shareReplay(1)
	);

	getReportTypes(): Observable<ReportType[]> {
		return this.reportTypes$;
	}
}
