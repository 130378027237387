import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { GraphInstitutionService } from 'src/app/services/graph/institution.service';
import { GraphReportLikeService } from 'src/app/services/graph/report-like.service';
import { Institution } from 'src/app/models/institution.model';
import { Location } from "@angular/common";
import { map, startWith, tap } from 'rxjs/operators';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { Observable, Subscription, combineLatest } from 'rxjs';
import { ReportViewModel } from 'src/app/view-models/report-view-model';
import { ThemeService } from 'src/app/shared/services/theme.service';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { PirlReportTypeService } from 'src/app/services/report-type.service';
import { PirlReportType, ReportType } from 'src/app/models/report-type.model';

export interface SortBy {
	value: string;
	viewValue: string;
}

@Component({
	selector: 'app-factbooks',
	templateUrl: './factbooks.component.html',
	styleUrls: ['./factbooks.component.scss']
})
export class FactbooksComponent implements OnInit, OnDestroy {
	universities: InstitutionExtended[] = [];
	colleges: InstitutionExtended[] = [];
	indigenousInstitutes: InstitutionExtended[] = [];
	institutionImages: [] = [];
	sortUniversitiesControl = new FormControl('');
	sortCollegesControl = new FormControl('');
	sortIndigenousInstitutesControl = new FormControl('');
	sortBys: SortBy[];
	sortUniversitiesValue = "institution";
	sortCollegesValue = "institution";
	sortIndigenousInstitutesValue = "institution";

	subscriptions$: Subscription[] = [];

	institutionName: string;
	selectedInstitution$: Observable<Institution>;
	reports: ReportViewModel[] = [];
	imageUrl: string;
	showAllInstitutions = false;

	reportTypes: PirlReportType[];
	selectedReportType: PirlReportType;
	selectedInstitution: InstitutionExtended;
	institutions$ = this.institutionService.getInstitutions();

	constructor(
		private readonly reportLikeService: GraphReportLikeService,
		private readonly activatedRoute: ActivatedRoute,
		private readonly router: Router,
		private readonly institutionService: GraphInstitutionService,
		private readonly translateService: TranslateService,
		private readonly location: Location,
		private readonly pirlReportTypeService: PirlReportTypeService, 
		public readonly themeService: ThemeService
	) {
		this.subscriptions$.push(this.router.events.subscribe((e: any) => {
			if (e instanceof NavigationEnd) {
				this.initData();
			}
		}));

		this.subscriptions$.push(this.location.subscribe(() => {
			this.resetSelectedReportType(this.colleges);
			this.resetSelectedReportType(this.universities);
			this.resetSelectedReportType(this.indigenousInstitutes);

  		}) as Subscription);

		this.initData();
	}

	ngOnInit() {
		this.setSortByText();

		this.subscriptions$.push(this.translateService.onLangChange.subscribe(x => {
			this.setSortByText();
		}));

		this.onSortColleges();
		this.onSortUniversities();
		this.onSortIndigenousInstitutes();
	}

	initReportTypes(): Observable<ReportType[]> {
		return combineLatest([
			this.translateService.onLangChange.pipe(
				startWith({ lang: this.translateService.currentLang } as LangChangeEvent)),
			this.pirlReportTypeService.getReportTypes()
		]).pipe(
			tap(([params, reportTypes]) => this.setReportTypes(params.lang, reportTypes)),
			map(([, reportTypes]) => reportTypes)
		);
	}

	initData() {
		this.resetSelectedReportType(this.universities);
		this.resetSelectedReportType(this.colleges);
		this.resetSelectedReportType(this.indigenousInstitutes);
		// Get the user's selected institution name and report type, if provided
		this.institutionName = this.activatedRoute.snapshot.queryParamMap.get('institution');
		const typeCode = this.activatedRoute.snapshot.queryParamMap.get('selectedReportType');
		
		this.reports = [];

		this.initReportTypes().subscribe(() => {
			this.selectedReportType = typeCode
			? this.reportTypes.find(x => x.code === typeCode)
			: null;
		
			if(this.selectedReportType) {
				this.getReportsByType();
			} else {
				this.getInstitution();
			}
		});
	}

	private getReportsByType(): void {
		if(this.selectedReportType != null && this.institutionName != null) {
			combineLatest([
				this.reportLikeService.getByReportType(this.selectedReportType),
				this.institutions$,
			]
			).subscribe(([reports, inst]) => {
				this.reports = this.institutionName ? reports.filter(fb => fb.model.institution === this.institutionName) : reports;
				this.selectedInstitution = inst.find(i => i.name === this.institutionName) as InstitutionExtended;
			});
		}
	}

	private getInstitution(): void {
		// Analyze the reports to see how this page should display
		this.subscriptions$.push(
			this.reportLikeService.allPirlReports$
				.subscribe(reports => {
					// See if there are more than 1 institution represented in the reports.
					if (reports.length > 1) {
						const instutitions = reports
							.map(item => item.model.institution)
							.filter((value, index, self) => self.indexOf(value) === index)

						this.showAllInstitutions = instutitions.length > 1;

						if (!this.showAllInstitutions) {
							// Only one institution to show, so get the institution name based on the reports
							this.institutionName = reports[0].model.institution;
							this.selectedInstitution$ = this.institutions$.pipe(
								map((institutions) => {
									const selected = institutions.find(i => i.name === this.institutionName) as InstitutionExtended;

									if(selected) {
										// @ts-ignore
										const uniqueItemReports =  [...new Set(reports.filter(x => x.model.institution === selected.displayName).map(x => x.pirlReportCode))];	
										const pirlReports = [...this.reportTypes].filter(x => uniqueItemReports.includes(x.code));
										selected.reportTypes = pirlReports;

										this.selectedInstitution = selected;
										return this.selectedInstitution;
									}
								})
							);
						}

						this.initInstitutionType();
					}
				}
			)
		);
	}

	ngOnDestroy() {
		this.subscriptions$.map(a => a.unsubscribe());
	}

	private initInstitutionType() {
		combineLatest([
			this.institutions$,
			this.reportLikeService.allPirlReports$,
		])
		.subscribe(([institutions, reports]) => {
			if (institutions.length === 0 || !this.showAllInstitutions) {
				return;
			}
			// @ts-ignore
			const institutionsExtended = [ ...institutions ] as InstitutionExtended[]; 
			institutionsExtended.forEach(item => {
				// @ts-ignore
				const uniqueItemReports =  [...new Set(reports.filter(x => x.model.institution === item.displayName).map(x => x.pirlReportCode))];	
				const pirlReports = [...this.reportTypes].filter(x => uniqueItemReports.includes(x.code));
				item.reportTypes = pirlReports;
			});

			this.universities = institutionsExtended.filter(y => y.reportTypes.length > 0 && y.institutionType === "University");
			this.colleges = institutionsExtended.filter(y => y.reportTypes.length > 0 && y.institutionType === "College");
			this.indigenousInstitutes = institutionsExtended.filter(y => y.reportTypes.length > 0 && y.institutionType === "Indigenous Institute");
		});
	}

	setSortByText() {
		this.sortBys = [
			{ value: "institution", viewValue: this.translateService.instant("Factbooks.SortBy.Institution") },
			{ value: "reverseInstitution", viewValue: this.translateService.instant("Factbooks.SortBy.ReverseInstitution") },
		];
	}

	onSortColleges() {
		this.colleges = this.onSortByChange(this.sortCollegesValue, this.colleges);
	}

	onSortUniversities() {
		this.universities = this.onSortByChange(this.sortUniversitiesValue, this.universities);
	}

	onSortIndigenousInstitutes() {
		this.indigenousInstitutes = this.onSortByChange(this.sortIndigenousInstitutesValue, this.indigenousInstitutes);
	}

	onTabChanged($event: MatTabChangeEvent): void {
		const selectedTab = $event.index;
		if (selectedTab === 0) { // Colleges selected, resetting others
			this.resetSelectedReportType(this.universities);
			this.resetSelectedReportType(this.indigenousInstitutes);
		} else if (selectedTab === 1) {
			this.resetSelectedReportType(this.colleges);
			this.resetSelectedReportType(this.indigenousInstitutes);
		} else if (selectedTab === 2) {
			this.resetSelectedReportType(this.colleges);
			this.resetSelectedReportType(this.universities);
		}
	}

	private setReportTypes(langCode: string, reportTypes: ReportType[]): void {
		this.reportTypes = reportTypes.map(type => ({ 
			code: type.name, 
			title: langCode === 'en' ? type.titleEn : type.titleFr 
		}))
	}

	private resetSelectedReportType(institution: InstitutionExtended[]): void {
		institution.forEach(i => i.selectedReportTypeCode = undefined);
	}

	private onSortByChange(sortValue: string, institutions: InstitutionExtended[]): InstitutionExtended[] {
		return institutions.sort((x, y) => {
			return sortValue === 'institution' ?
				this.stringSort(x.name, y.name) :
				this.stringSort(y.name, x.name);
		});
	}

	stringSort(x, y) {
		return !x ? 1 : !y ? -1 : x < y ? -1 : 1;
	}
}

class InstitutionExtended extends Institution {
	selectedReportTypeCode: string = undefined;
	reportTypes: PirlReportType[]
}