<div class="title">
	<div>{{'ReportRefresh.Paragraph' | translate}}{{selectedReport.reportName}}</div>
</div>

<div class="refresh-container" [ngClass]="{'app-light-theme': !themeService.isDark, 'app-dark-theme': themeService.isDark}">
	<div class="top-container">
		<div class="last-updated-container">
			<div><label id="report-label">{{ 'ReportRefresh.LastRefreshDate' | translate }}</label></div>
			<div class="updated-date-bold">{{selectedReport.reportRefreshedDate | dateFormatted:'mediumDate' }}</div>
		</div>
		<div class="button-container">
			<button class="upload-button" (click)="onReportRefresh()" matTooltip="{{'DataFilesManagement.SelectFile' | translate}}" fxFlex="180px" fxFlex.gt-sm="180px" mat-flat-button color="primary">{{'ReportRefresh.RefreshReport' | translate}}</button>
			<div class="go-back-link"><a role="button" routerLink="/uat-workflow" routerLinkActive="active">{{'DataFilesManagement.Back' | translate}}</a></div>
		</div>
	</div>
	<div class="progress" *ngIf="isRefreshStarted" [ngClass]="{'app-light-theme': !themeService.isDark, 'app-dark-theme': themeService.isDark}">
		<div class="process-spinner">
			<mat-progress-spinner [diameter]="60" [mode]="spinnerMode"></mat-progress-spinner>
		</div>
		<span>{{'DataFilesManagement.Processing' | translate}}</span>
	</div>
	<div class="grid-container">
		<app-report-refresh-grid [reportCode]="selectedReportCode"></app-report-refresh-grid>
	</div>
</div>
