<div class="title">
	<div id="maincontent" tabindex="0">{{'ApprovalWorkflow.TransferFromUat' | translate}}</div>
</div>
<div class="main-container" [ngClass]="{'app-light-theme': !themeService.isDark, 'app-dark-theme': themeService.isDark}">
	<div class="description-container">
		<div><b>{{'DataFilesManagement.Report' | translate}}:</b>&nbsp;{{selectedReportTitle}}</div>
		<div class="go-back-link"><a role="button" routerLink="/prod-workflow" routerLinkActive="active">{{'DataFilesManagement.Back' | translate}}</a></div>
	</div>
	<div class="top-container" *ngIf="selectedUatApprovedReport; else noReports">
		<div class="last-updated-container">
			<div><label id="report-label">{{ 'ApprovalWorkflow.ApproveDate' | translate }}</label></div>
			<div class="updated-date-bold">{{approvalDate | dateFormatted:'mediumDate' }}</div>
		</div>
		<div class="button-container">
			<button class="upload-button" *ngIf="uatDataFiles?.length" (click)="onFilesTransfer()" matTooltip="{{'ApprovalWorkflow.FilesTransferTooltip' | translate}}" fxFlex="140px" fxFlex.gt-sm="140px" mat-flat-button color="primary">{{'ApprovalWorkflow.Transfer' | translate}}</button>
		</div>
	</div>
	<div class="progress" *ngIf="isUploadStarted" [ngClass]="{'app-light-theme': !themeService.isDark, 'app-dark-theme': themeService.isDark}">
		<div class="process-spinner">
			<mat-progress-spinner [diameter]="60" mode="indeterminate"></mat-progress-spinner>
		</div>
		<span>{{'DataFilesManagement.Processing' | translate}}</span>
	</div>
	<div class="grid-container" *ngIf="selectedUatApprovedReport">
		<app-uat-transfer-grid *ngIf="uatDataFiles?.length; else noFiles" [uatDataFiles]="uatDataFiles" [reportCode]="selectedReportCode"></app-uat-transfer-grid>
	</div>
</div>
<ng-template #noFiles>
	<div fxLayoutAlign="center centers">
		<h3>
			{{ 'ApprovalWorkflow.NoFilesUat' | translate }}
		</h3>
	</div>
</ng-template>
<ng-template #noReports>
	<div fxLayoutAlign="center centers">
		<h4>
			{{ 'ApprovalWorkflow.NoReportsUat' | translate }}
		</h4>
	</div>
</ng-template>