import { Injectable } from "@angular/core";
import { Observable, BehaviorSubject } from "rxjs";
import { BuildVersion } from "src/build-version"; // this file is generated (and deleted) by `build-for-sharepoint.ps1`

@Injectable({
	providedIn: 'root'
})
export class VersionService {
	public static readonly defaultCurrentVersion = BuildVersion.buildNumber;
	public static readonly defaultCurrentBuildLink = BuildVersion.buildPageLink;

	public readonly currentVersion: Observable<string> = new BehaviorSubject<string>(
		VersionService.defaultCurrentVersion
	);

	public readonly currentBuildLink: Observable<string> = new BehaviorSubject<string>(
		VersionService.defaultCurrentBuildLink
	);
}