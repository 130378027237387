import { ErrorHandlerService } from './services/error-handler.service';
import { OverlayContainer } from '@angular/cdk/overlay';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { registerLocaleData } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import localeFr from '@angular/common/locales/fr';
import { ErrorHandler, NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { VgBufferingModule } from '@videogular/ngx-videogular/buffering';
import { VgControlsModule } from '@videogular/ngx-videogular/controls';
import { VgCoreModule } from '@videogular/ngx-videogular/core';
import { VgOverlayPlayModule } from '@videogular/ngx-videogular/overlay-play';
import { environment } from 'src/environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AboutComponent } from './components/about/about.component';
import { AccessibilityComponent } from './components/accessibility/accessibility.component';
import { AdminDashboardComponent } from './components/admin-dashboard/admin-dashboard.component';
import { BetaReportsSlideinComponent } from './components/beta-reports-slidein/beta-reports-slidein.component';
import { BookmarksComponent } from './components/bookmarks/bookmarks.component';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { CategoryComponent } from './components/category/category.component';
import { ContactComponent } from './components/contact/contact.component';
import { FactbooksComponent } from './components/factbooks/factbooks.component';
import { FooterComponent } from './components/footer/footer.component';
import { GlossaryComponent } from './components/glossary/glossary.component';
import { HeaderComponent } from './components/header/header.component';
import { HelpComponent } from './components/help/help.component';
import { HomeComponent } from './components/home/home.component';
import { HyperlinkComponent } from './components/hyperlink/hyperlink.component';
import { LatestReportsAndNewsComponent } from './components/latest-reports-and-news/latest-reports-and-news.component';
import { LoginComponent } from './components/login/login.component';
import { MainComponent } from './components/main/main.component';
import { MenuComponent } from './components/menu/menu.component';
import { ModalOrientationComponent } from './components/modal-orientation/modal-orientation.component';
import { ModalPopupComponent } from './components/modal-popup/modal-popup.component';
import { ModalProfileComponent } from './components/modal-profile/modal-profile.component';
import { ModalReportDetailsComponent } from './components/modal-report-details/modal-report-details.component';
import { ModalSearchComponent } from './components/modal-search/modal-search.component';
import { ModalVideoComponent } from './components/modal-video/modal-video.component';
import { NewsCardComponent } from './components/news-card/news-card.component';
import { NewsListingComponent } from './components/news-listing/news-listing.component';
import { NoInternetComponent } from './components/no-internet/no-internet.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { ReadMoreComponent } from './components/read-more/read-more.component';
import { ReportCardComponent } from './components/report-card/report-card.component';
import { ReportCategoriesComponent } from './components/report-categories/report-categories.component';
import { ReportListingComponent } from './components/report-listing/report-listing.component';
import { ReportViewComponent } from './components/report-view/report-view.component';
import { SearchBarComponent } from './components/search-bar/search-bar.component';
import { SearchComponent } from './components/search/search.component';
import { SignUpComponent } from './components/sign-up/sign-up.component';
import { StepperOrientationComponent } from './components/stepper-orientation/stepper-orientation.component';
import { TermsComponent } from './components/terms/terms.component';
import { VideoGalleryComponent } from './components/video-gallery/video-gallery.component';
import { AuthGuard } from './services/guards/auth-guard';
import { NetworkService } from './services/network.service';
import { CustomBreakPointsProvider } from './shared/custom-breakpoints';
import { CustomFlexDirective } from './shared/custom-flex.directive';
import { CustomLayoutAlignDirective } from './shared/custom-layout-align.directive';
import { CustomLayoutDirective } from './shared/custom-layout.directive';
import { A11yActivateDirective } from './shared/directives/a11y-activate.directive';
import { EllipsifyDirective } from './shared/directives/ellipsify.directive';
import { MaterialDesignModule } from './shared/material-design.module';
import { LayoutService } from './shared/services/layout.service';
import { ThemeService } from './shared/services/theme.service';
import { JumpControlComponent } from './components/vg/jump-control/jump-control.component';
import { VideoPlayerComponent } from './components/video-player/video-player.component';
import { RecaptchaV3Module, RECAPTCHA_V3_SITE_KEY } from 'ng-recaptcha';
import { IPublicClientApplication, PublicClientApplication } from '@azure/msal-browser';
import { MsalBroadcastService, MsalService, MSAL_INSTANCE } from './services/msal';
import { ModalNewsDetailsComponent } from './components/modal-news-details/modal-news-details.component';
import { DataFilesManagementComponent } from './components/data-files-management/data-files-management.component';
import { DataFilesGridComponent } from './components/data-files-grid/data-files-grid.component';
import { ReportRefreshGridComponent } from './components/report-refresh-grid/report-refresh-grid.component';
import { ReportRefreshComponent } from './components/report-refresh/report-refresh.component';

import { NewlinePipe } from './shared/newline.pipe';
import { ApprovalWorkflowComponent } from './components/approval-workflow/approval-workflow.component';
import { ProjectDatasetsComponent } from './components/project-datasets/project-datasets.component';
import { DatasetManagerComponent } from './components/dataset-manager/dataset-manager.component';
import { DatasetUploaderComponent } from './components/dataset-uploader/dataset-uploader.component';
import { DatasetDownloaderComponent } from './components/dataset-downloader/dataset-downloader.component';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { ConfirmationPopupService } from './shared/services/confirmation-popup.service';
import { DateFormattedPipe } from './shared/date-formatted.pipe';
import { UserGuidesComponent } from './components/user-guides/user-guides.component';
import { ApprovalWorkflowProdComponent } from './components/approval-workflow-prod/approval-workflow-prod.component';
import { UatTransferGridComponent } from './components/uat-transfer-grid/uat-transfer-grid.component';
import { UatTransferComponent } from './components/uat-transfer/uat-transfer.component';
import { ApprovalWorkflowUatComponent } from './components/approval-workflow-uat/approval-workflow-uat.component';
import { DatasetStakeholderSelectorComponent } from './components/dataset-stakeholder-selector/dataset-stakeholder-selector.component';
import { UserAuditStepComponent } from './components/user-audit-step/user-audit-step.component';
import { UserAuditComponent } from './components/user-audit/user-audit.component';

registerLocaleData(localeFr, 'fr');

function MSALInstanceFactory(): IPublicClientApplication {
	return new PublicClientApplication({
		auth: {
			clientId: environment.auth.clientId,
			authority: environment.auth.authority,
			redirectUri: environment.auth.redirectUri,
			navigateToLoginRequestUrl: false
		},
		cache: {
			cacheLocation: "localStorage",
			storeAuthStateInCookie: false,
		},
	});
}
@NgModule({
	declarations: [
		AppComponent,
		HomeComponent,
		MenuComponent,
		HeaderComponent,
		FooterComponent,
		CustomLayoutDirective,
		CustomLayoutAlignDirective,
		CustomFlexDirective,
		ModalProfileComponent,
		StepperOrientationComponent,
		ModalOrientationComponent,
		ReportCategoriesComponent,
		ReadMoreComponent,
		BetaReportsSlideinComponent,
		A11yActivateDirective,
		EllipsifyDirective,
		CategoryComponent,
		ReportCardComponent,
		HyperlinkComponent,
		ModalReportDetailsComponent,
		ReportViewComponent,
		AboutComponent,
		HelpComponent,
		AccessibilityComponent,
		TermsComponent,
		ContactComponent,
		SearchBarComponent,
		ModalSearchComponent,
		SearchComponent,
		ReportListingComponent,
		PageNotFoundComponent,
		NoInternetComponent,
		ModalPopupComponent,
		BookmarksComponent,
		BreadcrumbComponent,
		LoginComponent,
		GlossaryComponent,
		FactbooksComponent,
		MainComponent,
		ModalVideoComponent,
		LatestReportsAndNewsComponent,
		NewsCardComponent,
		NewsListingComponent,
		AdminDashboardComponent,
		VideoGalleryComponent,
		SignUpComponent,
		JumpControlComponent,
		VideoPlayerComponent,
		ModalNewsDetailsComponent,
		DataFilesManagementComponent,
		DataFilesGridComponent,
		ReportRefreshGridComponent,
		ReportRefreshComponent,
		NewlinePipe,
		ApprovalWorkflowComponent,
		DatasetDownloaderComponent,
		NewlinePipe,
		ProjectDatasetsComponent,
		DatasetUploaderComponent,
		DatasetManagerComponent,
		DateFormattedPipe,
		UserGuidesComponent,
		ApprovalWorkflowProdComponent,
		UatTransferGridComponent,
		UatTransferComponent,
		ApprovalWorkflowUatComponent,
		DatasetStakeholderSelectorComponent,
		UserAuditStepComponent,
		UserAuditComponent
	],
	imports: [
		BrowserModule,
		RecaptchaV3Module,
		AppRoutingModule,
		HttpClientModule,
		ReactiveFormsModule,
		FormsModule,
		MaterialDesignModule,
		CdkStepperModule,
		BrowserModule,
		VgCoreModule,
		VgControlsModule,
		VgOverlayPlayModule,
		VgBufferingModule,
		FlexLayoutModule.withConfig({ disableDefaultBps: true, addOrientationBps: true }, CustomBreakPointsProvider.useValue),
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: httpTranslateLoader,
				deps: [HttpClient]
			}
		})
	],
	providers: [{ provide: ErrorHandler, useClass: ErrorHandlerService }, LayoutService, ThemeService, NetworkService, MsalService,
		MsalBroadcastService, ConfirmationPopupService,
		{
			provide: MSAL_INSTANCE,
			useFactory: MSALInstanceFactory
		}, AuthGuard,
	    { provide: RECAPTCHA_V3_SITE_KEY, useValue: environment.reCaptchaSiteKey },
		{ provide: MAT_DATE_LOCALE, useValue: 'en-CA'}
	],
	bootstrap: [AppComponent],
	entryComponents: [ModalProfileComponent]
})
export class AppModule {
	constructor(overlayContainer: OverlayContainer, msalService: MsalService) {
		msalService.handleRedirectObservable().subscribe((a) => {
			//nothing
		});
		overlayContainer.getContainerElement().classList.add('app-light-theme');
	}
}

// AOT compilation support
export function httpTranslateLoader(http: HttpClient) {
	// SharePoint does not allow a direct HTTP GET of JSON files, so workaround it to use text file format instead.
	// Technically SharePoint JSON files can be referenced via download.aspx?SourceUrl method, but that would be messy to implement.
	return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}
