import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { OverlayContainer } from '@angular/cdk/overlay';

@Injectable()
export class ThemeService {
	private _darkTheme: Subject<boolean> = new Subject<boolean>();
	isDarkTheme = this._darkTheme.asObservable();
	public isDark: boolean;

	constructor(public overlayContainer: OverlayContainer) {}
	setDarkTheme(isDarkTheme: boolean) {
		this._darkTheme.next(isDarkTheme);

		if (isDarkTheme) {
			this.isDark = true;
		} else {
			this.isDark = false;
		}

		if (this.isDark) {
			this.overlayContainer.getContainerElement().classList.remove('app-light-theme');
			this.overlayContainer.getContainerElement().classList.add('app-dark-theme');
		} else {
			this.overlayContainer.getContainerElement().classList.remove('app-dark-theme');
			this.overlayContainer.getContainerElement().classList.add('app-light-theme');
		}
	}
}