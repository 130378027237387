<div class="title">
	<div>{{'DataFilesManagement.Paragraph' | translate}}{{selectedReportName}}</div>
</div>
<form #form="ngForm" [formGroup]="dataFileForm"  class="upload-criteria-container" [ngClass]="{'app-light-theme': !themeService.isDark, 'app-dark-theme': themeService.isDark}" *ngIf="canManageFiles">
	<div class="upload-criteria">
		<label id="subject-area-label">{{ 'DataFilesManagement.SubjectArea' | translate }}</label>
		<mat-form-field appearance="outline" aria-labelledby="subject-area-label">
			<mat-select required formControlName="subjectAreaControl" (selectionChange)="onSubjectAreaChange()" placeholder="{{'DataFilesManagement.SubjectAreaPlaceholder' | translate}}">
				<mat-option *ngFor="let area of areas" [value]="area.subjectAreaCode" [disabled]="area.isDisabledInReport">
					{{ area.subjectAreaTitle }}
				</mat-option>
			</mat-select>
			<mat-error *ngIf="dataFileForm.get('subjectAreaControl').hasError('required')">{{'DataFilesManagement.Required' | translate}}</mat-error>
		</mat-form-field>
	</div>

	<input #fileUpload type="file" class="file-input" (change)="onFileSelected($event)" [accept]="requiredFileType">
	
	<button 
		class="upload-button" 
		[disabled]="!(selectedSubjectArea && selectedSubjectArea.isActive)" 
		(click)="fileUpload.click()" 
		matTooltip="{{'DataFilesManagement.SelectFile' | translate}}" 
		fxFlex="80px" 
		fxFlex.gt-sm="100px"
		mat-flat-button 
		color="primary"
	>
		{{'DataFilesManagement.Browse' | translate}}
	</button>

	<div class="upload-file-name-and-errors">
		<div class="upload-file-name"> {{fileName || ""}} </div>
		<mat-error *ngIf="!isFileNameValid">{{'DataFilesManagement.IncorrectFileName' | translate}}</mat-error>
		<mat-error *ngIf="isUploadFailed">{{'DataFilesManagement.UploadError' | translate}}</mat-error>
		<mat-error *ngIf="selectedSubjectArea && !selectedSubjectArea.isActive">{{'DataFilesManagement.UploadForbidden_Short' | translate}}</mat-error>
	</div>	
	
	<button 
		class="upload-button" 
		(click)="onUploadFile()" 
		[disabled]="!(fileToUpload && selectedSubjectArea.subjectAreaCode  && isFileNameValid)" 
		matTooltip="{{'DataFilesManagement.UploadFile' | translate}}" 
		fxFlex="80px" 
		fxFlex.gt-sm="100px" 
		mat-flat-button 
		color="primary"
	>
		{{'DataFilesManagement.Upload' | translate}}
	</button>
	
	<button 
		class="upload-button" 
		*ngIf="isAllFilesUploaded && dataFileForm.get('subjectAreaControl').value" 
		(click)="onPipelineTrigger()"  
		matTooltip="{{'DataFilesManagement.TriggerPipeline' | translate}}" 
		fxFlex="80px" 
		fxFlex.gt-sm="100px" 
		mat-flat-button 
		color="primary"
	>
		{{'DataFilesManagement.Trigger' | translate}}
	</button>
	
	<div class="go-back-link"><a role="button" (click)="onBack()">{{'DataFilesManagement.Back' | translate}}</a></div>
	<div class="go-back-link"><a role="button" (click)="onRefresh()"> {{'DataFilesManagement.Refresh' | translate}}</a></div>
	
	<div class="upload-comment">
		<!-- <div *ngIf="selectedSubjectArea && isMultipleFilesSubjectArea" class="multiple-files-notice">* Please note, that {{selectedSubjectArea.subjectAreaTitle}} is multiple files Subject Area</div> -->
		<label id="comment-label">{{ 'DataFilesManagement.Comment' | translate }}</label>
		<mat-form-field appearance="outline" aria-labelledby="comment-label">
			<input  formControlName="commentControl" matInput>
		</mat-form-field>
	</div>
</form>
<div fxLayout="row wrap">
	<div class="go-back-link" *ngIf="!canManageFiles"><a role="button" (click)="onBack()">{{'DataFilesManagement.Back' | translate}}</a></div>
	<div class="go-back-link" *ngIf="!canManageFiles"><a role="button" (click)="onRefresh()"> {{'DataFilesManagement.Refresh' | translate}}</a></div>
</div>

<div class="progress" *ngIf="isUploadStarted" [ngClass]="{'app-light-theme': !themeService.isDark, 'app-dark-theme': themeService.isDark}">
	<div class="process-spinner">
		<mat-progress-spinner [diameter]="60" [mode]="spinnerMode" [value]="uploadProgress" ></mat-progress-spinner>
	</div>
	<span *ngIf="spinnerMode=='indeterminate'">{{'DataFilesManagement.Processing' | translate}}</span>
</div>

<app-data-files-grid *ngIf="subjectAreas && subjectAreas.length" [reportCode]="selectedReportCode" [subjectAreas]="subjectAreas" [gridUpdateEvents]="eventsSubject.asObservable()"></app-data-files-grid>