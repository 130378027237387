import { Hyperlink } from './hyperlink.model';
import { HasLanguageCode } from "src/app/services/language.service";
import { TypeName } from "src/app/type-name";
import { getPropertyNames } from "src/app/property-names";

@TypeName.decorate("NewsEntry")
export class NewsEntry implements HasLanguageCode {
	public constructor(
		public readonly ID: null | string = null,
		public readonly title: null | string = null,
		public readonly author: Hyperlink = new Hyperlink(),
		public readonly content: null | string = null,
		public readonly Attachments: null | string = null,
		public readonly expiryDate: null | Date = null,
		public readonly activeDate: null | Date = null,
		public readonly languageCode: null | string = null,
		public readonly image: Hyperlink = new Hyperlink(),
	) { }

	public static readonly DefaultPropertyNames = getPropertyNames(new NewsEntry());

	public getDateField(name): null | Date {
		this[name]
		if(this[name])
			return new Date(this[name]);
		return this[name];
	}
}
