import { getPropertyNames } from "src/app/property-names";
import { TypeName } from "src/app/type-name";
import { WorkflowStatuses } from "../services/graph/resource-names";

@TypeName.decorate("ApprovalWorkflowModel")
export class ApprovalWorkflowModel  {
	public constructor(
		public readonly reportName: string | null = null, 
		public readonly reportCode: string | null = null,
		public readonly isRequereAllFiles: boolean | null = null,
		public status: WorkflowStatuses | null = null, 
		public statusDate: Date = null,
		public statusChangedBy: string | null = null,
		public uatReportRefreshDate: Date | null = null,
		public uatReportRefreshedBy: string | null = null,
		public approvedDate: Date | null = null,
		public approvedBy: string | null = null,
		public promotedDate: Date | null = null,
		public promotedBy: string | null = null,
		public readonly id: number | null = null,
	) {}

	public static readonly defaultPropertyNames = getPropertyNames(new ApprovalWorkflowModel());
}