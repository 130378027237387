<div *ngIf="!loggedIn">
	<app-header></app-header>
	<div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px">

		<div class="banner" fxLayoutAlign="center center" fxLayout="column" fxLayoutGap="12px">
			<img class="logo" fxHide.gt-md src="assets/img/open_sims_logo_sm.svg" alt="{{'Common.ImageDescriptions.OpenSIMSLogo' | translate}}">
			<img class="logo" fxHide.lt-lg src="assets/img/open_sims_logo_md.svg" alt="{{'Common.ImageDescriptions.OpenSIMSLogo' | translate}}">
			<span>{{'Home.SubHeader' | translate}}</span>
		</div>

		<p>{{'Login.Please' | translate}}</p>
		<button mat-flat-button color="primary" id="loginbtn" (click)="login()">{{'Login.ClickToLogin' | translate}}</button>
		<button mat-flat-button color="primary" id="signupbtn" (click)="signup()">{{'Login.ClickToSignUp' | translate}}</button>
		<img class="laptop" aria-hidden="true" alt="" src="assets/img/laptop.svg">
	</div>
	<app-footer></app-footer>
</div>