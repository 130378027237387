import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of, ReplaySubject, Subscription } from 'rxjs';
import { distinctUntilChanged, filter } from 'rxjs/operators';
import { GraphCategoryService } from 'src/app/services/graph/category.service';
import { Category } from './../../models/category.model';
import { ThemeService } from './../../shared/services/theme.service';

export interface IBreadCrumb {
	label: Observable<string>;
	url: string;
	params: object;
}

@Component({
	selector: 'app-breadcrumb',
	templateUrl: './breadcrumb.component.html',
	styleUrls: ['./breadcrumb.component.scss']
})

export class BreadcrumbComponent implements OnInit, OnDestroy, AfterViewInit {
	public breadcrumbs: IBreadCrumb[]
	private event$: Subscription = Subscription.EMPTY;

	constructor(
		private router: Router,
		private activatedRoute: ActivatedRoute,
		public themeService: ThemeService,
		private translateService: TranslateService,
		private categoryService: GraphCategoryService
	) {
		this.breadcrumbs = this.buildBreadCrumb(this.activatedRoute.root);
	}

	ngOnInit() {
		this.event$ = this.router.events.pipe(
			filter(event => event instanceof NavigationEnd),
			distinctUntilChanged(),
		).subscribe(() => {
			this.breadcrumbs = this.buildBreadCrumb(this.activatedRoute.root);
		});
	}

	ngOnDestroy() {
		this.event$.unsubscribe();
	}

	ngAfterViewInit() {
		this.breadcrumbs = this.buildBreadCrumb(this.activatedRoute.root);
	}

	buildBreadCrumb(route: ActivatedRoute, url: string = '', breadcrumbs: IBreadCrumb[] = []): IBreadCrumb[] {
		let label: Observable<string> = of(route.routeConfig?.data?.breadcrumb || 'Common.Titles.Home');
		const path = route.routeConfig?.path || '/';
		const params = route.snapshot.params;

		if (route.snapshot?.params?.categoryKey) {
			const sub$ = new ReplaySubject<string>();
			label = sub$.asObservable();

			if (route.snapshot.params.categoryKey.toLowerCase() === 'beta') {
				sub$.next(this.translateService.instant("Home.BetaReports.Title"));
			} else {
				this.categoryService.getCategories().subscribe(x => {
					if (x.length > 0) {
						sub$.next(x.filter((y): y is Category => y.key.toLowerCase() === route.snapshot.params.categoryKey.toLowerCase())[0].title);
					}
				});
			}
		}

		if (route.snapshot?.params?.keywords) {
			label = of('Search.Results.HeadingSimple');
		}

		const nextUrl = path ? `${url}/${path}` : url;

		const breadcrumb: IBreadCrumb = {
			label,
			url: nextUrl,
			params
		};

		const newBreadcrumbs = breadcrumb.label ? [...breadcrumbs, breadcrumb] : [...breadcrumbs];
		if (route.firstChild) {
			return this.buildBreadCrumb(route.firstChild, nextUrl, newBreadcrumbs);
		}
		return newBreadcrumbs;
	}
}
