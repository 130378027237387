import { Component, OnDestroy } from '@angular/core';
import { EMPTY, Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { GraphReportLikeService } from 'src/app/services/graph/report-like.service';
import { ReportViewModel } from '../../view-models/report-view-model';
import { BookmarkService } from './../../services/bookmark.service';
import { ThemeService } from './../../shared/services/theme.service';

@Component({
	selector: 'app-bookmarks',
	templateUrl: './bookmarks.component.html',
	styleUrls: ['./bookmarks.component.scss']
})
export class BookmarksComponent implements OnDestroy {
	bookmarkedReports: Observable<ReportViewModel[]> = EMPTY;
	bookmarks$: Subscription = Subscription.EMPTY;

	constructor(
		private readonly reportLikeService: GraphReportLikeService,
		private readonly bookmarkService: BookmarkService,
		public themeService: ThemeService,
	) {

		this.bookmarks$ = this.bookmarkService.bookmarks.subscribe(b => {
			this.bookmarkedReports = this.reportLikeService.all
				.pipe(map(r => r.filter(f => this.bookmarkService.tryFindExistingBookmarkFor(f) !== undefined)));

		});
	}

	ngOnDestroy() {
		this.bookmarks$.unsubscribe();
	}
}
