/// <reference path="../../../../node_modules/@types/sharepoint/index.d.ts" />
import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSidenav } from '@angular/material/sidenav';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { GraphReportLikeService } from 'src/app/services/graph/report-like.service';
import { GraphUserSettingsService } from 'src/app/services/graph/user-settings.service';
import { LanguageService } from 'src/app/services/language.service';
import { ThemeService } from 'src/app/shared/services/theme.service';
import { Subscriptions } from 'src/app/subscriptions';
import { ModalOrientationComponent } from '../../components/modal-orientation/modal-orientation.component';
import { ApplicationInsightsService } from 'src/app/services/application-insights.service';

@Component({
	selector: 'app-main',
	templateUrl: './main.component.html',
	styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit, OnDestroy {

	events: string[] = [];
	opened = false;
	betaOpened = false;

	private readonly subscriptions = new Subscriptions();
	private hasCheckedOrientationFlag = false;
	isDarkTheme: Observable<boolean>;
	hasBetaReports = false;
	showBetaPullTab = false;
	@ViewChild('betasidenav') betasidenav: MatSidenav;

	constructor(
		@Inject(DOCUMENT) private readonly document: Document,
		private readonly languageService: LanguageService,
		public translateService: TranslateService,
		public themeService: ThemeService,
		public dialog: MatDialog,
		private readonly applicationInsightsService: ApplicationInsightsService,
		public router: Router,
		private userSettingsService: GraphUserSettingsService,
		private readonly reportLikeService: GraphReportLikeService

	) { }

	ngOnInit() {
		this.subscriptions.push(
			this.userSettingsService.userSettings.subscribe(s => {
				const isDarkTheme = s.settings.dark === 'true';
				const hasSeenOrientation = s.settings.hasSeenOrientation === 'true';

				this.themeService.setDarkTheme(isDarkTheme);
				this.setLanguage(s.settings.lang);
				if (!this.hasCheckedOrientationFlag && !hasSeenOrientation && s.userId != null) {
					this.dialog.open(ModalOrientationComponent, {
						width: '100%',
						panelClass: ['my-panel', 'orientation-panel'],
						data: {}
					});

					this.subscriptions.push(
						this.dialog.afterAllClosed.subscribe(value => {
							this.userSettingsService.setUserSetting('hasSeenOrientation', 'true');
						})
					);
					this.hasCheckedOrientationFlag = true;
				}
			})
		);

		// initialize app insights logging
		// on URL changes, log page view to application insights
		this.subscriptions.push(
			this.router.events.subscribe(event => {
				if (event instanceof NavigationEnd) {
					this.applicationInsightsService.logPageView("Navigation", location.href);
				}
			})
		);
		// log external links
		this.document.addEventListener('click', event => {
			const anchor = event.composedPath().find((x: any): x is HTMLAnchorElement => x.tagName === "A");
			if (anchor === undefined || anchor.hash.length !== 0) {
				return;
			}
			this.applicationInsightsService.logPageView("External", anchor.href);
		}, true);

		// Does user have access to any Beta reports?
		this.subscriptions.push(this.reportLikeService.reports.subscribe(reps => {
			this.hasBetaReports = reps.filter(x => x.status === "Beta").length > 0;
			this.setBetaPullTab(this.router.routerState.snapshot.url);
		}));

		// First route event on load
		this.setBetaPullTab(this.router.routerState.snapshot.url);
		// Subsequent events after load
		this.router.events
			.pipe(filter(event => event instanceof NavigationEnd))
			.subscribe((event: NavigationEnd) => {
				if (!navigator.onLine) {
					this.router.navigate(['/no-internet']);
				}
				this.setBetaPullTab(event.url);
			});
	}

	ngOnDestroy() {
		this.subscriptions.unsubscribeAll();
	}

	setBetaPullTab(url: string) {
		this.showBetaPullTab = this.hasBetaReports && url === "/categories";
	}

	onOpened(opened: boolean) {
		this.opened = opened;
	}

	onClosed(closed: boolean) {
		this.opened = !closed;
	}

	onBetaOpened(opened: boolean) {
		this.betaOpened = opened;
	}

	onBetaClosed(closed: boolean) {
		this.betaOpened = !closed;
	}

	private setLanguage(newLanguageCode: string): void {
		this.translateService.use(newLanguageCode); // triggers a change of language through the translate service, including static text keys piped through `translate`
		this.document.documentElement.setAttribute("lang", newLanguageCode); // <html lang="en">
	}

	betaPanelToggle() {
		this.betaOpened = !this.betaOpened;

		if (this.betaOpened) {
			this.betasidenav.open();
		} else {
			this.betasidenav.close();
		}
	}

	betaPanelClose() {
		this.betaOpened = false;
		this.betasidenav.close();
	}
}
