import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { GlossaryEntry } from 'src/app/models/glossary-entry.model';
import { LanguageService } from "src/app/services/language.service";
import { ColumnMapEntry, GraphService } from './graph.service';
import { ResourceNames } from "./resource-names";

@Injectable({
	providedIn: 'root'
})
export class GraphGlossaryService {
	private glossaryList: BehaviorSubject<GlossaryEntry[]> = new BehaviorSubject<GlossaryEntry[]>([]);

	constructor(
		private graphService: GraphService,
		private languageService: LanguageService
	) {
	}

	getGlossary(): Observable<GlossaryEntry[]> {
		if (this.glossaryList.value.length > 0) {
			return this.glossaryList;
		}
		const entities = this.graphService.getListItems<GlossaryEntry>(GraphGlossaryService.listName, GlossaryEntry, GraphGlossaryService.columnMap);
		// filter the sharepoint data using the current language according to the language service
		this.languageService
			.filterByLanguage(entities)
			.subscribe((result) => {
				this.glossaryList.next(result);
			});
		return this.glossaryList;
	}

	static listName = ResourceNames.GLOSSARY_LIST_NAME;

	static readonly columnMap: ReadonlyArray<ColumnMapEntry<GlossaryEntry>> = [
		{ modelKey: "title", spFriendlyFieldName: "Title" },
		{ modelKey: "termDefinition", spFriendlyFieldName: "GlossaryTermLongDefinition" },
		{ modelKey: "termLetter", spFriendlyFieldName: "GlossaryTermLetter", },
		{ modelKey: "languageCode", spFriendlyFieldName: "Language" },
	];

}