<mat-sidenav-container fxFlex="0 1 auto" [ngClass]="{'app-light-theme': !themeService.isDark, 'app-dark-theme': themeService.isDark}">
	<div class="table-container">
		<table mat-table *ngIf="dataSource" [dataSource]="dataSource" matSort>
			<!-- subjectAreaTitle -->
			<ng-container matColumnDef="subjectAreaTitle">
				<mat-header-cell *matHeaderCellDef mat-sort-header> {{'DataFilesManagement.SubjectArea' | translate}} </mat-header-cell>
				<mat-cell *matCellDef="let element"> {{element.subjectAreaTitle}} </mat-cell>
			</ng-container>
			<!-- providedFileName -->
			<ng-container matColumnDef="providedFileName">
				<mat-header-cell *matHeaderCellDef mat-sort-header> {{'ApprovalWorkflow.ApprovedFile' | translate}} </mat-header-cell>
				<mat-cell *matCellDef="let element">
					<div class="croppedTexts" title="{{element.name}}" [innerHTML]="element.name | newline"></div>
				</mat-cell>
			</ng-container>
			<!-- uploadedDate -->
			<ng-container matColumnDef="uploadedDate">
				<mat-header-cell *matHeaderCellDef> {{'ApprovalWorkflow.UploadedDate' | translate}} </mat-header-cell>
				<mat-cell *matCellDef="let element"> 
					<div title="{{element.uploadedDate}}" [innerHTML]="element.uploadedDate | dateFormatted:'mediumCustom'"></div>
				</mat-cell>
			</ng-container>
			
			<mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
			<mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
		</table>
		<!-- <div class="files-paginator">
			<mat-paginator [length]="dataSource.reports.length" [pageSize]="10" [pageSizeOptions]="[5, 10, 25]"></mat-paginator>
		</div> -->
	</div>
</mat-sidenav-container>