import { combineLatest } from 'rxjs';
import { ModalPopupComponent } from './../modal-popup/modal-popup.component';
import { TranslateService } from '@ngx-translate/core';
import { OverlayContainer } from '@angular/cdk/overlay';
import { Component, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { SafeUrl } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { Video } from 'src/app/models/video.model';
import { GraphVideoService } from 'src/app/services/graph/video.service';
import { ThemeService } from 'src/app/shared/services/theme.service';

@Component({
  selector: 'app-modal-video',
  templateUrl: './modal-video.component.html',
  styleUrls: ['./modal-video.component.scss']
})
export class ModalVideoComponent implements OnDestroy {
  public videoSrc: SafeUrl;
  public captionSrc: SafeUrl;
  public transcriptUrl: string;
  public videoName: string;
  private video$ = Subscription.EMPTY;
  public showSpinner = true;

  constructor(
    public themeService: ThemeService,
    public dialogRef: MatDialogRef<ModalVideoComponent>,
    public overlayContainer: OverlayContainer,
    private translateService: TranslateService,
    private dialog: MatDialog,
    private readonly videoService: GraphVideoService,
    @Inject(MAT_DIALOG_DATA) public data: {showTranscript: boolean, video: Video},
  ) {
	this.video$ = combineLatest([
		this.videoService.getVideoFile(data.video.group),
		this.videoService.getVideoData(data.video.group)]).subscribe(
			([videoFile, videoData]) => {
				this.videoSrc = videoFile;
				this.videoName = videoData.videoName;
        		this.captionSrc = videoData.captionsUrl;
        		this.transcriptUrl = videoData.transcriptionUrl;
				this.showSpinner = false;
			},
        	error => {
				const errorMessage =
					error.message.indexOf("Due to organizational policies") > -1 ?
					this.translateService.instant('Error.CannotAccessVideoOPS') :
					this.translateService.instant('Error.CannotAccessVideo');
				this.showSpinner = false;
				this.dialogRef.close();
				this.dialog.open(ModalPopupComponent, {
					width: 'rem(300px)',
					panelClass: 'popup',
					data: {
					popupMessage: errorMessage,
					popupOk: this.translateService.instant('Common.Controls.Ok'),
					reloadOnClose: false,
					closeWindowOnOk: false
					}
				});
				throw error;
			}
	);
  }

  ngOnDestroy() {
	this.video$.unsubscribe();
  }

  onClose(): void {
    this.dialogRef.close();
  }
}
