<ng-container *ngIf="!isLoading; else loadingData">
	<ng-container *ngIf="selectedProjectName && projectSets.length > 0; else noDataToDisplay">
		<div fxLayout="column" fxLayoutAlign="center center">
			<h2>{{selectedProjectName}}</h2>
		</div>
		<app-report-listing [reports]="projectSets"></app-report-listing>
	</ng-container>
	
	<ng-template #noDataToDisplay>
		<div fxLayout="column" fxLayoutAlign="center center">
			<h2>No data found for project code: ({{selectedProjectCode}})</h2>
		</div>
	</ng-template>
</ng-container>

<ng-template #loadingData>
	<div fxLayout="column" fxLayoutAlign="center center" class="process-spinner">
		<mat-spinner [diameter]="60"></mat-spinner>
	</div>
</ng-template>