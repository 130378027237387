import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, FormGroupDirective, ValidatorFn, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { Subscription } from 'rxjs';
import { Institution } from 'src/app/models/institution.model';
import { Signup } from 'src/app/models/signup.model';
import { SignUpService } from 'src/app/services//graph/sign-up.service';
import { ApplicationInsightsService } from 'src/app/services/application-insights.service';
import { GraphUserService } from 'src/app/services/graph/user.service';
import { LanguageService } from 'src/app/services/language.service';
import { ModalPopupComponent } from './../modal-popup/modal-popup.component';

@Component({
	selector: 'app-sign-up',
	templateUrl: './sign-up.component.html',
	styleUrls: ['./sign-up.component.scss']
})
export class SignUpComponent implements OnInit, OnDestroy {
	@ViewChild(FormGroupDirective) formRef: FormGroupDirective;
	captchaResponse = '';
	success = false;
	isSaving = false;
	ready = false;
	langCode = '';
	institutions: Institution[];
	filtered: Institution[] = [];
	orgTypes = [];
	subscriptions$: Subscription[] = [];
	constructor(private signupService: SignUpService, private fb: FormBuilder,
		private translateService: TranslateService, private router: Router,
		private userService: GraphUserService,
		private dialog: MatDialog,
		private reCaptchaV3Service: ReCaptchaV3Service,
		private applicationInsightsService: ApplicationInsightsService,
		private languageService: LanguageService) {
	}
	signup: FormGroup;

	customEmail(emailFld): ValidatorFn {
		return (control: AbstractControl): { [key: string]: boolean } | null => {
			if (control.value !== undefined && (emailFld.value !== control.value)) {
				return { confirmEmail: true };
			}
			return null;
		};
	}

	ngOnInit(): void {
		const user = this.userService.getUser();
		this.signup = this.fb.group({
			firstName: [(user && user.firstName) ? user.firstName : ''],
			lastName: [(user && user.lastName) ? user.lastName : ''],
			email: [user ? user.email : ''],
			confirmEmail: [user ? user.email : ''],
			phoneNumber: [''],
			reasonForAccess: [''],
			organization: [user ? user.companyName : ''],
			organizationType: [(user && user.email.includes('@ontario.ca')) ? 'OPS' : ''],
			language: ['']

		});

		this.setSubmitValidators();

		this.subscriptions$.push(this.signupService.getInstitutions().subscribe(a => {
			if (a.length === 0) {
				return;
			}
			this.institutions = a;
			this.ready = true;
		}));

		this.subscriptions$.push(this.languageService.currentLanguageCode.subscribe(x => this.langCode = x.toLowerCase()));
	}

	private getSubmitValidators() {
		return {
			['firstName']: Validators.required,
			['lastName']: Validators.required,
			['email']: [Validators.required, Validators.email],
			['confirmEmail']: [Validators.required, Validators.email, this.customEmail(this.signup.controls.email)],
			['phoneNumber']: Validators.required,
			['reasonForAccess']: Validators.maxLength(450),
			['organization']: Validators.required,
			['organizationType']: Validators.required,
			['language']: Validators.required

		};
	}


	signUp() {
		this.reCaptchaV3Service.execute("opensims_signup")
			.subscribe(response => {
				this.captchaResponse = response;
				if (this.canSubmit()) {
					this.isSaving = true;
					this._signup();
				}
			}, (error: string) => {

				try {
					this.applicationInsightsService.logException(error);
				} catch { // nothing
				}
				
				if (error?.toUpperCase() === "CANNOT VALIDATE SUBMISSION") { // failed recaptcha because of missing response token, or recaptcha service not reachable, or recaptcha scored too low.
					return;
				}
				this.dialog.open(ModalPopupComponent, {
					width: 'rem(300px)',
					panelClass: 'popup',
					data: {
						popupMessage: error?.toUpperCase() === "SIGNUP MODEL IS INVALID" ? this.translateService.instant('Error.SomeEmptyFields') : this.translateService.instant('Error.ServerErrorPleaseRetry'),
						popupOk: this.translateService.instant('Common.Controls.Ok')
					}
				});
			});
	}

	canSubmit() {
		Object.keys(this.signup.controls).forEach(field => {
			const control = this.signup.get(field);
			control.markAsTouched();
			control.updateValueAndValidity({ emitEvent: true });
		});
		return this.signup.valid;
	}

	setSubmitValidators() {
		const validators = this.getSubmitValidators();

		Object.keys(this.signup.controls).forEach(field => {
			const control = this.signup.get(field);
			if (validators[field]) {
				control.clearValidators();
				control.setValidators(validators[field]);
			}
		});
	}

	organizationTypeChanged() {
		this.filtered = this.institutions.filter(a => a.institutionType === this.getOrganizationType()).sort((a, b) => a.displayName.localeCompare(b.displayName));
		this.signup.controls.organization.setValue('');
	}

	getOrganizationType(): string {
		const orgType = this.signup.controls.organizationType.value;
		
		if (this.langCode === "fr") {
			// need to return organization type in English for proper filtering
			if (orgType.indexOf("Universit") > -1) return "University"
			else if (orgType.indexOf("Coll") > -1) return "College"
			else return orgType;
		}
		
		return orgType;
	}

	cancel() {
		this.router.navigateByUrl('sign-in');
	}

	ngOnDestroy() {
		this.subscriptions$.map(a => a.unsubscribe);
	}

	_signup() {
		if (this.captchaResponse.length === 0) {
			return;
		}
		const signupModel: Signup = {
			email: this.signup.get("email").value,
			firstName: this.signup.get("firstName").value,
			lastName: this.signup.get("lastName").value,
			phone: this.signup.get("phoneNumber").value,
			reason: this.signup.get("reasonForAccess").value,
			language: this.signup.get("language").value,
			organization: this.signup.get("organizationType").value + "-" + this.signup.get("organization").value,
			captchaResponse: this.captchaResponse
		};
		this.signupService.save(signupModel).subscribe(result => {
			this.dialog.open(ModalPopupComponent, {
				width: 'rem(300px)',
				panelClass: 'popup',
				data: {
					popupMessage: result ? this.translateService.instant('SignUp.SUCCESS_SIGNUP') : this.translateService.instant('Error.ServerErrorPleaseRetry'),
					popupOk: this.translateService.instant('Common.Controls.Ok')
				}
			}).afterClosed().subscribe(() => {
				if (result) {
					this.success = true;
					this.router.navigateByUrl('sign-in');
				} else {
					this.formRef.resetForm(); // Removes red validation messages
					this.signup.reset(); // empties form fields
				}
			});
		});
	}
}

