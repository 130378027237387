import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { combineLatest } from 'rxjs';
import { Video } from 'src/app/models/video.model';
import { GraphVideoService } from 'src/app/services/graph/video.service';
import { ThemeService } from './../../shared/services/theme.service';
import { ModalVideoComponent } from './../modal-video/modal-video.component';

@Component({
	selector: 'app-video-gallery',
	templateUrl: './video-gallery.component.html',
	styleUrls: ['./video-gallery.component.scss']
})
export class VideoGalleryComponent {
	public videos: Video[];

	constructor(
		videoService: GraphVideoService,
		private readonly route: ActivatedRoute,
		public themeService: ThemeService,
		private dialog: MatDialog,
	) {
		combineLatest([videoService.getVideos(), this.route.queryParams]).subscribe(([videos, params]) => {
			const id = params.id;
			this.videos = id ? videos.filter(x => x.group === id) : videos;
		});
	}

	ShowVideo(video) {
		this.dialog.open(ModalVideoComponent, {
			width: '50vw',
			panelClass: ['my-panel', 'video-panel'],
			data: {showTranscript: false, video},
		});
	}

	GetTranscriptUrl(group) {
		return this.videos.find(x => x.group === group && x.fileType === "Transcript")?.url.address;
	}
}
