import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AdminDashboardEntry } from 'src/app/models/admin-dashboard-entry.model';
import { GraphAdminDashboardService } from 'src/app/services/graph/admin-dashboard.service';
import { ThemeService } from './../../shared/services/theme.service';
import { environment } from 'src/environments/environment';
import { GraphService } from 'src/app/services/graph/graph.service';

const UI_URL_SUFFIX = "index.html#/";
const UAT_WORKFLOW_URL_SUFFIX = "uat-workflow";
const PROD_WORKFLOW_URL_SUFFIX = "prod-workflow";

@Component({
  selector: "app-admin-dashboard",
  templateUrl: "./admin-dashboard.component.html",
  styleUrls: ["./admin-dashboard.component.scss"],
})
export class AdminDashboardComponent {
  readonly entries: Observable<AdminDashboardEntryExtended[]>;
  readonly environment = environment;

  constructor(
    private readonly adminDashboardService: GraphAdminDashboardService,
    private readonly graphService: GraphService,
    public themeService: ThemeService
  ) {
    this.entries = adminDashboardService.getEntries().pipe(
      map((entry: AdminDashboardEntry[]) =>
        entry.map((item: AdminDashboardEntry) => {
          let extended = item as AdminDashboardEntryExtended;
          extended.isPowerBiReport = this.isPowerBiEntry(extended.url);
          extended.isOpenInSameTab = this.isUiPage(extended.url);
          extended.isHidden =
            this.isHiddenUatApprovalWorkflow(extended.url) ||
            this.isHiddenProdApprovalWorkflow(extended.url);
          return extended;
        })
      )
    );
  }

  getentryURL(url) {
    return url.startsWith("http") || this.isUiPage(url)
      ? url
      : environment.sharepointSiteBaseUrl + environment.simsSite + url;
  }

  isUiPage(url: string): boolean {
    return url.indexOf(UI_URL_SUFFIX) > -1;
  }

  isPowerBiEntry(url: string): boolean {
    return url.startsWith(environment.powerBiUrl);
  }

  isHiddenUatApprovalWorkflow(url: string) {
    return (
      this.isUiPage(url) &&
      url.includes(UAT_WORKFLOW_URL_SUFFIX) &&
      !environment.dataFilesManagement.hasUatApprovalWorkflow
    );
  }

  isHiddenProdApprovalWorkflow(url: string) {
    return (
      this.isUiPage(url) &&
      url.includes(PROD_WORKFLOW_URL_SUFFIX) &&
      !environment.dataFilesManagement.hasProdApprovalWorkflow
    );
  }

  OpenReportWindow(entry: AdminDashboardEntryExtended) {
    const reportCode = entry.title.replace(" ", "");
    window.name = "ParentWindow" + reportCode;
    (window as any).ChildWindowName = window.open("#report-view/" + reportCode);
  }

  public testEmailNotify(): void {
    const action = "Open SIMS Test Email Notification";

    const formData = new FormData();
    formData.append("subject", action);
    formData.append("body", action);

    this.graphService.testEmailNotifications(formData).subscribe(
      () => {
        console.log("emailed");
      },
      (error) => {
        console.log(error);
      }
    );
  }
}

class AdminDashboardEntryExtended extends AdminDashboardEntry {
	public isPowerBiReport: boolean = false;
	public isOpenInSameTab: boolean = false;
	public isHidden: boolean = false;
}