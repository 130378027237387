import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { AdminDashboardEntry } from 'src/app/models/admin-dashboard-entry.model';
import { ColumnMapEntry, GraphService } from 'src/app/services/graph/graph.service';
import { ResourceNames } from "./resource-names";

@Injectable({
	providedIn: 'root'
})
export class GraphAdminDashboardService {
	private entries: BehaviorSubject<AdminDashboardEntry[]> = new BehaviorSubject<AdminDashboardEntry[]>([]);
	private pendingRequests: boolean = false;
	constructor(
		private graphService: GraphService
	) {
	}

	getEntries(): Observable<AdminDashboardEntry[]> {
		if (this.entries.value.length > 0 || (this.pendingRequests)) {
			return this.entries;
		}
		this.pendingRequests = true;
		this.graphService.getListItems<AdminDashboardEntry>(GraphAdminDashboardService.listTitle, AdminDashboardEntry, GraphAdminDashboardService.columnMap)
			.subscribe((result) => {
				this.entries.next(result);
				this.pendingRequests = false;
			});
		return this.entries;

	}
	static readonly listTitle = ResourceNames.ADMIN_DASHBOARD_LIST_NAME;
	static readonly columnMap: ReadonlyArray<ColumnMapEntry<AdminDashboardEntry>> = [
		{ modelKey: "title", spFriendlyFieldName: "Title" },
		{ modelKey: "url", spFriendlyFieldName: "URL" },
		{ modelKey: "description", spFriendlyFieldName: "Description", },
	];
}