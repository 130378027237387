import { Injectable } from "@angular/core";
import { Observable } from 'rxjs';
import { Contact } from "src/app/models/contact.model";
import { GraphService, ColumnMapEntry } from './graph.service';
import { ResourceNames } from "./resource-names";

@Injectable({
	providedIn: 'root'
})
export class GraphContactService {

	constructor(
		private readonly graphService: GraphService
	) { }


	static readonly listTitle = ResourceNames.CONTACT_US_LIST_NAME;
	static readonly columnMap: ReadonlyArray<ColumnMapEntry<Contact>> = [
		{ modelKey: "categoryCode", spFriendlyFieldName: "Section" },
		{ modelKey: "message", spFriendlyFieldName: "Comment" },
		{ modelKey: "id", spFriendlyFieldName: "Id" },
	];

	public submit(contact: Contact): Observable<Contact> {
		const spContact = this.convertToSpFriendlyItem(contact)

		return this.graphService.addListItem(GraphContactService.listTitle, spContact);
	}

	private convertToSpFriendlyItem(contact: Contact) {
		let item = {};
		GraphContactService.columnMap.map(prop => {
			const spProperty = prop.spFriendlyFieldName;
			const contactProperty = contact[prop.modelKey];
			if (!Array.isArray(spProperty) && contactProperty) {
				item[spProperty] = contactProperty;
			}
		});
		return item;
	}
}