import { Injectable, OnDestroy } from "@angular/core";
import { BehaviorSubject, Observable, Subscription } from "rxjs";
import { GraphUserSettingsService } from 'src/app/services/graph/user-settings.service';
import { ReportViewModel } from "src/app/view-models/report-view-model";

@Injectable({
	providedIn: 'root'
})
export class BookmarkService implements OnDestroy {
	private readonly _bookmarkIds: BehaviorSubject<string[]>;
	get bookmarks(): Observable<string[]> { return this._bookmarkIds; }
	subscription$: Subscription = Subscription.EMPTY;

	constructor(private userSettingsService: GraphUserSettingsService,
	) {
		this._bookmarkIds = new BehaviorSubject<string[]>([]);
		this.subscription$ = this.userSettingsService.userSettings.subscribe(s => {
			const bookmarkIds = s.settings.bookmarkIds.split(',');
			this._bookmarkIds.value.push(bookmarkIds);
			this._bookmarkIds.next(bookmarkIds);
		});
	}

	ngOnDestroy(): void {
		this.subscription$.unsubscribe();
	}

	private addToLocalBookmarks(bookmarkId: string): void {
		this._bookmarkIds.value.push(bookmarkId); // just use the existing array
		this._bookmarkIds.next(this._bookmarkIds.value); // then re-publish to tell everyone about the "new" array
	}

	private removeFromCurrentBookmarks(bookmarkId: string): void {
		const filtered = this._bookmarkIds.value.filter(x => x !== bookmarkId);
		this._bookmarkIds.next(filtered);
	}

	private async addBookmark(report: ReportViewModel): Promise<void> {
		this.addToLocalBookmarks(report.code);
		this.userSettingsService.setUserSetting('bookmarkIds', this._bookmarkIds.value.join());
	}

	private async deleteBookmark(bookmarkId: string): Promise<void> {
		this.removeFromCurrentBookmarks(bookmarkId);
		this.userSettingsService.setUserSetting('bookmarkIds', this._bookmarkIds.value.join());
	}

	public tryFindExistingBookmarkFor(viewModel: ReportViewModel): string | undefined {
		const found = this._bookmarkIds.value.find(b => b === viewModel.code);
		return found;
	}

	public async toggleBookmark(viewModel: ReportViewModel): Promise<boolean> {
		const existingBookmark = this.tryFindExistingBookmarkFor(viewModel);
		if (existingBookmark !== undefined) {
			await this.deleteBookmark(existingBookmark);
			return false;
		}
		await this.addBookmark(viewModel);
		return true;
	}
}
