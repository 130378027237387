<div class="container" *ngIf="showCategories" [ngClass]="{'app-light-theme': !themeService.isDark, 'app-dark-theme': themeService.isDark}">
    <h1 
		id="maincontent" 
		tabindex="0"
		role="heading"
	>
		{{ 'Home.Sections.Categories.md' | translate}}
	</h1>	
    <p 
		tabindex="0"
		role="contentinfo"
	>
		{{ 'Home.Sections.Categories.SubTitle' | translate}}
	</p>
    <div class="categories-container" fxLayout="row wrap" fxLayoutAlign="center" fxLayoutGap.gt-sm="15px" role="menu">
        <!-- need or Sass doesn't match against theme changes -->
        <mat-card [appA11yActivate]="{method:'openCategory', params:category.key, context:this}" [attr.aria-label]="category.title" fxLayoutAlign="space-between" fxLayout="column"
            *ngFor="let category of categories" tabindex="0" role="menuitem">
            <div aria-hidden="true">
                <mat-card-header fxLayoutAlign="start center" fxLayout="row" fxLayout.gt-sm="column">
                    <img aria-hidden="true" alt="" class="icon" mat-card-avatar src="assets/img/{{themeService.isDark ? 'dark/': ''}}{{ category.getIcon() }}_icon.svg" />
                    <mat-card-title>{{category.title}}</mat-card-title>
                </mat-card-header>
            </div>
            <mat-card-content class="objective">{{ category.objective}}</mat-card-content>
            <button mat-flat-button color="primary" tabindex="-1" [attr.aria-label]="('Home.Sections.Categories.ViewCategory' | translate) + ': ' + category.title">{{'Home.Sections.Categories.ViewCategory' | translate}}</button>
        </mat-card>
    </div>
</div>
<router-outlet></router-outlet>