
import { Component, OnDestroy, OnInit, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { MsalBroadcastService, MsalService } from 'src/app/services/msal';
import { filter, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { MsalBroadcastEvent } from 'src/app/services/msal/constants';
import { AuthenticationResult, EventMessage } from '@azure/msal-browser';
import { LanguageService } from 'src/app/services/language.service';
import { DOCUMENT } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { GraphUserSettingsService } from 'src/app/services/graph/user-settings.service';

@Component({
	selector: 'app-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {
	loggedIn = false;
	currentLanguageCode: string;
	private readonly _destroying$ = new Subject<void>();

	constructor(
		private readonly broadcastService: MsalBroadcastService,
		private readonly authService: MsalService,
		private readonly router: Router,
		@Inject(DOCUMENT) private readonly document: Document,
		private readonly languageService: LanguageService,
		private readonly translateService: TranslateService,
		private readonly userSettingsService: GraphUserSettingsService,
 	) {
		this.checkAccount();
		if (this.loggedIn) {
			this.router.navigateByUrl('categories');
		} else {
			this.loggedIn = true;
			setTimeout(a => this.loggedIn = false, 2000); // avoid flickering. If user is not logged in login will appear.
		}
	}

	ngOnInit() {
		this.broadcastService.msalSubject$.pipe(
			filter(msg => msg.type === MsalBroadcastEvent.LOGIN_SUCCESS),
			takeUntil(this._destroying$)
		).subscribe((result: EventMessage) => {
			const payload = result.payload as AuthenticationResult;
			this.notifyLoginSuccess();
			this.setLanguage(payload?.state);
		});

		this.languageService.currentLanguageCode.pipe(
			takeUntil(this._destroying$)
		).subscribe(lang => {
			this.currentLanguageCode = lang;
		});
	}

	notifyLoginSuccess() {
		this.broadcastService.broadcast('osims:loginSuccess',<AuthenticationResult>{});
	}

	checkAccount() {
		this.loggedIn = !!this.authService.getAccount();
	}

	login() {
		this.authService.loginRedirect(undefined, this.currentLanguageCode);
	}

	signup() {
		this.router.navigateByUrl('sign-up');
	}

	setLanguage(langState: string): void {
		const langCode = langState != null 
			? langState.split(':')[1] ?? undefined 
			: undefined;

		if(langCode) {
			this.translateService.use(langCode);
			this.userSettingsService.init().subscribe(() => {
				this.languageService.overrideCurrentBrowserLanguage(langCode);
				this.document.documentElement.setAttribute("lang", langCode); // <html lang="en">
			});
		}
	}

	ngOnDestroy(): void {
		this._destroying$.next(null);
		this._destroying$.complete();
	}
}
