import { DOCUMENT } from '@angular/common';
import { AfterViewInit, Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { EMPTY, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { Category } from 'src/app/models/category.model';
import { GraphCategoryService } from 'src/app/services/graph/category.service';
import { GraphGlossaryService } from 'src/app/services/graph/glossary.service';
import { GraphReportLikeService } from 'src/app/services/graph/report-like.service';
import { ReportViewModel } from 'src/app/view-models/report-view-model';
import { ThemeService } from './../../shared/services/theme.service';

export interface SortBy {
	value: string;
	viewValue: string;
}

@Component({
	selector: 'app-category',
	templateUrl: './category.component.html',
	styleUrls: ['./category.component.scss']
})
export class CategoryComponent implements OnInit, AfterViewInit {
	public reports: ReportViewModel[] = [];
	public category: Observable<Category> = EMPTY;

	currentLanguageCode: string;

	isBetaReportsPage: boolean;

	constructor(
		@Inject(DOCUMENT) private readonly document: Document,
		private readonly glossaryService: GraphGlossaryService,
		private readonly reportLikeService: GraphReportLikeService,
		readonly categoryService: GraphCategoryService,
		private activatedRouter: ActivatedRoute,
		public themeService: ThemeService,
		private translateService: TranslateService
	) {}

	ngOnInit(): void {
		this.currentLanguageCode = this.translateService.currentLang;

		this.translateService.onLangChange.subscribe((params: LangChangeEvent) => {
			this.currentLanguageCode = params.lang;
			this.getReportsAndCategory();
		});

		this.getReportsAndCategory();
	}

	getReportsAndCategory() {
		const categoryKey = this.activatedRouter.snapshot.params.categoryKey;
		this.isBetaReportsPage = categoryKey.toLowerCase() === 'beta';

		this.reportLikeService.reports
			.pipe(map(reps => reps.filter(x => (x.lang.toLowerCase() === this.currentLanguageCode.toLowerCase()) 
				&& (this.isBetaReportsPage 
					? x.status.toLowerCase() === 'beta'
					: x.categoryKeys.indexOf(categoryKey) > -1 )
		))).subscribe(r => this.reports = r);

		this.category = this.isBetaReportsPage
			? of(new Category("Beta",
				this.translateService.instant("Home.BetaReports.Title"),
				this.translateService.instant("Home.BetaReports.Text"),
				))
			: this.categoryService.getCategories().pipe(map(x =>
				x.filter((y): y is Category => y.key.toLowerCase() === categoryKey.toLowerCase() && y.languageCode.toLowerCase() === this.currentLanguageCode.toLowerCase())[0]));
	}

	ngAfterViewInit() {
		if (this.isBetaReportsPage) {
			this.skipToMainContent();
		}
	}

	skipToMainContent() {
		let htmlElement = this.document.getElementById('maincontent');
		htmlElement.focus();
		htmlElement.scrollIntoView();
	}
}