import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ProjectDatasetViewModel } from 'src/app/models/dataset.model';
import { ProjectDatasetService, IDownloadableFile } from 'src/app/services/graph/project-dataset.service';
import { ThemeService } from 'src/app/shared/services/theme.service';
import { ModalPopupComponent } from '../modal-popup/modal-popup.component';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { ConfirmationPopupService } from 'src/app/shared/services/confirmation-popup.service';
import { Constants } from 'src/app/services/graph/resource-names';

@Component({
  selector: 'app-download-picker',
  templateUrl: './dataset-downloader.component.html',
  styleUrls: ['./dataset-downloader.component.scss']
})
export class DatasetDownloaderComponent implements OnInit {
	@Input() set projectCode(value: string) {
		this._projectCode = this.selectedProjectCode = value;
		this.isForRemoval = this._projectCode && this._projectCode.length > 0;

		this.loadData();
	};

	_projectCode: string;

	displayedColumns: string[];
	selectedProjectCode: string;
	selectedProjectName: string;

	isForRemoval: boolean = false;

	projectDataSets: ProjectDatasetViewModel[] = [];
	dataSource: MatTableDataSource<ProjectDatasetViewModel>;

	constructor(
		private activatedRoute: ActivatedRoute,
		private datasetService: ProjectDatasetService,
		private translateService: TranslateService,
		private dialog: MatDialog,
		public router: Router,
		public themeService: ThemeService,
		private popupService: ConfirmationPopupService,
	) { }

	@ViewChild(MatSort) sort: MatSort;

	ngOnInit(): void {
		this.isForRemoval = this._projectCode && this._projectCode.length > 0;
		this.displayedColumns = this.isForRemoval ? ['datasetName', 'lastRefreshedDate', 'fileNames', 'action'] : ['datasetName', 'datasetDescription', 'lastRefreshedDate', 'fileNames', 'action'];
		this.selectedProjectCode = this._projectCode || this.activatedRoute.snapshot.params.project || this.activatedRoute.snapshot.queryParamMap.get('project');

		this.loadData();

		this.translateService.onLangChange.subscribe(_ => {
			this.loadData()
		});
	}

	loadData() {
		this.datasetService.getProjectDataSetViewModel(null, this.selectedProjectCode, this.isForRemoval).subscribe(projectSets => {
			this.selectedProjectName = projectSets.find(x => x.projectCode.toLowerCase() === this.selectedProjectCode.toLowerCase()).projectTitle;
			this.projectDataSets = projectSets.sort((a, b) => a.datasetName.localeCompare(b.datasetName));
			this.loadDataSourcePage();
		});
	}

	loadDataSourcePage() {
		this.dataSource = new MatTableDataSource(this.projectDataSets);
		this.dataSource.sort = this.sort;
	}

	onDownloadDataset(dataset: ProjectDatasetViewModel) {
		const isMultilpeFilesDataSet = dataset.url.indexOf(Constants.SEPARATOR_SEMICOLON) > -1;

		if (isMultilpeFilesDataSet) this.datasetService.downloadZipArchivedDataSet(dataset.url).subscribe(
			(file: Blob) => this.createDownloadableLink(window.URL.createObjectURL(file), `${this.sanitizeFileName(dataset.datasetName)}.zip`),
			error => this.onError(error));
		else this.datasetService.downloadDataSet(dataset.url).subscribe(
			(file: IDownloadableFile) => this.createDownloadableLink(file.url, file.fileName),
			error => this.onError(error));
	}

	onRemoveFromDataset(dataset: ProjectDatasetViewModel) {
		const dialogRef = this.popupService.openConfirmDialog("DownloadDataset.ConfirmRemoveFromDataset");
	
		dialogRef.afterClosed().subscribe(dialogResult => {
			if (dialogResult) this.removeFromDataset(dataset); 
		});
	}

	removeFromDataset(dataset: ProjectDatasetViewModel) {
		this.datasetService.removeFileFromDataset(dataset).subscribe(() => {
			this.popupService.openConfirmMessage("DownloadDataset.Removed");
			this.loadData();
		},
		error => this.popupService.openConfirmMessage("DownloadDataset.RemoveError"));
	}

	createDownloadableLink(href: any, fileName: string) {
		const link = document.createElement('a');
			link.setAttribute('href', href);
			link.setAttribute('download', fileName);
			link.setAttribute('target', "_blank");
			document.body.appendChild(link);
			link.click();
			link.remove();
	}

	onError(error) {
		const errorMessage = this.translateService.instant('Error.CannotAccessSourceData');
		this.dialog.open(ModalPopupComponent, {
			width: 'rem(300px)',
			panelClass: 'popup',
			data: {
				popupMessage: errorMessage,
				popupOk: this.translateService.instant('Common.Controls.Ok'),
				reloadOnClose: false,
				closeWindowOnOk: false
			}
		});
		throw error;
	}

	sortData(sort: Sort) {
		const data = this.projectDataSets.slice();
		if (!sort.active || sort.direction === '') {
			return;
		}
	
		this.projectDataSets = data.sort((a, b) => {
			const isAsc = sort.direction === 'asc';
			switch (sort.active) {
				case 'id': return compare(a.id, b.id, isAsc);
				case 'datasetName': return compare(a.datasetName, b.datasetName, isAsc);
				case 'datasetDescription': return compare(a.datasetDescription, b.datasetDescription, isAsc);
				case 'lastRefreshedDate': return compare(a.lastRefreshedDate.toString(), b.lastRefreshedDate.toString(), isAsc);
				case 'fileNames': return compare(a.fileNames, b.fileNames, isAsc);
				default: return 0;
			}
		});

		this.loadDataSourcePage();
	}

	private sanitizeFileName(fileName: string): string {
		return fileName.trim().replace(/[^a-z0-9 ]/gi, "");
	}
}

function compare(a: number | string, b: number | string, isAsc: boolean) {
	return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
