import { Component, Input, OnChanges } from '@angular/core';
import { ThemeService } from './../../shared/services/theme.service';

@Component({
	selector: 'app-read-more',
	templateUrl: './read-more.component.html',
	styleUrls: ['./read-more.component.scss']
})

export class ReadMoreComponent implements OnChanges {
	@Input() text: string;
	@Input() maxLength = 100;
	currentText: string;
	hideToggle = true;
	public isCollapsed = true;

	constructor(
		public themeService: ThemeService,
	) { }

	toggleView() {
		this.isCollapsed = !this.isCollapsed;
		this.determineView();
	}

	determineView() {
		if (!this.text && this.text.length > 0 && this.text.length <= this.maxLength) {
			this.currentText = this.text;
			this.isCollapsed = false;
			this.hideToggle = true;
			return;
		}
		this.hideToggle = false;
		if (this.isCollapsed === true) {
			this.currentText = this.text.substring(0, this.maxLength);
			this.currentText = this.currentText.substr(0, Math.min(this.currentText.length, this.currentText.lastIndexOf(" ")))
		} else if (this.isCollapsed === false) {
			this.currentText = this.text;
		}
	}

	ngOnChanges() {
		this.determineView();
	}
}