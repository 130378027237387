import { SearchableViewModel } from './../../view-models/searchable-view-model';
import { Component, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { combineLatest, EMPTY, Observable, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter } from 'rxjs/operators';
import { GraphReportLikeService } from 'src/app/services/graph/report-like.service';
import { LanguageService } from '../../services/language.service';
import { SearchIndexService } from '../../services/search-index.service';
import { ThemeService } from '../../shared/services/theme.service';
import { ReportViewModel } from '../../view-models/report-view-model';

@Component({
	selector: 'app-search-bar',
	templateUrl: './search-bar.component.html',
	styleUrls: ['./search-bar.component.scss']
})
export class SearchBarComponent implements OnInit, OnDestroy {
	private readonly searchService: SearchIndexService;
	public isLowRes = false;
	public selectedReportType = "All";
	public options = [];

	searchForm: FormGroup;
	searchInputControl = new FormControl('');
	results: Observable<SearchableViewModel[]> = EMPTY;
	currentLanguageCode: string;
	subscriptions$: Subscription[] = [];
	searchResult: SearchableViewModel[] = [];
	@Input() keywords: string;
	@Output() searchTerms = new EventEmitter<{ keywords: string, type: string }>();

	@HostListener('window:resize', ['$event'])
	getScreenSize() {
		this.isLowRes = window.innerWidth <= 1280;
	}

	constructor(
		private readonly reportLikeService: GraphReportLikeService,
		public themeService: ThemeService,
		private languageService: LanguageService,
		private router: Router,
		private activatedRoute: ActivatedRoute
	) {
		this.searchService = new SearchIndexService(this.reportLikeService.nofactbooks);
		this.isLowRes = window.innerWidth <= 1280;
		this.searchForm = new FormGroup({ searchInputControl: this.searchInputControl });

		this.subscriptions$.push(this.router.events.subscribe((e: any) => {
			// If it is a NavigationEnd event re-initalise the component
			if (e instanceof NavigationEnd) {
				if (this.activatedRoute.snapshot.queryParamMap.get('keywords')) {
					this.keywords = this.activatedRoute.snapshot.queryParamMap.get('keywords');
					this.searchInputControl.setValue(this.keywords);
				}
			}
		}));
	}

	ngOnInit() {
		this.subscriptions$.push(this.languageService.currentLanguageCode.subscribe(x => {
			this.currentLanguageCode = x;
		}));

		this.subscriptions$.push(this.searchInputControl.valueChanges
			.pipe(
				filter((res: any) => res.length > 2),
				debounceTime(250),
				distinctUntilChanged()
			).
			subscribe(value => {
				if (value === this.keywords) {
					return;
				}

				this.results = combineLatest(
					this.reportLikeService.nofactbooks,
					this.searchService.index,
					(reports, searchService) => {
						if (value !== "") {
														this.keywords = (value instanceof ReportViewModel ? value.title : value).trim().
								replace(/[^A-Za-z0-9_\sàâçéèêëïîôùûüÿÀÂÇÉÈÊËÏÎÔÙÛÜŸ()]/g, '');
							this.searchResult = searchService.search(this.keywords, "p");
						} else { // show all when nothing is entered
							this.searchResult = reports;
						}
						return this.searchResult.filter(x => !this.reportLikeService.isExpiredSearchableViewModel(x));
					});
			}));

		if (this.keywords) {
			this.searchInputControl.setValue(this.keywords);
		}
	}

	ngOnDestroy(): void {
		this.subscriptions$.map(a => a.unsubscribe());
	}

	displayFn(report: ReportViewModel): string {
		return report && report.title ? report.title : String(report);
	}

	optionSelected(e) {
		this.keywords = e.option.value.title;
		this.submitSearch("f");
	}

	submitSearch(type = "p") {
		this.keywords = this.keywords || this.searchInputControl.value;
		this.searchTerms.emit({ keywords: this.keywords, type });
	}
}
