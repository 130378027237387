import { environment } from 'src/environments/environment';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Optional, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { EMPTY, Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { Category } from 'src/app/models/category.model';
import { Factbook } from 'src/app/models/factbook-model';
import { Video } from 'src/app/models/video.model';
import { BookmarkService } from 'src/app/services/bookmark.service';
import { GraphCategoryService } from 'src/app/services/graph/category.service';
import { GraphVideoService } from 'src/app/services/graph/video.service';
import { LanguageService } from 'src/app/services/language.service';
import { TypeName } from 'src/app/type-name';
import { ReportViewModel } from 'src/app/view-models/report-view-model';
import { ThemeService } from './../../shared/services/theme.service';
import { ModalReportDetailsComponent } from './../modal-report-details/modal-report-details.component';
import { ModalVideoComponent } from './../modal-video/modal-video.component';
import { ModalPopupComponent } from './../modal-popup/modal-popup.component';
import { GraphService } from 'src/app/services/graph/graph.service';
import { ApplicationInsightsService } from 'src/app/services/application-insights.service';

const POWER_BI_REPORT_URL = "app.powerbi.com/";

@Component({
	selector: 'app-report-card',
	templateUrl: './report-card.component.html',
	styleUrls: ['./report-card.component.scss'],
})

export class ReportCardComponent implements OnInit, OnDestroy {
	@Input() isInList = false;
	@Input() isLowRes = false;
	private _keywords: string;
	@Input() set keywords(value: string) {
		this._keywords = value;
		this.reg = this._keywords ? new RegExp(this._keywords + '|' + this._keywords.replace(/\//g, '//').replace(/\s+/g, '|'), "gi") : null;
		this.description = this.getReportDesctiption();
	}
	get keywords(): string {
		return this._keywords;
	}

	@Input() isBookmarkPage: boolean;
	@Input() isFactbookPage: boolean;
	@Output() ViewReportDetail: EventEmitter<any> = new EventEmitter();
	categories: Category[];
	readonly environment = environment;
	readonly ReportDisplayType = ReportDisplayType;

	isInDialog = false;
	isFactbook = false;
	reportDisplayType: ReportDisplayType;
	isExternal = false;
	currentLanguageCode: string;
	subscription$: Subscription = Subscription.EMPTY;
	bookmarkIcon: Observable<'bookmark' | 'bookmark_border'> = EMPTY;
	video: Video;

	factbookDataOwner: IDataOwnerUnknown;

	isBookmarked: boolean;
	isMultipleFiles = false;
	description: string;
	originalDescription: string;
	reg: RegExp;

	private _viewModel: ReportViewModel | null = null;
	@Input() set viewModel(viewModel: ReportViewModel | null) {
		this._viewModel = viewModel;
		if (this._viewModel === null || typeof this._viewModel === 'undefined') {
			return;
		}
		const isBookmarked = this.bookmarkService.tryFindExistingBookmarkFor(this._viewModel) !== undefined;
		this._viewModel.isBookmarked.next(isBookmarked);
		this.bookmarkIcon = this._viewModel.isBookmarked.pipe(map(x => {
			this.isBookmarked = x;
			return x ? 'bookmark' : 'bookmark_border';
		}));
		this.isFactbook = this._viewModel.isModelOfType(Factbook);
		this.isMultipleFiles = this._viewModel.downloadableFiles && viewModel.downloadableFiles.length > 1; 
		this.reportDisplayType = this.getReportDisplayType();		
		this.originalDescription = this._viewModel.description;
		this.description = this.getReportDesctiption();
		this.isExternal = this.viewModel.tags.includes("External");
		this.subscription$.add(this.categoryService.getCategories().subscribe(cats => {
			this.categories= cats.filter(cat => this.viewModel.categoryKeys.indexOf(cat.key) > -1 && cat.languageCode.toUpperCase() === this.currentLanguageCode.toUpperCase());
		}));
		this.videoService.getVideos().pipe(map(vids => {
			if (!this.viewModel.properties.videoId) {
				return null;
			}
			return vids.filter(x => x.group === this.viewModel.properties.videoId.queryParameters.id)[0];
		})).subscribe(v => this.video = v);
	}

	get viewModel() { return this._viewModel; }

	constructor(
		public readonly translateService: TranslateService,
		private readonly languageService: LanguageService,
		private readonly bookmarkService: BookmarkService,
		public themeService: ThemeService,
		private readonly categoryService: GraphCategoryService,
		private dialog: MatDialog,
		private videoService: GraphVideoService,
		private router: Router,
		private graphService: GraphService,
		private applicationInsightsService: ApplicationInsightsService,
		@Optional() private reportDetailModal?: ModalReportDetailsComponent,
	) {
		this.currentLanguageCode = this.translateService.currentLang;
		this.isBookmarked = this.isBookmarkPage;
	}

	ngOnInit() {
		this.subscription$ = this.languageService.currentLanguageCode.subscribe(x => {
			this.currentLanguageCode = x;
		});

		this.isInDialog = (this.reportDetailModal != null);

		const list = document.body.getElementsByClassName("croppedTexts");
		for (let i = 0; i < list.length; i++) {
			this.cropTextToFit(list[i]);
		}

		if (this.isFactbook && !this.viewModel.dataOwner.address) {
			this.getFactbookDataOwner();

			this.translateService.onLangChange.subscribe((params: LangChangeEvent) => {
				this.currentLanguageCode = params.lang;
				this.getFactbookDataOwner();
			});
		}
	}

	ngOnDestroy(): void {
		this.subscription$.unsubscribe();
	}

	getTypeName(propertyValue: any): string | undefined {
		const decoratedTypeName = TypeName.tryGet(propertyValue);
		if (decoratedTypeName !== undefined) {
			return decoratedTypeName;
		}
		if (propertyValue !== undefined && propertyValue !== null && typeof propertyValue.constructor.name === typeof "") {
			return propertyValue.constructor.name as string;
		}
		return undefined;
	}

	async toggleBookmark(): Promise<void> {
		if (this.viewModel !== null) {
			this.isBookmarked = this.isBookmarkPage ? false : !this.isBookmarked;
			this.viewModel.isBookmarked.next(await this.bookmarkService.toggleBookmark(this.viewModel));
		}
	}

	viewMore() {
		this.ViewReportDetail.emit();
	}

	OpenReportWindow(address) {
		if (address.startsWith(environment.powerBiUrl)) {
			// PowerBI report;
			window.name = "ParentWindow" + this.viewModel.code;
			(window as any).ChildWindowName = window.open("#report-view/" + this.viewModel.code);
		} else {
			this.downloadReportFile(address);
		}
	}

	downloadReportFile(address: string): void {
		this.applicationInsightsService.logPageView("Download", address);
		window.open(address, "_blank");
	}

	downloadAllReportFiles(): void {
		const { downloadableFiles } = this._viewModel;

		for(const file of downloadableFiles) {
			this.downloadReportFile(file.url.address);
		}
	}

	OpenReportExternal(address) {
		let openDialog = this.dialog.open(ModalPopupComponent, {
			width: 'rem(300px)',
			panelClass: 'popup',
			data: { popupMessage: this.translateService.instant('Reports.Dialog.Message'), 
					popupOk: this.translateService.instant('Reports.Dialog.Proceed'), 
					popupCancel: this.translateService.instant('Reports.Dialog.Cancel')
				}
		});

		openDialog.beforeClosed().subscribe((value) => {
			if (value == 'confirm') {
				window.open(address, "_blank")
			}
		});
	}

	highlight(content) {
		if (!this._keywords || !content) {
			return content;
		}
		return content.replace(this.reg, match => {
			return '<span class="highlightText">' + match + '</span>';
		});
	}

	cropTextToFit(o) {
		let lastIndex;
		let txt = o.innerHTML;

		while (o.scrollHeight > o.clientHeight) {
			lastIndex = txt.lastIndexOf(" ");
			if (lastIndex === -1) {
				return;
			}
			txt = txt.substring(0, lastIndex);
			o.innerHTML = txt + "…";
		}
	}

	ShowVideo() {
		this.dialog.open(ModalVideoComponent, {
			width: '80vw',
			panelClass: ['my-panel', 'video-panel'],
			data: this.video
		});
	}

	OpenReport(address) {
		if (this.isExternal) {
			this.OpenReportExternal(address);
		} else {
			this.OpenReportWindow(address);
		}
	}

	downloadData() {
		let address: string = this.viewModel.model.linkToData.address;
		
		const path = address.split("ontariogov.sharepoint.com").pop();
		this.graphService.getSharepointBlob(path)
		.subscribe(() => {
			let fileName = address.lastIndexOf('/') > -1 ? address.substring(address.lastIndexOf('/') + 1) : address;
			const link = document.createElement('a');

			link.setAttribute('href', address);
			link.setAttribute('download', fileName);
			link.setAttribute('target', "_blank");
			document.body.appendChild(link);
			link.click();
			link.remove();
		},
		error => {
			const errorMessage = this.translateService.instant('Error.CannotAccessSourceData');
			this.dialog.open(ModalPopupComponent, {
				width: 'rem(300px)',
				panelClass: 'popup',
				data: {
					popupMessage: errorMessage,
					popupOk: this.translateService.instant('Common.Controls.Ok'),
					reloadOnClose: false,
					closeWindowOnOk: false
				}
			});
			throw error;
		});
	}

	getFactbookDataOwner() {
		this.factbookDataOwner = {
			beforeText: this.translateService.instant('Factbooks.DataOwnerUnknownBeforeText'),
			afterText: this.translateService.instant('Factbooks.DataOwnerUnknownAfterText'),
		};
	}

	private getReportDisplayType(): ReportDisplayType {
		if (this._viewModel.model?.url?.address?.includes(POWER_BI_REPORT_URL)) {
			return ReportDisplayType.powerBi;
		}

		if (this._viewModel.model?.reportFileType) {
			return ReportDisplayType[this._viewModel.model?.reportFileType];
		}
		
		return ReportDisplayType.powerBi;
	} 

	private getReportDesctiption() {
		return this.isFactbook && !this.originalDescription.length ? "" : this.highlight(this.originalDescription);
	}
}

interface IDataOwnerUnknown {
	beforeText: string,
	afterText: string,
}

enum ReportDisplayType {
	powerBi = 'powerBi',
	pdf = 'pdf',
	zip = 'zip',
}
