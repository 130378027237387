import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, FormGroupDirective } from '@angular/forms';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { ReportRefreshModel } from 'src/app/models/report-refresh';
import { ApprovalWorkflowService } from 'src/app/services/approval-workflow.service';
import { DataFilesManagementService } from 'src/app/services/graph/data-files-management.service';
import { FileUploadStatuses } from 'src/app/services/graph/resource-names';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { ConfirmationPopupService } from 'src/app/shared/services/confirmation-popup.service';
import { MatPaginatorI18nService } from 'src/app/shared/services/mat-paginator-i18n.service';
import { ThemeService } from 'src/app/shared/services/theme.service';

@Component({
  selector: 'app-report-refresh',
  templateUrl: './report-refresh.component.html',
  styleUrls: ['./report-refresh.component.scss'],
  providers: [
	{ provide: MatPaginatorIntl, useClass: MatPaginatorI18nService }
  ]
})
export class ReportRefreshComponent implements OnInit {

	reportsList: ReportRefreshModel[];
	selectedReportCode: string;
	selectedReport: ReportRefreshModel;

	isSubjectAreaShared: boolean;
	isRefreshStarted: boolean = false;
	spinnerMode = "indeterminate";

	@ViewChild('form') form : FormGroupDirective;

	readonly reportForm = new FormGroup({
		reportsControl: new FormControl(),
	});

	constructor(
		private workflowService: ApprovalWorkflowService,
		private activatedRoute: ActivatedRoute,
		private snackBar: SnackbarService,
		private translateService: TranslateService,
		private popupService: ConfirmationPopupService,
		private dataFilesService: DataFilesManagementService,
		public themeService: ThemeService,
		private router: Router
	) { }

	ngOnInit(): void {
		this.selectedReportCode =  this.activatedRoute.snapshot.params.report || this.activatedRoute.snapshot.queryParamMap.get('report');

		this.workflowService.getReports().subscribe(
			reports => {
				this.selectedReport = reports.find(x => x.reportCode.toLowerCase() === this.selectedReportCode.toLowerCase());
				const selectedReportSubjectAreas = reports.filter(x => x.reportCode.toLowerCase() === this.selectedReportCode.toLowerCase()).map(x => x.subjectAreaCode);
				this.isSubjectAreaShared = reports.some(x => x.reportCode != this.selectedReport.reportCode && selectedReportSubjectAreas.includes(x.subjectAreaCode));
			}
		);
	}

	canBeRefreshed(): Observable<boolean> {
		if (!this.selectedReport.allFilesRequired) return of(true);
		
		return this.workflowService.checkAllReportFilesAreInStatus(FileUploadStatuses.UAT_OK, this.selectedReportCode, this.selectedReport.reportRefreshedDate, this.dataFilesService.getDataFiles());
	}

	isAnySubjectAreaProcessing(): Observable<boolean> {
		return this.dataFilesService.getDataFiles().pipe(map(files => {
			const reportFiles = files.filter(x => x.reportCode === this.selectedReportCode);
			return reportFiles.some(x => x.status === FileUploadStatuses.UAT_UPLOADED || x.status === FileUploadStatuses.UAT_PROCESSING);
		}));
	}

	onReportRefresh(): void {
		combineLatest([
			this.canBeRefreshed(),
			this.isAnySubjectAreaProcessing()
		])
		.subscribe(([canBeRefreshed, isCurrentlyBeingProcessed]) => {
			if (canBeRefreshed && !isCurrentlyBeingProcessed) {
				const dialogRef = this.popupService.openConfirmDialog(this.isSubjectAreaShared ? "ReportRefresh.ConfirmRefreshSharedData" : "ReportRefresh.ConfirmRefresh");
	
				dialogRef.afterClosed().subscribe(dialogResult => {
					if (dialogResult) this.refreshReport(); 
				});
			} else {
				if (!canBeRefreshed) this.snackBar.error(this.translateService.instant("ReportRefresh.RefreshNotAllowed"));
				else if (isCurrentlyBeingProcessed) this.snackBar.error(this.translateService.instant("ReportRefresh.RefreshNotAllowed_Processing"));
			}
		});
	}

	refreshReport() {
		this.isRefreshStarted = true;
		this.workflowService.refreshUatReport(this.selectedReport).subscribe(refreshed => {
			if (refreshed) this.workflowService.setUatReportRefreshedStatus(this.selectedReport.id).subscribe(isStatusChanged => {
				if (isStatusChanged) {
					this.isRefreshStarted = false;
					this.snackBar.success(this.translateService.instant("ReportRefresh.Refreshed"));
					this.router.navigateByUrl(`uat-workflow`);
				}
			});
		}, error => {
			this.isRefreshStarted = false;
			this.snackBar.error(error);
		});
	}
}