import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, from } from "rxjs";
import { HttpEventType } from '@angular/common/http';

import { GraphService, ColumnMapEntry, SharepointDataSource, NamedBlob } from './graph.service';
import { AuditActions, FileUploadStatuses, ResourceNames } from "./resource-names";
import { DataFileModel, PromoteModel } from 'src/app/models/data-file.model';
import { SubjectArea } from 'src/app/models/subject-area.model';
import { map, mapTo, mergeMap, switchMap, take, toArray, tap } from 'rxjs/operators';
import { IntermediateStorageModel } from 'src/app/models/intermediate-storage.model';
import { AuditLogService } from '../audit-log.service';

@Injectable({
  providedIn: 'root'
})
export class DataFilesManagementService {

	private subjectAreaList: BehaviorSubject<SubjectArea[]> = new BehaviorSubject<SubjectArea[]>([]);

	constructor(
		private readonly graphService: GraphService,
		private readonly auditService: AuditLogService
	) { }

	static readonly dataFileColumnMap: ReadonlyArray<ColumnMapEntry<DataFileModel>> = [
		{ modelKey: "id", spFriendlyFieldName: "ID" },
		{ modelKey: "comment", spFriendlyFieldName: "Comment" },
		{ modelKey: "name", spFriendlyFieldName: "Title" },
		{ modelKey: "reportCode", spFriendlyFieldName: "ReportCode" },
		{ modelKey: "subjectAreaCode", spFriendlyFieldName: "SubjectAreaCode" },
		{ modelKey: "createdBy", spFriendlyFieldName: "AuthorId" },
		{ modelKey: "uploadedDate", spFriendlyFieldName: "Created" },
		{ modelKey: "status", spFriendlyFieldName: "PipelineStatus" },
		{ modelKey: "lookupFileId", spFriendlyFieldName: "LookupfileId" },		
	];

	static readonly intermediateStorageColumnMap: ReadonlyArray<ColumnMapEntry<IntermediateStorageModel>> = [
		{ modelKey: "lookupId", spFriendlyFieldName: "Id" },
		{ modelKey: "title", spFriendlyFieldName: "Title" },
		{ modelKey: "fileName", spFriendlyFieldName: "Lookupfile" },		
		{ modelKey: "url", spFriendlyFieldName: "URL" },	
	];

	getUserNameById(userId: number): Observable<string> {
		return this.graphService.getUserNameById(userId);
	}

	getDataFiles(): Observable<DataFileModel[]> {
		return this.graphService.getListItems<DataFileModel>(ResourceNames.UPLOADED_FILES_LIST_TITLE, DataFileModel, DataFilesManagementService.dataFileColumnMap);
	}

	getUatDataFiles(): Observable<DataFileModel[]> {
		return this.graphService.getListItems<DataFileModel>(ResourceNames.UPLOADED_FILES_LIST_TITLE, DataFileModel, DataFilesManagementService.dataFileColumnMap, null, SharepointDataSource.UAT);
	}

	getIntermediateStorage(dataSource: SharepointDataSource): Observable<IntermediateStorageModel[]> {
		return this.graphService.getListItems<IntermediateStorageModel>(ResourceNames.INTERMEDIATE_STORAGE_LIST_NAME, IntermediateStorageModel, DataFilesManagementService.intermediateStorageColumnMap, null, dataSource);
	}

	getBlobsFromIntermediateStorage(lookupIds: number[], dataSource = SharepointDataSource.Production): Observable<NamedBlob[]> {
		return this.getIntermediateStorage(dataSource).pipe(
			switchMap(storage => {
				const urls = storage.filter(x => lookupIds.includes(+x.lookupId)).map(x => x.url);
				return this.graphService.getMultipleSharepointBlobs(urls, dataSource);
			})
		);
	}

	getDataFilesToTriggerPipeline(subjectAreaCode: string, dataFileStatus: FileUploadStatuses = FileUploadStatuses.PENDING): Observable<PromoteModel[]> {
		return this.getDataFiles().pipe(
			map(files => {
				const filtered = files.filter(x => x.subjectAreaCode === subjectAreaCode);
				return  this.getLatestFileInEachSubjectArea(filtered).filter(x => x.status === dataFileStatus);
			}),
			switchMap((dataFiles: PromoteModel[]) => this.getBlobsFromIntermediateStorage(dataFiles.map(x => x.lookupFileId)).pipe(map(blobs => {
				for(let i = 0; i < dataFiles.length; i++) {
					dataFiles[i].file = blobs.find(x => x.fileName === dataFiles[i].name).blob;
				}	
				return dataFiles;
			}))
		));
	}

	getDataFilesToPromote(areas: SubjectArea[], promotedDate: Date): Observable<PromoteModel[]> {
		return this.getDataFiles().pipe(map(files => {
			const filtered = files.filter(x => areas.map(x => x.subjectAreaCode).includes(x.subjectAreaCode) && x.uploadedDate > promotedDate);
 			const result = this.getLatestFileInEachSubjectArea(filtered).filter(x => x.status === FileUploadStatuses.UAT_OK);

			console.log(`Files to Promote:`);
			result.forEach(x => console.log(`${x.subjectAreaCode}/${x.name}`));

			return result;
		}));
	}

	uploadToIntermediateStorage(file: File | Blob, lookupMetadata: IntermediateStorageModel): Observable<number> {
		const overwriteFile = true;
		if (!(file instanceof File)) {
			console.log("file is Blob");
			file = this.convertToFile(file, lookupMetadata.fileName);
		}

		let spMetadata = this.convertToSpFriendlyItem(lookupMetadata);
		return this.graphService.addDocumentLibraryItem(ResourceNames.INTERMEDIATE_STORAGE_LIST_NAME, file as File, spMetadata, overwriteFile);
	}

	replaceBlobFolder(folder: string): string {
		if (folder !== 'TEMP_IVAN') {
			console.log(`Folder: ${folder}. Replacing with: TEMP_IVAN`);
		}
		return "TEMP_IVAN";
	}

	saveUploadedFileHistory(dataFile: DataFileModel): Observable<void> {
		const spDataFile = this.convertToSpFriendlyItem(dataFile);
		return this.deletePreviousUploadedFileIfPending(dataFile).pipe(
			switchMap(() => this.removeIntermediateStorageReference(dataFile)),
			switchMap(() => this.graphService.addListItem(ResourceNames.UPLOADED_FILES_LIST_TITLE, spDataFile)),
			tap(_ => this.auditService.logAction(AuditActions.STORAGE_UPLOAD, { FileName: dataFile.name, SubjectArea: dataFile.subjectAreaCode }))
		);
	}

	removeIntermediateStorageReference(dataFile: DataFileModel): Observable<boolean> {
		return this.getDataFiles().pipe(switchMap(files => {
			const existingReference = files.find(x => x.lookupFileId === dataFile.lookupFileId);
			if (existingReference) {
				existingReference.lookupFileId = null;
				const spDataFile = this.convertToSpFriendlyItem(existingReference);
				return this.graphService.updateListItem(ResourceNames.UPLOADED_FILES_LIST_TITLE, existingReference.id, spDataFile);
			} else return of(true);
		}));
	}

	deletePreviousUploadedFileIfPending(dataFile: DataFileModel): Observable<boolean> {
		return this.getDataFiles().pipe(switchMap(files => {
			const pendingFile = files.find(x => x.name.toLowerCase() === dataFile.name.toLowerCase() && (x.status === FileUploadStatuses.PENDING || x.status === FileUploadStatuses.PROD_PENDING));
			if (pendingFile) return this.graphService.deleteListItem(ResourceNames.UPLOADED_FILES_LIST_TITLE, pendingFile.id)
			else return of(true);
		}));
	}

	changeDataFileStatus(fileId: number, status: FileUploadStatuses, source: SharepointDataSource): Observable<DataFileModel> {
		const getFiles$ = source === SharepointDataSource.UAT ? this.getUatDataFiles() : this.getDataFiles();
		return getFiles$.pipe(map(items => {
			let item = items.find(x => x.id === fileId);
			item.status = status;
			return item;
		}));		
	}

	setUatUploadedStatus(fileId: number, source = SharepointDataSource.UAT) {
		return this.changeDataFileStatus(fileId, FileUploadStatuses.UAT_UPLOADED, source).pipe(
			switchMap(item => this.graphService.updateListItem(ResourceNames.UPLOADED_FILES_LIST_TITLE, item.id, this.convertToSpFriendlyItem(item), source)),
			take(1));
	}

	setProdUploadedStatus(fileId: number, source: SharepointDataSource) {
		return this.changeDataFileStatus(fileId, FileUploadStatuses.PROD_UPLOADED, source).pipe(
			switchMap(item => this.graphService.updateListItem(ResourceNames.UPLOADED_FILES_LIST_TITLE, item.id, this.convertToSpFriendlyItem(item), source)),
			take(1));
	}

	markAsUploaded(fileIds: IdAble[], source: SharepointDataSource): Observable<void> {
		return from(fileIds.map(x => x.id)).pipe(
			mergeMap(id => this.setUatUploadedStatus(id, source)),
			toArray(),
			mapTo(undefined)
		);
	}

	markAsProdUploaded(fileIds: IdAble[], source: SharepointDataSource): Observable<void> {
		return from(fileIds.map(x => x.id)).pipe(
			mergeMap(id => this.setProdUploadedStatus(id, source)),
			toArray(),
			mapTo(undefined)
		);
	}

	private convertToFile(blob: Blob, fileName: string): File {
		blob["lastModifiedDate"] = new Date();
		blob["name"] = fileName;
		return blob as File;
	}


	private convertToSpFriendlyItem(dataFile: DataFileModel | IntermediateStorageModel) {
		let item = {};
		if (dataFile instanceof DataFileModel) {
			DataFilesManagementService.dataFileColumnMap.map(prop => {
				const spProperty = prop.spFriendlyFieldName;
				const contactProperty = dataFile[prop.modelKey];
				if (!Array.isArray(spProperty) && (contactProperty || prop.modelKey === "lookupFileId")) {
					item[spProperty] = contactProperty;
				}
			});
		} else if (dataFile instanceof IntermediateStorageModel) {
			DataFilesManagementService.intermediateStorageColumnMap.map(prop => {
				const spProperty = prop.spFriendlyFieldName;
				const contactProperty = dataFile[prop.modelKey];
				if (!Array.isArray(spProperty) && contactProperty) {
					item[spProperty] = contactProperty;
				}
			});
		}
		return item;
	}

	private getLatestFileInEachSubjectArea(filteredFiles: DataFileModel[]): PromoteModel[] {
		return (Object.values(filteredFiles.reduce((a, {id, subjectAreaCode, uploadedDate, name, status, lookupFileId}) => {
			if (a[name]) {
			  if (a[name].uploadedDate < uploadedDate) a[name] = { id, subjectAreaCode, name, uploadedDate, status, lookupFileId };
			} else a[name] = { id, subjectAreaCode, uploadedDate, name, status, lookupFileId };
			return a;
		  }, {}))) as PromoteModel[];
	}
}

export interface IBlobUploadProgress {
	type: HttpEventType,
	loaded?: number
}

export interface IdAble {
	id: number;
}