import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { Institution } from 'src/app/models/institution.model';
import { Signup } from 'src/app/models/signup.model';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class SignUpService {
	entries: BehaviorSubject<Institution[]> = new BehaviorSubject<Institution[]>([]);

  constructor(private http: HttpClient) {
   }

   save(model: Signup) {
	const headers = new HttpHeaders();
	const subj: Subject<boolean> = new Subject<boolean>();
	this.http.post(environment.signup.api, model, {headers}).toPromise().then(a => {
			subj.next(true);
	}, (err => {
		subj.next(false);
	}));
	return subj;
}

   public getInstitutions()
   {
	   if (this.entries.value.length > 0)
	   {
		   return this.entries;
	   }
	   const headers = new HttpHeaders();
	   this.http.get (environment.signup.institutions, {headers}).subscribe( (a: any []) => {
		   const result: Institution[] = a.map(x => (new Institution(x.institutionName, x.institutionDisplayName, null, x.institutionType)));
		   this.entries.next(result);
	});
	   return this.entries;
   }
}
