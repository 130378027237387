import { Directive } from '@angular/core';
import { LayoutAlignDirective } from '@angular/flex-layout';

const selector = `[fxLayoutAlign],
                  [fxLayoutAlign.gt-smd]
                  `;

const inputs = ['fxLayoutAlign',
                'fxLayoutAlign.gt-smd'];

@Directive({selector, inputs})
export class CustomLayoutAlignDirective extends LayoutAlignDirective {
  protected inputs = inputs;
}