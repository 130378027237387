<a *ngIf="showTranscript && transcriptUrl" [href]="transcriptUrl" target="_blank" class="transcript" matTooltip="{{'Common.Notifications.OpensInNewWindow' | translate}}">Transcript</a>
<vg-player (onPlayerReady)="onPlayerReady($event)">
	<vg-overlay-play></vg-overlay-play>
	<vg-scrub-bar>
		<vg-scrub-bar-current-time></vg-scrub-bar-current-time>
		<vg-scrub-bar-buffering-time></vg-scrub-bar-buffering-time>
	</vg-scrub-bar>
	<vg-controls [vgAutohide]="true" [vgAutohideTime]="3" fxFlex="100" fxLayoutAlign="space-between" fxLayout="row">
		<div fxLayout="row" fxLayoutAlign="center start">
			<app-jump-control BackOrForward="back" Amount="-10"></app-jump-control>
			<vg-play-pause></vg-play-pause>
			<app-jump-control BackOrForward="forward" Amount="10"></app-jump-control>
			<vg-playback-button></vg-playback-button>
			<vg-time-display vgProperty="current" vgFormat="mm:ss"></vg-time-display>
		</div>
		<div fxLayout="row" fxLayoutAlign="center end">
			<vg-time-display vgProperty="total" vgFormat="mm:ss"></vg-time-display>
			<vg-track-selector *ngIf="captionSrc" (change)="toggleCaptions()" ></vg-track-selector>
			<vg-mute></vg-mute>
			<vg-volume></vg-volume>
			<vg-fullscreen></vg-fullscreen>
		</div>
	</vg-controls>
		<video [src]="videoSrc" [vgMedia]="media" #media playsinline >
			{{'Common.Notifications.VideoNotSupported' | translate}}
			<track [src]="captionSrc" srclang="{{translateService.currentLang.toLocaleUpperCase()}}" label="{{translateService.currentLang.toLocaleUpperCase()}}" kind="subtitles" [default]="captionsOn">
		</video>
</vg-player>
