import {BREAKPOINT} from '@angular/flex-layout';

const CUSTOM_BREAKPOINTS = [
	// { alias: 'xs', suffix: 'Xs', mediaQuery: '(max-width: 319px)', overlapping: false, priority: 1001 },
	// { alias: 'gt-xs', suffix: 'GtXs', mediaQuery: '(min-width: 320px)', overlapping: false, priority: 1001 },
	
	{ alias: 'xs', suffix: 'xs', mediaQuery: '(max-width: 374px)', overlapping: false, priority: 1001 },
	{ alias: 'gt-xs', suffix: 'Gtxs', mediaQuery: '(min-width: 375px)', overlapping: false, priority: 1001 }, 

	{ alias: 'sm', suffix: 'sm', mediaQuery: '(min-width: 375px) and (max-width: 689px)', overlapping: false, priority: 1001 },
	{ alias: 'lt-sm', suffix: 'LtSm', mediaQuery: '(max-width: 374px)', overlapping: false, priority: 1001 },
	{ alias: 'gt-sm', suffix: 'GtSm', mediaQuery: '(min-width: 690px)', overlapping: false, priority: 1001 }, 

	{ alias: 'smd', suffix: 'smd', mediaQuery: '(min-width: 690px) and (max-width: 767px)', overlapping: false, priority: 1001 },
	{ alias: 'lt-smd', suffix: 'LtSmd', mediaQuery: '(max-width: 689px)', overlapping: false, priority: 1001 },
	{ alias: 'gt-smd', suffix: 'GtSmd', mediaQuery: '(min-width: 768px)', overlapping: false, priority: 1001 },

	{ alias: 'md', suffix: 'md', mediaQuery: '(min-width: 768px) and (max-width: 1279px)', overlapping: false, priority: 1001 },
	{ alias: 'lt-md', suffix: 'LtMd', mediaQuery: '(max-width: 767px)', overlapping: false, priority: 1001 },
	{ alias: 'gt-md', suffix: 'GtMd', mediaQuery: '(min-width: 1280px)', overlapping: false, priority: 1001 },

	{ alias: 'lg', suffix: 'lg', mediaQuery: '(min-width: 1280px) and (max-width: 1919px)', overlapping: false, priority: 1001 },
	{ alias: 'lt-lg', suffix: 'LtLg', mediaQuery: '(max-width: 1279px)', overlapping: false, priority: 1001 },
	{ alias: 'gt-lg', suffix: 'GtLg', mediaQuery: '(min-width: 1920px)', overlapping: false, priority: 1001 },

	{ alias: 'xl', suffix: 'xl', mediaQuery: '(min-width: 1920px)', overlapping: false, priority: 1001 },
	{ alias: 'lt-xl', suffix: 'LtXl', mediaQuery: '(max-width: 1919px)', overlapping: false, priority: 1001 }


];

export const CustomBreakPointsProvider = { 
  provide: BREAKPOINT,
  useValue: CUSTOM_BREAKPOINTS,
  multi: true
};