import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AdminDashboardEntry } from 'src/app/models/admin-dashboard-entry.model';
import { GraphAdminDashboardService } from 'src/app/services/graph/admin-dashboard.service';
import { GraphReportLikeService } from 'src/app/services/graph/report-like.service';
import { GraphUserService } from 'src/app/services/graph/user.service';
import { GraphVideoService } from 'src/app/services/graph/video.service';
import { Subscriptions } from 'src/app/subscriptions';
import { ThemeService } from './../../shared/services/theme.service';
import { ModalProfileComponent } from './../modal-profile/modal-profile.component';
import { ModalOrientationComponent } from './../modal-orientation/modal-orientation.component';

@Component({
	selector: 'app-menu',
	templateUrl: './menu.component.html',
	styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit, OnDestroy {
	events: string[] = [];
	opened: boolean;
	private readonly subscriptions = new Subscriptions();
	hasBetaReports = false;
	readonly profileName: string;
	readonly profileEmail: string;
	adminEntries: Observable<AdminDashboardEntry[]>

	@Output() closed = new EventEmitter<boolean>();
	hasFactbooks = false;

	constructor(
		private readonly reportLikeService: GraphReportLikeService,
		public dialog: MatDialog,
		private userService: GraphUserService,
		public themeService: ThemeService,
		readonly adminDashboardService: GraphAdminDashboardService,
		private router: Router,
		private videoService: GraphVideoService,
	) {
		this.profileName = this.userService.getUser().displayName;
		this.profileEmail = this.userService.getUser().email;
		this.adminEntries = adminDashboardService.getEntries();
		this.subscriptions.push(this.reportLikeService.factbooks.subscribe(fbs => {
			this.hasFactbooks = fbs.length > 1;
		}));
	}

	ngOnInit() {
		// Does user have access to any Beta reports?
		this.subscriptions.push(this.reportLikeService.reports.subscribe(reps => {
			this.hasBetaReports = reps.filter(x => x.status === "Beta").length > 0;
		}));
	}

	ngOnDestroy() {
		this.subscriptions.unsubscribeAll();
	}

	CloseMenu() {
		this.closed.emit(true);
	}

	ShowTour() {
		this.dialog.open(ModalOrientationComponent, {
			ariaLabel: "Modal Orientation Dialog",
			width: '100%',
			panelClass: ['my-panel', 'orientation-panel'],
			data: {}
		});
	}

	OpenProfile() {
		this.CloseMenu();
		this.dialog.open(ModalProfileComponent, {
			ariaLabel: "Modal Profile Dialog",
			minWidth: '300px',
			panelClass: ['my-panel', 'profile'],
			data: {}
		});
	}

	OpenRouterLink(routerLink) {
		this.CloseMenu();
		this.router.navigate([routerLink]);
	}

	GetFormattedUserName(name) {
		try {
			if (!this.profileEmail?.endsWith("@ontario.ca")) { return name; }
			const splitname = name.split(" (")[0].split(", ");
			const result = splitname[1] + " " + splitname[0];
			return result.indexOf('undefined') >= 0 ? name : result;
		} catch {
			return name;
		}
	}

}
