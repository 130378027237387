import { Injectable } from '@angular/core';
import { combineLatest, from, Observable, of } from 'rxjs';
import { map, mapTo, mergeMap, switchMap, tap, toArray } from 'rxjs/operators';
import { DatasetModel, ProjectDatasetModel, ProjectDatasetViewModel } from 'src/app/models/dataset.model';
import { ColumnMapEntry, GraphService } from './graph.service';
import { AuditActions, Constants, ResourceNames } from './resource-names';
import * as JSZip from 'jszip';
import { ApplicationInsightsService } from '../application-insights.service';
import { AuditLogService } from '../audit-log.service';

@Injectable({
  providedIn: 'root'
})
export class ProjectDatasetService {

	constructor(
		private readonly graphService: GraphService,
		private readonly applicationInsightsService: ApplicationInsightsService,
		private readonly auditService: AuditLogService
	){ }

	static readonly projectDatasetColumnMap: ReadonlyArray<ColumnMapEntry<ProjectDatasetModel>> = [
		{ modelKey: "id", spFriendlyFieldName: "Id" },
		{ modelKey: "title", spFriendlyFieldName: "Title" },
		{ modelKey: "datasetFileName", spFriendlyFieldName: "DataSetFileName" },
		{ modelKey: "projectTitle", spFriendlyFieldName: "Project" },
		{ modelKey: "projectCode", spFriendlyFieldName: "ProjectCode" },		
		{ modelKey: "stakeholder", spFriendlyFieldName: "Stakeholder" },		
		{ modelKey: "lookupFileId", spFriendlyFieldName: "LookupfileId" },		
		{ modelKey: "datasetName", spFriendlyFieldName: "DataSetName" },		
		{ modelKey: "datasetDescription", spFriendlyFieldName: "DataSetDescription" },		
	];

	static readonly lookupDatasetColumnMap: ReadonlyArray<ColumnMapEntry<DatasetModel>> = [
		{ modelKey: "lookupId", spFriendlyFieldName: "Id" },
		{ modelKey: "fileNames", spFriendlyFieldName: "DataSetFiles" },
		{ modelKey: "datasetFileName", spFriendlyFieldName: "Lookupfile" },		
		{ modelKey: "title", spFriendlyFieldName: "Title" },
		{ modelKey: "lastRefreshedDate", spFriendlyFieldName: "LastRefreshedDate" },		
		{ modelKey: "period", spFriendlyFieldName: "Period" },		
		{ modelKey: "url", spFriendlyFieldName: "URL" },		
	];

	getOrganizations(): Observable<string[]> {
		return this.graphService.getListFoldersNames(ResourceNames.PROJECT_DATASET_LIST_NAME);
	}

	getProjectDataSets(): Observable<ProjectDatasetModel[]> {
		return this.graphService.getListItems<ProjectDatasetModel>(ResourceNames.PROJECT_DATASET_LIST_NAME, ProjectDatasetModel, ProjectDatasetService.projectDatasetColumnMap);
	}

	getLookupDataSets(): Observable<DatasetModel[]> {
		return this.graphService.getListItems<DatasetModel>(ResourceNames.LOOKUP_DATASET_LIST_NAME, DatasetModel, ProjectDatasetService.lookupDatasetColumnMap);
	}

	getProjectDataSetViewModel(stakeholder: string, projectCode: string = null, isForRemoval: boolean = false): Observable<ProjectDatasetViewModel[]> {
		return combineLatest([
			this.getProjectDataSets(),
			this.getLookupDataSets()
		])
		.pipe(map(([projectSets, dataSets]) => {
			const projectDataSets: ProjectDatasetViewModel[] = [];
			if (stakeholder) {
				projectSets = projectSets.filter(x => x.stakeholder && x.stakeholder.toLowerCase() === stakeholder.toLowerCase());
			}
			if (projectCode) {
				projectSets = projectSets.filter(x => x.projectCode && x.projectCode.toLowerCase() === projectCode.toLowerCase());
			}

			for (const projectSet of projectSets.filter(x => x.lookupFileId !== null)) {
				const lookup = dataSets.find(x => x.lookupId === projectSet.lookupFileId);
				projectDataSets.push({ ...projectSet, ...lookup });
			}
			return isForRemoval ? projectDataSets : this.mergeDataSetFileNames(projectDataSets);
		}));
	}

	downloadDataSet(dataSetSourceFileUrl: string): Observable<IDownloadableFile> {
		const serverRelativeUrl = dataSetSourceFileUrl.split("ontariogov.sharepoint.com").pop();

		return this.graphService.getSharepointBlob(serverRelativeUrl).pipe(switchMap(() => {
			let fileName = dataSetSourceFileUrl.lastIndexOf('/') > -1 ? dataSetSourceFileUrl.substring(dataSetSourceFileUrl.lastIndexOf('/') + 1) : dataSetSourceFileUrl;
			return of({ url: dataSetSourceFileUrl, fileName });
		}));
	}

	downloadZipArchivedDataSet(dataSetSourceFileUrls: string): Observable<any> {
		let zipFile: JSZip = new JSZip();
		const urls = dataSetSourceFileUrls.split(Constants.SEPARATOR_SEMICOLON);
		return this.graphService.getMultipleSharepointBlobs(urls).pipe(switchMap(async blobs => {
			for (let i = 0; i < urls.length; i++) {
				zipFile.file(blobs[i].fileName, blobs[i].blob);
				this.applicationInsightsService.logPageView("External", urls[i]);
			}
			return await zipFile.generateAsync({type: "blob"});;
		}));
	}

	uploadDataset(projectMetadata: ProjectDatasetModel, lookupMetadata: DatasetModel, file: File, overwriteFile: boolean = false): Observable<number> {
		let spMetadata = this.convertToSpFriendlyItem(lookupMetadata);
		const filterString = `DataSetFileName eq '${this.escapeFilterString(projectMetadata.datasetFileName)}' and DataSetName eq '${this.escapeFilterString(projectMetadata.datasetName)}'`;
		return combineLatest([
			this.graphService.addDocumentLibraryItem(ResourceNames.LOOKUP_DATASET_LIST_NAME, file, spMetadata, overwriteFile),
			this.graphService.getFilteredListItems(ResourceNames.PROJECT_DATASET_LIST_NAME, filterString)
		]).pipe(switchMap(([id, items]) => {
				if (items && items.length) return of(items[0].Id);

				projectMetadata.lookupFileId = id.toString();
				spMetadata = this.convertToSpFriendlyItem(projectMetadata);
				return this.graphService.addListItemToFolder(ResourceNames.PROJECT_DATASET_LIST_NAME, projectMetadata.stakeholder, spMetadata).pipe(
					tap(_ => this.auditService.logAction(AuditActions.DATASET_UPLOAD, { FileName: projectMetadata.datasetFileName, Dataset: projectMetadata.datasetName }))
				);
		}));
	}

	updateMetadata(oldDatasetName: string, metadata: ProjectDatasetModel): Observable<boolean[]> {
		const filterString = `DataSetName eq '${this.escapeFilterString(oldDatasetName)}'`;
		return this.graphService.getFilteredListItems(ResourceNames.PROJECT_DATASET_LIST_NAME, filterString).pipe(switchMap(items => from(items).pipe(
			mergeMap(item => {
				const updated = { ...item, DataSetName: metadata.datasetName, DataSetDescription: metadata.datasetDescription };
				return this.graphService.updateListItem(ResourceNames.PROJECT_DATASET_LIST_NAME, updated.Id, updated);
			}), 
			toArray(),
			tap(_ => this.auditService.logAction(AuditActions.DATASET_UPDATE, { PreviousName: oldDatasetName, NewName: metadata.datasetName }))
		)));
	}

	removeFileFromDataset(dataset: ProjectDatasetViewModel): Observable<boolean> {
		return this.graphService.deleteListItem(ResourceNames.PROJECT_DATASET_LIST_NAME, +dataset.id).pipe(
			switchMap(_ => {
				const filterString = `DataSetFileName eq '${this.escapeFilterString(dataset.datasetFileName)}'`;
				return this.graphService.getFilteredListItems(ResourceNames.PROJECT_DATASET_LIST_NAME, filterString);
			}),
			map(items => {
				if (items && items.length) return undefined;
				// no datasets associated with this file -> removing the file
				else return this.graphService.deleteDocumentLibraryItem(ResourceNames.LOOKUP_DATASET_LIST_NAME, dataset.datasetFileName);
			}),
			tap(_ => this.auditService.logAction(AuditActions.DATASET_REMOVE, { DatasetName: dataset.datasetName, FileName: dataset.datasetFileName })),
			mapTo(true)
		);
	}

	private escapeFilterString(filter: string): string {
		return filter.replace(/'/g, "''");
	} 

	private mergeDataSetFileNames(dataSets: ProjectDatasetViewModel[]): ProjectDatasetViewModel[] {
		const res: ProjectDatasetViewModel[] = [];
		dataSets = dataSets.sort((a, b) => new Date(b.lastRefreshedDate).getTime() - new Date(a.lastRefreshedDate).getTime());
		for (const dataSet of dataSets) {
			let set = res.find(x => x.datasetName === dataSet.datasetName);
			if (set) {
				set.fileNames = set.fileNames + `;${dataSet.datasetFileName}`;
				set.url = set.url + `;${dataSet.url}`;
			} else res.push(dataSet);
		}
		return res;
	}

	private convertToSpFriendlyItem(dataFile: DatasetModel | ProjectDatasetModel) {
		let item = {};
		if (dataFile instanceof DatasetModel) {
			ProjectDatasetService.lookupDatasetColumnMap.map(prop => {
				const spProperty = prop.spFriendlyFieldName;
				const contactProperty = dataFile[prop.modelKey];
				if (!Array.isArray(spProperty) && contactProperty) {
					item[spProperty] = contactProperty;
				}
			});
		} else if (dataFile instanceof ProjectDatasetModel) {
			ProjectDatasetService.projectDatasetColumnMap.map(prop => {
				const spProperty = prop.spFriendlyFieldName;
				const contactProperty = dataFile[prop.modelKey];
				if (!Array.isArray(spProperty) && contactProperty) {
					item[spProperty] = contactProperty;
				}
			});
		}
		
		return item;
	}
}

export interface IDownloadableFile {
	url: string,
	fileName: string
}
