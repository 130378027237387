<div class="step-title">
	<div>{{currentTranslation.title}}</div>
</div>
<div class="step-legend">
	<div>{{currentTranslation.legend}}</div>
</div>
<form #form="ngForm" class="upload-criteria-container" [ngClass]="{'app-light-theme': !themeService.isDark, 'app-dark-theme': themeService.isDark}">
	<div class="step-option">
		<div class="step-option-legend">{{currentTranslation.approveLegend}}</div>
		<div>
			<button 
				class="upload-button" 
				(click)="onApprove()" 
				matTooltip="{{currentTranslation.approveButtonTooltip}}" 
				fxFlex="80px" 
				fxFlex.gt-sm="100px"
				mat-flat-button 
				color="primary"
			>
				{{currentTranslation.approveButtonTitle}}
			</button>
		</div>
	</div>
	<div class="step-option">
		<div class="step-option-legend">{{currentTranslation.rejectLegend}}</div>
		<div>
			<input #fileUpload type="file" class="file-input" (change)="onFileSelected($event)" [accept]="requiredFileType">
			<button 
				class="upload-button" 
				(click)="fileUpload.click()" 
				matTooltip="{{'DataFilesManagement.SelectFile' | translate}}" 
				fxFlex="80px" 
				fxFlex.gt-sm="100px"
				mat-flat-button 
				color="primary"
			>
				{{'DataFilesManagement.Browse' | translate}}
			</button>
			<button 
				class="upload-button" 
				(click)="onUploadFile()" 
				*ngIf="fileToUpload" 
				matTooltip="{{'DataFilesManagement.UploadFile' | translate}}" 
				fxFlex="80px" 
				fxFlex.gt-sm="100px" 
				mat-flat-button 
				color="primary"
			>
				{{currentTranslation.rejectButtonTitle}}
			</button>
		</div>
	</div>
	
</form>
