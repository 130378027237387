import { AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
import { debounceTime, distinctUntilChanged, map, startWith, tap } from 'rxjs/operators';
import { combineLatest, merge, Observable, Subscription } from 'rxjs';

import { DataFilesManagementService } from 'src/app/services/graph/data-files-management.service';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { SubjectArea } from 'src/app/models/subject-area.model';
import { DataFilesDataSource } from './data-files-data-source';
import { FileUploadStatuses } from 'src/app/services/graph/resource-names';
import { FormControl } from '@angular/forms';
import { ThemeService } from 'src/app/shared/services/theme.service';
import { MatPaginatorI18nService } from 'src/app/shared/services/mat-paginator-i18n.service';

@Component({
  selector: 'app-data-files-grid',
  templateUrl: './data-files-grid.component.html',
  styleUrls: ['./data-files-grid.component.scss'],
  providers: [
	{ provide: MatPaginatorIntl, useClass: MatPaginatorI18nService }
  ]
})
export class DataFilesGridComponent implements OnInit, AfterViewInit, OnDestroy {
	@Input() subjectAreas: SubjectArea[];
	@Input() gridUpdateEvents: Observable<void>;
	@Input() set reportCode( value: string) {
		this.reportCodeFilter = value ? `reportCode:${value}` : "" ;
	};

	reportCodeFilter: string;

	private eventsSubscription: Subscription;
	
	@ViewChild(MatSort) sort: MatSort;
	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

	dataSource: DataFilesDataSource;
	displayedColumns: string[] = ['id', 'name', 'subjectAreaName', 'status', 'createdBy', 'uploadedDate', 'comment'];

	subjectAreaFilter = new FormControl();
	fileNameFilter = new FormControl();

	filter: string = "";

	constructor(
		private readonly dataFilesService: DataFilesManagementService,
		public readonly themeService: ThemeService,
		private readonly translateService: TranslateService
	) { }

	ngOnInit(): void {
		this.dataSource = new DataFilesDataSource(this.dataFilesService, this.subjectAreas);
		this.dataSource.loadDataFiles(this.reportCodeFilter);

		this.eventsSubscription = this.gridUpdateEvents.subscribe(() => this.dataSource.loadDataFiles(this.reportCodeFilter));
	}

	ngAfterViewInit() {
		this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);

		merge(this.sort.sortChange, this.paginator.page).pipe(
			tap(() => this.loadDataFilesPage())
		).subscribe();

		const fileNameFilter$: Observable<string> = this.fileNameFilter.valueChanges.pipe(
			debounceTime(400),
			distinctUntilChanged()
		).pipe(map(filterValue => filterValue && `name:${filterValue}` || ""), startWith(""));

		const subjectAreaFilter$: Observable<string> = this.subjectAreaFilter.valueChanges.pipe(
			debounceTime(400),
			distinctUntilChanged()
		).pipe(map(filterValue => filterValue && `subjectAreaName:${filterValue}` || ""), startWith(""));

		combineLatest([
			fileNameFilter$,
			subjectAreaFilter$, 
		]).subscribe(([nameFilter, subFilter]) => {
			this.filter = [this.reportCodeFilter, nameFilter, subFilter].filter(Boolean).join(",");
			console.log(`filter: <${this.filter}>`);
			this.loadDataFilesPage();
		});
		
		this.translateService.onLangChange.subscribe((params: LangChangeEvent) => {
			this.loadDataFilesPage();
		});
	}

	loadDataFilesPage() {
		this.dataSource.loadDataFiles(this.filter, this.sort.active, this.sort.direction, this.paginator.pageIndex, this.paginator.pageSize);
	}
	
	isPending(status: string): boolean {
		return status === FileUploadStatuses.PENDING || status=== FileUploadStatuses.PROD_PENDING || status === FileUploadStatuses.UAT_UPLOADED || status === FileUploadStatuses.PROD_UPLOADED;
	}

	isOk(status: string): boolean {
		return status === FileUploadStatuses.UAT_OK || status=== FileUploadStatuses.PROD_OK;
	}

	isFailed(status: string): boolean {
		return status === FileUploadStatuses.UAT_FAILED || status=== FileUploadStatuses.PROD_FAILED;
	}

	isProcessing(status: string): boolean {
		return status === FileUploadStatuses.UAT_PROCESSING || status=== FileUploadStatuses.PROD_PROCESSING;
	}

	ngOnDestroy() {
		this.eventsSubscription.unsubscribe();
	}
}