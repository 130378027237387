import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatTabGroup } from '@angular/material/tabs';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { GraphReportLikeService } from 'src/app/services/graph/report-like.service';
import { ThemeService } from 'src/app/shared/services/theme.service';
import { ReportViewModel } from 'src/app/view-models/report-view-model';
import { NewsEntry } from './../../models/news-entry.model';
import { Report } from './../../models/report.model';
import { BookmarkService } from './../../services/bookmark.service';
import { SearchIndexService } from './../../services/search-index.service';

@Component({
	selector: 'app-search',
	templateUrl: './search.component.html',
	styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnDestroy, OnInit {
	@ViewChild('tabs') tabGroup: MatTabGroup;
	private readonly searchService: SearchIndexService;
	keywords: string;
	results: ReportViewModel[] = [];
	reportsResults: ReportViewModel[] = [];
	bookmarkedReportsResults: ReportViewModel[] = [];
	newsResults: NewsEntry[] = [];
	navigationSubscription$: Subscription = Subscription.EMPTY;

	constructor(
		private readonly reportLikeService: GraphReportLikeService,
		private readonly bookmarkService: BookmarkService,
		private activatedRoute: ActivatedRoute,
		private router: Router,
		private cd: ChangeDetectorRef,
		public readonly themeService: ThemeService
	) {

		this.navigationSubscription$ = this.router.events.subscribe((e: any) => {
			if (e instanceof NavigationEnd) {
				this.keywords = this.activatedRoute.snapshot.params.keywords || this.activatedRoute.snapshot.queryParamMap.get('keywords') || this.keywords;
				if (this.keywords && this.keywords !== "undefined") {
					this.onSearch(this.keywords);
				}
			}
		});
		this.searchService = new SearchIndexService(this.reportLikeService.nofactbooks);
	}

	ngOnInit() {
		this.keywords = this.activatedRoute.snapshot.params.keywords || this.activatedRoute.snapshot.queryParamMap.get('keywords') || this.keywords;
		if (this.keywords && this.keywords !== "undefined") {
			this.onSearch(this.keywords);
		}
	}

	ngOnDestroy(): void {
		this.navigationSubscription$.unsubscribe();
	}

	onGoSearch($event) {
		this.router.navigate(['/search/', { keywords: $event.keywords, type: $event.type }]);
	}

	onSearch(value) {
		const minDateToBeDisplayed = this.reportLikeService.getNewsMinDateToBeDisplayed();

		this.searchService.index.subscribe(index => {
			const type = this.activatedRoute.snapshot.queryParamMap.get('type') || this.activatedRoute.snapshot.params.type;
			this.results = index.search(value, type);
			this.reportsResults = this.results.filter(f => f.categoryKeys.indexOf('News') === -1);
			this.bookmarkedReportsResults = this.results.filter(f => f.isModelOfType(Report) ? this.bookmarkService.tryFindExistingBookmarkFor(f as ReportViewModel) !== undefined : false);
			this.newsResults = this.results.filter(f => f.categoryKeys.indexOf('News') > -1 && !this.reportLikeService.isExpiredNewsEntry(f)).map(n => n.model as NewsEntry);
			this.cd.detectChanges();

			if (this.tabGroup && this.results) {
				this.tabGroup.selectedIndex = this.reportsResults.length === 0 && this.newsResults.length > 0 ? 2 : 0;
			}
		});
	}
}
