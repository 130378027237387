import { Component, ElementRef, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ThemeService } from 'src/app/shared/services/theme.service';
import { GraphReportLikeService } from './../../services/graph/report-like.service';


@Component({
	selector: 'app-home',
	templateUrl: './home.component.html',
	styleUrls: ['./home.component.scss']
})
export class HomeComponent {
	isConnected = true;
	subscriptions$: Subscription[] = [];
	showPirl = false;
	screenWidth: number;

	constructor(public themeService: ThemeService,
		private readonly reportLikeService: GraphReportLikeService,
		public router: Router) {
		// Does user have access to any Factbook reports?
		this.subscriptions$.push(this.reportLikeService.allPirlReports$.subscribe(reports => {
			this.showPirl = reports.length > 1;
		}));
		this.onResize()
	}

	@HostListener('window:afterprint', ['$event'])
	onAfterPrint() {
		window.location.reload();
	}

	@HostListener('window:resize', ['$event'])
	onResize() {
		this.screenWidth = window.innerWidth;
	}

	OnDestroy() {
		this.subscriptions$.map(a => a.unsubscribe());
	}

	showWelcome() {
		return this.router.routerState.snapshot.url === "/categories";
	}

	isWide() {
		return this.screenWidth >= 1280;
	  }

	checkOverflow(element) {
		let bounding = element.getBoundingClientRect()

		if (bounding.right + 100 > (window.innerWidth || document.documentElement.clientWidth)) {
			return true
		} else {
			return false;
		}
	}
}
