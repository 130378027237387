import { Injectable } from '@angular/core';
import { GraphService } from './graph/graph.service';
import { AuditActions } from './graph/resource-names';
import { GraphUserService } from './graph/user.service';

@Injectable({
  providedIn: 'root'
})
export class AuditLogService {

  constructor(
	  private graphService: GraphService
  ) { }

  public logAction(action: AuditActions, auditData: any): void {
	const formData = new FormData();
	formData.append("action", action.toString());
	formData.append("auditData", JSON.stringify(auditData));

	this.graphService.auditLog(formData).subscribe(
		_ => {console.log("logged")}, 
		error => { console.log(error) });
  }

  public emailNotify(action: AuditActions, body: string): void {
	const formData = new FormData();
	formData.append("subject", action.toString());
	formData.append("body", body);

	this.graphService.emailNotify(formData).subscribe(
		_ => {console.log("emailed")}, 
		error => { console.log(error) });
  }
}
