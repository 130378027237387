<mat-tab-group
  mat-align-tabs="center"
  [ngClass]="{
    'app-light-theme': !themeService.isDark,
    'app-dark-theme': themeService.isDark
  }"
>
  <mat-tab
    label="{{ 'LatestReportsAndNews.Reports.Title' | translate }}"
  >
    <div fxLayout="column" fxLayoutAlign="center center">
      <h2 id="maincontent" tabindex="0">
        {{ "LatestReportsAndNews.Reports.Title" | translate }}
      </h2>
      <span tabindex="0" *ngIf="recentReports.length > 0">{{
        "LatestReportsAndNews.Reports.Subtitle" | translate
      }}</span>
    </div>
    <app-report-listing
      *ngIf="recentReports.length > 0"
      [reports]="recentReports"
    ></app-report-listing>
    <div
      tabindex="0"
      fxLayout="column"
      fxLayoutAlign="center center"
      *ngIf="recentReports.length === 0"
    >
      {{ "LatestReportsAndNews.Reports.NoReports" | translate }}
    </div>
  </mat-tab>
  <mat-tab
    label="{{ 'LatestReportsAndNews.News.Title' | translate }}"
    tabindex="0"
  >
    <div fxLayout="column" fxLayoutAlign="center center">
      <h2 tabindex="0">{{ "LatestReportsAndNews.News.Title" | translate }}</h2>
      <span tabindex="0" *ngIf="recentNews.length > 0">{{
        "LatestReportsAndNews.News.Subtitle" | translate
      }}</span>
    </div>
    <app-news-listing
      *ngIf="recentNews.length > 0"
      [news]="recentNews"
    ></app-news-listing>
    <div
      tabindex="0"
      fxLayout="column"
      fxLayoutAlign="center center"
      *ngIf="recentNews.length === 0"
    >
      {{ "LatestReportsAndNews.News.NoNews" | translate }}
    </div>
  </mat-tab>
</mat-tab-group>
