import { Component, OnInit } from '@angular/core';
import { PROD_ENV_KEY } from 'src/app/services/approval-workflow.service';

@Component({
  selector: 'app-approval-workflow-prod',
  templateUrl: './approval-workflow-prod.component.html',
  styleUrls: ['./approval-workflow-prod.component.scss']
})
export class ApprovalWorkflowProdComponent {
	EnvKey: string = PROD_ENV_KEY;
}