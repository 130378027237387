import { TypeName } from "src/app/type-name";

interface IAttach {
  fileId: string;
  fileUrl: string;
  fileName: string;
}

@TypeName.decorate("NewsAttachment")
export class NewsAttachment implements IAttach {
  public constructor(
    public readonly fileId: null | string = null,
    public readonly fileUrl: null | string = null,
    public readonly fileName: null | string = null
  ) { }
}

