<ng-container *ngIf="selected" [ngTemplateOutlet]="selected.content"></ng-container>
<footer fxLayout="column" fxLayoutGap="1rem" fxLayoutGap.md="4rem" fxLayout.gt-md="row" fxLayoutAlign.gt-md="space-between" aria-label="Orientation Module Footer">
	<div fxLayout="row" fxLayoutGap="1rem" fxLayoutAlign="center center">
		<button class="btn-round" [ngClass]="{'btn-fill':i == selectedIndex} " [disabled]='i == selectedIndex' *ngFor="let step of _steps; let i = index;" (click)="onClick(i)" [attr.aria-label]="'Orientation.StepXof4' |translate:{step:i+1}"></button>
	</div>
	<div class="btn-container" fxLayout="column" fxLayout.gt-smd="row" fxLayoutGap="1rem" fxLayoutAlign="stretch stretch" fxLayoutAlign.gt-smd="center center">
		<button #getStarted mat-stroked-button color="primary" (click)="onClose()" [innerHtml]="'Orientation.Screen' + this.selectedIndex + '.CloseButtonLabel' | translate"></button>
		<button cdkFocusInitial *ngIf="selectedIndex != 3" mat-flat-button color="primary" (click)="nextClick()">
			<div [innerHtml]="'Orientation.Screen' + this.selectedIndex + '.NextButtonLabel' | translate"></div>
		</button>
	</div>
</footer>