export function compareDates(a: Date | null | undefined, b: Date | null | undefined): number {
	// sort by date, most recent first, null and undefined last
	const aTime = a === undefined || a === null || !a.getTime ? 0 : a.getTime();
	const bTime = b === undefined || b === null || !b.getTime ? 0 : b.getTime();
	if (aTime > bTime) {
		return -1;
	}
	else if (aTime < bTime) {
		return 1;
	}
	else {
		return 0;
	}
}
