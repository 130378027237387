import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { take, switchMap } from 'rxjs/operators';
import { getPropertyNames } from "src/app/property-names";
import { ColumnMapEntry, GraphService } from 'src/app/services/graph/graph.service';
import { TypeName } from "src/app/type-name";
import { ResourceNames, UserAuditSteps } from "./resource-names";

@Injectable({
	providedIn: 'root'
})
export class UserAuditService {
	private value: BehaviorSubject<UserAuditSteps> = new BehaviorSubject<UserAuditSteps>(undefined);
	constructor(
		private graphService: GraphService
	) {
	}

	getItems(): Observable<UserAuditServiceActiveStep[]> {
		return this.graphService.getListItems<UserAuditServiceActiveStep>(UserAuditService.listTitle, UserAuditServiceActiveStep, UserAuditService.columnMap);
	}

	getActiveStep(): Observable<UserAuditSteps | undefined> {
		this.getItems().subscribe((result) => {
				const activeStep = (Object.values(UserAuditSteps).some((existingStep: string) => result[0].activeStep == existingStep)) 
					? result[0].activeStep as unknown as UserAuditSteps 
					: undefined;
				this.value.next(activeStep);
			});
		return this.value;
	}

	clearActiveStep() {
		return this.getItems().pipe(
			switchMap(items => {
				const item = items[0];
				const updatedItem = new UserAuditServiceActiveStep(item.id, item.title, "Disabled");
				return this.graphService.updateListItem(ResourceNames.USER_AUDIT_STEPS_LIST_TITLE, item.id, this.convertToSpFriendlyItem(updatedItem));
			}),
			take(1));
	}

	private convertToSpFriendlyItem(dataFile: UserAuditServiceActiveStep) {
		let item = {};
		UserAuditService.columnMap.map(prop => {
			const spProperty = prop.spFriendlyFieldName;
			const contactProperty = dataFile[prop.modelKey];
			if (!Array.isArray(spProperty) && contactProperty) {
				item[spProperty] = contactProperty;
			}
		});
		
		return item;
	}

	static readonly listTitle = ResourceNames.USER_AUDIT_STEPS_LIST_TITLE;
	static readonly columnMap: ReadonlyArray<ColumnMapEntry<UserAuditServiceActiveStep>> = [
		{ modelKey: "id", spFriendlyFieldName: "ID" },
		{ modelKey: "title", spFriendlyFieldName: "Title" },
		{ modelKey: "activeStep", spFriendlyFieldName: "ActiveStep" },
	];
}

@TypeName.decorate("UserAuditServiceActiveStep")
export class UserAuditServiceActiveStep {
	public constructor(
		public readonly id      : null | number = null,
		public readonly title      : null | string = null,
		public readonly activeStep        : null | string = null,
	) {}

	public static readonly defaultPropertyNames = getPropertyNames(new UserAuditServiceActiveStep());
}