import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, ReplaySubject } from "rxjs";
import { map } from "rxjs/operators";
import { Factbook } from 'src/app/models/factbook-model';
import { Hyperlink } from "src/app/models/hyperlink.model";
import { environment } from 'src/environments/environment';
import { compareDates } from "../../compare-dates";
import { ColumnMapEntry, GraphService } from './graph.service';
import { PirlReportTypeCodes, ResourceNames } from "./resource-names";
import { ApplicationInsightsService } from "../application-insights.service";

@Injectable({
	providedIn: 'root'
})
export class GraphFactbookService {
	private factbooks: BehaviorSubject<Factbook[]> = new BehaviorSubject<Factbook[]>([]);
	private capitalOperatingGrants: ReplaySubject<Factbook[]> = new ReplaySubject<Factbook[]>(1);
	private institutionalElmlp: ReplaySubject<Factbook[]> = new ReplaySubject<Factbook[]>(1);
	private institutionalProfiles: ReplaySubject<Factbook[]> = new ReplaySubject<Factbook[]>(1);

	constructor(
		private graphService: GraphService,
		private appInstightsService: ApplicationInsightsService,
	) {}

	getFactbooks(): Observable<Factbook[]> {
		if (this.factbooks.value.length > 0) {
			return this.factbooks;
		}

		const entities = this.graphService.getListItems<Factbook>(GraphFactbookService.listName, Factbook, GraphFactbookService.columnMap);
		// filter the sharepoint data using the current language according to the language service
		entities.pipe(
			map(x => this.factbookFilterAndSort(x))
		).subscribe(
			(result) => this.factbooks.next(result),
			(error) => this.handleError(error, 'getFactbooks()'),
		);
		return this.factbooks;
	}

	getCapitalOperatingGrants(): Observable<Factbook[]> {
		const entities = this.graphService.getListItems<Factbook>(PirlReportTypeCodes.CAP_OPER_GRANTS, Factbook, GraphFactbookService.columnMap);
		entities.pipe(
			map(x => this.factbookFilterAndSort(x))
		).subscribe(
			(result) => this.capitalOperatingGrants.next(result),
			(error) => this.handleError(error, 'getCapitalOperatingGrants()'),
		);
		return this.capitalOperatingGrants;
	}

	getInstitutionalElmlp(): Observable<Factbook[]> {
		const entities = this.graphService.getListItems<Factbook>(PirlReportTypeCodes.INST_ELMLP, Factbook, GraphFactbookService.columnMap);
		entities.pipe(
			map(x => this.factbookFilterAndSort(x))
		).subscribe(
			(result) => this.institutionalElmlp.next(result),
			(error) => this.handleError(error, 'getInstitutionalElmlp()'),
		);
		return this.institutionalElmlp;
	}

	getInstitutionalProfiles(): Observable<Factbook[]> {
		const entities = this.graphService.getListItems<Factbook>(PirlReportTypeCodes.INST_PROFILES, Factbook, GraphFactbookService.columnMap);
		entities.pipe(
			map(x => this.factbookFilterAndSort(x))
		).subscribe(
			(result) => this.institutionalProfiles.next(result),
			(error) => this.handleError(error, 'getInstitutionalProfiles()'),
		);
		return this.institutionalProfiles;
	}

	static readonly listName = ResourceNames.FACTBOOK_LIST_NAME;

	static readonly columnMap: ReadonlyArray<ColumnMapEntry<Factbook>> = [
		{ modelKey: "name", spFriendlyFieldName: "Title" },
		{ modelKey: "reportFile", spFriendlyFieldName: "FileRef", transformer: x => x == null ? null : new Hyperlink(environment.sharepointSiteBaseUrl + x, x) },
		{ modelKey: "institutionType", spFriendlyFieldName: "InstitutionType", },
		{ modelKey: "institution", spFriendlyFieldName: "Institution", },
		{ modelKey: "academicYear", spFriendlyFieldName: "AcademicYear", },
		{ modelKey: "reportFileType", spFriendlyFieldName: "ReportFileType", },
		{ modelKey: "id", spFriendlyFieldName: "ID", transformer: x => x == null ? null : (x + ""), /* convert to string (wtf javascript?! toString doesn't work) */ },
		{ modelKey: "lastRefresh", spFriendlyFieldName: "Modified" }
	];

	factbookFilterAndSort(factbooks: Factbook[]): Factbook[] {
		return factbooks
			.filter(value => value != null && value.institution != null && value.institutionType != null)
			.sort((a, b) => compareDates(a.getDate(), b.getDate()))
	}

	private handleError(error: Error, callerFunctionName?: string) {
		if(callerFunctionName != null) {
			this.appInstightsService.logException(callerFunctionName);
		}

		this.appInstightsService.logException(error);
	}

}
