import { getPropertyNames } from "src/app/property-names";
import { HasLanguageCode } from "src/app/services/language.service";
import { TypeName } from "src/app/type-name";
import { Hyperlink } from "./hyperlink.model";

export type Status = "Final" | "Preliminary" | "Period To Date" | "Beta";

@TypeName.decorate("Report")
export class Report implements HasLanguageCode {
	public constructor(
		public readonly id                  : null | string    = null,
		public readonly title               : null | string    = null,
		public readonly code 		        : null | string    = null,
		public readonly description         : null | string    = null,
		public readonly lastRefresh         : null | Date      = null,
		public readonly categories          : null | string[]  = null,
		public readonly dataOwner           : null | Hyperlink = null,
		public readonly dataProvider        : null | string    = null,
		public readonly tags                : null | string[]  = null,
		public readonly url                 : null | Hyperlink = null,
		public readonly status              : null | Status    = null,
		public readonly videoId             : null | Hyperlink = null,
		public readonly linkToData          : null | Hyperlink = null,
		public readonly languageCode        : null | string    = null,
	) {}

	public getLastRefresh(): null | Date {
		if(this.lastRefresh)
			return new Date(this.lastRefresh);
		return this.lastRefresh;
	}

	public static readonly defaultPropertyNames = getPropertyNames(new Report());
}