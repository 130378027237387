<button mat-mini-fab class="close" (click)="$event.stopPropagation();onClose()" [attr.aria-label]="'Common.Controls.Close' | translate">
	<mat-icon>close</mat-icon>
</button>

<app-news-card id="article" class="full" [article]="(news || [])[newsIndex]" [attachmentList]="attachmentList" [isInList]="false" [isLowRes]="false" [isInDialog]="true" aria-live="assertive"></app-news-card>

<a *ngIf="newsIndex > 0" class="outside control prev" (click)="PrevArticle()" tabindex="0" aria-controls="article" [attr.aria-label]="'Common.Controls.Previous' | translate">
	<span fxHide.gt-sm aria-hidden="true">{{'Common.Controls.Previous' | translate}}</span>
	<span fxHide.lt-md aria-hidden="true"><</span>
</a>
<div class="outside news-position" [attr.aria-label]="'LatestReportsAndNews.News.NewsXofY' | translate:{X:newsIndex+1, Y:numberOfNews}">{{'LatestReportsAndNews.News.NewsArticle' | translate}} {{newsIndex+1}}/{{numberOfNews}}</div>
<a *ngIf="newsIndex < news.length -1" class="outside control next" (click)="NextArticle()" tabindex="0" aria-controls="article" [attr.aria-label]="'Common.Controls.Next' | translate">
	<span fxHide.gt-sm>{{'Common.Controls.Next' | translate}}</span>
	<span fxHide.lt-md>></span>
</a>