import { TranslateService } from '@ngx-translate/core';
import { Component, OnInit } from '@angular/core';
import { ThemeService } from 'src/app/shared/services/theme.service';

@Component({
	selector: 'app-accessibility',
	templateUrl: './accessibility.component.html',
	styleUrls: ['./accessibility.component.scss']
})
export class AccessibilityComponent implements OnInit {

	constructor(public themeService: ThemeService,
		private readonly translateService: TranslateService,
	) { }

	ngOnInit(): void {
	}

	public getRawPhone() {
		return this.translateService.instant('Accessibility.ContactPhone').replace(/\D/g, "");
	}

}
