import { Component, Input, OnInit, OnChanges } from '@angular/core';
import { ThemeService } from 'src/app/shared/services/theme.service';
import { DataFileModelExtended } from '../uat-transfer/uat-transfer.component';

@Component({
  selector: 'app-uat-transfer-grid',
  templateUrl: './uat-transfer-grid.component.html',
  styleUrls: ['./uat-transfer-grid.component.scss']
})
export class UatTransferGridComponent implements OnInit {
	@Input()uatDataFiles: DataFileModelExtended[];
	@Input() reportCode: string;

	dataSource: DataFileModelExtended[];
	displayedColumns: string[] = [ 'subjectAreaTitle', 'providedFileName', 'uploadedDate'];

	constructor(
		public themeService: ThemeService
	) { }

	ngOnInit(): void {
		this.loadDataSource();
	}

	loadDataSource() {
		if (this.reportCode) this.dataSource = this.uatDataFiles.filter(x => x.reportCode.includes(this.reportCode));
	}

	ngOnChanges() {
		this.loadDataSource();
	}
}
