import { OverlayContainer } from '@angular/cdk/overlay';
import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Observable, Subscription } from 'rxjs';
import { Category } from 'src/app/models/category.model';
import { NewsAttachment } from 'src/app/models/news-attachment';
import { NewsEntry } from 'src/app/models/news-entry.model';
import { ThemeService } from './../../shared/services/theme.service';

@Component({
	selector: 'app-modal-news-details',
	templateUrl: './modal-news-details.component.html',
	styleUrls: ['./modal-news-details.component.scss']
})
export class ModalNewsDetailsComponent implements OnInit {

	article: NewsEntry;
	news: NewsEntry[] = [];
	category: Observable<Category>;
	newsIndex: number;
	numberOfNews: number;
	newsSubscription$: Subscription = Subscription.EMPTY;
	attachmentList: { newsId: string, attachments: NewsAttachment[] }[] = [];

	constructor(
		public themeService: ThemeService,
		public dialogRef: MatDialogRef<ModalNewsDetailsComponent>,
		public overlayContainer: OverlayContainer,
	) { }

	ngOnInit(): void {
		this.newsIndex = this.news.findIndex(rep => rep.ID === this.article.ID);
		this.numberOfNews = this.news.length;
	}

	onClose(): void {
		this.dialogRef.close();
	}

	NextArticle() {
		this.newsIndex = this.newsIndex < this.numberOfNews ? this.newsIndex + 1 : this.numberOfNews + 1;
	}

	PrevArticle() {
		this.newsIndex = this.newsIndex > 0 ? this.newsIndex - 1 : 0;
	}
}
