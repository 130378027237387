import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { Category } from "src/app/models/category.model";
import { GraphService, ColumnMapEntry } from './graph.service';
import { ResourceNames } from "./resource-names";

@Injectable({
	providedIn: 'root'
})
export class GraphCategoryService {
	private categoriesList: BehaviorSubject<Category[]> = new BehaviorSubject<Category[]>([]);
	private pendingRequests = false;
	constructor(
		private graphService: GraphService | null
	) {

	}

	getCategories(): Observable<Category[]> {

		if (this.categoriesList.value.length > 0 || (this.pendingRequests)) {
			return this.categoriesList;
		}
		this.pendingRequests = true;
		this.graphService.getListItems<Category>(GraphCategoryService.listName, Category, GraphCategoryService.columnMap)
			.subscribe((result) => {
				this.categoriesList.next(result);
				this.pendingRequests = false;
			});
		return this.categoriesList;
	}

	static listName = ResourceNames.CATEGORIES_LIST_NAME;
	static readonly columnMap: ReadonlyArray<ColumnMapEntry<Category>> = [
		{
			modelKey: "key",
			spFriendlyFieldName: "CategoryKey",
		},
		{
			modelKey: "title",
			spFriendlyFieldName: "Title",
		},
		{
			modelKey: "objective",
			spFriendlyFieldName: "Objective",
		},
		{
			modelKey: "keyDimensions",
			spFriendlyFieldName: "KeyDimensions",
			transformer: x => (x as string).split("\n"),
		},
		{
			modelKey: "typeOfMetrics",
			spFriendlyFieldName: "TypesOfMetrics",
		},
		{
			modelKey: "sources",
			spFriendlyFieldName: "Sources",
			transformer: x => (x as string).split("\n"),
		},
		{
			modelKey: "languageCode",
			spFriendlyFieldName: "Language"
		}
	];

}