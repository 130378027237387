import { Component, OnInit, ViewChild } from '@angular/core';
import { combineLatest, from, Observable, of } from 'rxjs';
import { map, mapTo, mergeMap, switchMap, take, tap, toArray } from 'rxjs/operators';
import { ApprovalWorkflowModel } from 'src/app/models/approval-workflow.model';
import { DataFileModel } from 'src/app/models/data-file.model';
import { IntermediateStorageModel } from 'src/app/models/intermediate-storage.model';
import { ApprovalWorkflowService } from 'src/app/services/approval-workflow.service';
import { DataFilesManagementService, IdAble } from 'src/app/services/graph/data-files-management.service';
import { NamedBlob, SharepointDataSource } from 'src/app/services/graph/graph.service';
import { AuditActions, Constants, FileUploadStatuses, WorkflowStatuses } from 'src/app/services/graph/resource-names';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { ConfirmationPopupService } from 'src/app/shared/services/confirmation-popup.service';
import { ThemeService } from 'src/app/shared/services/theme.service';
import { formatDate } from '@angular/common';
import { SubjectArea } from 'src/app/models/subject-area.model';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute } from '@angular/router';
import { AuditLogService } from 'src/app/services/audit-log.service';

@Component({
  selector: 'app-uat-transfer',
  templateUrl: './uat-transfer.component.html',
  styleUrls: ['./uat-transfer.component.scss']
})
export class UatTransferComponent implements OnInit {

	allSubjectAreas: SubjectArea[];
	uatApprovedReports: ApprovalWorkflowModel[];
	uatDataFiles: DataFileModelExtended[];

	selectedReportCode: string;
	selectedReportTitle: string;
	selectedUatApprovedReport: ApprovalWorkflowModel;
	approvalDate: Date;

	isUploadStarted: boolean = false;

	constructor(
		public workflowService: ApprovalWorkflowService,
		public dataFilesService: DataFilesManagementService,
		public themeService: ThemeService,
		private popupService: ConfirmationPopupService,
		private snackBar: SnackbarService,
		private translateService: TranslateService,
		private activatedRoute: ActivatedRoute,
		private readonly auditService: AuditLogService
	) { }

	ngOnInit(): void {
		this.selectedReportCode = this.activatedRoute.snapshot.params.report || this.activatedRoute.snapshot.queryParamMap.get('report') || "NO_REPORT_SELECTED";
		this.getUatData();
	}

	getUatData() {
		combineLatest([
			this.workflowService.getUatApprovalItems(),
			this.dataFilesService.getUatDataFiles(),
			this.workflowService.getSubjectAreas()
		]).subscribe(([uatWorkflowItems, uatDataFiles, subjectAreas]) => {
			this.allSubjectAreas = subjectAreas;
			this.uatApprovedReports = uatWorkflowItems.filter(x => x.status === WorkflowStatuses.UAT_APPROVED);
			this.selectedReportTitle = uatWorkflowItems.find(x => x.reportCode === this.selectedReportCode).reportName;
			this.selectedUatApprovedReport = this.uatApprovedReports.find(x => x.reportCode === this.selectedReportCode);
			
			this.uatDataFiles = uatDataFiles.filter(x => x.reportCode.includes(this.selectedReportCode) && x.status === FileUploadStatuses.UAT_OK && x.lookupFileId !== null) as DataFileModelExtended[];
			this.uatDataFiles.forEach(x => x.subjectAreaTitle = subjectAreas.find(area => area.subjectAreaCode === x.subjectAreaCode)?.subjectAreaTitle);
			this.approvalDate = this.uatApprovedReports.find(x => x.reportCode === this.selectedReportCode).approvedDate;
		});
	}

	onFilesTransfer() {
		const dialogRef = this.popupService.openConfirmDialog("ApprovalWorkflow.ConfirmTransfer");

		dialogRef.afterClosed().subscribe(dialogResult => {
			if (dialogResult) this.transferFiles(); 
		});
	}

	transferFiles() {
		this.isUploadStarted = true;

		const fileIds = this.uatDataFiles.map(x => x.lookupFileId);
		this.dataFilesService.getBlobsFromIntermediateStorage(fileIds, SharepointDataSource.UAT).pipe(
			switchMap(blobs => from(blobs).pipe(
				tap(_ => this.auditService.logAction(AuditActions.PROD_TRANSFER, { FileName: blobs.map(x => x.fileName).join(";") })),
				mergeMap(blob => this.uploadToIntermediateStorage(blob)),
				toArray())
			),
			tap(uploaded => this.auditService.emailNotify(AuditActions.PROD_TRANSFER, `${AuditActions.PROD_TRANSFER} of the following files: \n${uploaded.map(x => x.fileName).join("\n") }`)),
			switchMap(uploaded => from(uploaded).pipe(
				mergeMap(file => this.saveUploadedFileHistory(file)),
				toArray()
			)),
			switchMap(_ => {
				//@ts-ignore
				const uniqueAreas = [...new Set(this.uatDataFiles.map(x => x.subjectAreaCode))];
				const areaDetails = uniqueAreas.map(x => ({ 
					name: x, 
					isTriggerFileRequired: this.allSubjectAreas.find(
						s => s.subjectAreaCode === x && s.reportCode === this.selectedReportCode
					)?.triggerFileName !== null ?? false
				}) as SubjectAreaDetails);
				return from(areaDetails).pipe(
					mergeMap(area => this.triggerProdPipeline(area)),
					toArray()
				);
			}),
			switchMap(_ => this.workflowService.setProdPromotedStatus(this.selectedReportCode, SharepointDataSource.UAT)),
			switchMap(_ => this.workflowService.setProdPromotedStatus(this.selectedReportCode, SharepointDataSource.Production))
		).subscribe(_ => this.onUploadSuccess(), error => this.onUploadError(error));
	}

	saveUploadedFileHistory(file: FileNameId): Observable<void> {
		const thisFile = this.uatDataFiles.find(x => x.name === file.fileName);
		const comment = `Transferred from UAT on ${formatDate(new Date(), "medium", "en_CA")}`;

		return this.dataFilesService.saveUploadedFileHistory(new DataFileModel(file.fileName, thisFile.reportCode, thisFile.subjectAreaCode, comment, FileUploadStatuses.PROD_PENDING, file.id)).pipe(take(1));
	}

	uploadToIntermediateStorage(blob: NamedBlob): Observable<FileNameId> {
		const metadata = new IntermediateStorageModel(blob.fileName, blob.fileName);
		return this.dataFilesService.uploadToIntermediateStorage(blob.blob, metadata).pipe(
			map(id => { return { id, fileName: blob.fileName }}),
			take(1));
	}

	triggerProdPipeline(subjectArea: SubjectAreaDetails): Observable<void> {
		return this.dataFilesService.getDataFilesToTriggerPipeline(
			subjectArea.name,
			FileUploadStatuses.PROD_PENDING
		).pipe(
			switchMap((files) => this.workflowService.uploadToAdf(files).pipe(mapTo(files))),
			switchMap((files) => {
				if (subjectArea.isTriggerFileRequired) {
					return this.workflowService
						.dropTriggerFile(subjectArea.name, this.selectedReportCode)
						.pipe(mapTo(files));
				} else {
					return of(files);
				}
			}),
			switchMap((files) => this.dataFilesService.markAsProdUploaded(
				files,
				SharepointDataSource.Production
			)),
			switchMap(() => this.dataFilesService.markAsProdUploaded(
				this.uatDataFiles.filter((x) => x.subjectAreaCode === subjectArea.name),
				SharepointDataSource.UAT
			)),
			take(1)
		);
	}

	onUploadSuccess() {
		this.isUploadStarted = false;
		this.snackBar.success(this.translateService.instant("ApprovalWorkflow.FilesTransferred"));
		this.getUatData();
	}

	onUploadError(error: any) {
		error = this.checkErrorType(error);
		const errorText = typeof(error) === "string" 
			? error
			: error.error instanceof String ? error.error : error.message
		this.snackBar.error(errorText);
		this.isUploadStarted = false;
	}

	private checkErrorType(error: any): any {
		if (error.error == "FileAlreadyExists") {
			return new Error(`${this.translateService.instant('DataFilesManagement.ErrorFileExists')}`);
		} else if (error.message.includes("Unknown Error")) {
			return new Error(`${this.translateService.instant('DataFilesManagement.ErrorUnknown')}`);
		}
		return error;
	}
}

export type DataFileModelExtended = DataFileModel & { subjectAreaTitle: string | null }

interface FileNameId {
	id: number,
	fileName: string
}

interface SubjectAreaDetails {
	name: string,
	isTriggerFileRequired: boolean
}