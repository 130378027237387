import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { combineLatest } from "rxjs";
import { ProjectDatasetViewModel } from "src/app/models/dataset.model";
import { Hyperlink } from "src/app/models/hyperlink.model";
import { Report } from "src/app/models/report.model";
import { ProjectDatasetService } from "src/app/services/graph/project-dataset.service";
import { GraphReportService } from "src/app/services/graph/report.service";
import { ReportViewModel } from "src/app/view-models/report-view-model";

@Component({
  selector: "app-project-datasets",
  templateUrl: "./project-datasets.component.html",
  styleUrls: ["./project-datasets.component.scss"],
})
export class ProjectDatasetsComponent implements OnInit {
  selectedProjectName: string;
  selectedProjectCode: string;

  projectSets: ReportViewModel[] = [];

  isLoading = true;

  constructor(
    private activatedRoute: ActivatedRoute,
    private datasetService: ProjectDatasetService,
    private readonly reportService: GraphReportService,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
	this.selectedProjectCode =
		this.activatedRoute.snapshot.params.project ||
		this.activatedRoute.snapshot.queryParamMap.get("project");

	combineLatest([
		this.datasetService.getProjectDataSetViewModel(null, this.selectedProjectCode),
		this.reportService.reportList$,
	]).subscribe(([dataSets, reports]) => {
		this.isLoading = false;
		
		const report = reports.find(
			x => x.code.toLowerCase() === this.selectedProjectCode.toLowerCase()
		);
		this.setProjectName(report, dataSets);

		const projects = this.flattenDataSets(dataSets);

		this.projectSets = projects.map((projectSet) => {
			const properties: { [key: string]: any } = {};
			properties["lastRefresh"] = projectSet.lastRefreshedDate;
			properties["dataOwner"] = report.dataOwner;

			return new ReportViewModel(
				// model
				report,
				// id
				projectSet.id || "",
				// title
				projectSet.projectTitle || "",
				// code
				projectSet.projectCode || "",
				// description
				report.description || "",
				// dataOwner
				report.dataOwner || new Hyperlink(),
				// url
				new Hyperlink(
				`/index.html#/download-dataset?project=${projectSet.projectCode}`
				),
				// categoryKeys
				[], //report.categories || [],
				// properties
				properties,
				// tags
				report.tags || [],
				// published
				projectSet.lastRefreshedDate,
				// status
				report.status,
				// lang
				report.languageCode || ""
			);
		});
	}, (error) => {
		console.error(error);	
		this.isLoading = false;
	});
  }

  private setProjectName(report: Report, dataSets: ProjectDatasetViewModel[]) {
    this.selectedProjectName =
      report.title +
      (dataSets.length > 0
        ? ""
        : `  -  ${this.translateService.instant("Reports.NoDatasets")}`);
  }

  private flattenDataSets(
    dataSets: ProjectDatasetViewModel[]
  ): ProjectDatasetViewModel[] {
    dataSets = this.removeEmptyDataSets(dataSets);
    // sorting by date asc to make sure the latest refresh date is picked up after flattenning
    dataSets = dataSets.sort(
      (a, b) =>
        new Date(a.lastRefreshedDate).getTime() -
        new Date(b.lastRefreshedDate).getTime()
    );
    
    const arrayUniqueByKey = [
	// @ts-ignore
      ...new Map(dataSets.map((item) => [item["projectTitle"], item])).values(),
    ];

    return arrayUniqueByKey;
  }

  private removeEmptyDataSets(
    dataSets: ProjectDatasetViewModel[]
  ): ProjectDatasetViewModel[] {
    return dataSets.filter((x) => x.lookupFileId);
  }
}
