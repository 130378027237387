import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { EMPTY, Observable, Subscription } from 'rxjs';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';
import { Category } from 'src/app/models/category.model';
import { GraphCategoryService } from 'src/app/services/graph/category.service';
import { LanguageService } from 'src/app/services/language.service';
import { ThemeService } from 'src/app/shared/services/theme.service';
import { ReportViewModel } from './../../view-models/report-view-model';
@Component({
	selector: 'app-report-categories',
	templateUrl: './report-categories.component.html',
	styleUrls: ['./report-categories.component.scss']
})
export class ReportCategoriesComponent implements OnInit, OnDestroy {
	currentLanguageCode: string;
	subscriptions$: Subscription[] = [];

	categories: Category[] = [];
	betaReports: Observable<ReportViewModel[]> = EMPTY;
	showCategories = true;

	constructor(
		readonly graphCategoryService: GraphCategoryService,
		readonly languageService: LanguageService,
		public themeService: ThemeService,
		private router: Router,
		private translateService: TranslateService
	) { }

	ngOnInit(): void {
		this.subscriptions$.push(this.router.events.pipe(
			filter(event => event instanceof NavigationEnd),
			distinctUntilChanged(),
		).subscribe(() => {
			this.showCategories = this.router.routerState.snapshot.url === "/categories";
		}));

		this.showCategories = this.router.routerState.snapshot.url === "/categories";

		if (this.showCategories) {
			this.subscriptions$.push(this.translateService.onLangChange.subscribe((e: Event) => {
				this.setCategories();
			}));

			this.setCategories();
		}
	}

	openCategory(categoryKey) {
		this.router.navigate(['/categories/category', { categoryKey }]);
	}

	setCategories() {
		this.currentLanguageCode = this.translateService.currentLang;
		this.graphCategoryService.getCategories().pipe(map(x =>
			x.filter((y): y is Category => y.languageCode.toLowerCase() === this.currentLanguageCode.toLowerCase()))).subscribe(a => this.categories = a);
	}

	ngOnDestroy(): void {
		this.subscriptions$.map(a => a.unsubscribe());
	}
}
