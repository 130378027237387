import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ApprovalWorkflowModel } from '../models/approval-workflow.model';
import { DataFileModel, PromoteModel } from '../models/data-file.model';
import { ReportRefreshApiModel, ReportRefreshModel } from '../models/report-refresh';
import { SubjectArea } from '../models/subject-area.model';
import { AuditLogService } from './audit-log.service';
import { ColumnMapEntry, GraphService, SharepointDataSource } from './graph/graph.service';
import { AuditActions, Constants, FileUploadStatuses, ResourceNames, WorkflowStatuses } from './graph/resource-names';
import { GraphUserService } from './graph/user.service';

const FILENAMES_WILDCARD = "YY";
const FILENAMES_REGEX_WILDCARD = "??";
const FLEXIBLE_TRIGGER_FILE_NAME = `Trigger_File_${FILENAMES_REGEX_WILDCARD}.csv`;

export const PROD_ENV_KEY = "prod";
export const UAT_ENV_KEY = "uat";

@Injectable({
  providedIn: 'root'
})
export class ApprovalWorkflowService {

	private reportsList: BehaviorSubject<ReportRefreshModel[]> = new BehaviorSubject<ReportRefreshModel[]>([]);

	constructor(
		private readonly graphService: GraphService,
		private readonly userServide: GraphUserService,
		private readonly auditService: AuditLogService
	) { }

	static readonly subjectAreaColumnMap: ReadonlyArray<ColumnMapEntry<SubjectArea>> = [
		{ modelKey: "subjectAreaCode", spFriendlyFieldName: "SubjectAreaCode" },
		{ modelKey: "subjectAreaTitle", spFriendlyFieldName: "SubjectAreaTitle" },
		{ modelKey: "sourceFileName", spFriendlyFieldName: "SourceFileName", },
		{ modelKey: "reportCode", spFriendlyFieldName: "ReportCode" },
		{ modelKey: "reportName", spFriendlyFieldName: "ReportName" },
		{ modelKey: "isActive", spFriendlyFieldName: "Enabled_x0020_Upload_x0020_File" },
		{ modelKey: "triggerFileName", spFriendlyFieldName: "TriggerFileName" },
		{ modelKey: "isFlexible", spFriendlyFieldName: "Is_x0020_Flexible" },
	];

	static readonly approvalWorkflowColumnMap: ReadonlyArray<ColumnMapEntry<ApprovalWorkflowModel>> = [
		{ modelKey: "reportName", spFriendlyFieldName: "Title" },
		{ modelKey: "reportCode", spFriendlyFieldName: "ReportCode" },
		{ modelKey: "isRequereAllFiles", spFriendlyFieldName: "IsActive" },
		{ modelKey: "status", spFriendlyFieldName: "Status" },
		{ modelKey: "statusDate", spFriendlyFieldName: "StatusDate" },
		{ modelKey: "statusChangedBy", spFriendlyFieldName: "StatusChangedBy" },
		{ modelKey: "uatReportRefreshDate", spFriendlyFieldName: "UATReportRefreshDate" },
		{ modelKey: "uatReportRefreshedBy", spFriendlyFieldName: "UATReportRefreshedBy" },
		{ modelKey: "approvedDate", spFriendlyFieldName: "ApprovedDate" },
		{ modelKey: "approvedBy", spFriendlyFieldName: "ApprovedBy" },
		{ modelKey: "promotedDate", spFriendlyFieldName: "PromotedDate" },
		{ modelKey: "promotedBy", spFriendlyFieldName: "PromotedBy" },
		{ modelKey: "id", spFriendlyFieldName: "Id" },
	];

	static readonly refreshReportColumnMap: ReadonlyArray<ColumnMapEntry<ReportRefreshModel>> = [
		{ modelKey: "reportName", spFriendlyFieldName: "Title" },
		{ modelKey: "reportCode", spFriendlyFieldName: "ReportName" },
		{ modelKey: "subjectAreaCode", spFriendlyFieldName: "SubjectAreaCode" },
		{ modelKey: "subjectAreaTitle", spFriendlyFieldName: "SubjectAreaTitle" },
		{ modelKey: "sourceFileName", spFriendlyFieldName: "SourceFileName" },
		{ modelKey: "reportRefreshedDate", spFriendlyFieldName: "ReportRefreshDate" },
		{ modelKey: "refreshedBy", spFriendlyFieldName: "RefreshedBy" },
		{ modelKey: "refreshedDate", spFriendlyFieldName: "ReportRefreshDate" },
		{ modelKey: "allFilesRequired", spFriendlyFieldName: "AllFilesRequired" },
		{ modelKey: "id", spFriendlyFieldName: "Id" },
	];

	static readonly refreshReportApiColumnMap: ReadonlyArray<ColumnMapEntry<ReportRefreshApiModel>> = [
		{ modelKey: "Environment", spFriendlyFieldName: "Environment" },
		{ modelKey: "Title", spFriendlyFieldName: "Title" },
		{ modelKey: "Report_Name", spFriendlyFieldName: "Report_Name" },
		{ modelKey: "Report_Code", spFriendlyFieldName: "Report_Code" },
		{ modelKey: "Workspace_Name", spFriendlyFieldName: "Workspace_Name" },
		{ modelKey: "Workspace_Id", spFriendlyFieldName: "Workspace_Id" },
		{ modelKey: "Refresh_Date", spFriendlyFieldName: "Refresh_Date" },
		{ modelKey: "Last_Refresh_Date", spFriendlyFieldName: "Last_Refresh_Date" },
		{ modelKey: "Id", spFriendlyFieldName: "Id" },
	];

	getApprovalItems(): Observable<ApprovalWorkflowModel[]> {
		return this.graphService.getListItems<ApprovalWorkflowModel>(ResourceNames.APPROVAL_WORKFLOW_LIST_NAME, ApprovalWorkflowModel, ApprovalWorkflowService.approvalWorkflowColumnMap);
	}

	getUatApprovalItems(): Observable<ApprovalWorkflowModel[]> {
		return this.graphService.getListItems<ApprovalWorkflowModel>(ResourceNames.APPROVAL_WORKFLOW_LIST_NAME, ApprovalWorkflowModel, ApprovalWorkflowService.approvalWorkflowColumnMap, null, SharepointDataSource.UAT);
	}

	getSubjectAreas(): Observable<SubjectArea[]> {
		return this.graphService.getListItems<SubjectArea>(ResourceNames.SUBJECT_AREA_LIST_NAME, SubjectArea, ApprovalWorkflowService.subjectAreaColumnMap);
	}

	getReports(): Observable<ReportRefreshModel[]> {
		return combineLatest([
			this.getApprovalItems(),
			this.getSubjectAreas()
		]).pipe(map(([items, areas]) => {
			let reports: ReportRefreshModel[] = [];
			for (const item of items) {
				for (const area of areas.filter(x => x.reportCode === item.reportCode)) {
					reports.push(new ReportRefreshModel(
						item.reportName,
						item.reportCode,
						area.subjectAreaCode,
						area.subjectAreaTitle,
						area.sourceFileName,
						item.uatReportRefreshDate,
						item.isRequereAllFiles,
						item.uatReportRefreshedBy,
						item.uatReportRefreshDate,
						item.id 
					));
				}
			}
			return reports;
		}));
	}

	getReportsForApiCall(): Observable<ReportRefreshApiModel[]> {
		return this.graphService.getListItems<ReportRefreshApiModel>(ResourceNames.REPORT_REFRESH_LIST_NAME, ReportRefreshApiModel, ApprovalWorkflowService.refreshReportApiColumnMap);
	}

	setUatDataApprovedStatus(reportId: number): Observable<boolean> {
		const user = this.userServide.getUser();
		return this.setApprovalItemStatus(reportId, WorkflowStatuses.UAT_APPROVED, user.displayName).pipe(switchMap(item => {
			item.approvedDate = new Date();
			item.approvedBy = user.displayName;
			return this.graphService.updateListItem(ResourceNames.APPROVAL_WORKFLOW_LIST_NAME, reportId, this.convertToSpFriendlyItem(item)).pipe(
				tap(_ => this.auditService.logAction(AuditActions.REPORT_APPROVE, { ReportName: item.reportName })),
				tap(_ => this.auditService.emailNotify(AuditActions.REPORT_APPROVE, `${AuditActions.REPORT_APPROVE} : ${item.reportName}`)),
			);
		}));
	}

	setUatReportRefreshedStatus(reportId: number): Observable<boolean> {
		const user = this.userServide.getUser();
		return this.setApprovalItemStatus(reportId, WorkflowStatuses.UAT_REFRESHED, user.displayName).pipe(switchMap(item => {
			item.uatReportRefreshDate = new Date();
			item.uatReportRefreshedBy = user.displayName;
			return this.graphService.updateListItem(ResourceNames.APPROVAL_WORKFLOW_LIST_NAME, reportId, this.convertToSpFriendlyItem(item));
		}));
	}

	setProdPromotedStatus(reportId: number | string, dataSource: SharepointDataSource): Observable<boolean> {
		const user = this.userServide.getUser();
		return this.setApprovalItemStatus(reportId, WorkflowStatuses.PROD_PROMOTED, user.displayName, dataSource).pipe(switchMap(item => {
			item.promotedDate = new Date();
			item.promotedBy = user.displayName;
			return this.graphService.updateListItem(ResourceNames.APPROVAL_WORKFLOW_LIST_NAME, item.id, this.convertToSpFriendlyItem(item), dataSource);
		}));
	}

	setProdRefreshedStatus(reportId: number | string): Observable<boolean> {
		const user = this.userServide.getUser();
		return this.setApprovalItemStatus(reportId, WorkflowStatuses.PROD_REFRESHED, user.displayName).pipe(switchMap(item => {
			// item.prodRefreshRequestDate = new Date();
			// item.prodRefreshRequestBy = user.displayName;
			return this.graphService.updateListItem(ResourceNames.APPROVAL_WORKFLOW_LIST_NAME, item.id, this.convertToSpFriendlyItem(item));
		}));
	}

	setNewFilesAddedStatus(reportCode: string): Observable<boolean> {
		const user = this.userServide.getUser();
		return this.setApprovalItemStatus(reportCode, WorkflowStatuses.UAT_PROCESSING, user.displayName).pipe(switchMap(item => {
			return this.graphService.updateListItem(ResourceNames.APPROVAL_WORKFLOW_LIST_NAME, item.id, this.convertToSpFriendlyItem(item));
		}));
	}

	setApprovalItemStatus(reportIdOrCode: number | string, status: WorkflowStatuses, userName: string, dataSource: SharepointDataSource = SharepointDataSource.Production): Observable<ApprovalWorkflowModel> {
		const dataSource$ = dataSource === SharepointDataSource.Production ? this.getApprovalItems() : this.getUatApprovalItems();
		return dataSource$.pipe(map(items => {
			let item = items.find(x => typeof reportIdOrCode === "number" ? x.id === reportIdOrCode : x.reportCode === reportIdOrCode);
			item.statusDate = new Date();
			item.statusChangedBy = userName;
			item.status = status;
			return item;
		}));
	}

	uploadOneToAdf(file: Blob, fileName: string, folder: string): Observable<void> {
		const blobName =  `${folder}/${fileName}`;

		const formData = new FormData();
		formData.append("adfContainer", environment.dataFilesManagement.adfContainerName);
		formData.append(fileName, file, fileName);
		formData.append("subjectAreas", blobName);

		return this.graphService.uploadToAdfSourceFolder(formData);
	}

	uploadToAdf(filesInfo: PromoteModel[]): Observable<void> {
		const blobNames = filesInfo.map(x => `${x.subjectAreaCode}/${x.name}`).join(Constants.SEPARATOR_SEMICOLON);

		const formData = new FormData();
		formData.append("adfContainer", environment.dataFilesManagement.adfContainerName);

		for(const fileInfo of filesInfo) {
			formData.append(fileInfo.name, fileInfo.file, fileInfo.name);
		}
		formData.append("subjectAreas", blobNames);

		return this.graphService.uploadToAdfSourceFolder(formData).pipe(
			tap(_ => this.auditService.logAction(AuditActions.ADF_UPLOAD, { FileName: blobNames })),
		);
	}

	dropFile(folder: string, fileName: string): Observable<void> {
		const formData = new FormData();
		formData.append("blobName", `${folder}/${fileName}`);
		formData.append("adfContainer", environment.dataFilesManagement.adfContainerName);
		formData.append("storageContainer", environment.dataFilesManagement.triggerStorageContainerName);

		return this.graphService.triggerMultipleFilesPipeline(formData);
	}

	dropTriggerFile(folder: string, reportCode: string): Observable<void> {
		return this.getSubjectAreas().pipe(switchMap(areas => {
			const selectedArea = areas.find(x => x.subjectAreaCode === folder && x.reportCode === reportCode);
			if(selectedArea?.triggerFileName == null) {
				return of(null);
			}

			return this.dropFile(folder, selectedArea.triggerFileName);
		}));
	}

	refreshUatReport(report: ReportRefreshModel): Observable<boolean> {
		return this.getReportsForApiCall().pipe(switchMap(reports => {
			const reportToUpdate = reports.find(x => x.Report_Code === report.reportCode);
			if (!reportToUpdate) throw new Error ("Report Code was not found in Report_Refresh list");

			return this.graphService.refreshUatReport(reportToUpdate).pipe(
				tap(_ => this.auditService.logAction(AuditActions.REPORT_REFRESH, { ReportName: report.reportName })),
				tap(_ => this.auditService.emailNotify(AuditActions.REPORT_REFRESH, `${AuditActions.REPORT_REFRESH} on UAT: ${report.reportName}`)),
			);
		}));
	}

	refreshProdReport(report: ApprovalWorkflowModel): Observable<boolean> {
		this.auditService.logAction(AuditActions.PROD_REPORT_REFRESH, { ReportName: report.reportName });
		this.auditService.emailNotify(AuditActions.PROD_REPORT_REFRESH, `${AuditActions.PROD_REPORT_REFRESH}: ${report.reportName}`);
		return of(true);
	}

	checkAllReportFilesAreInStatus(status: FileUploadStatuses, reportCode: string, uploadThresholdDate: Date, getDataFiles$: Observable<DataFileModel[]>): Observable<boolean> {
		return combineLatest([
			this.getSubjectAreas(),
			getDataFiles$
		]).pipe(map(([areas, files]) => {
			const expectedNames: IFileProvided[] = [];
			areas.filter(x => x.reportCode === reportCode).map(x => x.sourceFileName).forEach(x => x.split(Constants.SEPARATOR_SEMICOLON).forEach(fileName => expectedNames.push({ name: fileName, isInStatus: false })));
			return this.checkAllInStatus(status, expectedNames, files, uploadThresholdDate);
		}));
	}

	checkAllFilesUploadedForSubjectArea(subjectAreCode: string, getDataFiles$: Observable<DataFileModel[]>): Observable<boolean> {
		return combineLatest([
			this.getSubjectAreas(),
			getDataFiles$
		]).pipe(map(([areas, files]) => {
			const selectedArea = areas.find(x => x.subjectAreaCode === subjectAreCode);
			
			const expectedNames: IFileProvided[] = selectedArea.sourceFileName
				.split(Constants.SEPARATOR_SEMICOLON)
				.map(x => { return { name: x, isInStatus: false }});
			const { isFlexible } = selectedArea;

			return isFlexible 
				? this.checkSomeInStatus(subjectAreCode, FileUploadStatuses.PENDING, expectedNames, files)
				: this.checkAllInStatus(FileUploadStatuses.PENDING, expectedNames, files);
		}));
	}

	wildcardCompare(expectedName: string, candidate: string): boolean {
		expectedName = this.getExpectedNameForRegex(expectedName);

		return this.wildcardContains(expectedName, candidate);
	}

	private getExpectedNameForRegex(expectedName: string): string {
		return expectedName.split(FILENAMES_WILDCARD).join(FILENAMES_REGEX_WILDCARD);
	}

	private wildcardContains(wildcard: string, candidate: string): boolean {
		let w = wildcard.trim().replace(/[.+^${}()|[\]\\]/g, '\\$&'); // regexp escape 
		const re = new RegExp(`^${w.replace(/\*/g,'.*').replace(/\?/g,'.')}$`);
		return re.test(candidate.trim());
	}

	private checkSomeInStatus(subjectAreaCode: string, status: FileUploadStatuses, expectedNames: IFileProvided[], files: DataFileModel[]) {
		this.checkFilesStatus(status, expectedNames, files);
		
		const triggerFileName = FLEXIBLE_TRIGGER_FILE_NAME.replace(FILENAMES_REGEX_WILDCARD, subjectAreaCode);
		const triggerFile = expectedNames.find(x => x.name === triggerFileName); 

		return expectedNames
			.filter(x => x.name !== triggerFileName)
			.some(x => x.isInStatus) &&
			triggerFile && triggerFile.isInStatus;
	}

	private checkAllInStatus(status: FileUploadStatuses, expectedNames: IFileProvided[], files: DataFileModel[], uploadThresholdDate: Date = null): boolean {
		this.checkFilesStatus(status, expectedNames, files, uploadThresholdDate);
		return expectedNames.every(x => x.isInStatus);
	}

	private checkFilesStatus(status: FileUploadStatuses, expectedNames: IFileProvided[], files: DataFileModel[], uploadThresholdDate: Date = null) {
		for (const expected of expectedNames) {
			const expectedName = this.getExpectedNameForRegex(expected.name);
			let filteredFiles = this.getLatestFileInEachSubjectArea(files.filter(x => this.wildcardContains(expectedName, x.name)));

			if (uploadThresholdDate) {
				filteredFiles = filteredFiles.filter(x => x.uploadedDate > uploadThresholdDate);
				expected.isInStatus = filteredFiles.length > 0 && filteredFiles.every(x => x.status === status);
			} else {
				const latestFile = (filteredFiles.length > 0 && filteredFiles.reduce((a, b) => (a.uploadedDate > b.uploadedDate ? a : b))) || undefined;
				expected.isInStatus = latestFile != null && latestFile.status === status;
			}
		}
	}

	private getLatestFileInEachSubjectArea(filteredFiles: DataFileModel[]): PromoteModel[] {
		return (Object.values(filteredFiles.reduce((a, {subjectAreaCode, uploadedDate, name, status}) => {
			if (a[name]) {
			  if (a[name].uploadedDate < uploadedDate) a[name] = {subjectAreaCode, name, uploadedDate, status};
			} else a[name] = { subjectAreaCode, uploadedDate, name, status };
			return a;
		  }, {}))) as PromoteModel[];
	}

	private convertToSpFriendlyItem(approvalItem: ApprovalWorkflowModel) {
		let item = {};
		ApprovalWorkflowService.approvalWorkflowColumnMap.map(prop => {
			const spProperty = prop.spFriendlyFieldName;
			const contactProperty = approvalItem[prop.modelKey];
			if (!Array.isArray(spProperty) && contactProperty) {
				item[spProperty] = contactProperty;
			}
		});
		return item;
	}
}

export interface IFileProvided {
	name: string,
	isInStatus: boolean
}