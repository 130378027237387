<div class="main-container">
	<form #form="ngForm" [formGroup]="dataFileForm"  class="upload-criteria-container" [ngClass]="{'app-light-theme': !themeService.isDark, 'app-dark-theme': themeService.isDark}">
		<div class="upload-criteria">
			<div class="upload-row">
				<div>
					<label id="dataset-label">{{ 'DownloadDataset.Dataset' | translate }}</label>
					<mat-form-field appearance="outline" aria-labelledby="dataset-label" class="upload-select-wide">
						<mat-select required formControlName="datasetControl" (selectionChange)="onDatasetChange()" placeholder="{{'DownloadDataset.DatasetPlaceholder' | translate}}">
							<mat-option *ngFor="let dataSet of datasetDropdowns" [value]="dataSet.key">
								{{ dataSet.name }}
							</mat-option>
						</mat-select>
						<mat-error *ngIf="dataFileForm.get('datasetControl').hasError('required')">{{'ContactUs.Required' | translate}}</mat-error>
					</mat-form-field>
					<ng-container *ngIf="!isMetadataUpdate">
						<label style="padding-top:25px;" id="refresh-date-label">{{ 'DownloadDataset.RefreshDate' | translate }}</label>
						<mat-form-field appearance="fill"  aria-labelledby="refresh-date-label">
							<input required matInput [matDatepicker]="picker" formControlName="refreshDateControl" >
							<mat-datepicker-toggle matSuffix [for]="picker">
								<mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
							</mat-datepicker-toggle>
							<mat-datepicker #picker></mat-datepicker>
							<mat-error *ngIf="dataFileForm.get('refreshDateControl').hasError('required')">{{'ContactUs.Required' | translate}}</mat-error>
						</mat-form-field>
					</ng-container>
				</div>
			</div>
	
			<div class="upload-row" *ngIf="isNewDataset" class="custom-dataset-info">
				<label id="new-dataset-label" *ngIf="isNewDataset">{{ 'DownloadDataset.DatasetName' | translate }}</label>
				<mat-form-field appearance="outline" aria-labelledby="new-dataset-label" class="upload-select-wide" *ngIf="isNewDataset">
					<input required formControlName="newDatasetControl" matInput placeholder="{{'DownloadDataset.NewDatasetPlaceholder' | translate}}">
					<mat-error *ngIf="dataFileForm.get('newDatasetControl').hasError('required')">{{'ContactUs.Required' | translate}}</mat-error>
					<mat-error *ngIf="dataFileForm.get('newDatasetControl').hasError('maxlength')">{{'DownloadDataset.MaxLengthError' | translate}}</mat-error>
				</mat-form-field>
			</div>
		</div>
	
		<div class="upload-comment">
			<label id="description-label">{{ 'DownloadDataset.Description' | translate }}</label>
			<mat-form-field appearance="outline" aria-labelledby="description-label">
				<input required formControlName="descriptionControl" matInput placeholder="{{'DownloadDataset.DescriptionPlaceholder' | translate}}">
				<mat-error *ngIf="dataFileForm.get('descriptionControl').hasError('required') && (dataFileForm.get('descriptionControl').touched || dataFileForm.get('descriptionControl').dirty)">{{'ContactUs.Required' | translate}}</mat-error>
				<mat-error *ngIf="dataFileForm.get('descriptionControl').hasError('maxlength')">{{'DownloadDataset.MaxLengthError' | translate}}</mat-error>
			</mat-form-field>
		</div>
	
		<div class="upload-comment" *ngIf="filesInDataset.length">
			<label id="files-label">{{ 'DownloadDataset.FilesInDataset' | translate }}</label>
			<mat-list dense aria-labelledby="files-label" *ngFor="let file of filesInDataset">
				<mat-list-item>&#8226;	 {{file.fileName}} ({{'DownloadDataset.RefreshedOn' | translate}} {{file.refreshDate | dateFormatted }}) </mat-list-item>
			</mat-list>
		</div>
		<div class="progress" *ngIf="isUploadStarted" [ngClass]="{'app-light-theme': !themeService.isDark, 'app-dark-theme': themeService.isDark}">
			<div class="process-spinner">
				<mat-progress-spinner [diameter]="60" [mode]="spinnerMode"></mat-progress-spinner>
			</div>
			<span >{{'DownloadDataset.Processing' | translate}}</span>
		</div>
		<input #fileUpload type="file" class="file-input" (change)="onFileSelected($event)" [accept]="requiredFileType">
		<ng-container *ngIf="!isMetadataUpdate">
			<button class="upload-button" (click)="fileUpload.click()" matTooltip="{{'DownloadDataset.SelectFile' | translate}}" fxFlex="80px" fxFlex.gt-sm="100px" mat-flat-button color="primary">{{'DownloadDataset.Browse' | translate}}</button>
			<span class="upload-file-name"> {{fileName || ""}} </span>
			<mat-error *ngIf="isUploadFailed">{{'DownloadDataset.UploadError' | translate}}</mat-error>
			<mat-error *ngIf="isUnsupportedFileType">{{'DownloadDataset.UnsupportedFileType' | translate}}{{requiredFileType}}</mat-error>
			<mat-error *ngIf="isUnsupportedFileSize">{{'DownloadDataset.UnsupportedFileSize' | translate}}{{maxFileSizeMb}} Mb</mat-error>
			<button class="upload-button" (click)="onUploadFile()" [disabled]="isUploadDisabled()" matTooltip="{{'DownloadDataset.UploadFile' | translate}}" fxFlex="80px" fxFlex.gt-sm="100px" mat-flat-button color="primary">{{'DownloadDataset.UploadButton' | translate}}</button>
		</ng-container>

		<ng-container *ngIf="isMetadataUpdate">
			<button class="upload-button" (click)="onUpdateMetadata()" [disabled]="!(selectedDataset && form.valid)" matTooltip="{{'DownloadDataset.ClickToUpdateMetadata' | translate}}" fxFlex="140px" fxFlex.gt-sm="230px" mat-flat-button color="primary">{{'DownloadDataset.UpdateMetadata' | translate}}</button>
		</ng-container>
	</form>
</div>