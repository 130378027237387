import {
	Component,
	ElementRef,
	HostListener,
	OnInit,
	Input,
	ViewEncapsulation,
	OnDestroy,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { VgApiService, VgStates } from '@videogular/ngx-videogular/core';

export enum Direction {
	BACK = 'back',
	FORWARD = 'forward'
}

@Component({
	selector: 'app-jump-control',
	encapsulation: ViewEncapsulation.None,
	templateUrl: './jump-control.component.html',
	styleUrls: ['./jump-control.component.scss']
})

export class JumpControlComponent implements OnInit, OnDestroy {
	@Input() vgFor: string;
	@Input() BackOrForward: Direction = Direction.FORWARD;
	@Input() Amount: string;

	target: any;
	subscriptions$: Subscription[] = [];

	constructor(public API: VgApiService) {
	}

	ngOnInit() {
		if (this.API.isPlayerReady) {
			this.onPlayerReady();
		} else {
			this.subscriptions$.push(
				this.API.playerReadyEvent.subscribe(() => this.onPlayerReady())
			);
		}
	}

	onPlayerReady() {
		this.target = this.API.getMediaById(this.vgFor);
	}

	@HostListener('click')
	onClick() {
		this.jump();
	}

	@HostListener('keydown', ['$event'])
	onKeyDown(event: KeyboardEvent) {
		// On press Enter (13) or Space (32)
		if (event.keyCode === 13 || event.keyCode === 32) {
			event.preventDefault();
			this.jump();
		}
	}

	jump() {
		const newTime = this.target.currentTime + Number.parseFloat(this.Amount);
		this.target.currentTime = newTime < 0 ? 0 : newTime;
	}

	ngOnDestroy() {
		this.subscriptions$.forEach((s) => s.unsubscribe());
	}
}
