import { DOCUMENT } from '@angular/common';
import { Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { PrintHook } from '@angular/flex-layout';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationResult } from '@azure/msal-browser';
import { TranslateService } from '@ngx-translate/core';
import * as pbi from 'powerbi-client';
import { combineLatest } from 'rxjs';
import { GraphService } from 'src/app/services/graph/graph.service';
import { GraphReportLikeService } from 'src/app/services/graph/report-like.service';
import { ReportViewModel } from 'src/app/view-models/report-view-model';
import { ApplicationInsightsService } from './../../services/application-insights.service';
import { GraphUserSettingsService } from './../../services/graph/user-settings.service';
import { Subscriptions } from './../../subscriptions';
import { ModalPopupComponent } from './../modal-popup/modal-popup.component';
import { Hyperlink } from 'src/app/models/hyperlink.model';
import { environment } from 'src/environments/environment';
import { ThemeService } from 'src/app/shared/services/theme.service';

@Component({
	selector: 'app-report-view',
	templateUrl: './report-view.component.html',
	styleUrls: ['./report-view.component.scss']
})
export class ReportViewComponent implements OnInit, OnDestroy {
	@ViewChild('reportContainer', { static: false }) reportContainer: ElementRef;
	parentRouteId: string;
	viewModel: ReportViewModel;
	report: pbi.Report;
	hideFrame = false;
	private readonly subscriptions = new Subscriptions();
	powerbi: pbi.service.Service;
	userLanguage: string;

	constructor(
		@Inject(DOCUMENT) private readonly document: Document,
		private activatedRouter: ActivatedRoute,
		private readonly reportLikeService: GraphReportLikeService,
		private readonly graphService: GraphService,
		private dialog: MatDialog,
		public translateService: TranslateService,
		private readonly router: Router,
		private readonly applicationInsightsService: ApplicationInsightsService,
		private readonly userSettingsService: GraphUserSettingsService,
		private readonly printHook: PrintHook,
		public readonly themeService: ThemeService
	) {
		this.parentRouteId = this.activatedRouter.snapshot.params.id;

		const printHookAny = this.printHook as any;
    	const orig = printHookAny.collectActivations;
    	printHookAny.collectActivations = (event) => {
      		if (!printHookAny.isPrintingBeforeAfterEvent) {
 	       		orig.bind(printHookAny, event)();
      		}
    	};
	}

	ngOnInit(): void {
		
		combineLatest([this.reportLikeService.reports, this.graphService.getPowerBIEmbedToken()])
			.subscribe(([reps, authResult]) => {
				if (reps.length > 0) {
					reps = this.includeSiteAnalyticsReport(reps);
					this.viewModel = this.getViewModel(reps);
					try {
						this.EmbedReport(authResult);
					} catch (ex) {
						this.dialog.open(ModalPopupComponent, {
							width: 'rem(300px)',
							panelClass: 'popup',
							data: {
								popupMessage: this.translateService.instant('Error.PowerBIReportFailure'),
								popupOk: this.translateService.instant('Common.Controls.Ok'),
								reloadOnClose: false,
								closeWindowOnOk: true
							}
						}).afterClosed().subscribe(event => {
							this.CloseWindow();
						});
						throw ex;
					}
				}
			});

		this.subscriptions.push(
			this.userSettingsService.userSettings.subscribe(s => {
				this.setLanguage(s.settings.lang);
			})
		);
	}

	ngOnDestroy() {
		this.subscriptions.unsubscribeAll();
	}

	CloseWindow() {
		window.open('', "ParentWindow" + this.viewModel.code).focus();
		window.close();
	}

	EmbedReport(authResult: AuthenticationResult) {
		const accessToken = authResult.accessToken;
		const embedUrl = this.viewModel.url.address.replace(/\&autoAuth.*$/, '');

		// get report id from powerbi URL
		const txtEmbedReportId = this.router.parseUrl(this.viewModel.url.address.replace(/(^\w+:|^)\/\//, '')).queryParams.reportId;

		// Embed configuration used to describe the what and how to embed.
		// This object is used when calling powerbi.embed.
		// This also includes settings and options such as filters.
		// You can find more information at https://github.com/Microsoft/PowerBI-JavaScript/wiki/Embed-Configuration-Details.
		const config: pbi.IEmbedConfiguration = {
			type: 'report',
			tokenType: pbi.models.TokenType.Aad,
			accessToken,
			embedUrl,
			permissions: pbi.models.Permissions.Read,
			id: txtEmbedReportId,
			settings: {
				panes: {
					filters: {
						visible: false
					},
					pageNavigation: {
						visible: true
					}
				}
			}
		};

		this.powerbi = new pbi.service.Service(pbi.factories.hpmFactory, pbi.factories.wpmpFactory, pbi.factories.routerFactory);
		const reportContainer = this.reportContainer.nativeElement;
		this.report = this.powerbi.embed(reportContainer, config) as pbi.Report;

		this.report.off("loaded"); // remove the loaded event handler
		
		this.report.on("loaded", () => {
			this.setTokenExpirationListener(authResult.expiresOn, 2);
			this.setIFrameTitle();
			this.applicationInsightsService.logPageView(`ReportOpen`, location.href);
		})
	}

	getViewModel(reps: ReportViewModel[]): ReportViewModel | undefined {
		if (this.isNumeric(this.parentRouteId)) {
			return reps.find(rep => rep.id === this.parentRouteId);
		} else {
			const reportsByCode = reps.filter(x => x.code === this.parentRouteId);
			return reportsByCode.find(x => x.lang.toUpperCase() === this.userLanguage.toUpperCase()) || reportsByCode[0];
		}
	}

	isNumeric(str: string): boolean {
		if (typeof str != "string") return false;

		return !(isNaN(str as any) || isNaN(parseFloat(str)));
	}

	setIFrameTitle(title?: string): void {
		if (!title || title.trim().length === 0) title = "Embedded PowerBi Report";

		const iFrames = document.getElementsByTagName('iframe');
		if (iFrames) iFrames[0].setAttribute("title", title);
	}

	setTokenExpirationListener(tokenExpiration, minutesToRefresh = 2) {
		// get current time
		var currentTime = Date.now();
		var expiration = Date.parse(tokenExpiration);
		var safetyInterval = minutesToRefresh * 60 * 1000;

		// time until token refresh in milliseconds
		var timeout = expiration - currentTime - safetyInterval;

		// if token already expired, generate new token and set the access token
		if (timeout <= 0) {
			this.updateToken();
		}
		// set timeout so minutesToRefresh minutes before token expires, token will be updated
		else {
			setTimeout(() => {
				this.updateToken();
			}, timeout);
		}
	}

	updateToken() {
		this.graphService.getPowerBIEmbedToken()
			.subscribe((authResult) => {
				try {
					const embedContainer = this.reportContainer.nativeElement;
					var report = this.powerbi.get(embedContainer);

					// Set AccessToken
					report.setAccessToken(authResult.accessToken).then(() => {
						// Set token expiration listener
						// result.expiration is in ISO format
						this.setTokenExpirationListener(authResult.expiresOn, 2 /*minutes before expiration*/);
					});
				} catch (ex) {
					this.dialog.open(ModalPopupComponent, {
						width: 'rem(300px)',
						panelClass: 'popup',
						data: {
							popupMessage: this.translateService.instant('Error.PowerBIReportFailure'),
							popupOk: this.translateService.instant('Common.Controls.Ok'),
							reloadOnClose: false,
							closeWindowOnOk: true
						}
					});
					throw ex;
				}
			});
	}

	powerBiPrint() {
		this.report.print().catch(error => {
			this.applicationInsightsService.logException(error);
		});
	}

	private setLanguage(newLanguageCode: string): void {
		this.userLanguage = newLanguageCode;
		this.translateService.use(newLanguageCode); // triggers a change of language through the translate service, including static text keys piped through `translate`
		this.document.documentElement.setAttribute("lang", newLanguageCode); // <html lang="en">
	}

	private includeSiteAnalyticsReport(reports: ReportViewModel[]): ReportViewModel[] {
		const siteAnalytics: ReportViewModel = new ReportViewModel(
			{},
			'',
			'Site Analytics',
			'SiteAnalytics',
			'Open SIMS site analytics and report usage metric',
			new Hyperlink(),
			new Hyperlink(environment.siteAnalyticsReportUrl),
			[],
			{},
			[],
			null,
			null,
			"EN"
		);

		reports.push(siteAnalytics);
		return reports;
	}
}
