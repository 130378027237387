import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSelect } from '@angular/material/select';
import { Router } from '@angular/router';
import { ProjectDatasetService } from 'src/app/services/graph/project-dataset.service';
import { ThemeService } from 'src/app/shared/services/theme.service';
import { UploadDropdownModel } from '../dataset-manager/dataset-manager.component';

@Component({
  selector: 'app-dataset-stakeholder-selector',
  templateUrl: './dataset-stakeholder-selector.component.html',
  styleUrls: ['./dataset-stakeholder-selector.component.scss']
})
export class DatasetStakeholderSelectorComponent implements OnInit {
	@ViewChild('organizationsSelector', {static: false}) organizationsSelector: MatSelect;
	
	organizationDropdowns: UploadDropdownModel[];
	selectedOrganization: string;

	constructor(
		private readonly datasetService: ProjectDatasetService,
		private readonly router: Router,
		public readonly themeService: ThemeService,
	) { }

	ngOnInit(): void {
		this.initProjectsDropdown();
	}

	initProjectsDropdown(): void {
		this.datasetService.getOrganizations().subscribe(orgs => {
			this.organizationDropdowns = orgs.map(x => { return { key: x, name: x }});
		});
	}

	onOrganizationSelected() {
		this.selectedOrganization = this.organizationsSelector.value;
		this.router.navigate(['/manage-datasets/'], { queryParams: { stakeholder: this.selectedOrganization }});
	}
}
