<div fxFlex="100" fxLayout="column" fxLayoutAlign="center center" fxGap="20px">
	<ng-container *ngIf="!keywords">
		<h1 id="maincontent" tabindex="0">{{'Search.Title' | translate}}</h1>
		<p tabindex="0">{{'Search.Description' | translate}}</p>
	</ng-container>

	<h1 *ngIf="keywords && results.length != 0" [innerHtml]="'Search.Results.Heading' | translate :{keywords:keywords}"></h1>
	<h1 *ngIf="keywords && results.length === 0">{{'Search.Results.NoResults.Title' | translate : {keywords: keywords} }}</h1>

	<app-search-bar fxFlex [keywords]="keywords" (searchTerms)="onGoSearch($event)"></app-search-bar>

	<img *ngIf="!keywords" aria-hidden="true" alt="" src="assets/img/search_landing_bg.svg" />
	<div *ngIf="keywords && results.length === 0" class="suggestions">
		<div [innerHtml]="'Search.Results.NoResults.NotFound' | translate: {keywords: keywords}"></div>
		<div [innerHtml]="'Search.Results.NoResults.Suggestions' | translate: {keywords: keywords}"></div>
	</div>
	<ng-container *ngIf="keywords && results.length > 0">
		<mat-tab-group #tabs *ngIf="keywords" mat-align-tabs="center" disablePagination="true" dynamicHeight="true" class="search" [backgroundColor]="'primary'" [color]="'primary'">
			<mat-tab [disabled]="reportsResults.length == 0">
				<ng-template mat-tab-label>
					<mat-chip fxHide fxShow.lt-lg>{{'Search.Results.Reports' | translate: {count: reportsResults.length} }}</mat-chip>
					<div fxHide fxShow.gt-md>{{'Search.Results.Reports' | translate: {count: reportsResults.length} }}</div>
				</ng-template>
				<app-report-listing [reports]="reportsResults" [keywords]="keywords"></app-report-listing>
			</mat-tab>
			<mat-tab [disabled]="bookmarkedReportsResults.length == 0">
				<ng-template mat-tab-label>
					<mat-chip fxHide fxShow.lt-lg>{{'Search.Results.Bookmarks' | translate: {count: bookmarkedReportsResults.length} }}</mat-chip>
					<div fxHide fxShow.gt-md>{{'Search.Results.Bookmarks' | translate: {count: bookmarkedReportsResults.length} }}</div>
				</ng-template>
				<app-report-listing [reports]="bookmarkedReportsResults" [keywords]="keywords"></app-report-listing>
			</mat-tab>
			<mat-tab [disabled]="newsResults.length == 0">
				<ng-template mat-tab-label>
					<mat-chip fxHide fxShow.lt-lg>{{'Search.Results.News' | translate: {count: newsResults.length} }}</mat-chip>
					<div fxHide fxShow.gt-md>{{'Search.Results.News' | translate: {count: newsResults.length} }}</div>
				</ng-template>
				<app-news-listing [news]="newsResults"></app-news-listing>
			</mat-tab>
		</mat-tab-group>
	</ng-container>
</div>