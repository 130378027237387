export const environment = {
	production: true,
	appInsightsInstrumentationKey: '016126df-e41f-4c4a-89fb-be1fa2030300',
	name: "Phase 3 IST",
	headerColor: "orange",
	dateFormat: "longDate",
	hostWebUrl: "/sites/Teletraan/OpenSIMS_IST/",
	sharepointSiteId: 'ontariogov.sharepoint.com,f0767913-2548-4a1f-8fab-2e4005b7b82a,81e86aa8-0810-4d6f-90cf-4bdfbf397c05',
	sharepointSiteBaseUrl: 'https://ontariogov.sharepoint.com/',
	simsSite: "sites/Teletraan/OpenSIMS_IST/",
	lowerSimsSite: "sites/Teletraan/OpenSIMS_DEV/",
	signup: {
		api: "https://ws.test.opensims-api.tcu.gov.on.ca/api/Signup?code=iFeO3/GCIAy4r4T66ciwNdVaJiPqEl3NoZnKwhko5hecZ43/Q5kwbg==",
		institutions: "https://ws.test.opensims-api.tcu.gov.on.ca/api/institutions?code=iFeO3/GCIAy4r4T66ciwNdVaJiPqEl3NoZnKwhko5hecZ43/Q5kwbg==&origin=ist",
	},
	powerBiUrl: "https://app.powerbi.com/",
	auth:
	{
		clientId: '0b161072-4451-47d2-adaa-518aebf9391e',
		authority: 'https://login.microsoftonline.com/cddc1229-ac2a-4b97-b78a-0e5cacb5865c/',
		redirectUri: 'https://test.opensims.tcu.gov.on.ca',
		apiScope: 'api://csc.ddsb.opensims/DataFile.Upload'
	},
	reCaptchaSiteKey: '6LdhcAEaAAAAAB6acamsdEQpxtZiMvMG_YTIX4MZ',
	userGuideEnUrl: "https://ontariogov.sharepoint.com/sites/Teletraan/OpenSIMS_IST/DataFiles/User%20Guide.pdf",
	userGuideFrUrl: "https://ontariogov.sharepoint.com/sites/Teletraan/OpenSIMS_IST/DataFiles/Guide%20de%20l'utilisateur.pdf",
	dataFilesManagement: {
		hasUatApprovalWorkflow: true,
		hasProdApprovalWorkflow: false,
		prodUrl: "https://stage.opensims.tcu.gov.on.ca",
		adfContainerName: "adf-srcfiles",
		triggerStorageContainerName: "adf-trigger-files",
		azureAuditLogUrl: "https://ws.test.opensims-api.tcu.gov.on.ca/api/AuditLog",
		azureEmailNotifyUrl: "https://ws.test.opensims-api.tcu.gov.on.ca/api/EmailNotify",
		azureFunctionUrl: "https://ws.test.opensims-api.tcu.gov.on.ca/api/UploadDataFile",
		azureRefreshUatFunctionUrl: "https://ws.test.opensims-api.tcu.gov.on.ca/api/RefreshReport",
		azureTriggerUatFunctionUrl: "https://ws.test.opensims-api.tcu.gov.on.ca/api/TriggerPipeline",
		azureTestEmailUrl: "https://ws.test.opensims-api.tcu.gov.on.ca/api/TestEmailNotifications",
	},
	siteAnalyticsReportUrl: "https://app.powerbi.com/reportEmbed?reportId=45c5e647-59a8-4ed4-902a-c97ed947812e"
};
