<ng-template #paginatorTemplate>
	<div *ngIf="hidePaginator"></div>
	<div fxLayout="row reverse-wrap" fxLayoutAlign="center center" *ngIf="!hidePaginator">
		<mat-paginator fxFlexOrder.gt-sm="1" fxFlexOrder="2" #paginator [pageSize]="pageSize" [length]="reports.length" [pageSizeOptions]="[5, 10, 20, 50]" [showFirstLastButtons]="false" (page)="getPaginatorData($event)">
		</mat-paginator>

		<div fxFlexOrder.gt-sm="2" fxFlexOrder="1" fxLayoutAlign="center center" class="page-index-selector mat-paginator-page-size">
			<span class="page-index-text-wrapper mat-select-trigger mat-paginator-page-size-label">
				{{'Search.Results.Page' | translate}}
			</span>

			<mat-form-field fxLayoutAlign="center center" class="mat-paginator-page-size-select mat-input-page-number mat-primary">
				<input [disabled]="pages == 1" matInput type="number" [max]="pages" [min]="1" [ngModel]="getPage" [attr.aria-label]="'Search.Results.EnterPageNumber' | translate" (keyup.enter)="pageToIndex($event.target.value)">
			</mat-form-field>

			<span class="page-index-text-wrapper mat-select-trigger mat-paginator-page-size-label">
				{{'Search.Results.OfXPages' | translate : {X: pages} }}
			</span>

			<button *ngIf="hasPrevPage" mat-stroked (click)="previousPage()" class="mat-paginator-page-size-select next-prev" [attr.aria-label]="'Common.Controls.Previous' | translate">
				<mat-icon>chevron_left</mat-icon>
			</button>
			<button *ngIf="hasNextPage" mat-stroked (click)="nextPage()" class="mat-paginator-page-size-select next-prev" [attr.aria-label]="'Common.Controls.Next' | translate">
				<mat-icon>chevron_right</mat-icon>
			</button>
		</div>
	</div>
</ng-template>
<div class="listing-controls" fxFlex="auto" fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-md="row" fxLayoutAlign.gt-md="space-between center" [ngClass]="{'app-light-theme': !themeService.isDark, 'app-dark-theme': themeService.isDark}">
	<mat-card *ngIf="isFactbookPage" class="logo-small"><img src="{{(institution | async)?.logoImageSource.address}}" alt="" aria-hidden="true" /></mat-card>
	<ng-container [ngTemplateOutlet]="paginatorTemplate"></ng-container>
	<div fxLayout="row" fxLayoutAlign="end center">
		<div class="sort-by" fxLayout="row" fxLayoutAlign="center center">
			<label id="sortby-label">{{ 'Common.Controls.SortBy.Title' | translate }}</label>

			<mat-form-field appearance="outline">
				<mat-select aria-labelledby="sortby-label" [formControl]="sortControl" (selectionChange)="onSortByChange()">
					<mat-option *ngFor="let sortby of sortBys" [value]="sortby.value">
						{{ sortby.viewValue }}
					</mat-option>
				</mat-select>
			</mat-form-field>
		</div>
		<button fxHide fxShow.gt-md *ngIf="!viewAsList" mat-button color="primary" (click)="viewAsList = true">
			<mat-icon>list</mat-icon>{{'Reports.ViewAsList' | translate}}
		</button>
		<button fxHide fxShow.gt-md *ngIf="viewAsList" mat-button color="primary" (click)="viewAsList = false">
			<mat-icon>apps</mat-icon>{{'Reports.ViewAsGrid' | translate}}
		</button>
	</div>
</div>
<div [ngClass]="{'app-light-theme': !themeService.isDark, 'app-dark-theme': themeService.isDark}">
	<!-- List View -->
	<mat-table mat-table [dataSource]="dataSource" [@listStagger]='sortValue + dataSource.data.length' *ngIf="viewAsList && !isLowRes" fxLayout="column" fxLayoutAlign="center center" [attr.aria-label]="'Reports.ViewAsList' | translate">
		<ng-container matColumnDef="id">
			<app-report-card mat-cell *matCellDef="let report" [hidden]="isBookmarkPage && !report.isBookmarked.value" class="full" [keywords]="keywords" [isBookmarkPage]="isBookmarkPage" [viewModel]="report" [isInList]="viewAsList" [isLowRes]="isLowRes" (ViewReportDetail)="openReportDetailModal(report)" fxLayout="column" fxLayoutGap="25px"></app-report-card>
		</ng-container>
		<mat-row fxLayoutAlign="center center" class="list-row" *matRowDef="let row; columns: columnsToDisplay">
		</mat-row>
	</mat-table>
	<!-- Grid View -->
	<mat-table mat-table [dataSource]="dataSource" [@listStagger]='sortValue + dataSource.data.length' *ngIf="!viewAsList || isLowRes" fxLayout="column" fxLayout.gt-md="row wrap" fxLayoutGap="20px" fxLayoutAlign="center center" [attr.aria-label]="'Reports.ViewAsGrid' | translate">
		<ng-container matColumnDef="id">
			<app-report-card mat-cell *matCellDef="let report" [hidden]="isBookmarkPage && !report.isBookmarked.value" [viewModel]="report" [keywords]="keywords" [isBookmarkPage]="isBookmarkPage" [isFactbookPage]="isFactbookPage" [isInList]="viewAsList" [isLowRes]="isLowRes" (ViewReportDetail)="openReportDetailModal(report)"></app-report-card>
		</ng-container>
		<mat-row *matRowDef="let row; columns: columnsToDisplay">
		</mat-row>
	</mat-table>
	<ng-container [ngTemplateOutlet]="paginatorTemplate"></ng-container>
</div>
