import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

const HTML_NEW_LINE = "<br />"

@Pipe({
	name: 'dateFormatted'
})
export class DateFormattedPipe implements PipeTransform {

	constructor(private translateService: TranslateService) {}

	transform(value: Date | string, format: string = "mediumDate"): string {
		const locale = this.translateService.currentLang === "en" ? "en_CA" : "fr_CA";
		if (format === "mediumCustom") {
			format = locale === "en_CA" ? "MMM d, y, HH:mm" : "d MMM y à HH:mm";
		}

		if (typeof(value) === "string") {
			return value.split(HTML_NEW_LINE).map(x => new DatePipe(locale).transform(x, format)).join(HTML_NEW_LINE);
		}

		return new DatePipe(locale).transform(value, format);
	}

}