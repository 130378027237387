import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, combineLatest, fromEvent, Observable, Subject, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { GraphUserSettingsService } from 'src/app/services/graph/user-settings.service';
import { ConfirmationPopupService } from 'src/app/shared/services/confirmation-popup.service';
import { FrenchExperiencePopup } from '../french-experience-popup';

@Injectable({
	providedIn: 'root'
})
export class LanguageService implements OnDestroy {
	static readonly supportedLanguageCodes: ['en', 'fr'] = ['en', 'fr'];
	static readonly defaultLanguageCode = LanguageService.supportedLanguageCodes[0];

	readonly currentLanguageCode = new BehaviorSubject<string>(this.getBrowserLanguage());
	private subscription$: Subscription;

	private static getValidLanguageCodeOrDefault(unvalidatedLanguageCode: string) {
		const supportedLanguageCodes: string[] = LanguageService.supportedLanguageCodes;
		if (supportedLanguageCodes.indexOf(unvalidatedLanguageCode) != -1) {
			return unvalidatedLanguageCode;
		} else {
			return LanguageService.defaultLanguageCode;
		}
	}

	constructor (
		private userSettingsService: GraphUserSettingsService,
		private popupService: ConfirmationPopupService,
	) {
		// create observable of the current browser language, and inject an initial value of the current browser language
		this.subscription$ =
			fromEvent(window, 'languagechange')
				.subscribe(x => {
					this.getCurrentBrowserLanguageCodeOrDefault().pipe(map(x => this.currentLanguageCode.next(x)));
				});
	}

	ngOnDestroy(): void {
		if (this.subscription$) {
			this.subscription$.unsubscribe();
		}
	}

	overrideCurrentBrowserLanguage(newLanguageCode: string): void {
		const validatedNewLanguageCode = LanguageService.getValidLanguageCodeOrDefault(newLanguageCode);
		this.userSettingsService.setUserSetting('lang', validatedNewLanguageCode);
		this.currentLanguageCode.next(validatedNewLanguageCode);
	}

	displayFrenchPopup() {
		this.popupService.openConfirmMessage(null, FrenchExperiencePopup.MESSAGE, "align-justify");
	}

	private getBrowserLanguage() {
		const currentBrowserLanguageCode = navigator.language.substr(0, 2);
		return LanguageService.getValidLanguageCodeOrDefault(currentBrowserLanguageCode);
	}

	getCurrentBrowserLanguageCodeOrDefault(): Observable<string> {
		const subj$ = new Subject<string>();
		this.userSettingsService.loaded.subscribe(loaded => {
			if (!loaded)
			{
				return subj$;
			}
			const lang = this.userSettingsService.getUserSetting('lang');
			const currentBrowserLanguageCode = lang || navigator.language.substr(0, 2);
			const validatedLanguage = LanguageService.getValidLanguageCodeOrDefault(currentBrowserLanguageCode);
			subj$.next(validatedLanguage);
		});
		return subj$;
	}

	public filterByLanguage<T extends HasLanguageCode>(observable: Observable<T[]>): Observable<T[]> {
		return combineLatest(
			observable,
			this.currentLanguageCode,
			(data, currentLanguageCode): T[] => data.filter(item =>
				(item.languageCode || LanguageService.defaultLanguageCode).toLocaleLowerCase() === currentLanguageCode)
		);
	}

	public filterByLang<T extends HasLanguageCode>(data: T[]): Observable<T[]> {
		return this.currentLanguageCode.pipe(map(currentLanguageCode => data.filter(item => (item.languageCode || LanguageService.defaultLanguageCode).toLocaleLowerCase() === currentLanguageCode)
		));
	}
}

export interface HasLanguageCode {
	languageCode: string | null;
}
