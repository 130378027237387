<button 
	mat-mini-fab 
	class="close" 
	(click)="$event.stopPropagation(); onClose()" 
	[attr.aria-label]="'Common.Controls.Close' | translate"
>
	<mat-icon>close</mat-icon>
</button>

<div [ngClass]="{'app-light-theme': !themeService.isDark, 'app-dark-theme': themeService.isDark}" fxLayout="column" fxLayoutGap="10%">

	<h1 mat-dialog-title>{{'Profile.Title' | translate}}</h1>
	<mat-divider></mat-divider>

	<div fxLayout="row" mat-dialog-content fxLayoutGap="10px">
		<img aria-hidden="true" alt="" src="assets/img/user_icon.svg">
		<div class="profile-info">
			<div>
				{{GetFormattedUserName(profileName)}}
			</div>
			<div>
				{{profileEmail}}
			</div>
		</div>

	</div>
	<mat-divider></mat-divider>

	<mat-slide-toggle [labelPosition]="'before'" [checked]="themeService.isDark" (change)="toggleDarkTheme($event.checked)">{{'Common.Controls.DarkTheme' | translate}}</mat-slide-toggle>
	<mat-divider></mat-divider>

	<mat-slide-toggle [labelPosition]="'before'" [checked]="captionsOn" (change)="toggleVideoCaptioning($event.checked)">{{'Common.Controls.ShowVideoCaptioning' | translate}}</mat-slide-toggle>
	<mat-divider></mat-divider>

	<div mat-dialog-actions fxLayoutAlign="space-between" fxLayoutGap.lt-sm="1rem">
		<button mat-button (click)="toggleLang()">{{(translateService.currentLang === 'en' ? 'Common.Language.French' : 'Common.Language.English') | translate}}</button>
		<mat-divider vertical style="height:2rem"></mat-divider>
		<button mat-button (click)="openSignOutPopup()">{{'SignOut.Title' | translate}}</button>
	</div>
</div>