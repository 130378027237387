<div *ngIf="ready">
	<app-header></app-header>
	<div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px">
		<div class="banner" fxLayoutAlign="center center" fxLayout="column" fxLayoutGap="12px">
			<img fxHide.gt-md src="assets/img/open_sims_logo_sm.svg" alt="{{'Common.ImageDescriptions.OpenSIMSLogo' | translate}}">
			<img fxHide.lt-lg src="assets/img/open_sims_logo_md.svg" alt="{{'Common.ImageDescriptions.OpenSIMSLogo' | translate}}">
			<span>{{'Home.SubHeader' | translate}}</span>
		</div>
	</div>

	<mat-card *ngIf="!success">
		<div class="formHeader" fxLayout="column" fxLayoutAlign="center center">
			<h1>{{'SignUp.Title' | translate}}</h1>
			<p>{{'SignUp.SubHeader' | translate}}</p>
		</div>
		<form id="newProgramForm" [formGroup]="signup" #formDirective="ngForm">
			<div fxLayout="column" fxLayoutGap="20px" fxLayoutAlign="space-between stretch">
				<div fxLayout="row wrap" fxLayoutAlign="center stretch">
					<mat-form-field id="email">
						<mat-label aria-hidden="true">{{'SignUp.FIELD_EMAIL' | translate}}</mat-label>
						<input id="Content" matInput maxlength="255" formControlName="email" email [attr.aria-label]="'SignUp.FIELD_EMAIL' | translate">
						<mat-error *ngIf="signup.controls['email'].invalid">
							<span *ngIf="signup.controls['email'].errors.required">{{'SignUp.VALIDATION_ERRORS.REQUIRED_Email' | translate}}</span>
							<span *ngIf="signup.controls['email'].errors.email">{{'SignUp.VALIDATION_ERRORS.FORMAT_Email' | translate}}</span>
						</mat-error>
					</mat-form-field>
					<mat-form-field id="email-confirm">
						<mat-label>{{'SignUp.FIELD_CONFIRM_EMAIL' | translate}}</mat-label>
						<input matInput maxlength="255" formControlName="confirmEmail" email [attr.aria-label]="'SignUp.FIELD_CONFIRM_EMAIL' | translate">
						<mat-error *ngIf="signup.controls['confirmEmail'].invalid">
							<span *ngIf="signup.controls['confirmEmail'].errors.required">{{'SignUp.VALIDATION_ERRORS.REQUIRED_ConfirmEmail' | translate}}</span>
							<span *ngIf="signup.controls['confirmEmail'].errors.email">{{'SignUp.VALIDATION_ERRORS.FORMAT_Email' | translate}}</span>
							<span *ngIf="signup.controls['confirmEmail'].errors.confirmEmail">{{'SignUp.VALIDATION_ERRORS.MATCH_Email' | translate}}</span>
						</mat-error>
					</mat-form-field>
					<mat-form-field id="firstName">
						<mat-label>{{'SignUp.FIELD_FIRSTNAME' | translate}}</mat-label>
						<input matInput maxlength="255" formControlName="firstName" [attr.aria-label]="'SignUp.FIELD_FIRSTNAME' | translate">
						<mat-error *ngIf="signup.controls['firstName'].invalid">
							<span *ngIf="signup.controls['firstName'].errors.required">{{'SignUp.VALIDATION_ERRORS.REQUIRED_FirstName' | translate}}</span>
						</mat-error>
					</mat-form-field>
					<mat-form-field id="lastName">
						<mat-label>{{'SignUp.FIELD_LASTNAME' | translate}}</mat-label>
						<input matInput maxlength="255" formControlName="lastName" [attr.aria-label]="'SignUp.FIELD_LASTNAME' | translate">
						<mat-error *ngIf="signup.controls['lastName'].invalid">
							<span *ngIf="signup.controls['lastName'].errors.required">{{'SignUp.VALIDATION_ERRORS.REQUIRED_LastName' | translate}}</span>
						</mat-error>
					</mat-form-field>
					<mat-form-field id="phoneNumber">
						<mat-label>{{'SignUp.FIELD_PHONE' | translate}}</mat-label>
						<input matInput maxlength="255" formControlName="phoneNumber" [attr.aria-label]="'SignUp.FIELD_PHONE' | translate">
						<mat-error *ngIf="signup.controls['phoneNumber'].invalid">
							<span *ngIf="signup.controls['phoneNumber'].errors.required">{{'SignUp.VALIDATION_ERRORS.REQUIRED_Phone' | translate}}</span>
						</mat-error>
					</mat-form-field>
					<mat-form-field id="language">
						<mat-label>{{'SignUp.FIELD_LANGUAGE' | translate}}</mat-label>
						<mat-select formControlName="language" name="language">
							<mat-option>
							</mat-option>
							<mat-option [value]="'SignUp.LANG_EN' | translate">
								{{'SignUp.LANG_EN' | translate}}
							</mat-option>
							<mat-option  [value]="'SignUp.LANG_FR' | translate">
								{{'SignUp.LANG_FR' | translate}}
							</mat-option>												
						</mat-select>
						<mat-error *ngIf="signup.controls['language'].invalid">
							<span *ngIf="signup.controls['language'].errors.required">{{'SignUp.VALIDATION_ERRORS.REQUIRED_LANGUAGE' | translate}}</span>
						</mat-error>									
					</mat-form-field>					
					<mat-form-field id="organizationType">
						<mat-label>{{'SignUp.FIELD_ORG_TYPE' | translate}}</mat-label>
						<mat-select formControlName="organizationType" name="organizationType" (selectionChange)="organizationTypeChanged()">
							<mat-option>
							</mat-option>
							<mat-option [value]="'SignUp.SEL_COLL' | translate">
								{{'SignUp.SEL_COLL' | translate}}
							</mat-option>
							<mat-option  [value]="'SignUp.SEL_UNI' | translate">
								{{'SignUp.SEL_UNI' | translate}}
							</mat-option>
							<mat-option  [value]="'OPS' | translate">
								{{'SignUp.SEL_OPS' | translate}}
							</mat-option>
							<mat-option  [value]="'OTHER' | translate">
								{{'SignUp.SEL_OTHER' | translate}}
							</mat-option>												
						</mat-select>	
						<mat-error *ngIf="signup.controls['organizationType'].invalid">
							<span *ngIf="signup.controls['organizationType'].errors.required">{{'SignUp.VALIDATION_ERRORS.REQUIRED_ORGTYPE' | translate}}</span>
						</mat-error>								
					</mat-form-field>
					<mat-form-field id="organization">
						<mat-label>{{'SignUp.FIELD_ORG_NAME' | translate}}</mat-label>
						<mat-select formControlName="organization" name="organization" *ngIf="!(signup.controls['organizationType'].value==='OPS' || signup.controls['organizationType'].value==='OTHER')">
							<mat-option *ngFor="let org of filtered; let i = index" [value]="org.name" [matTooltip]="org.displayName" matTooltipShowDelay="1000">
							{{org.displayName}}
							</mat-option>
						</mat-select>
						<input matInput maxlength="255" formControlName="organization" [attr.aria-label]="'SignUp.FIELD_ORG_NAME' | translate" *ngIf="(signup.controls['organizationType'].value==='OPS' || signup.controls['organizationType'].value==='OTHER')">
						<mat-error *ngIf="signup.controls['organization'].invalid">
							<span *ngIf="signup.controls['organization'].errors.required">{{'SignUp.VALIDATION_ERRORS.REQUIRED_ORGNAME' | translate}}</span>
						</mat-error>									
					</mat-form-field>
					<mat-form-field id="reasonForAccess">
						<mat-label>{{'SignUp.FIELD_REASON' | translate}}</mat-label>
						<textarea matTextareaAutosize matAutosizeMinRows="5" matAutosizeMaxRows="10" matInput formControlName="reasonForAccess" [attr.aria-label]=""></textarea>
						<mat-error *ngIf="signup.controls['reasonForAccess'].invalid">
							<span *ngIf="signup.controls['reasonForAccess'].errors.maxlength">{{'SignUp.VALIDATION_ERRORS.MAX_LENGTH' | translate}}</span>
						</mat-error>
					</mat-form-field>		
				</div>
				<div fxLayoutGap="6px">
					<button mat-flat-button color="primary" id="signup" type="button" (click)="signUp()" [disabled]="isSaving || signup.invalid" type="submit">
						{{'SignUp.CONFIRM_BUTTON' | translate}}
					</button>
					<button mat-flat-button color="primary" id="cancel" type="button" (click)="cancel()">
						{{'SignUp.CANCEL_BUTTON' | translate}}
					</button>
				</div>
			</div>
		</form>
	</mat-card>
	<span class="recaptcha-notice">
		{{'SignUp.RECAPTCHA_NOTICE_SiteProtected' | translate}}
		<a href="https://policies.google.com/privacy{{'SignUp.RECAPTCHA_NOTICE_FrenchLocalizationParam' | translate}}" target="_blank" title="{{'Common.Notifications.OpensInNewWindow' | translate}}">{{'SignUp.RECAPTCHA_NOTICE_PrivacyPolicy' | translate}}</a>
		{{'SignUp.RECAPTCHA_NOTICE_And' | translate}}
		<a href="https://policies.google.com/terms{{'SignUp.RECAPTCHA_NOTICE_FrenchLocalizationParam' | translate}}" target="_blank" title="{{'Common.Notifications.OpensInNewWindow' | translate}}">{{'SignUp.RECAPTCHA_NOTICE_TermsOfService' | translate}}</a>
		{{'SignUp.RECAPTCHA_NOTICE_Apply' | translate}}
	</span>
	<app-footer></app-footer>
</div>