import { TypeName } from "src/app/type-name";
import { HasLanguageCode } from "src/app/services/language.service";
import { getPropertyNames } from "src/app/property-names";

@TypeName.decorate("Category")
export class Category implements HasLanguageCode {
	[key: string]: any;

	public static readonly defaultPropertyNames = getPropertyNames(new Category());


	public constructor(
		public readonly key:           null | string = null,
		public readonly title:         null | string = null,
		public readonly objective:     null | string = null,
		public readonly keyDimensions: null | string[] = null,
		public readonly typeOfMetrics: null | string = null,
		public readonly sources:       null | string[] = null,
		public readonly languageCode:  null | string = null,
	) {}


	public getIcon(): string | undefined {
		const categoryIcons = [
			{ key: 'Enr', icon: 'clients_served' },
			{ key: 'Sys', icon: 'system_funding' },
			{ key: 'Pro', icon: 'provincial_outcomes' },
			{ key: 'Acc', icon: 'access_and_equity' },
			{ key: 'Stu', icon: 'client_outcomes' },
			{ key: 'Beta', icon: 'beta_reports' }
		];

		const found = categoryIcons.find(x => x.key === this.key);
		return found === undefined ? undefined : found.icon;
	}
}
