import { Injectable } from "@angular/core";
import { combineLatest, from, Observable, EMPTY } from "rxjs";
import { groupBy, map, mergeMap, toArray } from "rxjs/operators";
import { TranslateService } from "@ngx-translate/core";
import { compareDates } from '../../compare-dates';
import { DownloadableFile, ReportViewModel } from "src/app/view-models/report-view-model";
import { Factbook } from '../../models/factbook-model';
import { GraphFactbookService } from "./factbook.service";
import { GraphNewsService } from './news.service';
import { GraphReportService } from './report.service';
import { Hyperlink } from '../../models/hyperlink.model';
import { NewsEntry } from './../../models/news-entry.model';
import { PirlReportType } from "src/app/models/report-type.model";
import { PirlReportTypeCodes } from "./resource-names";
import { Report } from '../../models/report.model';
import { SearchableViewModel } from "src/app/view-models/searchable-view-model";
import { TypeName } from '../../type-name';

@Injectable({
	providedIn: 'root'
})
export class GraphReportLikeService {
	private static readonly reportMetadataFields: Array<keyof Report> = [
		"lastRefresh",
		"dataOwner",
		"dataProvider",
		"videoId",
		"linkToData",
	];

	readonly all: Observable<ReportViewModel[]>;
	readonly reports: Observable<ReportViewModel[]>;
	readonly factbooks: Observable<ReportViewModel[]>;
	readonly news: Observable<ReportViewModel[]>;
	readonly nofactbooks: Observable<ReportViewModel[]>;
	readonly reportsByType: Map<string, Observable<ReportViewModel[]>> = new Map<string, Observable<ReportViewModel[]>>();
	readonly allPirlReports$: Observable<ReportViewModel[]>;

	pirlReportMetadataDescription: string;

	constructor(
		reportService: GraphReportService,
		factbookService: GraphFactbookService,
		newsService: GraphNewsService,
		private readonly translateService: TranslateService,
	) {
		this.reports = reportService.getReports().pipe(map(
			reports => reports.map(report => this.fromReport(report))
		));

		this.news = newsService.getNewsEntries().pipe(map(
			news => news.map(newsEntry => this.fromNewsEntry(newsEntry))
		));

		this.factbooks = factbookService.getFactbooks().pipe(
			mergeMap(
				factbookEntries => {
					const observableFactbookEntries = from(factbookEntries).pipe(
						groupBy(
							x => [x.institution, x.academicYear].join()
						),
						mergeMap(
							x => x.pipe(
								toArray()
							)
						)
					);
					return this.fromObservableFactbookEntries(observableFactbookEntries);
				}
			)
		);

		this.all = combineLatest([this.reports, this.news, this.factbooks])
			.pipe(
				map(values =>
					(values[0] // reports
						.concat(values[1]) // news
						.concat(values[2])) // factbooks
						.sort((a, b) => compareDates(a.published, b.published)))
		);

		this.nofactbooks = combineLatest([this.reports, this.news])
		.pipe(
			map(values =>
				(values[0] // reports
					.concat(values[1])) // news
					.sort((a, b) => compareDates(a.published, b.published)))
		);

		this.reportsByType.set(
			PirlReportTypeCodes.CAP_OPER_GRANTS, 
			factbookService.getCapitalOperatingGrants().pipe(mergeMap(reports => this.pirlGroupBy({ code: PirlReportTypeCodes.CAP_OPER_GRANTS, title: 'Capital Operating Grants' }, reports)))
		);

		this.reportsByType.set(
			PirlReportTypeCodes.INST_PROFILES, 
			factbookService.getInstitutionalProfiles().pipe(mergeMap(reports => this.pirlGroupBy({ code: PirlReportTypeCodes.INST_PROFILES, title: 'Institutional Profiles' }, reports)))
		);

		this.reportsByType.set(
			PirlReportTypeCodes.INST_ELMLP,
			factbookService.getInstitutionalElmlp().pipe(mergeMap(reports => this.pirlGroupBy({ code: PirlReportTypeCodes.INST_ELMLP, title: 'Institutional ELMLP' }, reports)))
		);


		this.reportsByType.set(
			PirlReportTypeCodes.FACTBOOKS,
			this.factbooks
		);

		this.allPirlReports$ = combineLatest([
			this.reportsByType.get(PirlReportTypeCodes.FACTBOOKS),
			this.reportsByType.get(PirlReportTypeCodes.CAP_OPER_GRANTS),
			this.reportsByType.get(PirlReportTypeCodes.INST_PROFILES),
			this.reportsByType.get(PirlReportTypeCodes.INST_ELMLP),
		]).pipe(map(([
				factbooks, 
				capitalOperatingGrants, 
				instProfiles, 
				instElmlp,
			]) => 
				factbooks
					.concat(capitalOperatingGrants)
					.concat(instProfiles)
					.concat(instElmlp)
		));
	}

	getByReportType(reportTypeName: PirlReportType): Observable<ReportViewModel[]> {
		return this.reportsByType.get(reportTypeName.code) ?? EMPTY;
	}

	private readonly viewModelCache = new Map<string, ReportViewModel>();
	private cacheGetOrAdd<TModel>(model: TModel, id: string | null, factory: (model: TModel) => ReportViewModel): ReportViewModel {
		const modelTypeName = TypeName.tryGet(model);
		if (modelTypeName === undefined || id === null) {
			throw new Error("View-model cache failed due to missing model type-name or model id");
		}
		const key = modelTypeName + id;
		const cached = this.viewModelCache.get(key);
		if (cached !== undefined) {
			return cached;
		}
		const newViewModel = factory(model);
		this.viewModelCache.set(key, newViewModel);
		return newViewModel;
	}

	private fromObservableFactbookEntries(fromEntry: Observable<Factbook[]>) {
		return fromEntry.pipe(map(factbookGroup => {
			const first = factbookGroup[0];
			return this.cacheGetOrAdd(first, first.id, factbook => {
				// assign the properties
				const properties: { [key: string]: any } = {};
				for (const factbookGroupMember of factbookGroup) {
					properties[factbookGroupMember.reportFileType || ''] = factbookGroupMember.reportFile;
				}
				return new ReportViewModel(
					factbook,
					factbook.id || '',
					[factbook.institution, factbook.institutionType, factbook.academicYear, "Factbook"].join(' '),
					factbook.name,
					'',
					new Hyperlink(),
					null,
					['Factbook'],
					properties,
					[factbook.institution, factbook.institutionType, factbook.academicYear, "Factbook"].filter((x): x is string => typeof x === 'string'),
					factbook.getLastRefresh(),
					null,
					"en",
					'Factbooks', 
				);
			});
		}), toArray());
	}

	private pirlGroupBy(reportType: PirlReportType, reports: Factbook[]): Observable<ReportViewModel[]> {
		const reports$ = from(reports).pipe(
			groupBy(
				x => [x.institution, x.academicYear].join()
			),
			mergeMap(
				x => x.pipe(
					toArray()
				)
			)
		);
		return this.getPirlReportViewModel(reports$, reportType);
	}

	private getPirlReportViewModel(fromEntry: Observable<Factbook[]>, reportType: PirlReportType) {
		return fromEntry.pipe(map(reportGroup => {
			const first = reportGroup[0];
			return this.cacheGetOrAdd(first, `${reportType.title}-${first.id}`, report => {
				// assign the properties
				const properties: { [key: string]: any } = {};
				for (const reportGroupMember of reportGroup) {
					properties[reportGroupMember.reportFileType || ''] = reportGroupMember.reportFile;
				}
				return new ReportViewModel(
					// model
					report,
					// id
					report.id || '',
					// title
					[report.institution, report.institutionType, report.academicYear, reportType.title].join(' '),
					// code
					report.name,
					//description
					this.getReportMetadataDecription(reportType, report),
					// dataOwner
					this.getReportMetadataDataOwner(reportType),
					//url
					null,
					// categoryKeys
					[ reportType.title ],
					// properties
					properties,
					// tags
					this.getReportMetadataTags(reportType, report)
						.concat(reportGroup.map(y => y.reportFileType === null ? null : y.reportFileType.toLocaleUpperCase()))
						.filter((x): x is string => typeof x === 'string'),
					// published
					report.getLastRefresh(),
					// status
					null,
					// lang
					"en",
					// pirlReportCode
					reportType.code,
					// downloadableFiles
					reportGroup.map(x => ({ 
						type: x.reportFileType, 
						url: x.reportFile, 
						name: x.name,
					} as DownloadableFile)),
				);
			});
		}), toArray());
	}

	fromNewsEntry(newsEntry: NewsEntry): ReportViewModel {
		// assign the properties
		const properties: { [key: string]: any } = {};
		properties[newsEntry.Attachments || ''] = newsEntry.Attachments;
		return new ReportViewModel(
			newsEntry,
			newsEntry.ID || '',
			newsEntry.title,
			null,
			newsEntry.content,
			new Hyperlink(),
			null,
			['News'],
			properties,
			[],
			newsEntry.getDateField('activeDate'),
			null,
			"en"
		);
	}

	public fromReport(report: Report) {
		return this.cacheGetOrAdd(report, report.id, report => {
			// assign the properties
			const properties: { [key: string]: any } = {};
			for (const metadataFieldName of GraphReportLikeService.reportMetadataFields.filter(x => report[x])) {
				properties[metadataFieldName] = report[metadataFieldName];
			}
			return new ReportViewModel(
				report,
				report.id || "",
				report.title || "",
				report.code || "",
				report.description || "",
				report.dataOwner || new Hyperlink(),
				report.url || new Hyperlink(),
				report.categories || [],
				properties,
				report.tags || [],
				report.getLastRefresh(),
				report.status,
				report.languageCode || ""
			);
		});
	}

	isExpiredNewsEntry(item: ReportViewModel): boolean {
		// Bug 28973: News Items still available to users more than 4 months after posting
		if (item.categoryKeys.indexOf('News') === -1) return false; // not a news

		return (this.isExpiredSearchableViewModel(item));
	}

	isExpiredSearchableViewModel(item: SearchableViewModel): boolean {
		const minDateToBeDisplayed = this.getNewsMinDateToBeDisplayed();
		return item.model.activeDate != null && new Date(item.model.activeDate) < minDateToBeDisplayed;
	}

	getNewsMinDateToBeDisplayed() {
		// Bug 28973: News Items still available to users more than 4 months after posting
		// news active date must not be earlier then 4 months ago to be diplayed
		const minDateToBeDisplayed = new Date();
		minDateToBeDisplayed.setMonth(minDateToBeDisplayed.getMonth() - 4);

		return minDateToBeDisplayed;
	}

	private getReportMetadataDecription(reportType: PirlReportType, report: Factbook): string {
		if (reportType.code === PirlReportTypeCodes.CAP_OPER_GRANTS) {
			if(report.institutionType === 'College' || report.institutionType === 'University') {
				return this.translateService.instant('Factbooks.PIRL.Reports_Metadata.CAP_OPER_GRANTS.Description_Colleges_Universities');
			} else if(report.institutionType === 'Indigenous Institute') {
				return this.translateService.instant('Factbooks.PIRL.Reports_Metadata.CAP_OPER_GRANTS.Description_Indigenous_Institutes');
			}
		}

		return "";
	}

	private getReportMetadataDataOwner(reportType: PirlReportType): Hyperlink {
		if (reportType.code === PirlReportTypeCodes.CAP_OPER_GRANTS) {
			return new Hyperlink('https://www.infogo.gov.on.ca/org?id=4758', 'Postsecondary Finance and Transfer Payment Branch');
		}

		return new Hyperlink();
	}

	private getReportMetadataTags(reportType: PirlReportType, report: Factbook): string[] {
		const tags: string[] = [
			report.institution, 
			report.institutionType, 
			report.academicYear, 
			reportType.title, 
			'Operating', 
			'Operational', 
			'Grant', 
			'Funding', 
			'Institution'
		];

		if (reportType.code === PirlReportTypeCodes.CAP_OPER_GRANTS) {
			if(report.institutionType === 'College' || report.institutionType === 'University') {
				tags.push('Capital');
				if(report.institutionType === 'College') {
					tags.push('College');
				} else if(report.institutionType === 'University') {
					tags.push('University');
				}	
			} else if(report.institutionType === 'Indigenous Institute') {
				tags.push('Indigenous Institute');
			}
		}

		return tags;
	}
}
