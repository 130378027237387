import { ModalPopupComponent } from './../modal-popup/modal-popup.component';
import { MatDialog } from '@angular/material/dialog';
import { Component, OnDestroy } from '@angular/core';
import { SafeUrl } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { Subscription, Subject, Observable } from 'rxjs';
import { GraphVideoService, IVideoData } from 'src/app/services/graph/video.service';
import { catchError } from 'rxjs/operators';

@Component({
	selector: 'app-modal-orientation',
	templateUrl: './modal-orientation.component.html',
	styleUrls: ['./modal-orientation.component.scss']
})
export class ModalOrientationComponent implements OnDestroy {
	public readonly videoSrc$: Subject<SafeUrl>;
	public readonly videoData$: Observable<IVideoData>;
	public captionSrc: SafeUrl;
	public numberOfSteps = 4;
	private videoSrcSubscription$: Subscription = Subscription.EMPTY;
	
	showVideo = false;
	videoGroup = "Open SIMS Training";

	constructor(
		private translateService: TranslateService,
		private dialog: MatDialog,
		private readonly videoService: GraphVideoService,
	) {
		this.videoData$ = this.videoService.getVideoData(this.videoGroup).pipe(
			catchError(error => {
				this.dialog.open(ModalPopupComponent, {
					width: 'rem(300px)',
					panelClass: 'popup',
					data: {
						popupMessage: this.translateService.instant('Error.CannotAccessVideo'),
						popupOk: this.translateService.instant('Common.Controls.Ok'),
						reloadOnClose: false,
						closeWindowOnOk: false
					}
				});
				this.showVideo = false;
				throw error;
			})
		);

		this.videoSrc$ = this.videoService.getVideoFile(this.videoGroup);
	}

	hasTranslation(key: string): boolean {
		return this.translateService.instant(key) !== key;
	}

	toggleVideo() {
		this.showVideo = true;
	}

	ngOnDestroy(): void {
		this.videoSrcSubscription$.unsubscribe();
	}
}
