import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { Hyperlink } from 'src/app/models/hyperlink.model';
import { Institution } from "src/app/models/institution.model";
import { GraphService, ColumnMapEntry } from './graph.service';
import { ResourceNames } from "./resource-names";

@Injectable({
	providedIn: 'root'
})
export class GraphInstitutionService {
	private institutions: BehaviorSubject<Institution[]> = new BehaviorSubject<Institution[]>([]);

	constructor(
		private graphService: GraphService
	) {

	}

	getInstitutions(): Observable<Institution[]> {
		if (this.institutions.value.length > 0) {
			return this.institutions;
		}

		const entities = this.graphService.getListItems<Institution>(GraphInstitutionService.listName, Institution, GraphInstitutionService.columnMap);
		// filter the sharepoint data using the current language according to the language service

		entities.subscribe((result) => {
			this.institutions.next(result);
		});
		return this.institutions;
	}

	static readonly listName = ResourceNames.INSTITUTIONS_LIST_NAME;

	static readonly columnMap: ReadonlyArray<ColumnMapEntry<Institution>> = [
		{ modelKey: "name", spFriendlyFieldName: "Title" },
		{ modelKey: "displayName", spFriendlyFieldName: "DisplayName", },
		{
			modelKey: "logoImageSource", spFriendlyFieldName: ["FileRef"], transformer: (x: string) => {
				if (x === null) {
					return null;
				}
				const parts = x[0].split('/');
				return new Hyperlink('/assets/img/institutions/' + parts[parts.length - 1], 'img')
			}
		},
		{ modelKey: "institutionType", spFriendlyFieldName: "InstitutionType", },
	];

}
