import { ReportViewModel } from './../view-models/report-view-model';
import { SearchableViewModel } from './../view-models/searchable-view-model';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

interface SearchIndex<T> {
	search(pattern: string, type: string): T[];
}

@Injectable({
	providedIn: 'root'
})
export class SearchIndexService {
	public readonly index: Observable<SearchIndex<ReportViewModel>>;

	constructor(
		items: Observable<SearchableViewModel[]>,
	) {
		this.index = items.pipe(
			map(searchableViewModels => {
				return {
					search(pattern: string, type: string = "p"): ReportViewModel[] {

						// Pre-compiled regext matcher. About 2X as fast as array.filter, albeit a bit more complicated.
						const hasValue = f => rep => {

							const exactTitle = rep.title.toLowerCase() === pattern.toLowerCase() ? 1 : 0;

							if (type === 'f') {
								return exactTitle;
							}

							const exactDesc = rep.description.toLowerCase() === pattern.toLowerCase() ? 1 : 0;
							const titleCount = f(rep.title);
							const tagCount = f(rep.tags.join(' '));
							const descCount = f(rep.description);

							// NB: exact hits in tag are caught by partial hits in tag. Exact tag hits do not supercede anything.
							return Number("" + exactTitle + exactDesc + titleCount + descCount + tagCount);
						};

						const toReg = str => new RegExp(str.replace(/\//g, '//').replace(/\s+/g, '|'), 'gi');
						const match = reg => x => ((x || '').match(reg) || []).length;

						// filter an array with a predicate
						const filter = f => a => {
							const ret = [];
							const l = a.length;
							for (let i = 0; i < l; i++) {
								a[i].properties.sort = f(a[i]);
								if (a[i].properties.sort > 0) {
									ret.push(a[i]);
								}
							}

							ret.sort((first, second) => {
								const compareScore = first.properties.sort === second.properties.sort ? 0 : first.properties.sort < second.properties.sort ? 1 : -1;

								if (compareScore === 0) {
									const compareTitle = first.title.localeCompare(second.title);
									return compareScore || compareTitle;
								}

								return compareScore;
							});

							return ret;
						}

						const filterArrByValue = value => {
							// create a regular expression based on your search value
							// cache it for all filter iterations
							const reg = toReg(value)
							// filter the array of report view models
							return filter(
								// only return the results that match the regex
								hasValue(match(reg))
							);
						};
						return (filterArrByValue(pattern.trim()))(searchableViewModels);
					}
				};
			})
		);

	}
}
