<mat-sidenav-container fxFlex="0 1 auto" [ngClass]="{'app-light-theme': !themeService.isDark, 'app-dark-theme': themeService.isDark}">
	<div class="table-container">
		<table mat-table *ngIf="dataSource" [dataSource]="dataSource" matSort>
			<!-- subjectAreaTitle -->
			<ng-container matColumnDef="subjectAreaTitle">
				<mat-header-cell *matHeaderCellDef mat-sort-header> {{'DataFilesManagement.SubjectArea' | translate}} </mat-header-cell>
				<mat-cell *matCellDef="let element"> {{element.subjectAreaTitle}} </mat-cell>
			</ng-container>
			<!-- sourceFileName -->
			<ng-container matColumnDef="sourceFileName">
				<mat-header-cell *matHeaderCellDef mat-sort-header> {{'ReportRefresh.ExpectedFileLabel' | translate}} </mat-header-cell>
				<mat-cell *matCellDef="let element">
					<div class="croppedTexts" title="{{element.sourceFileName}}" [innerHTML]="element.sourceFileName | newline"></div>
					<mat-icon tabindex="0" *ngIf="element.sharedWith" (click)="displaySharedReports(element)" class="icon-warning" title="{{'ReportRefresh.SubjectAreaShared' | translate}}">warning_amber</mat-icon> 
				</mat-cell>
			</ng-container>
			<!-- providedFileName -->
			<ng-container matColumnDef="providedFileName">
				<mat-header-cell *matHeaderCellDef mat-sort-header> {{'ReportRefresh.ProvidedFileLabel' | translate}} </mat-header-cell>
				<mat-cell *matCellDef="let element">
					<div class="croppedTexts" title="{{element.providedFileName}}" [innerHTML]="element.providedFileName | newline"></div>
				</mat-cell>
			</ng-container>
			<!-- uploadedDate -->
			<ng-container matColumnDef="uploadedDate">
				<mat-header-cell *matHeaderCellDef> {{'ApprovalWorkflow.UploadedDate' | translate}} </mat-header-cell>
				<mat-cell *matCellDef="let element"> 
					<div title="{{element.uploadedDate}}" [innerHTML]="element.uploadedDate | newline | dateFormatted:'mediumCustom'"></div>
				</mat-cell>
			</ng-container>
			
			<mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
			<mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
		</table>
		<div class="files-paginator">
			<mat-paginator [length]="dataSource.reports.length" [pageSize]="10" [pageSizeOptions]="[5, 10, 25]"></mat-paginator>
		</div>
	</div>
</mat-sidenav-container>