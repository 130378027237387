<div class="margin" [ngClass]="{'app-light-theme': !themeService.isDark, 'app-dark-theme': themeService.isDark}">
    <div class="info">
        <h1 id="maincontent" tabindex="0" role="note">{{'Accessibility.Title' | translate}}</h1>
        <p tabindex="0" [innerHTML]="'Accessibility.InfoParagraph' | translate" role="note"></p>
    </div>
    <mat-divider class="divider" *ngIf="themeService.isDark"></mat-divider>
    <div class="contact">
        <h1 tabindex="0" role="note">{{'Accessibility.ContactHeader' | translate}}</h1>
        <h2 tabindex="0" [innerHTML]="'Accessibility.ContactName' | translate" role="note"></h2>
        <div class="information" fxLayout="column" fxLayout.gt-md="row">
            <p [innerHTML]="'Accessibility.ContactParagraph' | translate" role="note"></p>
            <div class="contactInfo" fxLayout="column" fxLayout.gt-sm="row">
                <div class="email">
                    <mat-icon class="icon">mail_outline</mat-icon>
                    <p tabindex="0"> {{'Accessibility.Email' | translate}}
                        <a href="mailto:Matthew.Clarke@ontario.ca"><u>{{'Accessibility.ContactEmail' | translate}}</u></a>
                    </p>
                </div>
                <div class="phone">
                    <mat-icon class="icon">call</mat-icon>
                    <p tabindex="0" role="note"> {{'Accessibility.Phone' | translate}}
                        <a href="tel:{{getRawPhone()}}">{{'Accessibility.ContactPhone' | translate}}</a>
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>