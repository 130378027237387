
import { getPropertyNames } from "src/app/property-names";
import { TypeName } from "src/app/type-name";
import { Hyperlink } from "./hyperlink.model";

@TypeName.decorate("ReportType")
export class ReportType  {
	public constructor(
		public readonly name: null | string = null,
		public readonly reportTypeCode: null | string = null,
		public readonly titleEn: null | string = null,
		public readonly titleFr: null | string = null,
	) {}

	public static readonly defaultPropertyNames = getPropertyNames(new ReportType());
}

export interface PirlReportType {
	code: string;
	title: string;
}