import { Component, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { NewsEntry } from 'src/app/models/news-entry.model';
import { GraphNewsService } from 'src/app/services/graph/news.service';
import { GraphReportLikeService } from 'src/app/services/graph/report-like.service';
import { ReportViewModel } from 'src/app/view-models/report-view-model';
import { ThemeService } from './../../shared/services/theme.service';

@Component({
	selector: 'app-latest-reports-and-news',
	templateUrl: './latest-reports-and-news.component.html',
	styleUrls: ['./latest-reports-and-news.component.scss']
})

export class LatestReportsAndNewsComponent implements OnDestroy {
	recentReports: ReportViewModel[] = [];
	recentNews: NewsEntry[] = [];
	subscriptions$: Subscription[] = [];

	constructor(
		private readonly reportLikeService: GraphReportLikeService,
		private readonly newsService: GraphNewsService,
		public themeService: ThemeService
	) {
		const minDateToBeDisplayed = this.reportLikeService.getNewsMinDateToBeDisplayed();

		this.subscriptions$.push(reportLikeService.reports.subscribe(reports => this.recentReports = reports.filter(report => report.status === 'Final' && report.published > minDateToBeDisplayed)));
		this.subscriptions$.push(newsService.getNewsEntries().subscribe(news => {
			// Get all news items that either have: no active date or the active date is less than 4 months old
			// and
			// no expiry date, or expiry date is after today
			this.recentNews = news.filter( n => (!n.activeDate || new Date(n.activeDate) > minDateToBeDisplayed) && (!n.expiryDate  || new Date(n.expiryDate) > new Date()));
		}));

	 }

	 ngOnDestroy() {
		 this.subscriptions$.map(a=>a.unsubscribe());
	 }

}
