<mat-sidenav-container fxFlex="0 1 auto" [ngClass]="{'app-light-theme': !themeService.isDark, 'app-dark-theme': themeService.isDark}">
	<div class="table-container">
		<table mat-table *ngIf="dataSource" [dataSource]="dataSource" matSort>
			<!-- id -->
			<ng-container matColumnDef="id">
				<mat-header-cell *matHeaderCellDef mat-sort-header> ID </mat-header-cell>
				<mat-cell *matCellDef="let element"> {{element.id}} </mat-cell>
			</ng-container>
			<!-- name -->
			<ng-container matColumnDef="name">
				<mat-header-cell *matHeaderCellDef>
					<mat-form-field>
						<input matInput class="form-field" [formControl]="fileNameFilter">
						<mat-placeholder class="filter-placeholder">{{'ApprovalWorkflow.ColumnName' | translate}}</mat-placeholder>
					</mat-form-field>
				</mat-header-cell>
				<mat-cell *matCellDef="let element"> <div class="croppedTexts" title="{{element.name}}">{{element.name}}</div> </mat-cell>
			</ng-container>
			<!-- subjectArea -->
			<ng-container matColumnDef="subjectAreaName">
				<mat-header-cell *matHeaderCellDef>
					<mat-form-field>
						<input matInput class="form-field" [formControl]="subjectAreaFilter">
						<mat-placeholder class="filter-placeholder">{{'DataFilesManagement.SubjectArea' | translate}}</mat-placeholder>
					</mat-form-field>
				</mat-header-cell>
				<mat-cell *matCellDef="let element"> {{element.subjectAreaName}} </mat-cell>
			</ng-container>
			<!-- comment -->
			<ng-container matColumnDef="status">
				<mat-header-cell *matHeaderCellDef mat-sort-header> {{'DataFilesManagement.PipelineStatus' | translate}} </mat-header-cell>
				<mat-cell *matCellDef="let element"> 
					<mat-icon *ngIf="isOk(element.status)" class="icon-ok">file_download_done</mat-icon> 
					<mat-icon *ngIf="isFailed(element.status)" class="icon-error">error_outline</mat-icon> 
					<mat-icon *ngIf="isProcessing(element.status)">cached</mat-icon> 
					<mat-icon *ngIf="isPending(element.status)" class="icon-pending">pending_actions</mat-icon> 
					{{element.status}} 
				</mat-cell>
			</ng-container>
			<!-- createdBy -->
			<ng-container matColumnDef="createdBy">
				<mat-header-cell *matHeaderCellDef mat-sort-header> {{'DataFilesManagement.CreatedBy' | translate}} </mat-header-cell>
				<mat-cell *matCellDef="let element"> {{element.createdBy}} </mat-cell>
			</ng-container>
			<!-- uploadedDate -->
			<ng-container matColumnDef="uploadedDate">
				<mat-header-cell *matHeaderCellDef mat-sort-header> {{'ApprovalWorkflow.UploadedDate' | translate}} </mat-header-cell>
				<mat-cell *matCellDef="let element"> {{element.uploadedDate | dateFormatted:'mediumCustom' }} </mat-cell>
			</ng-container>
			<!-- comment -->
			<ng-container matColumnDef="comment">
				<mat-header-cell *matHeaderCellDef mat-sort-header>{{'DataFilesManagement.Comment' | translate}}</mat-header-cell>
				<mat-cell *matCellDef="let element"> <div title="{{element.comment}}">{{element.comment}}</div> </mat-cell>
			</ng-container>
			<mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
			<mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
		</table>
		<div class="files-paginator">
			<mat-paginator [length]="dataSource.filteredCount" [pageSize]="5" [pageSizeOptions]="[5, 10, 25]"></mat-paginator>
		</div>
	</div>
</mat-sidenav-container>